@import url(https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0b1 | 201101
   NOTE: WORK IN PROGRESS
   USE WITH CAUTION AND TEST WITH ABANDON */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

/* remember to define visible focus styles!
:focus {
    outline: ?????;
} */

/* remember to highlight inserts somehow! */

ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

input,
textarea,
keygen,
select,
button {
    font-size: 13px;
    font-family: "open_sansregular";
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.svg-container {
    display: inline-block;
}
.default-svg-icon {
    height: 24px;
    width: 24px;
}
.with-masked-container .loader-container {
        position: fixed;
        top: 0;
        left: 0;
    }
.dialog-container.delete-confirm-container .dialog-box-body.restricted {
        min-height: 65px;
    }
.dialog-container.delete-confirm-container .dialog-delete-confirm {
        display: inline;
        overflow: hidden;
        text-align: left;
        min-height: 76px;
        width: 750px;
        padding: 10px;
        line-height: 25px
    }
.dialog-container.delete-confirm-container .dialog-delete-confirm span {
            font-size: 20px;
            padding-right: 10px;
            padding-left: 10px;
        }
.default .default-svg-icon {
            fill: #ffffff
        }
.default .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #ffffff;
            }
.default .default-svg-icon.inverted-svg-icon {
                fill: #ffffff;
                stroke: #000000;
            }
.default .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.default-light .default-svg-icon {
            fill: #000000
        }
.default-light .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #000000;
            }
.default-light .default-svg-icon.inverted-svg-icon {
                fill: #000000;
                stroke: #ffffff;
            }
.default-light .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.sky .default-svg-icon {
            fill: #000000
        }
.sky .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #000000;
            }
.sky .default-svg-icon.inverted-svg-icon {
                fill: #000000;
                stroke: #ffffff;
            }
.sky .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.sky-light .default-svg-icon {
            fill: #000000
        }
.sky-light .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #000000;
            }
.sky-light .default-svg-icon.inverted-svg-icon {
                fill: #000000;
                stroke: #ffffff;
            }
.sky-light .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.grass .default-svg-icon {
            fill: #ffffff
        }
.grass .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #ffffff;
            }
.grass .default-svg-icon.inverted-svg-icon {
                fill: #ffffff;
                stroke: #000000;
            }
.grass .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.grass-light .default-svg-icon {
            fill: #000000
        }
.grass-light .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #000000;
            }
.grass-light .default-svg-icon.inverted-svg-icon {
                fill: #000000;
                stroke: #ffffff;
            }
.grass-light .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.mud .default-svg-icon {
            fill: #ffffff
        }
.mud .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #ffffff;
            }
.mud .default-svg-icon.inverted-svg-icon {
                fill: #ffffff;
                stroke: #000000;
            }
.mud .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.mud-light .default-svg-icon {
            fill: #000000
        }
.mud-light .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #000000;
            }
.mud-light .default-svg-icon.inverted-svg-icon {
                fill: #000000;
                stroke: #ffffff;
            }
.mud-light .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.illini .default-svg-icon {
            fill: #ffffff
        }
.illini .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #ffffff;
            }
.illini .default-svg-icon.inverted-svg-icon {
                fill: #ffffff;
                stroke: #000000;
            }
.illini .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }
.illini-light .default-svg-icon {
            fill: #000000
        }
.illini-light .default-svg-icon.border-only-svg-icon {
                fill: transparent;
                stroke: #000000;
            }
.illini-light .default-svg-icon.inverted-svg-icon {
                fill: #000000;
                stroke: #ffffff;
            }
.illini-light .dialog-delete-confirm span {
            color: #000000;
            background-color: #ffff00;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.form-input {
    height: 35px;
    border-radius: 4px 4px 4px 4px;
    padding: 3px 3px;
    margin: 10px;
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300
}
.form-input input {
        font-family: "open_sansregular";
        margin-top: 1px;
    }
.form-input textarea {
        font-family: "open_sansregular";
    }
.form-input :-ms-input-placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }
.form-input ::placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }
.form-input.error {
        height: 45px
    }
.form-input.error .input-label-container {
            height: 11px;
        }
.form-input .disabled.required-star,
        .form-input .disabled .expand-contract-arrow {
            opacity: 0.6;
        }
.default .form-input {
        background-color: #bfbfbf;
        border: 2px solid #bfbfbf;
        color: #000000
    }
.default .form-input input {
            background-color: #bfbfbf;
            color: #000000;
        }
.default .form-input textarea {
            background-color: #bfbfbf;
            color: #000000;
        }
.default .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #bfbfbf inset;
        }
.default .form-input.focus {
            border: 2px solid #8fa61a;
        }
.default .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.default .form-input :enabled::placeholder {
            color: #000000;
        }
.default .form-input.error {
            border: 2px solid #ff0000;
        }
.default .form-input .error-text {
            color: #ff0000;
        }
.default .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.default .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.default .form-input .disabled input {
            color: #777777 !important;
        }
.default .form-input input:disabled {
            color: #777777 !important;
        }
.default .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.default .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.default-light .form-input {
        background-color: #cfcfcf;
        border: 2px solid #cfcfcf;
        color: #ffffff
    }
.default-light .form-input input {
            background-color: #cfcfcf;
            color: #ffffff;
        }
.default-light .form-input textarea {
            background-color: #cfcfcf;
            color: #ffffff;
        }
.default-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #cfcfcf inset;
        }
.default-light .form-input.focus {
            border: 2px solid #8fa61a;
        }
.default-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.default-light .form-input :enabled::placeholder {
            color: #000000;
        }
.default-light .form-input.error {
            border: 2px solid #ff0000;
        }
.default-light .form-input .error-text {
            color: #ff0000;
        }
.default-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.default-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.default-light .form-input .disabled input {
            color: #777777 !important;
        }
.default-light .form-input input:disabled {
            color: #777777 !important;
        }
.default-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.default-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.sky .form-input {
        background-color: #c8c8c8;
        border: 2px solid #c8c8c8;
        color: #ffffff
    }
.sky .form-input input {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky .form-input textarea {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #c8c8c8 inset;
        }
.sky .form-input.focus {
            border: 2px solid #235635;
        }
.sky .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.sky .form-input :enabled::placeholder {
            color: #000000;
        }
.sky .form-input.error {
            border: 2px solid #ff0000;
        }
.sky .form-input .error-text {
            color: #ff0000;
        }
.sky .form-input .disabled .input-label-container {
            color: #878787 !important;
        }
.sky .form-input .input-label-container .disabled-text {
            color: #878787 !important;
        }
.sky .form-input .disabled input {
            color: #878787 !important;
        }
.sky .form-input input:disabled {
            color: #878787 !important;
        }
.sky .form-input input:disabled:-ms-input-placeholder {
            color: #878787 !important;
        }
.sky .form-input input:disabled::placeholder {
            color: #878787 !important;
        }
.sky-light .form-input {
        background-color: #c8c8c8;
        border: 2px solid #c8c8c8;
        color: #ffffff
    }
.sky-light .form-input input {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky-light .form-input textarea {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #c8c8c8 inset;
        }
.sky-light .form-input.focus {
            border: 2px solid #235635;
        }
.sky-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.sky-light .form-input :enabled::placeholder {
            color: #000000;
        }
.sky-light .form-input.error {
            border: 2px solid #ff0000;
        }
.sky-light .form-input .error-text {
            color: #ff0000;
        }
.sky-light .form-input .disabled .input-label-container {
            color: #878787 !important;
        }
.sky-light .form-input .input-label-container .disabled-text {
            color: #878787 !important;
        }
.sky-light .form-input .disabled input {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled:-ms-input-placeholder {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled::placeholder {
            color: #878787 !important;
        }
.grass .form-input {
        background-color: #9cadb2;
        border: 2px solid #9cadb2;
        color: #000000
    }
.grass .form-input input {
            background-color: #9cadb2;
            color: #000000;
        }
.grass .form-input textarea {
            background-color: #9cadb2;
            color: #000000;
        }
.grass .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #667c66 !important;
            -webkit-box-shadow: 0 0 0px 1000px #9cadb2 inset;
        }
.grass .form-input.focus {
            border: 2px solid #ffff00;
        }
.grass .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.grass .form-input :enabled::placeholder {
            color: #000000;
        }
.grass .form-input.error {
            border: 2px solid #ff0000;
        }
.grass .form-input .error-text {
            color: #ff0000;
        }
.grass .form-input .disabled .input-label-container {
            color: #e5e9e5 !important;
        }
.grass .form-input .input-label-container .disabled-text {
            color: #e5e9e5 !important;
        }
.grass .form-input .disabled input {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled:-ms-input-placeholder {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled::placeholder {
            color: #e5e9e5 !important;
        }
.grass-light .form-input {
        background-color: #424443;
        border: 2px solid #424443;
        color: #ffffff
    }
.grass-light .form-input input {
            background-color: #424443;
            color: #ffffff;
        }
.grass-light .form-input textarea {
            background-color: #424443;
            color: #ffffff;
        }
.grass-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #babfba !important;
            -webkit-box-shadow: 0 0 0px 1000px #424443 inset;
        }
.grass-light .form-input.focus {
            border: 2px solid #ffff00;
        }
.grass-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.grass-light .form-input :enabled::placeholder {
            color: #000000;
        }
.grass-light .form-input.error {
            border: 2px solid #ff0000;
        }
.grass-light .form-input .error-text {
            color: #ff0000;
        }
.grass-light .form-input .disabled .input-label-container {
            color: #e5e9e5 !important;
        }
.grass-light .form-input .input-label-container .disabled-text {
            color: #e5e9e5 !important;
        }
.grass-light .form-input .disabled input {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled:-ms-input-placeholder {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled::placeholder {
            color: #e5e9e5 !important;
        }
.mud .form-input {
        background-color: #f2f3f8;
        border: 2px solid #f2f3f8;
        color: #000000
    }
.mud .form-input input {
            background-color: #f2f3f8;
            color: #000000;
        }
.mud .form-input textarea {
            background-color: #f2f3f8;
            color: #000000;
        }
.mud .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #4b3705 !important;
            -webkit-box-shadow: 0 0 0px 1000px #f2f3f8 inset;
        }
.mud .form-input.focus {
            border: 2px solid #ffff00;
        }
.mud .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.mud .form-input :enabled::placeholder {
            color: #000000;
        }
.mud .form-input.error {
            border: 2px solid #ff0000;
        }
.mud .form-input .error-text {
            color: #ff0000;
        }
.mud .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.mud .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.mud .form-input .disabled input {
            color: #777777 !important;
        }
.mud .form-input input:disabled {
            color: #777777 !important;
        }
.mud .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.mud .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.mud-light .form-input {
        background-color: #d6c282;
        border: 2px solid #d6c282;
        color: #ffffff
    }
.mud-light .form-input input {
            background-color: #d6c282;
            color: #ffffff;
        }
.mud-light .form-input textarea {
            background-color: #d6c282;
            color: #ffffff;
        }
.mud-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fff0bb !important;
            -webkit-box-shadow: 0 0 0px 1000px #d6c282 inset;
        }
.mud-light .form-input.focus {
            border: 2px solid #8fa61a;
        }
.mud-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.mud-light .form-input :enabled::placeholder {
            color: #000000;
        }
.mud-light .form-input.error {
            border: 2px solid #ff0000;
        }
.mud-light .form-input .error-text {
            color: #ff0000;
        }
.mud-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.mud-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.mud-light .form-input .disabled input {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.illini .form-input {
        background-color: #f2f3f8;
        border: 2px solid #f2f3f8;
        color: #000000
    }
.illini .form-input input {
            background-color: #f2f3f8;
            color: #000000;
        }
.illini .form-input textarea {
            background-color: #f2f3f8;
            color: #000000;
        }
.illini .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #00467f !important;
            -webkit-box-shadow: 0 0 0px 1000px #f2f3f8 inset;
        }
.illini .form-input.focus {
            border: 2px solid #ffff00;
        }
.illini .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.illini .form-input :enabled::placeholder {
            color: #000000;
        }
.illini .form-input.error {
            border: 2px solid #ff0000;
        }
.illini .form-input .error-text {
            color: #ff0000;
        }
.illini .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.illini .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.illini .form-input .disabled input {
            color: #777777 !important;
        }
.illini .form-input input:disabled {
            color: #777777 !important;
        }
.illini .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.illini .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.illini-light .form-input {
        background-color: #abc8e2;
        border: 2px solid #abc8e2;
        color: #ffffff
    }
.illini-light .form-input input {
            background-color: #abc8e2;
            color: #ffffff;
        }
.illini-light .form-input textarea {
            background-color: #abc8e2;
            color: #ffffff;
        }
.illini-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #e1e6fa !important;
            -webkit-box-shadow: 0 0 0px 1000px #abc8e2 inset;
        }
.illini-light .form-input.focus {
            border: 2px solid #ffff00;
        }
.illini-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.illini-light .form-input :enabled::placeholder {
            color: #000000;
        }
.illini-light .form-input.error {
            border: 2px solid #ff0000;
        }
.illini-light .form-input .error-text {
            color: #ff0000;
        }
.illini-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.illini-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.illini-light .form-input .disabled input {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.sky .form-input {
        color: #000000
    }
.sky .form-input input {
            color: #000000;
        }
.sky .form-input textarea {
            color: #000000;
        }
.sky .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
        }
.sky-light .form-input {
        color: #000000
    }
.sky-light .form-input input {
            color: #000000;
        }
.sky-light .form-input textarea {
            color: #000000;
        }
.sky-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.auto-search .select-form-input-container .select-form-input-options .select-form-input-option .first-line {
                font-size: 13px;
                padding-left: 21px;
                height: 50%
            }
.auto-search .select-form-input-container .select-form-input-options .select-form-input-option .first-line span {
                    margin-left: 0px;
                }
.auto-search .select-form-input-container .select-form-input-options .select-form-input-option .second-line {
                padding-left: 21px;
                font-size: 11px;
                font-family: "open_sansitalic"
            }
.auto-search .select-form-input-container .select-form-input-options .select-form-input-option .second-line span {
                    margin-left: 0px;
                }
.auto-search .search-icon {
        width: 13px;
        height: 13px;
        padding-top: 4px;
    }
.default .auto-search .search-icon {
            fill: #000000;
        }
.default-light .auto-search .search-icon {
            fill: #000000;
        }
.sky .auto-search .search-icon {
            fill: #000000;
        }
.sky-light .auto-search .search-icon {
            fill: #000000;
        }
.grass .auto-search .search-icon {
            fill: #000000;
        }
.grass-light .auto-search .search-icon {
            fill: #000000;
        }
.mud .auto-search .search-icon {
            fill: #000000;
        }
.mud-light .auto-search .search-icon {
            fill: #000000;
        }
.illini .auto-search .search-icon {
            fill: #000000;
        }
.illini-light .auto-search .search-icon {
            fill: #000000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.form-input {
    height: 35px;
    border-radius: 4px 4px 4px 4px;
    padding: 3px 3px;
    margin: 10px;
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300
}
.form-input input {
        font-family: "open_sansregular";
        margin-top: 1px;
    }
.form-input textarea {
        font-family: "open_sansregular";
    }
.form-input :-ms-input-placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }
.form-input ::placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }
.form-input.error {
        height: 45px
    }
.form-input.error .input-label-container {
            height: 11px;
        }
.form-input .disabled.required-star,
        .form-input .disabled .expand-contract-arrow {
            opacity: 0.6;
        }
.default .form-input {
        background-color: #bfbfbf;
        border: 2px solid #bfbfbf;
        color: #000000
    }
.default .form-input input {
            background-color: #bfbfbf;
            color: #000000;
        }
.default .form-input textarea {
            background-color: #bfbfbf;
            color: #000000;
        }
.default .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #bfbfbf inset;
        }
.default .form-input.focus {
            border: 2px solid #8fa61a;
        }
.default .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.default .form-input :enabled::placeholder {
            color: #000000;
        }
.default .form-input.error {
            border: 2px solid #ff0000;
        }
.default .form-input .error-text {
            color: #ff0000;
        }
.default .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.default .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.default .form-input .disabled input {
            color: #777777 !important;
        }
.default .form-input input:disabled {
            color: #777777 !important;
        }
.default .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.default .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.default-light .form-input {
        background-color: #cfcfcf;
        border: 2px solid #cfcfcf;
        color: #ffffff
    }
.default-light .form-input input {
            background-color: #cfcfcf;
            color: #ffffff;
        }
.default-light .form-input textarea {
            background-color: #cfcfcf;
            color: #ffffff;
        }
.default-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #cfcfcf inset;
        }
.default-light .form-input.focus {
            border: 2px solid #8fa61a;
        }
.default-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.default-light .form-input :enabled::placeholder {
            color: #000000;
        }
.default-light .form-input.error {
            border: 2px solid #ff0000;
        }
.default-light .form-input .error-text {
            color: #ff0000;
        }
.default-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.default-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.default-light .form-input .disabled input {
            color: #777777 !important;
        }
.default-light .form-input input:disabled {
            color: #777777 !important;
        }
.default-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.default-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.sky .form-input {
        background-color: #c8c8c8;
        border: 2px solid #c8c8c8;
        color: #ffffff
    }
.sky .form-input input {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky .form-input textarea {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #c8c8c8 inset;
        }
.sky .form-input.focus {
            border: 2px solid #235635;
        }
.sky .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.sky .form-input :enabled::placeholder {
            color: #000000;
        }
.sky .form-input.error {
            border: 2px solid #ff0000;
        }
.sky .form-input .error-text {
            color: #ff0000;
        }
.sky .form-input .disabled .input-label-container {
            color: #878787 !important;
        }
.sky .form-input .input-label-container .disabled-text {
            color: #878787 !important;
        }
.sky .form-input .disabled input {
            color: #878787 !important;
        }
.sky .form-input input:disabled {
            color: #878787 !important;
        }
.sky .form-input input:disabled:-ms-input-placeholder {
            color: #878787 !important;
        }
.sky .form-input input:disabled::placeholder {
            color: #878787 !important;
        }
.sky-light .form-input {
        background-color: #c8c8c8;
        border: 2px solid #c8c8c8;
        color: #ffffff
    }
.sky-light .form-input input {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky-light .form-input textarea {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #c8c8c8 inset;
        }
.sky-light .form-input.focus {
            border: 2px solid #235635;
        }
.sky-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.sky-light .form-input :enabled::placeholder {
            color: #000000;
        }
.sky-light .form-input.error {
            border: 2px solid #ff0000;
        }
.sky-light .form-input .error-text {
            color: #ff0000;
        }
.sky-light .form-input .disabled .input-label-container {
            color: #878787 !important;
        }
.sky-light .form-input .input-label-container .disabled-text {
            color: #878787 !important;
        }
.sky-light .form-input .disabled input {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled:-ms-input-placeholder {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled::placeholder {
            color: #878787 !important;
        }
.grass .form-input {
        background-color: #9cadb2;
        border: 2px solid #9cadb2;
        color: #000000
    }
.grass .form-input input {
            background-color: #9cadb2;
            color: #000000;
        }
.grass .form-input textarea {
            background-color: #9cadb2;
            color: #000000;
        }
.grass .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #667c66 !important;
            -webkit-box-shadow: 0 0 0px 1000px #9cadb2 inset;
        }
.grass .form-input.focus {
            border: 2px solid #ffff00;
        }
.grass .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.grass .form-input :enabled::placeholder {
            color: #000000;
        }
.grass .form-input.error {
            border: 2px solid #ff0000;
        }
.grass .form-input .error-text {
            color: #ff0000;
        }
.grass .form-input .disabled .input-label-container {
            color: #e5e9e5 !important;
        }
.grass .form-input .input-label-container .disabled-text {
            color: #e5e9e5 !important;
        }
.grass .form-input .disabled input {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled:-ms-input-placeholder {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled::placeholder {
            color: #e5e9e5 !important;
        }
.grass-light .form-input {
        background-color: #424443;
        border: 2px solid #424443;
        color: #ffffff
    }
.grass-light .form-input input {
            background-color: #424443;
            color: #ffffff;
        }
.grass-light .form-input textarea {
            background-color: #424443;
            color: #ffffff;
        }
.grass-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #babfba !important;
            -webkit-box-shadow: 0 0 0px 1000px #424443 inset;
        }
.grass-light .form-input.focus {
            border: 2px solid #ffff00;
        }
.grass-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.grass-light .form-input :enabled::placeholder {
            color: #000000;
        }
.grass-light .form-input.error {
            border: 2px solid #ff0000;
        }
.grass-light .form-input .error-text {
            color: #ff0000;
        }
.grass-light .form-input .disabled .input-label-container {
            color: #e5e9e5 !important;
        }
.grass-light .form-input .input-label-container .disabled-text {
            color: #e5e9e5 !important;
        }
.grass-light .form-input .disabled input {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled:-ms-input-placeholder {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled::placeholder {
            color: #e5e9e5 !important;
        }
.mud .form-input {
        background-color: #f2f3f8;
        border: 2px solid #f2f3f8;
        color: #000000
    }
.mud .form-input input {
            background-color: #f2f3f8;
            color: #000000;
        }
.mud .form-input textarea {
            background-color: #f2f3f8;
            color: #000000;
        }
.mud .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #4b3705 !important;
            -webkit-box-shadow: 0 0 0px 1000px #f2f3f8 inset;
        }
.mud .form-input.focus {
            border: 2px solid #ffff00;
        }
.mud .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.mud .form-input :enabled::placeholder {
            color: #000000;
        }
.mud .form-input.error {
            border: 2px solid #ff0000;
        }
.mud .form-input .error-text {
            color: #ff0000;
        }
.mud .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.mud .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.mud .form-input .disabled input {
            color: #777777 !important;
        }
.mud .form-input input:disabled {
            color: #777777 !important;
        }
.mud .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.mud .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.mud-light .form-input {
        background-color: #d6c282;
        border: 2px solid #d6c282;
        color: #ffffff
    }
.mud-light .form-input input {
            background-color: #d6c282;
            color: #ffffff;
        }
.mud-light .form-input textarea {
            background-color: #d6c282;
            color: #ffffff;
        }
.mud-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fff0bb !important;
            -webkit-box-shadow: 0 0 0px 1000px #d6c282 inset;
        }
.mud-light .form-input.focus {
            border: 2px solid #8fa61a;
        }
.mud-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.mud-light .form-input :enabled::placeholder {
            color: #000000;
        }
.mud-light .form-input.error {
            border: 2px solid #ff0000;
        }
.mud-light .form-input .error-text {
            color: #ff0000;
        }
.mud-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.mud-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.mud-light .form-input .disabled input {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.illini .form-input {
        background-color: #f2f3f8;
        border: 2px solid #f2f3f8;
        color: #000000
    }
.illini .form-input input {
            background-color: #f2f3f8;
            color: #000000;
        }
.illini .form-input textarea {
            background-color: #f2f3f8;
            color: #000000;
        }
.illini .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #00467f !important;
            -webkit-box-shadow: 0 0 0px 1000px #f2f3f8 inset;
        }
.illini .form-input.focus {
            border: 2px solid #ffff00;
        }
.illini .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.illini .form-input :enabled::placeholder {
            color: #000000;
        }
.illini .form-input.error {
            border: 2px solid #ff0000;
        }
.illini .form-input .error-text {
            color: #ff0000;
        }
.illini .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.illini .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.illini .form-input .disabled input {
            color: #777777 !important;
        }
.illini .form-input input:disabled {
            color: #777777 !important;
        }
.illini .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.illini .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.illini-light .form-input {
        background-color: #abc8e2;
        border: 2px solid #abc8e2;
        color: #ffffff
    }
.illini-light .form-input input {
            background-color: #abc8e2;
            color: #ffffff;
        }
.illini-light .form-input textarea {
            background-color: #abc8e2;
            color: #ffffff;
        }
.illini-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #e1e6fa !important;
            -webkit-box-shadow: 0 0 0px 1000px #abc8e2 inset;
        }
.illini-light .form-input.focus {
            border: 2px solid #ffff00;
        }
.illini-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.illini-light .form-input :enabled::placeholder {
            color: #000000;
        }
.illini-light .form-input.error {
            border: 2px solid #ff0000;
        }
.illini-light .form-input .error-text {
            color: #ff0000;
        }
.illini-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.illini-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.illini-light .form-input .disabled input {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.sky .form-input {
        color: #000000
    }
.sky .form-input input {
            color: #000000;
        }
.sky .form-input textarea {
            color: #000000;
        }
.sky .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
        }
.sky-light .form-input {
        color: #000000
    }
.sky-light .form-input input {
            color: #000000;
        }
.sky-light .form-input textarea {
            color: #000000;
        }
.sky-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
        }
div.text-form-input div.input-label-container {
        font-size: 10px;
        line-height: 7px;
        min-height: 7px;
        text-indent: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 10%;
        max-width: 100%
    }
div.text-form-input div.input-label-container .red-star {
            font-size: 15px;
            line-height: 10px;
            padding-right: 5px;
        }
div.text-form-input div.input-label-container.label-visible {
            line-height: 12px;
        }
div.text-form-input .input-container {
        display: flex;
        flex-direction: row
    }
div.text-form-input .input-container .required-star {
            font-size: 15px;
            line-height: 15px;
        }
div.text-form-input .input-container input {
            flex-grow: 1;
            min-width: 20px;
            height: 22px;
            border: none;
            outline: none;
            text-indent: 5px;
            text-overflow: ellipsis
        }
div.text-form-input .input-container input.hint-visible {
                line-height: 17px;
            }
div.text-form-input .input-container .clear-btn-icon {
            margin: 4px 0;
            padding: 2px;
            border: none;
            cursor: pointer
        }
div.text-form-input .input-container .clear-btn-icon .default-svg-icon {
                width: 15px;
                height: 15px;
            }
div.text-form-input .input-container:not(.focus):not(:hover) .clear-btn-icon {
                visibility: hidden;
            }
& [data-tooltipinput]::before {
    /* needed */
    content: attr(data-tooltipinput);
    position: absolute;
    opacity: 0;

    /* customizable */
    white-space: pre-line;
}
& [data-tooltipinput]:hover::before {
    /* needed */
    opacity: 1;

    /* customizable */
    box-shadow: 1px 1px 3px #222222;
    border-radius: 2px;
    font-size: 12px;
    line-height: 15px;
    margin: auto;
    margin-top: 26px;
    margin-left: 10px;
    padding: 4px;
    transition-delay: 0.75s;
    z-index: 10;
}
& [data-tooltipinput]:not([data-tooltipinput-persistent])::before {
    pointer-events: none;
}
.default .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.default .text-form-input .input-label-container {
                color: #000000
            }
.default .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.default .text-form-input .required-star {
                color: #ff0000;
            }
.default .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.default [data-tooltipinput]:hover::before {
            background: #bfbfbf;
            color: #000000;
        }
.default-light .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.default-light .text-form-input .input-label-container {
                color: #000000
            }
.default-light .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.default-light .text-form-input .required-star {
                color: #ff0000;
            }
.default-light .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.default-light [data-tooltipinput]:hover::before {
            background: #cfcfcf;
            color: #000000;
        }
.sky .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.sky .text-form-input .input-label-container {
                color: #000000
            }
.sky .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.sky .text-form-input .required-star {
                color: #ff0000;
            }
.sky .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.sky [data-tooltipinput]:hover::before {
            background: #c8c8c8;
            color: #000000;
        }
.sky-light .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.sky-light .text-form-input .input-label-container {
                color: #000000
            }
.sky-light .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.sky-light .text-form-input .required-star {
                color: #ff0000;
            }
.sky-light .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.sky-light [data-tooltipinput]:hover::before {
            background: #c8c8c8;
            color: #000000;
        }
.grass .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.grass .text-form-input .input-label-container {
                color: #000000
            }
.grass .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.grass .text-form-input .required-star {
                color: #ff0000;
            }
.grass .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.grass [data-tooltipinput]:hover::before {
            background: #9cadb2;
            color: #000000;
        }
.grass-light .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.grass-light .text-form-input .input-label-container {
                color: #000000
            }
.grass-light .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.grass-light .text-form-input .required-star {
                color: #ff0000;
            }
.grass-light .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.grass-light [data-tooltipinput]:hover::before {
            background: #424443;
            color: #000000;
        }
.mud .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.mud .text-form-input .input-label-container {
                color: #000000
            }
.mud .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.mud .text-form-input .required-star {
                color: #ff0000;
            }
.mud .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.mud [data-tooltipinput]:hover::before {
            background: #f2f3f8;
            color: #000000;
        }
.mud-light .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.mud-light .text-form-input .input-label-container {
                color: #000000
            }
.mud-light .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.mud-light .text-form-input .required-star {
                color: #ff0000;
            }
.mud-light .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.mud-light [data-tooltipinput]:hover::before {
            background: #d6c282;
            color: #000000;
        }
.illini .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.illini .text-form-input .input-label-container {
                color: #000000
            }
.illini .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.illini .text-form-input .required-star {
                color: #ff0000;
            }
.illini .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.illini [data-tooltipinput]:hover::before {
            background: #f2f3f8;
            color: #000000;
        }
.illini-light .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid #ff0000 !important;
            border-radius: 4px;
        }
.illini-light .text-form-input .input-label-container {
                color: #000000
            }
.illini-light .text-form-input .input-label-container .red-star {
                    color: #ff0000;
                }
.illini-light .text-form-input .required-star {
                color: #ff0000;
            }
.illini-light .text-form-input .clear-btn-icon .default-svg-icon {
                fill: #000000;
            }
.illini-light [data-tooltipinput]:hover::before {
            background: #abc8e2;
            color: #000000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.search-input .form-input {
        height: 25px;
    }
.search-input .search-img {
        height: 13px;
        width: 13px;
        margin-top: 7px;
    }
.search-input .clear-img {
        width: 20px;
        height: 24px;
        cursor: pointer;
    }
.search-input .search-input-left-container {
        display: flex
    }
.search-input .search-input-left-container .search-input-required {
            font-size: 15px;
            line-height: 15px;
        }
.default .search-input .search-img {
            fill: #000000;
        }
.default .search-input .clear-img {
            fill: #000000;
        }
.default .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.default-light .search-input .search-img {
            fill: #000000;
        }
.default-light .search-input .clear-img {
            fill: #000000;
        }
.default-light .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.sky .search-input .search-img {
            fill: #000000;
        }
.sky .search-input .clear-img {
            fill: #000000;
        }
.sky .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.sky-light .search-input .search-img {
            fill: #000000;
        }
.sky-light .search-input .clear-img {
            fill: #000000;
        }
.sky-light .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.grass .search-input .search-img {
            fill: #000000;
        }
.grass .search-input .clear-img {
            fill: #000000;
        }
.grass .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.grass-light .search-input .search-img {
            fill: #000000;
        }
.grass-light .search-input .clear-img {
            fill: #000000;
        }
.grass-light .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.mud .search-input .search-img {
            fill: #000000;
        }
.mud .search-input .clear-img {
            fill: #000000;
        }
.mud .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.mud-light .search-input .search-img {
            fill: #000000;
        }
.mud-light .search-input .clear-img {
            fill: #000000;
        }
.mud-light .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.illini .search-input .search-img {
            fill: #000000;
        }
.illini .search-input .clear-img {
            fill: #000000;
        }
.illini .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }
.illini-light .search-input .search-img {
            fill: #000000;
        }
.illini-light .search-input .clear-img {
            fill: #000000;
        }
.illini-light .search-input .search-input-left-container .search-input-required {
                color: #ff0000;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.auto-search-input .search-input .form-input {
            height: auto;
        }
.default .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #bfbfbf;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #000000;
            z-index: 1;
            overflow-y: auto
        }
.default .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #000000;
            }
.default .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #000000;
                }
.default .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.default .auto-search-options .autosearch-list-item:hover {
                background-color: #1daaef;
            }
.default .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.default .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.default .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.default-light .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #cfcfcf;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #fcfcfc;
            z-index: 1;
            overflow-y: auto
        }
.default-light .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #fcfcfc;
            }
.default-light .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #fcfcfc;
                }
.default-light .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.default-light .auto-search-options .autosearch-list-item:hover {
                background-color: #0e55a8;
            }
.default-light .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.default-light .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.default-light .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.sky .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #c8c8c8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #fcfcfc;
            z-index: 1;
            overflow-y: auto
        }
.sky .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #fcfcfc;
            }
.sky .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #fcfcfc;
                }
.sky .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.sky .auto-search-options .autosearch-list-item:hover {
                background-color: #01425e;
            }
.sky .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.sky .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.sky .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.sky-light .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #c8c8c8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #fcfcfc;
            z-index: 1;
            overflow-y: auto
        }
.sky-light .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #fcfcfc;
            }
.sky-light .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #fcfcfc;
                }
.sky-light .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.sky-light .auto-search-options .autosearch-list-item:hover {
                background-color: #01425e;
            }
.sky-light .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.sky-light .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.sky-light .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.grass .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #9cadb2;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #667c66;
            z-index: 1;
            overflow-y: auto
        }
.grass .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #667c66;
            }
.grass .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #667c66;
                }
.grass .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.grass .auto-search-options .autosearch-list-item:hover {
                background-color: #e26816;
            }
.grass .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.grass .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.grass .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.grass-light .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #424443;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #babfba;
            z-index: 1;
            overflow-y: auto
        }
.grass-light .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #babfba;
            }
.grass-light .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #babfba;
                }
.grass-light .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.grass-light .auto-search-options .autosearch-list-item:hover {
                background-color: #e26816;
            }
.grass-light .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.grass-light .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.grass-light .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.mud .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #f2f3f8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #4b3705;
            z-index: 1;
            overflow-y: auto
        }
.mud .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #4b3705;
            }
.mud .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #4b3705;
                }
.mud .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.mud .auto-search-options .autosearch-list-item:hover {
                background-color: #f0c248;
            }
.mud .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.mud .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.mud .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.mud-light .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #d6c282;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #fff0bb;
            z-index: 1;
            overflow-y: auto
        }
.mud-light .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #fff0bb;
            }
.mud-light .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #fff0bb;
                }
.mud-light .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.mud-light .auto-search-options .autosearch-list-item:hover {
                background-color: #0e55a8;
            }
.mud-light .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.mud-light .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.mud-light .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.illini .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #f2f3f8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #00467f;
            z-index: 1;
            overflow-y: auto
        }
.illini .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #00467f;
            }
.illini .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #00467f;
                }
.illini .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.illini .auto-search-options .autosearch-list-item:hover {
                background-color: #f39c11;
            }
.illini .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.illini .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.illini .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }
.illini-light .auto-search-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            max-height: 300px;
            min-width: 100%;
            background-color: #abc8e2;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            color: #e1e6fa;
            z-index: 1;
            overflow-y: auto
        }
.illini-light .auto-search-options .autosearch-list-item {
                padding: 5px 5px 5px 10px;
                border-bottom: 1px solid #e1e6fa;
            }
.illini-light .auto-search-options .autosearch-list-item svg {
                    margin-top: 5px;
                    fill: #e1e6fa;
                }
.illini-light .auto-search-options .autosearch-list-item {

                cursor: pointer;
            }
.illini-light .auto-search-options .autosearch-list-item:hover {
                background-color: #b2962c;
            }
.illini-light .auto-search-options .autosearch-list-content {
                font-size: 13px
            }
.illini-light .auto-search-options .autosearch-list-content .autosearch-sub-item {
                    font-size: 12px;
                    font-style: italic;
                }
.illini-light .auto-search-options .autosearch-list-content div {
                    padding-top: 5px;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.bucket {
    margin: 10px 0px;
    padding: 0 0 0 5px;
    width: 100%;
    min-height: 40px
}
.bucket .bucket-header {
        width: 100%;
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none
    }
.bucket .bucket-header .bucket-symbol {
            float: left;
            margin-left: 3px;
            margin-right: 5px
        }
.bucket .bucket-header .bucket-symbol .bucket-arrow-icon {
                width: 15px;
                height: 15px;
            }
.bucket .bucket-content {
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        width: 100%;
    }
.bucket.non-collapsible .bucket-header {
        cursor: default;
    }
.default .bucket-arrow-icon {
        fill: #ffffff;
    }
.default-light .bucket-arrow-icon {
        fill: #000000;
    }
.sky .bucket-arrow-icon {
        fill: #000000;
    }
.sky-light .bucket-arrow-icon {
        fill: #000000;
    }
.grass .bucket-arrow-icon {
        fill: #ffffff;
    }
.grass-light .bucket-arrow-icon {
        fill: #000000;
    }
.mud .bucket-arrow-icon {
        fill: #ffffff;
    }
.mud-light .bucket-arrow-icon {
        fill: #000000;
    }
.illini .bucket-arrow-icon {
        fill: #ffffff;
    }
.illini-light .bucket-arrow-icon {
        fill: #000000;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.default-button {
    min-width: 100px;
    height: 34px;
    max-width: 150px;
    border-radius: 4px;
    border: none;
    text-align: center;
    display: inline-block;
    margin: 2px 3px;
    cursor: pointer;
    padding: 1px 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "open_sansregular";
    font-size: 14px;
    font-weight: 300;
}
.wide-button {
    max-width: 100%;
}
.default-button:disabled {
    cursor: default;
    opacity: 0.6;
}
.default .default-button {
            background-color: #1daaef;
            border: 1px solid #1daaef;
            color: #ffffff;
        }
.default .default-button:focus {
            border: 1px solid #8fa61a;
            outline: none;
        }
.default .cancel-button:focus {
            border: 1px solid #8fa61a;
            outline: none;
        }
.default .cancel-button {
            background-color: #737373;
            color: #ffffff;
            border: 1px solid #737373;
        }
.default-light .default-button {
            background-color: #0e55a8;
            border: 1px solid #0e55a8;
            color: #ffffff;
        }
.default-light .default-button:focus {
            border: 1px solid #8fa61a;
            outline: none;
        }
.default-light .cancel-button:focus {
            border: 1px solid #8fa61a;
            outline: none;
        }
.default-light .cancel-button {
            background-color: #b8b8b8;
            color: #ffffff;
            border: 1px solid #b8b8b8;
        }
.sky .default-button {
            background-color: #01425e;
            border: 1px solid #01425e;
            color: #ffffff;
        }
.sky .default-button:focus {
            border: 1px solid #235635;
            outline: none;
        }
.sky .cancel-button:focus {
            border: 1px solid #235635;
            outline: none;
        }
.sky .cancel-button {
            background-color: #838383;
            color: #ffffff;
            border: 1px solid #838383;
        }
.sky-light .default-button {
            background-color: #01425e;
            border: 1px solid #01425e;
            color: #ffffff;
        }
.sky-light .default-button:focus {
            border: 1px solid #235635;
            outline: none;
        }
.sky-light .cancel-button:focus {
            border: 1px solid #235635;
            outline: none;
        }
.sky-light .cancel-button {
            background-color: #838383;
            color: #ffffff;
            border: 1px solid #838383;
        }
.grass .default-button {
            background-color: #e26816;
            border: 1px solid #e26816;
            color: #ffffff;
        }
.grass .default-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.grass .cancel-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.grass .cancel-button {
            background-color: #424443;
            color: #ffffff;
            border: 1px solid #424443;
        }
.grass-light .default-button {
            background-color: #e26816;
            border: 1px solid #e26816;
            color: #ffffff;
        }
.grass-light .default-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.grass-light .cancel-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.grass-light .cancel-button {
            background-color: #bfbfbf;
            color: #ffffff;
            border: 1px solid #bfbfbf;
        }
.mud .default-button {
            background-color: #f0c248;
            border: 1px solid #f0c248;
            color: #ffffff;
        }
.mud .default-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.mud .cancel-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.mud .cancel-button {
            background-color: #ad8f68;
            color: #ffffff;
            border: 1px solid #ad8f68;
        }
.mud-light .default-button {
            background-color: #0e55a8;
            border: 1px solid #0e55a8;
            color: #ffffff;
        }
.mud-light .default-button:focus {
            border: 1px solid #8fa61a;
            outline: none;
        }
.mud-light .cancel-button:focus {
            border: 1px solid #8fa61a;
            outline: none;
        }
.mud-light .cancel-button {
            background-color: #735523;
            color: #ffffff;
            border: 1px solid #735523;
        }
.illini .default-button {
            background-color: #f39c11;
            border: 1px solid #f39c11;
            color: #ffffff;
        }
.illini .default-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.illini .cancel-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.illini .cancel-button {
            background-color: #3698db;
            color: #ffffff;
            border: 1px solid #3698db;
        }
.illini-light .default-button {
            background-color: #b2962c;
            border: 1px solid #b2962c;
            color: #ffffff;
        }
.illini-light .default-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.illini-light .cancel-button:focus {
            border: 1px solid #ffff00;
            outline: none;
        }
.illini-light .cancel-button {
            background-color: #00467f;
            color: #ffffff;
            border: 1px solid #00467f;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.checkbox-div {
    height: 25px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap
}
.checkbox-div .checkbox-container {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        cursor: pointer
    }
.checkbox-div .checkbox-container .checkbox-display {
            width: 16px;
            height: 16px;
            line-height: 15px;
            font-size: 15px;
            font-weight: 900;
            text-align: center;
        }
.checkbox-div .checkbox-container .checkbox-display.check-mark-color {
            font-weight: 900;
        }
.checkbox-div .checkbox-container:focus {
        outline: none;
    }
.checkbox-div .checkbox-label {
        display: inline-block;
        height: 19px;
        margin-left: 6px;
        margin-right: 6px;
        vertical-align: top;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 19px
    }
.checkbox-div .checkbox-label .red-star {
            padding-right: 3px;
        }
.checkbox-div .checkbox-label .checkbox-label-text-span {
            line-height: 19px;
            font-size: 13px;
        }
.default .checkbox-div .checkbox-container {
            border: 2px solid #bfbfbf;
            background-color: #bfbfbf;
            color: #000000
        }
.default .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #000000, 0 1px #000000,
                    1px 0 #000000, 0 -1px #000000;
            }
.default .checkbox-div .checkbox-container.focus {
            border: 2px solid #8fa61a;
        }
.default .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.default .checkbox-div .disabled-checkbox {
            color: #777777 !important;
        }
.default-light .checkbox-div .checkbox-container {
            border: 2px solid #cfcfcf;
            background-color: #cfcfcf;
            color: #000000
        }
.default-light .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #fcfcfc, 0 1px #fcfcfc,
                    1px 0 #fcfcfc, 0 -1px #fcfcfc;
            }
.default-light .checkbox-div .checkbox-container.focus {
            border: 2px solid #8fa61a;
        }
.default-light .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.default-light .checkbox-div .disabled-checkbox {
            color: #777777 !important;
        }
.sky .checkbox-div .checkbox-container {
            border: 2px solid #c8c8c8;
            background-color: #c8c8c8;
            color: #000000
        }
.sky .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #fcfcfc, 0 1px #fcfcfc,
                    1px 0 #fcfcfc, 0 -1px #fcfcfc;
            }
.sky .checkbox-div .checkbox-container.focus {
            border: 2px solid #235635;
        }
.sky .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.sky .checkbox-div .disabled-checkbox {
            color: #878787 !important;
        }
.sky-light .checkbox-div .checkbox-container {
            border: 2px solid #c8c8c8;
            background-color: #c8c8c8;
            color: #000000
        }
.sky-light .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #fcfcfc, 0 1px #fcfcfc,
                    1px 0 #fcfcfc, 0 -1px #fcfcfc;
            }
.sky-light .checkbox-div .checkbox-container.focus {
            border: 2px solid #235635;
        }
.sky-light .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.sky-light .checkbox-div .disabled-checkbox {
            color: #878787 !important;
        }
.grass .checkbox-div .checkbox-container {
            border: 2px solid #9cadb2;
            background-color: #9cadb2;
            color: #000000
        }
.grass .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #667c66, 0 1px #667c66,
                    1px 0 #667c66, 0 -1px #667c66;
            }
.grass .checkbox-div .checkbox-container.focus {
            border: 2px solid #ffff00;
        }
.grass .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.grass .checkbox-div .disabled-checkbox {
            color: #e5e9e5 !important;
        }
.grass-light .checkbox-div .checkbox-container {
            border: 2px solid #424443;
            background-color: #424443;
            color: #000000
        }
.grass-light .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #babfba, 0 1px #babfba,
                    1px 0 #babfba, 0 -1px #babfba;
            }
.grass-light .checkbox-div .checkbox-container.focus {
            border: 2px solid #ffff00;
        }
.grass-light .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.grass-light .checkbox-div .disabled-checkbox {
            color: #e5e9e5 !important;
        }
.mud .checkbox-div .checkbox-container {
            border: 2px solid #f2f3f8;
            background-color: #f2f3f8;
            color: #000000
        }
.mud .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #4b3705, 0 1px #4b3705,
                    1px 0 #4b3705, 0 -1px #4b3705;
            }
.mud .checkbox-div .checkbox-container.focus {
            border: 2px solid #ffff00;
        }
.mud .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.mud .checkbox-div .disabled-checkbox {
            color: #777777 !important;
        }
.mud-light .checkbox-div .checkbox-container {
            border: 2px solid #d6c282;
            background-color: #d6c282;
            color: #000000
        }
.mud-light .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #fff0bb, 0 1px #fff0bb,
                    1px 0 #fff0bb, 0 -1px #fff0bb;
            }
.mud-light .checkbox-div .checkbox-container.focus {
            border: 2px solid #8fa61a;
        }
.mud-light .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.mud-light .checkbox-div .disabled-checkbox {
            color: #777777 !important;
        }
.illini .checkbox-div .checkbox-container {
            border: 2px solid #f2f3f8;
            background-color: #f2f3f8;
            color: #000000
        }
.illini .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #00467f, 0 1px #00467f,
                    1px 0 #00467f, 0 -1px #00467f;
            }
.illini .checkbox-div .checkbox-container.focus {
            border: 2px solid #ffff00;
        }
.illini .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.illini .checkbox-div .disabled-checkbox {
            color: #777777 !important;
        }
.illini-light .checkbox-div .checkbox-container {
            border: 2px solid #abc8e2;
            background-color: #abc8e2;
            color: #000000
        }
.illini-light .checkbox-div .checkbox-container .checkbox-display.check-mark-color {
                text-shadow: -1px 0 #e1e6fa, 0 1px #e1e6fa,
                    1px 0 #e1e6fa, 0 -1px #e1e6fa;
            }
.illini-light .checkbox-div .checkbox-container.focus {
            border: 2px solid #ffff00;
        }
.illini-light .checkbox-div .checkbox-label .red-star {
                color: #ff0000;
            }
.illini-light .checkbox-div .disabled-checkbox {
            color: #777777 !important;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.color-picker-container .popover {
        position: absolute;
        z-index: 2;
    }
.color-picker-container .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
.color-picker-container .color-picker-popover-content {
        display: inline-block;
        position: relative;
    }
.color-picker-container .button-container {
        display: flex;
    }
.color-picker-container .color-selector-wrap {
        min-width: 37px;
        min-height: 37px;
        border-radius: 4px;
        display: inline-block;
        outline: none;
        overflow: hidden;
        position: relative
    }
.color-picker-container .color-selector-wrap div {
            z-index: -1;
        }
.color-picker-container .color-selector-wrap .color-selector-button {
            min-width: 37px;
            min-height: 37px;
            border-radius: 4px;
            outline: none;
            z-index: 2;
        }
.color-picker-container .default-svg-icon {
        margin-left: 6px;
        width: 15px;
        height: 35px;
    }
.color-picker-container .compact-picker,
    .color-picker-container .compact-transparency-picker {
        border-radius: 4px;
        padding: 5px
    }
.color-picker-container .compact-picker .flexbox-fix, .color-picker-container .compact-transparency-picker .flexbox-fix {
            padding-bottom: 0px
        }
.color-picker-container .compact-picker .flexbox-fix div input, .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                    height: 21px !important;
                }
.color-picker-container .compact-picker div:first-child div {
                border-radius: 4px;
            }
.color-picker-container .compact-transparency-picker .compact-swatches div {
                border-radius: 4px;
            }
.default .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.default .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.default .color-picker-container .compact-picker {
                background-color: #404040;
                border: 1px solid #ffffff
            }
.default .color-picker-container .compact-transparency-picker {
                background-color: #404040;
                border: 1px solid #ffffff
            }
.default .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.default .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.default .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.default .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.default .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.default .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.default .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.default .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.default .color-picker-container.focus .color-selector-button {
                border: 1px solid #8fa61a;
            }
.default .color-picker-container.focus .default-svg-icon {
                fill: #8fa61a;
            }
.default-light .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.default-light .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.default-light .color-picker-container .compact-picker {
                background-color: #919191;
                border: 1px solid #ffffff
            }
.default-light .color-picker-container .compact-transparency-picker {
                background-color: #919191;
                border: 1px solid #ffffff
            }
.default-light .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.default-light .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.default-light .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.default-light .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.default-light .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.default-light .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.default-light .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.default-light .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.default-light .color-picker-container.focus .color-selector-button {
                border: 1px solid #8fa61a;
            }
.default-light .color-picker-container.focus .default-svg-icon {
                fill: #8fa61a;
            }
.sky .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.sky .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.sky .color-picker-container .compact-picker {
                background-color: #0194d3;
                border: 1px solid #ffffff
            }
.sky .color-picker-container .compact-transparency-picker {
                background-color: #0194d3;
                border: 1px solid #ffffff
            }
.sky .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.sky .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.sky .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.sky .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.sky .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.sky .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.sky .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.sky .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.sky .color-picker-container.focus .color-selector-button {
                border: 1px solid #235635;
            }
.sky .color-picker-container.focus .default-svg-icon {
                fill: #235635;
            }
.sky-light .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.sky-light .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.sky-light .color-picker-container .compact-picker {
                background-color: #0194d3;
                border: 1px solid #ffffff
            }
.sky-light .color-picker-container .compact-transparency-picker {
                background-color: #0194d3;
                border: 1px solid #ffffff
            }
.sky-light .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.sky-light .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.sky-light .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.sky-light .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.sky-light .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.sky-light .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.sky-light .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.sky-light .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.sky-light .color-picker-container.focus .color-selector-button {
                border: 1px solid #235635;
            }
.sky-light .color-picker-container.focus .default-svg-icon {
                fill: #235635;
            }
.grass .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.grass .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.grass .color-picker-container .compact-picker {
                background-color: #325132;
                border: 1px solid #ffffff
            }
.grass .color-picker-container .compact-transparency-picker {
                background-color: #325132;
                border: 1px solid #ffffff
            }
.grass .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.grass .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.grass .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.grass .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.grass .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.grass .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.grass .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.grass .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.grass .color-picker-container.focus .color-selector-button {
                border: 1px solid #ffff00;
            }
.grass .color-picker-container.focus .default-svg-icon {
                fill: #ffff00;
            }
.grass-light .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.grass-light .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.grass-light .color-picker-container .compact-picker {
                background-color: #545f54;
                border: 1px solid #ffffff
            }
.grass-light .color-picker-container .compact-transparency-picker {
                background-color: #545f54;
                border: 1px solid #ffffff
            }
.grass-light .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.grass-light .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.grass-light .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.grass-light .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.grass-light .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.grass-light .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.grass-light .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.grass-light .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.grass-light .color-picker-container.focus .color-selector-button {
                border: 1px solid #ffff00;
            }
.grass-light .color-picker-container.focus .default-svg-icon {
                fill: #ffff00;
            }
.mud .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.mud .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.mud .color-picker-container .compact-picker {
                background-color: #766146;
                border: 1px solid #ffffff
            }
.mud .color-picker-container .compact-transparency-picker {
                background-color: #766146;
                border: 1px solid #ffffff
            }
.mud .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.mud .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.mud .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.mud .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.mud .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.mud .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.mud .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.mud .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.mud .color-picker-container.focus .color-selector-button {
                border: 1px solid #ffff00;
            }
.mud .color-picker-container.focus .default-svg-icon {
                fill: #ffff00;
            }
.mud-light .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.mud-light .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.mud-light .color-picker-container .compact-picker {
                background-color: #a37b42;
                border: 1px solid #ffffff
            }
.mud-light .color-picker-container .compact-transparency-picker {
                background-color: #a37b42;
                border: 1px solid #ffffff
            }
.mud-light .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.mud-light .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.mud-light .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.mud-light .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.mud-light .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.mud-light .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.mud-light .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.mud-light .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.mud-light .color-picker-container.focus .color-selector-button {
                border: 1px solid #8fa61a;
            }
.mud-light .color-picker-container.focus .default-svg-icon {
                fill: #8fa61a;
            }
.illini .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.illini .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.illini .color-picker-container .compact-picker {
                background-color: #636466;
                border: 1px solid #ffffff
            }
.illini .color-picker-container .compact-transparency-picker {
                background-color: #636466;
                border: 1px solid #ffffff
            }
.illini .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.illini .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.illini .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.illini .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.illini .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.illini .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.illini .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.illini .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.illini .color-picker-container.focus .color-selector-button {
                border: 1px solid #ffff00;
            }
.illini .color-picker-container.focus .default-svg-icon {
                fill: #ffff00;
            }
.illini-light .color-picker-container .color-selector-button {
                border: 1px solid #ffffff;
            }
.illini-light .color-picker-container .default-svg-icon {
                fill: #ffffff;
            }
.illini-light .color-picker-container .compact-picker {
                background-color: #375d81;
                border: 1px solid #ffffff
            }
.illini-light .color-picker-container .compact-transparency-picker {
                background-color: #375d81;
                border: 1px solid #ffffff
            }
.illini-light .color-picker-container .compact-picker input {
                    color: #ffffff !important;
                }
.illini-light .color-picker-container .compact-transparency-picker input {
                    color: #ffffff !important;
                }
.illini-light .color-picker-container .compact-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.illini-light .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                        color: #ffffff;
                    }
.illini-light .color-picker-container .compact-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.illini-light .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                        color: #ffffff !important;
                    }
.illini-light .color-picker-container .compact-picker div:first-child div {
                    border: 1px solid #ffffff;
                }
.illini-light .color-picker-container .compact-transparency-picker .compact-swatches div {
                    border: 1px solid #ffffff;
                }
.illini-light .color-picker-container.focus .color-selector-button {
                border: 1px solid #ffff00;
            }
.illini-light .color-picker-container.focus .default-svg-icon {
                fill: #ffff00;
            }
.sky .color-picker-container .color-selector-button {
            border: 1px solid #000000;
        }
.sky .color-picker-container .compact-picker {
            background-color: #e2e2e2;
            border: 1px solid #000000
        }
.sky .color-picker-container .compact-transparency-picker {
            background-color: #e2e2e2;
            border: 1px solid #000000
        }
.sky .color-picker-container .compact-picker input {
                color: #000000 !important;
            }
.sky .color-picker-container .compact-transparency-picker input {
                color: #000000 !important;
            }
.sky .color-picker-container .compact-picker .flexbox-fix div input {
                    color: #000000;
                }
.sky .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                    color: #000000;
                }
.sky .color-picker-container .compact-picker .flexbox-fix div span {
                    color: #000000 !important;
                }
.sky .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                    color: #000000 !important;
                }
.sky .color-picker-container .compact-picker .compact-swatches div {
                border: 1px solid #000000;
            }
.sky .color-picker-container .compact-transparency-picker .compact-swatches div {
                border: 1px solid #ffffff;
            }
.sky-light .color-picker-container .color-selector-button {
            border: 1px solid #000000;
        }
.sky-light .color-picker-container .compact-picker {
            background-color: #e2e2e2;
            border: 1px solid #000000
        }
.sky-light .color-picker-container .compact-transparency-picker {
            background-color: #e2e2e2;
            border: 1px solid #000000
        }
.sky-light .color-picker-container .compact-picker input {
                color: #000000 !important;
            }
.sky-light .color-picker-container .compact-transparency-picker input {
                color: #000000 !important;
            }
.sky-light .color-picker-container .compact-picker .flexbox-fix div input {
                    color: #000000;
                }
.sky-light .color-picker-container .compact-transparency-picker .flexbox-fix div input {
                    color: #000000;
                }
.sky-light .color-picker-container .compact-picker .flexbox-fix div span {
                    color: #000000 !important;
                }
.sky-light .color-picker-container .compact-transparency-picker .flexbox-fix div span {
                    color: #000000 !important;
                }
.sky-light .color-picker-container .compact-picker .compact-swatches div {
                border: 1px solid #000000;
            }
.sky-light .color-picker-container .compact-transparency-picker .compact-swatches div {
                border: 1px solid #ffffff;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.form-input {
    height: 35px;
    border-radius: 4px 4px 4px 4px;
    padding: 3px 3px;
    margin: 10px;
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300
}
.form-input input {
        font-family: "open_sansregular";
        margin-top: 1px;
    }
.form-input textarea {
        font-family: "open_sansregular";
    }
.form-input :-ms-input-placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }
.form-input ::placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }
.form-input.error {
        height: 45px
    }
.form-input.error .input-label-container {
            height: 11px;
        }
.form-input .disabled.required-star,
        .form-input .disabled .expand-contract-arrow {
            opacity: 0.6;
        }
.default .form-input {
        background-color: #bfbfbf;
        border: 2px solid #bfbfbf;
        color: #000000
    }
.default .form-input input {
            background-color: #bfbfbf;
            color: #000000;
        }
.default .form-input textarea {
            background-color: #bfbfbf;
            color: #000000;
        }
.default .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #bfbfbf inset;
        }
.default .form-input.focus {
            border: 2px solid #8fa61a;
        }
.default .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.default .form-input :enabled::placeholder {
            color: #000000;
        }
.default .form-input.error {
            border: 2px solid #ff0000;
        }
.default .form-input .error-text {
            color: #ff0000;
        }
.default .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.default .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.default .form-input .disabled input {
            color: #777777 !important;
        }
.default .form-input input:disabled {
            color: #777777 !important;
        }
.default .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.default .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.default-light .form-input {
        background-color: #cfcfcf;
        border: 2px solid #cfcfcf;
        color: #ffffff
    }
.default-light .form-input input {
            background-color: #cfcfcf;
            color: #ffffff;
        }
.default-light .form-input textarea {
            background-color: #cfcfcf;
            color: #ffffff;
        }
.default-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #cfcfcf inset;
        }
.default-light .form-input.focus {
            border: 2px solid #8fa61a;
        }
.default-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.default-light .form-input :enabled::placeholder {
            color: #000000;
        }
.default-light .form-input.error {
            border: 2px solid #ff0000;
        }
.default-light .form-input .error-text {
            color: #ff0000;
        }
.default-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.default-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.default-light .form-input .disabled input {
            color: #777777 !important;
        }
.default-light .form-input input:disabled {
            color: #777777 !important;
        }
.default-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.default-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.sky .form-input {
        background-color: #c8c8c8;
        border: 2px solid #c8c8c8;
        color: #ffffff
    }
.sky .form-input input {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky .form-input textarea {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #c8c8c8 inset;
        }
.sky .form-input.focus {
            border: 2px solid #235635;
        }
.sky .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.sky .form-input :enabled::placeholder {
            color: #000000;
        }
.sky .form-input.error {
            border: 2px solid #ff0000;
        }
.sky .form-input .error-text {
            color: #ff0000;
        }
.sky .form-input .disabled .input-label-container {
            color: #878787 !important;
        }
.sky .form-input .input-label-container .disabled-text {
            color: #878787 !important;
        }
.sky .form-input .disabled input {
            color: #878787 !important;
        }
.sky .form-input input:disabled {
            color: #878787 !important;
        }
.sky .form-input input:disabled:-ms-input-placeholder {
            color: #878787 !important;
        }
.sky .form-input input:disabled::placeholder {
            color: #878787 !important;
        }
.sky-light .form-input {
        background-color: #c8c8c8;
        border: 2px solid #c8c8c8;
        color: #ffffff
    }
.sky-light .form-input input {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky-light .form-input textarea {
            background-color: #c8c8c8;
            color: #ffffff;
        }
.sky-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fcfcfc !important;
            -webkit-box-shadow: 0 0 0px 1000px #c8c8c8 inset;
        }
.sky-light .form-input.focus {
            border: 2px solid #235635;
        }
.sky-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.sky-light .form-input :enabled::placeholder {
            color: #000000;
        }
.sky-light .form-input.error {
            border: 2px solid #ff0000;
        }
.sky-light .form-input .error-text {
            color: #ff0000;
        }
.sky-light .form-input .disabled .input-label-container {
            color: #878787 !important;
        }
.sky-light .form-input .input-label-container .disabled-text {
            color: #878787 !important;
        }
.sky-light .form-input .disabled input {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled:-ms-input-placeholder {
            color: #878787 !important;
        }
.sky-light .form-input input:disabled::placeholder {
            color: #878787 !important;
        }
.grass .form-input {
        background-color: #9cadb2;
        border: 2px solid #9cadb2;
        color: #000000
    }
.grass .form-input input {
            background-color: #9cadb2;
            color: #000000;
        }
.grass .form-input textarea {
            background-color: #9cadb2;
            color: #000000;
        }
.grass .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #667c66 !important;
            -webkit-box-shadow: 0 0 0px 1000px #9cadb2 inset;
        }
.grass .form-input.focus {
            border: 2px solid #ffff00;
        }
.grass .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.grass .form-input :enabled::placeholder {
            color: #000000;
        }
.grass .form-input.error {
            border: 2px solid #ff0000;
        }
.grass .form-input .error-text {
            color: #ff0000;
        }
.grass .form-input .disabled .input-label-container {
            color: #e5e9e5 !important;
        }
.grass .form-input .input-label-container .disabled-text {
            color: #e5e9e5 !important;
        }
.grass .form-input .disabled input {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled:-ms-input-placeholder {
            color: #e5e9e5 !important;
        }
.grass .form-input input:disabled::placeholder {
            color: #e5e9e5 !important;
        }
.grass-light .form-input {
        background-color: #424443;
        border: 2px solid #424443;
        color: #ffffff
    }
.grass-light .form-input input {
            background-color: #424443;
            color: #ffffff;
        }
.grass-light .form-input textarea {
            background-color: #424443;
            color: #ffffff;
        }
.grass-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #babfba !important;
            -webkit-box-shadow: 0 0 0px 1000px #424443 inset;
        }
.grass-light .form-input.focus {
            border: 2px solid #ffff00;
        }
.grass-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.grass-light .form-input :enabled::placeholder {
            color: #000000;
        }
.grass-light .form-input.error {
            border: 2px solid #ff0000;
        }
.grass-light .form-input .error-text {
            color: #ff0000;
        }
.grass-light .form-input .disabled .input-label-container {
            color: #e5e9e5 !important;
        }
.grass-light .form-input .input-label-container .disabled-text {
            color: #e5e9e5 !important;
        }
.grass-light .form-input .disabled input {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled:-ms-input-placeholder {
            color: #e5e9e5 !important;
        }
.grass-light .form-input input:disabled::placeholder {
            color: #e5e9e5 !important;
        }
.mud .form-input {
        background-color: #f2f3f8;
        border: 2px solid #f2f3f8;
        color: #000000
    }
.mud .form-input input {
            background-color: #f2f3f8;
            color: #000000;
        }
.mud .form-input textarea {
            background-color: #f2f3f8;
            color: #000000;
        }
.mud .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #4b3705 !important;
            -webkit-box-shadow: 0 0 0px 1000px #f2f3f8 inset;
        }
.mud .form-input.focus {
            border: 2px solid #ffff00;
        }
.mud .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.mud .form-input :enabled::placeholder {
            color: #000000;
        }
.mud .form-input.error {
            border: 2px solid #ff0000;
        }
.mud .form-input .error-text {
            color: #ff0000;
        }
.mud .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.mud .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.mud .form-input .disabled input {
            color: #777777 !important;
        }
.mud .form-input input:disabled {
            color: #777777 !important;
        }
.mud .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.mud .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.mud-light .form-input {
        background-color: #d6c282;
        border: 2px solid #d6c282;
        color: #ffffff
    }
.mud-light .form-input input {
            background-color: #d6c282;
            color: #ffffff;
        }
.mud-light .form-input textarea {
            background-color: #d6c282;
            color: #ffffff;
        }
.mud-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #fff0bb !important;
            -webkit-box-shadow: 0 0 0px 1000px #d6c282 inset;
        }
.mud-light .form-input.focus {
            border: 2px solid #8fa61a;
        }
.mud-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.mud-light .form-input :enabled::placeholder {
            color: #000000;
        }
.mud-light .form-input.error {
            border: 2px solid #ff0000;
        }
.mud-light .form-input .error-text {
            color: #ff0000;
        }
.mud-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.mud-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.mud-light .form-input .disabled input {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.mud-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.illini .form-input {
        background-color: #f2f3f8;
        border: 2px solid #f2f3f8;
        color: #000000
    }
.illini .form-input input {
            background-color: #f2f3f8;
            color: #000000;
        }
.illini .form-input textarea {
            background-color: #f2f3f8;
            color: #000000;
        }
.illini .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #00467f !important;
            -webkit-box-shadow: 0 0 0px 1000px #f2f3f8 inset;
        }
.illini .form-input.focus {
            border: 2px solid #ffff00;
        }
.illini .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.illini .form-input :enabled::placeholder {
            color: #000000;
        }
.illini .form-input.error {
            border: 2px solid #ff0000;
        }
.illini .form-input .error-text {
            color: #ff0000;
        }
.illini .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.illini .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.illini .form-input .disabled input {
            color: #777777 !important;
        }
.illini .form-input input:disabled {
            color: #777777 !important;
        }
.illini .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.illini .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.illini-light .form-input {
        background-color: #abc8e2;
        border: 2px solid #abc8e2;
        color: #ffffff
    }
.illini-light .form-input input {
            background-color: #abc8e2;
            color: #ffffff;
        }
.illini-light .form-input textarea {
            background-color: #abc8e2;
            color: #ffffff;
        }
.illini-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #e1e6fa !important;
            -webkit-box-shadow: 0 0 0px 1000px #abc8e2 inset;
        }
.illini-light .form-input.focus {
            border: 2px solid #ffff00;
        }
.illini-light .form-input :enabled:-ms-input-placeholder {
            color: #000000;
        }
.illini-light .form-input :enabled::placeholder {
            color: #000000;
        }
.illini-light .form-input.error {
            border: 2px solid #ff0000;
        }
.illini-light .form-input .error-text {
            color: #ff0000;
        }
.illini-light .form-input .disabled .input-label-container {
            color: #777777 !important;
        }
.illini-light .form-input .input-label-container .disabled-text {
            color: #777777 !important;
        }
.illini-light .form-input .disabled input {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled:-ms-input-placeholder {
            color: #777777 !important;
        }
.illini-light .form-input input:disabled::placeholder {
            color: #777777 !important;
        }
.sky .form-input {
        color: #000000
    }
.sky .form-input input {
            color: #000000;
        }
.sky .form-input textarea {
            color: #000000;
        }
.sky .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
        }
.sky-light .form-input {
        color: #000000
    }
.sky-light .form-input input {
            color: #000000;
        }
.sky-light .form-input textarea {
            color: #000000;
        }
.sky-light .form-input input:-webkit-autofill {
            -webkit-text-fill-color: #000000 !important;
            -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
        }
.select-form-input-container {
    position: relative;
    min-width: 20px
}
.select-form-input-container .select-form-input .expand-contract-arrow {
            margin: 4px 0;
            padding: 2px;
            flex-shrink: 0;
            width: 15px;
            height: 15px;
            border: none;
            cursor: pointer;
            min-width: 11px
        }
.select-form-input-container .select-form-input .expand-contract-arrow .default-svg-icon {
                width: 15px;
                height: 15px;
            }
.select-form-input-container .select-form-input .input-container input {
            cursor: pointer;
            min-width: 20px;
        }
.select-form-input-container .select-form-input-options {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0;
        padding-bottom: 0;
        z-index: 10001
    }
.select-form-input-container .select-form-input-options:focus {
            outline: none;
        }
.select-form-input-container .select-form-input-options .select-form-input-option {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            line-height: 18px;
            box-sizing: border-box;
            height: 100%;
            display: block;
            padding-top: 3px
        }
.select-form-input-container .select-form-input-options .select-form-input-option.header {
                font-weight: bold;
                cursor: default;
            }
.select-form-input-container .select-form-input-options .select-form-input-option span {
                white-space: pre;
                text-overflow: ellipsis;
                overflow: hidden
            }
.select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                    margin-left: 10px;
                    position: fixed;
                }
.select-form-input-container .select-form-input-options .select-form-input-option span.pre-match {
                    margin-left: 10px;
                }
.select-form-input-container .select-form-input-options .select-form-input-option span.match {
                    text-decoration: underline;
                }
.select-form-input-container .select-form-input-options .select-form-input-option .first-line span, .select-form-input-container .select-form-input-options .select-form-input-option .second-line span {
                    display: inline-block;
                    margin-left: 5px;
                }
.select-form-input-container:not(.open-upward) .select-form-input-expanded.form-input.focus {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
            padding-bottom: 5px;
        }
.select-form-input-container:not(.open-upward) .select-form-input-options {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
        }
.select-form-input-container.open-upward .select-form-input-expanded.form-input.focus {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
            padding-top: 5px;
        }
.select-form-input-container.open-upward .select-form-input-options {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }
.select-form-input-container [data-tooltipselectoption]::before {
        /* needed */
        content: attr(data-tooltipselectoption);
        position: absolute;
        opacity: 0;

        /* customizable */
        white-space: pre-line;
    }
.select-form-input-container [data-tooltipselectoption]:hover::before {
        /* needed */
        opacity: 1;

        /* customizable */
        box-shadow: 1px 1px 3px #222222;
        border-radius: 2px;
        font-size: 12px;
        line-height: 15px;
        margin: auto;
        margin-top: 26px;
        padding: 4px;
        text-align: center;
        transition-delay: 0.75s;
        z-index: 10000;
    }
.select-form-input-container [data-tooltipselectoption]:not([data-tooltipselectoption-persistent])::before {
        pointer-events: none;
    }
.default .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.default .select-form-input-container .select-form-input-options {
                border: 2px solid #8fa61a
            }
.default .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.default .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #e26816;
                    }
.default .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #1daaef;
                    }
.default .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #1daaef;
                    }
.default .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.default .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.default .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.default .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #404040 1px solid;
                    }
.default .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.default .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #404040 1px solid;
                    }
.default .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #bfbfbf;
                color: #000000;
            }
.default-light .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.default-light .select-form-input-container .select-form-input-options {
                border: 2px solid #8fa61a
            }
.default-light .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.default-light .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #e26816;
                    }
.default-light .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #0e55a8;
                    }
.default-light .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #0e55a8;
                    }
.default-light .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.default-light .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.default-light .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.default-light .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #919191 1px solid;
                    }
.default-light .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.default-light .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #919191 1px solid;
                    }
.default-light .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #cfcfcf;
                color: #000000;
            }
.sky .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.sky .select-form-input-container .select-form-input-options {
                border: 2px solid #235635
            }
.sky .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.sky .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #01425e;
                    }
.sky .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #01425e;
                    }
.sky .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #01425e;
                    }
.sky .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.sky .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.sky .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.sky .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #0194d3 1px solid;
                    }
.sky .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.sky .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #0194d3 1px solid;
                    }
.sky .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #c8c8c8;
                color: #000000;
            }
.sky-light .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.sky-light .select-form-input-container .select-form-input-options {
                border: 2px solid #235635
            }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #01425e;
                    }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #01425e;
                    }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #01425e;
                    }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.sky-light .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.sky-light .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #0194d3 1px solid;
                    }
.sky-light .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(0, 0, 0, 0.5)
                }
.sky-light .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #0194d3 1px solid;
                    }
.sky-light .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #c8c8c8;
                color: #000000;
            }
.grass .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.grass .select-form-input-container .select-form-input-options {
                border: 2px solid #ffff00
            }
.grass .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.grass .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #e26816;
                    }
.grass .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #e26816;
                    }
.grass .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #e26816;
                    }
.grass .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.grass .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.grass .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(66, 68, 67, 0.5)
                }
.grass .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #325132 1px solid;
                    }
.grass .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(66, 68, 67, 0.5)
                }
.grass .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #325132 1px solid;
                    }
.grass .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #9cadb2;
                color: #000000;
            }
.grass-light .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.grass-light .select-form-input-container .select-form-input-options {
                border: 2px solid #ffff00
            }
.grass-light .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.grass-light .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #e26816;
                    }
.grass-light .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #e26816;
                    }
.grass-light .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #e26816;
                    }
.grass-light .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.grass-light .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.grass-light .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(156, 173, 178, 0.5)
                }
.grass-light .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #545f54 1px solid;
                    }
.grass-light .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(156, 173, 178, 0.5)
                }
.grass-light .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #545f54 1px solid;
                    }
.grass-light .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #424443;
                color: #000000;
            }
.mud .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.mud .select-form-input-container .select-form-input-options {
                border: 2px solid #ffff00
            }
.mud .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.mud .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #f39104;
                    }
.mud .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #f0c248;
                    }
.mud .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #f0c248;
                    }
.mud .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.mud .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.mud .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(140, 116, 84, 0.5)
                }
.mud .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #766146 1px solid;
                    }
.mud .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(140, 116, 84, 0.5)
                }
.mud .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #766146 1px solid;
                    }
.mud .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #f2f3f8;
                color: #000000;
            }
.mud-light .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.mud-light .select-form-input-container .select-form-input-options {
                border: 2px solid #8fa61a
            }
.mud-light .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.mud-light .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #e26816;
                    }
.mud-light .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #0e55a8;
                    }
.mud-light .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #0e55a8;
                    }
.mud-light .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.mud-light .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.mud-light .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(178, 143, 90, 0.5)
                }
.mud-light .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #a37b42 1px solid;
                    }
.mud-light .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(178, 143, 90, 0.5)
                }
.mud-light .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #a37b42 1px solid;
                    }
.mud-light .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #d6c282;
                color: #000000;
            }
.illini .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.illini .select-form-input-container .select-form-input-options {
                border: 2px solid #ffff00
            }
.illini .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.illini .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #f39c11;
                    }
.illini .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #f39c11;
                    }
.illini .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #f39c11;
                    }
.illini .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.illini .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.illini .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(171, 200, 226, 0.5)
                }
.illini .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #636466 1px solid;
                    }
.illini .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(171, 200, 226, 0.5)
                }
.illini .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #636466 1px solid;
                    }
.illini .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #f2f3f8;
                color: #000000;
            }
.illini-light .select-form-input-container .expand-contract-arrow .default-svg-icon {
                fill: #000000;
            }
.illini-light .select-form-input-container .select-form-input-options {
                border: 2px solid #ffff00
            }
.illini-light .select-form-input-container .select-form-input-options .select-form-input-option {
                    color: #000000
                }
.illini-light .select-form-input-container .select-form-input-options .select-form-input-option.selected {
                        background-color: #f39c11;
                    }
.illini-light .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
                        background-color: #b2962c;
                    }
.illini-light .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
                        background-color: #b2962c;
                    }
.illini-light .select-form-input-container .select-form-input-options .select-form-input-option.default {
                        cursor: default;
                        background-color: transparent;
                        background-color: initial;
                    }
.illini-light .select-form-input-container .select-form-input-options .select-form-input-option span.default-option {
                        color: #000000;
                    }
.illini-light .select-form-input-container:not(.open-upward) .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px rgba(196, 215, 237, 0.5)
                }
.illini-light .select-form-input-container:not(.open-upward) .select-form-input-options .select-form-input-option {
                        border-top: #375d81 1px solid;
                    }
.illini-light .select-form-input-container.open-upward .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px rgba(196, 215, 237, 0.5)
                }
.illini-light .select-form-input-container.open-upward .select-form-input-options .select-form-input-option {
                        border-bottom: #375d81 1px solid;
                    }
.illini-light .select-form-input-container [data-tooltipselectoption]:hover::before {
                background: #abc8e2;
                color: #000000;
            }
.sky .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
            background-color: #01425e;
            color: #ffffff;
        }
.sky .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
            background-color: #01425e;
            color: #ffffff;
        }
.sky .select-form-input-container .select-form-input-options .select-form-input-option.selected {
            color: #ffffff;
        }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option:not(.header):hover {
            background-color: #01425e;
            color: #ffffff;
        }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option.filter-match {
            background-color: #01425e;
            color: #ffffff;
        }
.sky-light .select-form-input-container .select-form-input-options .select-form-input-option.selected {
            color: #ffffff;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
/*TODO: Clean up, create a common style css file*/
.data-table-cont {
    width: 1240px;
    margin: auto;
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300;
    flex: 1 1;
    display: flex;
    flex-flow: column;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.grid-col-5 {
    width: 5%;
    max-width: 60px;
}
.grid-col-10 {
    width: 10%;
    max-width: 121px;
}
.grid-col-13 {
    width: 13%;
    max-width: 13%;
}
.grid-col-15 {
    width: 15%;
    max-width: 183px;
}
.grid-col-16 {
    width: 16%;
    max-width: 16%;
}
.grid-col-18 {
    width: 18%;
    max-width: 200px;
}
.grid-col-20 {
    width: 20%;
    max-width: 242px;
}
.grid-col-25 {
    width: 25%;
    max-width: 255px;
}
.grid-col-30 {
    width: 30%;
    max-width: 369px;
}
.grid-col-40 {
    width: 40%;
    max-width: 484px;
}
.grid-expand {
    flex-grow: 1;
}
.col-shift-15 {
    left: 15px;
    margin-right: 15px;
}
.flex-grow-2 .data-table-cell {
        flex-grow: 2;
    }
.data-table-rows {
    margin-left: auto;
    margin-right: auto;
    clear: both;
    overflow-y: auto;
    flex-basis: max-content;
    width: 100%
}
.data-table-rows:after {
        flex-basis: "max-content";
    }
.data-table-rows .data-table-plain-row {
        padding-left: 20px;
    }
.data-table-rows .dt-row-log-window .data-table-cell.data-table-cell-relative {
            padding-left: 40px;
        }
.data-table-row {
    height: 26px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-shrink: 0;
}
.data-table-header {
    flex-shrink: 0;
    font-size: 13px
}
.data-table-header .search-input .form-input {
            margin: 0px;
            padding: 0px;
            border: none;
        }
.data-table-header .data-table-container {
        padding-top: 0px
    }
.data-table-header .data-table-container .default-svg-icon {
            height: 26px;
            width: 26px;
        }
.data-table-header .search-input .input-container input {
        width: 100%;
    }
.data-table-cell-relative {
    position: relative;
}
.data-table-cell {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 1px;
    min-height: 15px
}
.data-table-cell .svg-container {
        padding-right: 2px;
    }
.data-table-cell .checkbox-div {
        padding-top: 2px;
    }
.data-table-cell p,
    .data-table-cell a {
        max-width: 97%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 15px;
        font-size: 13px;
    }
.data-table-cell-absolute {
    position: absolute;
}
.data-table-row-selected .data-table-container .data-table-icon-box .svg-container {
                cursor: pointer;
            }
.data-table-paginator {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px 0 10px 5px;
    flex-shrink: 0;
}
.data-table-paginator-indices {
    display: flex;
    flex-wrap: nowrap;
}
.data-table-paginator-input {
    display: flex;
    align-items: center;
    margin-right: 22px
}
.data-table-paginator-input span {
        margin-right: 5px;
    }
.data-table-paginator-input .select-form-input-container {
        width: 60px;
        height: 20px;
        margin: 0;
        padding-bottom: 10px
    }
.data-table-paginator-input .select-form-input-container input {
            width: 30px;
        }
.data-table-paginator-input .select-form-input-container .form-input {
            margin: 0;
            padding: 0;
            height: auto;
        }
.data-table-paginator-input .select-form-input-container .input-label-container {
            display: none;
        }
.line-item {
    padding: 5px 0 0 5px;
    display: inline;
}
.data-header-sort {
    padding-left: 3px;
    padding-top: 10px;
    position: relative;
    flex-grow: 1;
    min-width: 20px;
}
.data-header-sort-shrinked {
    min-width: 15px;
    padding-left: 0px;
    padding-top: 10px;
}
.data-header-cell {
    display: flex
}
.data-header-cell .data-table-cell-relative {
        display: flex;
    }
.data-header-cell .filter-container {
        width: calc(100% - 5px)
    }
.data-header-cell .filter-container .auto-search-input.select-form-input-container {
            border: 1px solid transparent;
        }
.data-header-cell .filter-container div.text-form-input .input-container input {
            text-indent: 0px;
            cursor: text;
            margin-right: 5px;
            margin-left: 2px;
        }
.data-header-cell .filter-container .filter-input .form-input.text-form-input.focus {
                border: none;
            }
.data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
        border-radius: 4px;
    }
.data-header-cell .filter-input.has-filter-value.list-expanded.select-form-input-expanded.focus {
        border-radius: 4px 4px 0 0;
        border-bottom: none;
    }
.data-header-cell .select-form-options {
        position: fixed;
        z-index: 1
    }
.data-header-cell .select-form-options .form-input {
            margin: 0px;
        }
.data-header-cell .data-header-sorting-icon {
        height: 15px;
        width: 15px;
    }
.filter-input {
    height: 24px;
    margin: 0px;
    padding: 0px;
}
.data-table-container {
    height: 100%;
    min-width: 50px;
    padding-left: 15px
}
.data-table-container .nested-grid-expand-button {
        width: 100%;
        display: flex;
        justify-content: flex-end
    }
.data-table-container .nested-grid-expand-button .svg-container {
            cursor: pointer;
        }
.data-table-container .default-svg-icon {
        height: 22px;
        width: 22px;
    }
.data-table-footer {
    display: flex;
    height: 20px;
    align-items: center;
    padding-left: 15px;
    flex-shrink: 0
}
.data-table-footer a {
        margin-left: 10px;
    }
.data-table-footer .justify-links {
        margin-right: 40px;
    }
.data-table-add-icon {
    cursor: pointer;
}
.invisible-add-edit-icon {
    visibility: hidden;
    pointer-events: none;
}
.bar {
    margin-left: 10px;
}
.data-table-icon-box {
    display: flex;
}
.filter-input .auto-search-options {
        margin-top: 5px;
    }
.default .data-table-row {
            border-bottom: 1px solid #404040;
        }
.default .data-table-header {
            background-color: #404040;
        }
.default .data-table-row-selected {
            background-color: #1daaef;
        }
.default .line-item a:focus {
                color: #1daaef;
            }
.default .line-item a:active {
                color: #1daaef;
            }
.default .line-item .selected-page-index {
                color: #8fa61a !important;
            }
.default .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.default .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.default .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.default .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.default .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.default .data-table-footer {
            background-color: #404040
        }
.default .data-table-footer .disabled-link {
                color: #777777;
            }
.default .bar {
            border-left: 1px solid #bfbfbf;
        }
.default-light .data-table-row {
            border-bottom: 1px solid #919191;
        }
.default-light .data-table-header {
            background-color: #919191;
        }
.default-light .data-table-row-selected {
            background-color: #0e55a8;
        }
.default-light .line-item a:focus {
                color: #0e55a8;
            }
.default-light .line-item a:active {
                color: #0e55a8;
            }
.default-light .line-item .selected-page-index {
                color: #8fa61a !important;
            }
.default-light .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.default-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.default-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.default-light .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.default-light .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.default-light .data-table-footer {
            background-color: #919191
        }
.default-light .data-table-footer .disabled-link {
                color: #777777;
            }
.default-light .bar {
            border-left: 1px solid #cfcfcf;
        }
.sky .data-table-row {
            border-bottom: 1px solid #0194d3;
        }
.sky .data-table-header {
            background-color: #0194d3;
        }
.sky .data-table-row-selected {
            background-color: #01425e;
        }
.sky .line-item a:focus {
                color: #01425e;
            }
.sky .line-item a:active {
                color: #01425e;
            }
.sky .line-item .selected-page-index {
                color: #235635 !important;
            }
.sky .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.sky .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.sky .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.sky .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.sky .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.sky .data-table-footer {
            background-color: #0194d3
        }
.sky .data-table-footer .disabled-link {
                color: #878787;
            }
.sky .bar {
            border-left: 1px solid #c8c8c8;
        }
.sky-light .data-table-row {
            border-bottom: 1px solid #0194d3;
        }
.sky-light .data-table-header {
            background-color: #0194d3;
        }
.sky-light .data-table-row-selected {
            background-color: #01425e;
        }
.sky-light .line-item a:focus {
                color: #01425e;
            }
.sky-light .line-item a:active {
                color: #01425e;
            }
.sky-light .line-item .selected-page-index {
                color: #235635 !important;
            }
.sky-light .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.sky-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.sky-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.sky-light .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.sky-light .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.sky-light .data-table-footer {
            background-color: #0194d3
        }
.sky-light .data-table-footer .disabled-link {
                color: #878787;
            }
.sky-light .bar {
            border-left: 1px solid #c8c8c8;
        }
.grass .data-table-row {
            border-bottom: 1px solid #325132;
        }
.grass .data-table-header {
            background-color: #325132;
        }
.grass .data-table-row-selected {
            background-color: #e26816;
        }
.grass .line-item a:focus {
                color: #e26816;
            }
.grass .line-item a:active {
                color: #e26816;
            }
.grass .line-item .selected-page-index {
                color: #ffff00 !important;
            }
.grass .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.grass .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.grass .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.grass .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.grass .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.grass .data-table-footer {
            background-color: #325132
        }
.grass .data-table-footer .disabled-link {
                color: #e5e9e5;
            }
.grass .bar {
            border-left: 1px solid #9cadb2;
        }
.grass-light .data-table-row {
            border-bottom: 1px solid #545f54;
        }
.grass-light .data-table-header {
            background-color: #545f54;
        }
.grass-light .data-table-row-selected {
            background-color: #e26816;
        }
.grass-light .line-item a:focus {
                color: #e26816;
            }
.grass-light .line-item a:active {
                color: #e26816;
            }
.grass-light .line-item .selected-page-index {
                color: #ffff00 !important;
            }
.grass-light .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.grass-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.grass-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.grass-light .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.grass-light .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.grass-light .data-table-footer {
            background-color: #545f54
        }
.grass-light .data-table-footer .disabled-link {
                color: #e5e9e5;
            }
.grass-light .bar {
            border-left: 1px solid #424443;
        }
.mud .data-table-row {
            border-bottom: 1px solid #766146;
        }
.mud .data-table-header {
            background-color: #766146;
        }
.mud .data-table-row-selected {
            background-color: #f0c248;
        }
.mud .line-item a:focus {
                color: #f0c248;
            }
.mud .line-item a:active {
                color: #f0c248;
            }
.mud .line-item .selected-page-index {
                color: #ffff00 !important;
            }
.mud .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.mud .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(243, 145, 4, 0.7);
            }
.mud .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(243, 145, 4, 0.7);
            }
.mud .filter-input.has-filter-value .input-container {
                background-color: rgba(243, 145, 4, 0.7)
            }
.mud .filter-input.has-filter-value .input-container input {
                    background-color: rgba(243, 145, 4, 0.7);
                }
.mud .data-table-footer {
            background-color: #766146
        }
.mud .data-table-footer .disabled-link {
                color: #777777;
            }
.mud .bar {
            border-left: 1px solid #f2f3f8;
        }
.mud-light .data-table-row {
            border-bottom: 1px solid #a37b42;
        }
.mud-light .data-table-header {
            background-color: #a37b42;
        }
.mud-light .data-table-row-selected {
            background-color: #0e55a8;
        }
.mud-light .line-item a:focus {
                color: #0e55a8;
            }
.mud-light .line-item a:active {
                color: #0e55a8;
            }
.mud-light .line-item .selected-page-index {
                color: #8fa61a !important;
            }
.mud-light .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.mud-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.mud-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(226, 104, 22, 0.7);
            }
.mud-light .filter-input.has-filter-value .input-container {
                background-color: rgba(226, 104, 22, 0.7)
            }
.mud-light .filter-input.has-filter-value .input-container input {
                    background-color: rgba(226, 104, 22, 0.7);
                }
.mud-light .data-table-footer {
            background-color: #a37b42
        }
.mud-light .data-table-footer .disabled-link {
                color: #777777;
            }
.mud-light .bar {
            border-left: 1px solid #d6c282;
        }
.illini .data-table-row {
            border-bottom: 1px solid #636466;
        }
.illini .data-table-header {
            background-color: #636466;
        }
.illini .data-table-row-selected {
            background-color: #f39c11;
        }
.illini .line-item a:focus {
                color: #f39c11;
            }
.illini .line-item a:active {
                color: #f39c11;
            }
.illini .line-item .selected-page-index {
                color: #ffff00 !important;
            }
.illini .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.illini .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(243, 156, 17, 0.7);
            }
.illini .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(243, 156, 17, 0.7);
            }
.illini .filter-input.has-filter-value .input-container {
                background-color: rgba(243, 156, 17, 0.7)
            }
.illini .filter-input.has-filter-value .input-container input {
                    background-color: rgba(243, 156, 17, 0.7);
                }
.illini .data-table-footer {
            background-color: #636466
        }
.illini .data-table-footer .disabled-link {
                color: #777777;
            }
.illini .bar {
            border-left: 1px solid #f2f3f8;
        }
.illini-light .data-table-row {
            border-bottom: 1px solid #375d81;
        }
.illini-light .data-table-header {
            background-color: #375d81;
        }
.illini-light .data-table-row-selected {
            background-color: #b2962c;
        }
.illini-light .line-item a:focus {
                color: #b2962c;
            }
.illini-light .line-item a:active {
                color: #b2962c;
            }
.illini-light .line-item .selected-page-index {
                color: #ffff00 !important;
            }
.illini-light .data-header-cell .select-form-input.select-form-input-expanded.form-input.focus {
                padding: 0px;
            }
.illini-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(243, 156, 17, 0.7);
            }
.illini-light .filter-input.has-filter-value .form-input.text-form-input {
                background-color: rgba(243, 156, 17, 0.7);
            }
.illini-light .filter-input.has-filter-value .input-container {
                background-color: rgba(243, 156, 17, 0.7)
            }
.illini-light .filter-input.has-filter-value .input-container input {
                    background-color: rgba(243, 156, 17, 0.7);
                }
.illini-light .data-table-footer {
            background-color: #375d81
        }
.illini-light .data-table-footer .disabled-link {
                color: #777777;
            }
.illini-light .bar {
            border-left: 1px solid #abc8e2;
        }
.sky .data-table-header {
            color: #ffffff
        }
.sky .data-table-header .svg-container svg {
                fill: #ffffff;
            }
.sky .data-table-row-selected {
            background-color: #01425e;
            color: #ffffff
        }
.sky .data-table-row-selected .svg-container svg {
                fill: #ffffff;
            }
.sky .data-table-footer {
            font-weight: bold
        }
.sky .data-table-footer .disabled-link {
                color: #c8c8c8;
                font-weight: normal;
            }
.sky-light .data-table-header {
            color: #ffffff
        }
.sky-light .data-table-header .svg-container svg {
                fill: #ffffff;
            }
.sky-light .data-table-row-selected {
            background-color: #01425e;
            color: #ffffff
        }
.sky-light .data-table-row-selected .svg-container svg {
                fill: #ffffff;
            }
.sky-light .data-table-footer {
            font-weight: bold
        }
.sky-light .data-table-footer .disabled-link {
                color: #c8c8c8;
                font-weight: normal;
            }

.data-table-container {
    width: 80px;
    display: flex;
    justify-content: center
}

.data-table-container.data-table-checkbox {
        width: 44px;
    }

.data-table-container .expand-collapse-icon {
        height: 14px;
        width: 13px;
    }

.collapse-button-rotate-90 {
    transform: rotate(-90deg);
}

.collapse-button-rotate-45 {
    transform: rotate(-45deg);
}

.row-left-content {
    width: 30px;
    padding-top: 10px;
}

.nested-data-table .data-table-header .data-table-container {
            width: 60px
        }

.nested-data-table .data-table-header .data-table-container.data-table-checkbox {
                width: 44px;
            }

.nested-data-table .nested-table-row .data-table-container {
            padding-left: 0px;
            min-width: 44px;
            width: 50px
        }

.nested-data-table .nested-table-row .data-table-container.data-table-checkbox {
                width: 44px;
            }

.nested-data-table .nested-table-row .row-left-content .svg-container {
                padding-left: 20px;
            }

.child-component {
    margin-left: 15px
}

.child-component .data-table-cont {
        width: 1188px;
        margin-top: 3px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.simple-table .ReactVirtualized__Table__headerRow,
    .simple-table .ReactVirtualized__Table__row {
        box-sizing: border-box;
        cursor: pointer;
        line-height: normal;
    }
.simple-table .ReactVirtualized__Table__headerColumn > span {
        vertical-align: text-bottom;
    }
.simple-table .ReactVirtualized__Table__headerRow {
        text-transform: none;
        font-weight: 300;
        font-size: 14px;
    }
.default .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #404040;
        }
.default .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #404040;
        }
.default .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #000000;
        }
.default .simple-table .row-selected {
            background-color: #1daaef !important;
        }
.default-light .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #919191;
        }
.default-light .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #919191;
        }
.default-light .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #fcfcfc;
        }
.default-light .simple-table .row-selected {
            background-color: #0e55a8 !important;
        }
.sky .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #0194d3;
        }
.sky .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #0194d3;
        }
.sky .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #fcfcfc;
        }
.sky .simple-table .row-selected {
            background-color: #01425e !important;
        }
.sky-light .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #0194d3;
        }
.sky-light .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #0194d3;
        }
.sky-light .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #fcfcfc;
        }
.sky-light .simple-table .row-selected {
            background-color: #01425e !important;
        }
.grass .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #325132;
        }
.grass .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #325132;
        }
.grass .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #667c66;
        }
.grass .simple-table .row-selected {
            background-color: #e26816 !important;
        }
.grass-light .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #545f54;
        }
.grass-light .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #545f54;
        }
.grass-light .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #babfba;
        }
.grass-light .simple-table .row-selected {
            background-color: #e26816 !important;
        }
.mud .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #766146;
        }
.mud .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #766146;
        }
.mud .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #4b3705;
        }
.mud .simple-table .row-selected {
            background-color: #f0c248 !important;
        }
.mud-light .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #a37b42;
        }
.mud-light .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #a37b42;
        }
.mud-light .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #fff0bb;
        }
.mud-light .simple-table .row-selected {
            background-color: #0e55a8 !important;
        }
.illini .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #636466;
        }
.illini .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #636466;
        }
.illini .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #00467f;
        }
.illini .simple-table .row-selected {
            background-color: #f39c11 !important;
        }
.illini-light .simple-table .ReactVirtualized__Table__headerRow {
            background-color: #375d81;
        }
.illini-light .simple-table .ReactVirtualized__Table__row:hover {
            background-color: #375d81;
        }
.illini-light .simple-table .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #e1e6fa;
        }
.illini-light .simple-table .row-selected {
            background-color: #b2962c !important;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.plain-data-table {
    width: 100%;
    overflow-y: auto;
    font-size: 13px
}
.plain-data-table .data-header-cell {
        max-width: 102px;
        flex-grow: 1;
        height: 100%;
        line-height: 26px;
        padding-left: 5px
    }
.plain-data-table .data-header-cell .plain-data-table-sort-icon {
            padding-top: 5px;
        }
.plain-data-table .data-header-cell:last-child {
        max-width: none;
    }
.plain-data-table .data-header-cell:first-child {
        padding-left: 0px;
    }
.plain-data-table .data-table-row-restrict {
        padding-left: 34px;
    }
.plain-data-table .plain-table-body {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 225px;
        margin-bottom: 5px
    }
.plain-data-table .plain-table-body .data-table-row-selected .z-n-icon {
                cursor: pointer;
            }
.plain-data-table .data-table-cell {
        max-width: 96px;
        padding-left: 15px;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
.plain-data-table .z-n-icon {
        padding-left: 0px;
        width: 30px;
        flex-grow: 0
    }
.plain-data-table .z-n-icon:first-child {
            padding-left: 4px;
        }
.plain-data-table .z-n-icon .pencil-icon {
            width: 18px;
            height: 18px;
        }
.cell-stretch .data-table-row .data-table-cell {
            max-width: 80%;
        }
.default .plain-data-table .plain-table-body {
        border-bottom: 5px solid #404040;
    }
.default-light .plain-data-table .plain-table-body {
        border-bottom: 5px solid #919191;
    }
.sky .plain-data-table .plain-table-body {
        border-bottom: 5px solid #0194d3;
    }
.sky-light .plain-data-table .plain-table-body {
        border-bottom: 5px solid #0194d3;
    }
.grass .plain-data-table .plain-table-body {
        border-bottom: 5px solid #325132;
    }
.grass-light .plain-data-table .plain-table-body {
        border-bottom: 5px solid #545f54;
    }
.mud .plain-data-table .plain-table-body {
        border-bottom: 5px solid #766146;
    }
.mud-light .plain-data-table .plain-table-body {
        border-bottom: 5px solid #a37b42;
    }
.illini .plain-data-table .plain-table-body {
        border-bottom: 5px solid #636466;
    }
.illini-light .plain-data-table .plain-table-body {
        border-bottom: 5px solid #375d81;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
/*!
 * https://github.com/arqex/react-datetime
 */
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtPicker .rdtTimeToggle {
  text-align: center;
}
.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}
.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}
.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}
.rdtPicker thead button {
  width: 100%;
  height: 100%;
}
td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}
.rdtCounters {
  display: inline-block;
}
.rdtCounters > div {
  float: left;
}
.rdtCounter {
  height: 100px;
}
.rdtCounter {
  width: 40px;
}
.rdtCounterSeparator {
  line-height: 100px;
}
.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}
.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}
.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
.rdtTime td {
  cursor: default;
}
.date-form-input-container {
    position: relative;
    min-width: 20px;
    cursor: pointer
}
.date-form-input-container .expand-contract-date-icon {
        margin: 2px 2px 2px 0;
        width: 20px;
        height: 20px;
    }
.date-form-input-container .clear-date-icon {
        width: 20px;
    }
.date-form-input-container .rdtStatic {
        position: absolute;
        left: 10px;
        margin-top: -11px;
        z-index: 100;
    }
.date-form-input-container .date-open-left.rdtStatic {
        left: 0;
        right: auto;
    }
.date-form-input-container .date-open-right.rdtStatic {
        left: auto;
        right: 0;
    }
.date-form-input-container .rdtPicker {
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        padding: 2;
        border-radius: 2px 2px 8px 8px
    }
.date-form-input-container .rdtPicker .dow {
            cursor: default;
        }
.date-form-input-container .rdtPicker td,
        .date-form-input-container .rdtPicker th {
            vertical-align: middle;
        }
.date-form-input-container .rdtPicker .rdtPrev {
            width: 38px;
        }
.date-form-input-container .rdtPicker .rdtNext {
            width: 38px;
        }
.date-form-input-container .rdtPicker th.rdtSwitch {
            width: inherit
        }
.date-form-input-container .rdtPicker th.rdtSwitch:hover {
                background-color: inherit;
            }
.date-form-input-container .rdtPicker .rdtTime td {
                cursor: default;
            }
.date-form-input-container .rdtPicker .rdtTime :hover {
                background-color: inherit;
            }
.date-form-input-container .rdtPicker .rdtTime table tbody {
                background-color: inherit;
            }
.date-form-input-container .rdtPicker thead tr:first-child th:hover {
            background-color: inherit;
        }
.date-form-input-container .rdtPicker .rdtTimeToggle:hover {
            background-color: inherit;
            cursor: pointer;
        }
.default .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.default .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.default .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.default .rdtPicker {
            background: #000000
        }
.default .rdtPicker :not(.rdtTime) tbody {
                background-color: #404040;
            }
.default .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.default .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.default .rdtPicker .rdtTime td.rdtActive {
                    background-color: #e26816;
                }
.default .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #e26816;
                }
.default .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #1daaef;
                    }
.default-light .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.default-light .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.default-light .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.default-light .rdtPicker {
            background: #fcfcfc
        }
.default-light .rdtPicker :not(.rdtTime) tbody {
                background-color: #919191;
            }
.default-light .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.default-light .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.default-light .rdtPicker .rdtTime td.rdtActive {
                    background-color: #e26816;
                }
.default-light .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #e26816;
                }
.default-light .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.default-light .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.sky .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.sky .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.sky .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.sky .rdtPicker {
            background: #fcfcfc
        }
.sky .rdtPicker :not(.rdtTime) tbody {
                background-color: #0194d3;
            }
.sky .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.sky .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.sky .rdtPicker .rdtTime td.rdtActive {
                    background-color: #01425e;
                }
.sky .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #01425e;
                }
.sky .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.sky-light .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.sky-light .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.sky-light .rdtPicker {
            background: #fcfcfc
        }
.sky-light .rdtPicker :not(.rdtTime) tbody {
                background-color: #0194d3;
            }
.sky-light .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.sky-light .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.sky-light .rdtPicker .rdtTime td.rdtActive {
                    background-color: #01425e;
                }
.sky-light .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #01425e;
                }
.sky-light .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.sky-light .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                    }
.grass .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.grass .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.grass .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.grass .rdtPicker {
            background: #667c66
        }
.grass .rdtPicker :not(.rdtTime) tbody {
                background-color: #325132;
            }
.grass .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.grass .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.grass .rdtPicker .rdtTime td.rdtActive {
                    background-color: #e26816;
                }
.grass .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #e26816;
                }
.grass .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.grass-light .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.grass-light .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.grass-light .rdtPicker {
            background: #babfba
        }
.grass-light .rdtPicker :not(.rdtTime) tbody {
                background-color: #545f54;
            }
.grass-light .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.grass-light .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.grass-light .rdtPicker .rdtTime td.rdtActive {
                    background-color: #e26816;
                }
.grass-light .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #e26816;
                }
.grass-light .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.grass-light .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #e26816;
                    }
.mud .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.mud .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.mud .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.mud .rdtPicker {
            background: #4b3705
        }
.mud .rdtPicker :not(.rdtTime) tbody {
                background-color: #766146;
            }
.mud .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.mud .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.mud .rdtPicker .rdtTime td.rdtActive {
                    background-color: #f39104;
                }
.mud .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #f39104;
                }
.mud .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #f0c248;
                    }
.mud-light .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.mud-light .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.mud-light .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.mud-light .rdtPicker {
            background: #fff0bb
        }
.mud-light .rdtPicker :not(.rdtTime) tbody {
                background-color: #a37b42;
            }
.mud-light .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.mud-light .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.mud-light .rdtPicker .rdtTime td.rdtActive {
                    background-color: #e26816;
                }
.mud-light .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #e26816;
                }
.mud-light .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.mud-light .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #0e55a8;
                    }
.illini .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.illini .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.illini .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.illini .rdtPicker {
            background: #00467f
        }
.illini .rdtPicker :not(.rdtTime) tbody {
                background-color: #636466;
            }
.illini .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.illini .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.illini .rdtPicker .rdtTime td.rdtActive {
                    background-color: #f39c11;
                }
.illini .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #f39c11;
                }
.illini .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #f39c11;
                    }
.illini-light .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.illini-light .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.illini-light .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.illini-light .rdtPicker {
            background: #e1e6fa
        }
.illini-light .rdtPicker :not(.rdtTime) tbody {
                background-color: #375d81;
            }
.illini-light .rdtPicker .rdtPrev {
                border-right: #ffffff 1px solid;
            }
.illini-light .rdtPicker .rdtNext {
                border-left: #ffffff 1px solid;
            }
.illini-light .rdtPicker .rdtTime td.rdtActive {
                    background-color: #f39c11;
                }
.illini-light .rdtPicker .rdtTime td.rdtActive:hover {
                    background-color: #f39c11;
                }
.illini-light .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.illini-light .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #b2962c;
                    }
.sky .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.sky .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.sky .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.sky .rdtPicker :not(.rdtTime) tbody {
                background-color: #e2e2e2;
            }
.sky .rdtPicker .rdtTime td.rdtActive {
                background-color: #01425e;
                color: #ffffff;
            }
.sky .rdtPicker td.rdtActive:hover {
                background-color: #01425e;
                color: #ffffff;
            }
.sky .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .date-form-input-container .expand-contract-date-icon {
                fill: #000000;
            }
.sky-light .date-form-input-container .clear-date-icon {
                fill: #000000
            }
.sky-light .date-form-input-container .clear-date-icon:hover {
                    border: #000000 1px dotted;
                }
.sky-light .rdtPicker :not(.rdtTime) tbody {
                background-color: #e2e2e2;
            }
.sky-light .rdtPicker .rdtTime td.rdtActive {
                background-color: #01425e;
                color: #ffffff;
            }
.sky-light .rdtPicker td.rdtActive:hover {
                background-color: #01425e;
                color: #ffffff;
            }
.sky-light .rdtPicker .rdtDays td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtDays td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtDays td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtDays th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtDays th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtDays th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtMonths td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtMonths td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtMonths td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtMonths th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtMonths th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtMonths th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtYears td.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtYears td.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtYears td.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtYears th.rdtDay:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtYears th.rdtMonth:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }
.sky-light .rdtPicker .rdtYears th.rdtYear:not(.rdtActive):hover {
                        background-color: #01425e;
                        color: #ffffff;
                    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.letter-icon {
    height: 17px;
    min-width: 17px;
    line-height: 17px;
    font-size: 12px;
    border-radius: 50%;
    margin: 3px 0 3px 20px;
    text-align: center;
}
.dialog-window {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog-container {
    position: relative;
    min-width: 300px;
    max-height: 95%;
    max-width: 95%;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    flex-direction: column
}
.dialog-container .dialog-box-title {
        font-size: 15px;
        font-family: "open_sansregular";
        font-weight: 500;
        text-align: left;
        min-height: 28px;
        min-width: 100%;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0
    }
.dialog-container .dialog-box-title .letter-icon {
            margin: 3px;
        }
.dialog-container .dialog-box-title.restricted {
            max-width: 450px;
        }
.dialog-container .dialog-box-title > div {
            padding: 3px;
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
.dialog-container .dialog-box-body {
        font-size: 13px;
        font-family: "open_sansregular";
        font-weight: 300;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        min-height: 41px;
        padding: 0 10px;
        margin: 10px 0;
        flex-grow: 1
    }
.dialog-container .dialog-box-body.restricted {
            max-height: 300px;
            max-width: 450px;
        }
.dialog-container .dialog-box-body {

        /*fixes scrollbar edge rounding issues:*/
        width: calc(100% + 0.5px);
        box-sizing: border-box
    }
.dialog-container .dialog-box-body > div {
            padding: 2px 0;
        }
.dialog-container .dialog-box-body .select-form-input-container .select-form-options {
            margin-top: 0;
        }
.dialog-container .dialog-box-footer {
        padding: 5px 0 0 0;
        min-height: 38px;
        display: flex;
        justify-content: flex-end
    }
.dialog-container .dialog-box-footer .default-button:last-child {
            margin-right: 2px;
        }
.dialog-container .dialog-drag-bar {
        height: 25px;
        width: 100%;
        margin: -5px;
        cursor: move;
        position: absolute;
        z-index: 2;
    }
.dialog-container .dialog-tool-icon {
        cursor: pointer;
        z-index: 3;
        margin-right: 10px;
    }
.dialog-container .dialog-tool-icon:last-child {
        margin-right: 5px;
    }
.dialog-container.allow-overflow .dialog-box-body {
        overflow: visible;
    }
.dialog-container .loader-container {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
.dialog-expanded .dialog-box-body.restricted {
    min-width: 585px;
}
.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.error-message-dialog-box .dialog-box-body {
        -webkit-user-select: auto;
            -ms-user-select: auto;
                user-select: auto;
        * > div {
            padding-bottom: 7px;
        }
    }
.dialog-container.confirm-dialog .dialog-box-body {
    text-align: center;
}
.default .letter-icon {
        color: #ffffff;
        border: 1px solid #ffffff;
        box-shadow: 0 0 1px #ffffff;
    }
.default .dialog-container {
        background: #000000;
        color: #ffffff;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
        border: 1px solid #ffffff
    }
.default .dialog-container .dialog-box-title {
            border-bottom: 1px solid #262626;
        }
.default .dialog-container .dialog-box-footer {
            border-top: 1px solid #262626;
        }
.default .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #ffffff;
        }
.default .dialog-container .header-theme-title {
            background: #737373;
        }
.default .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #8fa61a;
        }
.default .modal-overlay {
        background: rgba(223, 224, 219, 0.3);
    }
.default-light .letter-icon {
        color: #000000;
        border: 1px solid #000000;
        box-shadow: 0 0 1px #000000;
    }
.default-light .dialog-container {
        background: #fcfcfc;
        color: #000000;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
        border: 1px solid #000000
    }
.default-light .dialog-container .dialog-box-title {
            border-bottom: 1px solid #e2e2e2;
        }
.default-light .dialog-container .dialog-box-footer {
            border-top: 1px solid #e2e2e2;
        }
.default-light .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #000000;
        }
.default-light .dialog-container .header-theme-title {
            background: #b8b8b8;
        }
.default-light .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #8fa61a;
        }
.default-light .modal-overlay {
        background: rgba(223, 224, 219, 0.5);
    }
.sky .letter-icon {
        color: #000000;
        border: 1px solid #000000;
        box-shadow: 0 0 1px #000000;
    }
.sky .dialog-container {
        background: #fcfcfc;
        color: #000000;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
        border: 1px solid #000000
    }
.sky .dialog-container .dialog-box-title {
            border-bottom: 1px solid #b1b1b1;
        }
.sky .dialog-container .dialog-box-footer {
            border-top: 1px solid #b1b1b1;
        }
.sky .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #000000;
        }
.sky .dialog-container .header-theme-title {
            background: #838383;
        }
.sky .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #235635;
        }
.sky .modal-overlay {
        background: rgba(223, 224, 219, 0.5);
    }
.sky-light .letter-icon {
        color: #000000;
        border: 1px solid #000000;
        box-shadow: 0 0 1px #000000;
    }
.sky-light .dialog-container {
        background: #fcfcfc;
        color: #000000;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
        border: 1px solid #000000
    }
.sky-light .dialog-container .dialog-box-title {
            border-bottom: 1px solid #b1b1b1;
        }
.sky-light .dialog-container .dialog-box-footer {
            border-top: 1px solid #b1b1b1;
        }
.sky-light .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #000000;
        }
.sky-light .dialog-container .header-theme-title {
            background: #838383;
        }
.sky-light .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #235635;
        }
.sky-light .modal-overlay {
        background: rgba(223, 224, 219, 0.5);
    }
.grass .letter-icon {
        color: #ffffff;
        border: 1px solid #ffffff;
        box-shadow: 0 0 1px #ffffff;
    }
.grass .dialog-container {
        background: #667c66;
        color: #ffffff;
        box-shadow: 0 3px 2px rgba(66, 68, 67, 0.3);
        border: 1px solid #ffffff
    }
.grass .dialog-container .dialog-box-title {
            border-bottom: 1px solid #424443;
        }
.grass .dialog-container .dialog-box-footer {
            border-top: 1px solid #424443;
        }
.grass .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #ffffff;
        }
.grass .dialog-container .header-theme-title {
            background: #424443;
        }
.grass .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #ffff00;
        }
.grass .modal-overlay {
        background: rgba(223, 224, 219, 0.3);
    }
.grass-light .letter-icon {
        color: #000000;
        border: 1px solid #000000;
        box-shadow: 0 0 1px #000000;
    }
.grass-light .dialog-container {
        background: #babfba;
        color: #000000;
        box-shadow: 0 3px 2px rgba(156, 173, 178, 0.3);
        border: 1px solid #000000
    }
.grass-light .dialog-container .dialog-box-title {
            border-bottom: 1px solid #9cadb2;
        }
.grass-light .dialog-container .dialog-box-footer {
            border-top: 1px solid #9cadb2;
        }
.grass-light .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #000000;
        }
.grass-light .dialog-container .header-theme-title {
            background: #bfbfbf;
        }
.grass-light .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #ffff00;
        }
.grass-light .modal-overlay {
        background: rgba(223, 224, 219, 0.5);
    }
.mud .letter-icon {
        color: #ffffff;
        border: 1px solid #ffffff;
        box-shadow: 0 0 1px #ffffff;
    }
.mud .dialog-container {
        background: #4b3705;
        color: #ffffff;
        box-shadow: 0 3px 2px rgba(140, 116, 84, 0.3);
        border: 1px solid #ffffff
    }
.mud .dialog-container .dialog-box-title {
            border-bottom: 1px solid #8c7454;
        }
.mud .dialog-container .dialog-box-footer {
            border-top: 1px solid #8c7454;
        }
.mud .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #ffffff;
        }
.mud .dialog-container .header-theme-title {
            background: #ad8f68;
        }
.mud .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #ffff00;
        }
.mud .modal-overlay {
        background: rgba(223, 224, 219, 0.3);
    }
.mud-light .letter-icon {
        color: #000000;
        border: 1px solid #000000;
        box-shadow: 0 0 1px #000000;
    }
.mud-light .dialog-container {
        background: #fff0bb;
        color: #000000;
        box-shadow: 0 3px 2px rgba(178, 143, 90, 0.3);
        border: 1px solid #000000
    }
.mud-light .dialog-container .dialog-box-title {
            border-bottom: 1px solid #b28f5a;
        }
.mud-light .dialog-container .dialog-box-footer {
            border-top: 1px solid #b28f5a;
        }
.mud-light .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #000000;
        }
.mud-light .dialog-container .header-theme-title {
            background: #735523;
        }
.mud-light .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #8fa61a;
        }
.mud-light .modal-overlay {
        background: rgba(223, 224, 219, 0.5);
    }
.illini .letter-icon {
        color: #ffffff;
        border: 1px solid #ffffff;
        box-shadow: 0 0 1px #ffffff;
    }
.illini .dialog-container {
        background: #00467f;
        color: #ffffff;
        box-shadow: 0 3px 2px rgba(171, 200, 226, 0.3);
        border: 1px solid #ffffff
    }
.illini .dialog-container .dialog-box-title {
            border-bottom: 1px solid #abc8e2;
        }
.illini .dialog-container .dialog-box-footer {
            border-top: 1px solid #abc8e2;
        }
.illini .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #ffffff;
        }
.illini .dialog-container .header-theme-title {
            background: #3698db;
        }
.illini .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #ffff00;
        }
.illini .modal-overlay {
        background: rgba(223, 224, 219, 0.3);
    }
.illini-light .letter-icon {
        color: #000000;
        border: 1px solid #000000;
        box-shadow: 0 0 1px #000000;
    }
.illini-light .dialog-container {
        background: #e1e6fa;
        color: #000000;
        box-shadow: 0 3px 2px rgba(196, 215, 237, 0.3);
        border: 1px solid #000000
    }
.illini-light .dialog-container .dialog-box-title {
            border-bottom: 1px solid #c4d7ed;
        }
.illini-light .dialog-container .dialog-box-footer {
            border-top: 1px solid #c4d7ed;
        }
.illini-light .dialog-container .dialog-tool-icon .default-svg-icon {
            fill: #000000;
        }
.illini-light .dialog-container .header-theme-title {
            background: #00467f;
        }
.illini-light .dialog-container .dialog-tool-icon:hover .default-svg-icon {
            fill: #ffff00;
        }
.illini-light .modal-overlay {
        background: rgba(223, 224, 219, 0.3);
    }

.draggable-list-cont {
    list-style: none;
    margin: auto;
    padding: 0;
    height: 250px;
    width: 350px;
    overflow: auto
}
.draggable-list-cont .draggable-list-row {
        padding: 5px;
        list-style-position: inside;
        font-size: 15px;
        display: flex;
        justify-content: space-between
    }
.draggable-list-cont .draggable-list-row .default-svg-icon {
            height: 15px;
            width: 15px;
            padding: 0 5px;
            color: red;
        }
.draggable-list-cont .draggable-list-row .draggable-text {
            flex-grow: 2;
            padding-left: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
.draggable-list-cont .draggable-list-row .svg-icon {
            flew-grow: 1;
        }
.draggable-list-cont .draggable-list-row .draggable-icon,
        .draggable-list-cont .draggable-list-row .draggable-pencil-icon,
        .draggable-list-cont .draggable-list-row .draggable-trashcan-icon {
        }
.draggable-list-cont .draggable-list-row.placeholder {
            height: 20px;
            width: 80%;
            border-radius: 4px;
            border: 2px dashed red;
        }
.draggable-list-cont .draggable-list-row:before {
            color: rgb(225, 210, 90);
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    z-index: 10000;
    animation: fadein 0.5s;
    cursor: default
}
.loader-container > div {
        display: flex;
        flex: 1 1;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
.default .loader-container > div {
        background-color: rgba(0, 0, 0, 0.5);
    }
.default-light .loader-container > div {
        background-color: rgba(0, 0, 0, 0.5);
    }
.sky .loader-container > div {
        background-color: rgba(0, 0, 0, 0.5);
    }
.sky-light .loader-container > div {
        background-color: rgba(0, 0, 0, 0.5);
    }
.grass .loader-container > div {
        background-color: rgba(66, 68, 67, 0.5);
    }
.grass-light .loader-container > div {
        background-color: rgba(156, 173, 178, 0.5);
    }
.mud .loader-container > div {
        background-color: rgba(140, 116, 84, 0.5);
    }
.mud-light .loader-container > div {
        background-color: rgba(178, 143, 90, 0.5);
    }
.illini .loader-container > div {
        background-color: rgba(171, 200, 226, 0.5);
    }
.illini-light .loader-container > div {
        background-color: rgba(196, 215, 237, 0.5);
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.menu-items,
.menu-dots {
    cursor: pointer;
}
.menu-dots {
    height: 30px;
    line-height: 14px;
    width: 18px;
}
.menu-dot {
    height: 8px;
    text-align: center;
    font-size: 7px;
}
.menu-items-arrow {
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 7px;
    margin-right: 2px
}
.menu-items-arrow .svg-container {
        pointer-events: none
    }
.menu-items-arrow .svg-container svg {
            width: 10px;
            height: 10px;
        }
.menu-item-right-arrow {
    float: right;
    margin-left: 5px;
    width: 10px
}
.menu-item-right-arrow .svg-container {
        pointer-events: none
    }
.menu-item-right-arrow .svg-container svg {
            width: 10px;
            height: 10px;
        }
.context-menu .menu-items-drop-down ul {
            font-size: 12px;
        }
.menu-items-drop-down {
    position: fixed;
    /* show above .loader-container */
    z-index: 10001
}
.menu-items-drop-down.menu-position-bottom {
        margin-top: 4px;
        border-radius: 0 0 4px 4px
    }
.menu-items-drop-down.menu-position-bottom li:first-child {
            border-radius: 0;
        }
.menu-items-drop-down.menu-position-bottom li:last-child {
            border-radius: 0 0 4px 4px;
        }
.menu-items-drop-down.menu-position-left {
        border-radius: 4px 0 0 4px
    }
.menu-items-drop-down.menu-position-left li:first-child {
            border-radius: 4px 0 0 0;
        }
.menu-items-drop-down.menu-position-left li:last-child {
            border-radius: 0 0 0 4px;
        }
.menu-items-drop-down.menu-position-right {
        border-radius: 0 4px 4px 0
    }
.menu-items-drop-down.menu-position-right li:first-child {
            border-radius: 0 4px 0 0;
        }
.menu-items-drop-down.menu-position-right li:last-child {
            border-radius: 0 0 4px 0;
        }
.menu-items-drop-down ul {
        list-style-type: none;
        display: block;
        text-align: left;
        margin: 0;
        padding: 0;
        font-family: "open_sansregular";
        font-size: 13px;
        font-weight: 300
    }
.menu-items-drop-down ul li {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            min-height: 24px;
            line-height: 24px;
            padding: 0 10px;
            list-style: none
        }
.menu-items-drop-down ul li span {
                vertical-align: baseline;
            }
.menu-items-drop-down ul li:last-child {
            border-bottom: none;
        }
.nav-menu.menu-items {
    padding: 2px 13px;
}
.default .menu-dots:hover {
            color: #8fa61a;
            background-color: #404040;
        }
.default .menu-items-arrow {
            fill: #ffffff;
        }
.default .menu-items-drop-down {
            background-color: #000000;
            box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
            border: solid 1px #404040
        }
.default .menu-items-drop-down li {
                border-bottom: 1px solid #404040
            }
.default .menu-items-drop-down li a {
                    color: #1daaef;
                }
.default .menu-items-drop-down li span {
                    color: #777777;
                }
.default .menu-items-drop-down li:hover {
                background-color: #404040
            }
.default .menu-items-drop-down li:hover a {
                    color: #8fa61a;
                }
.default .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #404040
                }
.default .menu-items-drop-down li:hover li a {
                        color: #1daaef;
                    }
.default .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.default .menu-items-drop-down li:hover li:hover a {
                        color: #8fa61a;
                    }
.default .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #8fa61a;
                    }
.default .nav-menu.menu-items:hover a.menu-items-text {
                color: #8fa61a;
            }
.default .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #8fa61a;
            }
.default .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #8fa61a;
                }
.default .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #8fa61a;
                }
.default-light .menu-dots:hover {
            color: #8fa61a;
            background-color: #919191;
        }
.default-light .menu-items-arrow {
            fill: #ffffff;
        }
.default-light .menu-items-drop-down {
            background-color: #fcfcfc;
            box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
            border: solid 1px #919191
        }
.default-light .menu-items-drop-down li {
                border-bottom: 1px solid #919191
            }
.default-light .menu-items-drop-down li a {
                    color: #0e55a8;
                }
.default-light .menu-items-drop-down li span {
                    color: #777777;
                }
.default-light .menu-items-drop-down li:hover {
                background-color: #404040
            }
.default-light .menu-items-drop-down li:hover a {
                    color: #8fa61a;
                }
.default-light .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #919191
                }
.default-light .menu-items-drop-down li:hover li a {
                        color: #0e55a8;
                    }
.default-light .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.default-light .menu-items-drop-down li:hover li:hover a {
                        color: #8fa61a;
                    }
.default-light .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #8fa61a;
                    }
.default-light .nav-menu.menu-items:hover a.menu-items-text {
                color: #8fa61a;
            }
.default-light .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #8fa61a;
            }
.default-light .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #8fa61a;
                }
.default-light .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #8fa61a;
                }
.sky .menu-dots:hover {
            color: #235635;
            background-color: #0194d3;
        }
.sky .menu-items-arrow {
            fill: #ffffff;
        }
.sky .menu-items-drop-down {
            background-color: #fcfcfc;
            box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
            border: solid 1px #0194d3
        }
.sky .menu-items-drop-down li {
                border-bottom: 1px solid #0194d3
            }
.sky .menu-items-drop-down li a {
                    color: #01425e;
                }
.sky .menu-items-drop-down li span {
                    color: #878787;
                }
.sky .menu-items-drop-down li:hover {
                background-color: #afafaf
            }
.sky .menu-items-drop-down li:hover a {
                    color: #235635;
                }
.sky .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #0194d3
                }
.sky .menu-items-drop-down li:hover li a {
                        color: #01425e;
                    }
.sky .menu-items-drop-down li:hover li:hover {
                    background-color: #afafaf
                }
.sky .menu-items-drop-down li:hover li:hover a {
                        color: #235635;
                    }
.sky .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #235635;
                    }
.sky .nav-menu.menu-items:hover a.menu-items-text {
                color: #235635;
            }
.sky .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #235635;
            }
.sky .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #235635;
                }
.sky .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #235635;
                }
.sky-light .menu-dots:hover {
            color: #235635;
            background-color: #0194d3;
        }
.sky-light .menu-items-arrow {
            fill: #ffffff;
        }
.sky-light .menu-items-drop-down {
            background-color: #fcfcfc;
            box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
            border: solid 1px #0194d3
        }
.sky-light .menu-items-drop-down li {
                border-bottom: 1px solid #0194d3
            }
.sky-light .menu-items-drop-down li a {
                    color: #01425e;
                }
.sky-light .menu-items-drop-down li span {
                    color: #878787;
                }
.sky-light .menu-items-drop-down li:hover {
                background-color: #afafaf
            }
.sky-light .menu-items-drop-down li:hover a {
                    color: #235635;
                }
.sky-light .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #0194d3
                }
.sky-light .menu-items-drop-down li:hover li a {
                        color: #01425e;
                    }
.sky-light .menu-items-drop-down li:hover li:hover {
                    background-color: #afafaf
                }
.sky-light .menu-items-drop-down li:hover li:hover a {
                        color: #235635;
                    }
.sky-light .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #235635;
                    }
.sky-light .nav-menu.menu-items:hover a.menu-items-text {
                color: #235635;
            }
.sky-light .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #235635;
            }
.sky-light .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #235635;
                }
.sky-light .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #235635;
                }
.grass .menu-dots:hover {
            color: #ffff00;
            background-color: #325132;
        }
.grass .menu-items-arrow {
            fill: #ffffff;
        }
.grass .menu-items-drop-down {
            background-color: #667c66;
            box-shadow: 1px 2px 5px rgba(66, 68, 67, 0.2);
            border: solid 1px #325132
        }
.grass .menu-items-drop-down li {
                border-bottom: 1px solid #325132
            }
.grass .menu-items-drop-down li a {
                    color: #e26816;
                }
.grass .menu-items-drop-down li span {
                    color: #e5e9e5;
                }
.grass .menu-items-drop-down li:hover {
                background-color: #404040
            }
.grass .menu-items-drop-down li:hover a {
                    color: #ffff00;
                }
.grass .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #325132
                }
.grass .menu-items-drop-down li:hover li a {
                        color: #e26816;
                    }
.grass .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.grass .menu-items-drop-down li:hover li:hover a {
                        color: #ffff00;
                    }
.grass .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #ffff00;
                    }
.grass .nav-menu.menu-items:hover a.menu-items-text {
                color: #ffff00;
            }
.grass .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #ffff00;
            }
.grass .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #ffff00;
                }
.grass .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #ffff00;
                }
.grass-light .menu-dots:hover {
            color: #ffff00;
            background-color: #545f54;
        }
.grass-light .menu-items-arrow {
            fill: #ffffff;
        }
.grass-light .menu-items-drop-down {
            background-color: #babfba;
            box-shadow: 1px 2px 5px rgba(156, 173, 178, 0.2);
            border: solid 1px #545f54
        }
.grass-light .menu-items-drop-down li {
                border-bottom: 1px solid #545f54
            }
.grass-light .menu-items-drop-down li a {
                    color: #e26816;
                }
.grass-light .menu-items-drop-down li span {
                    color: #e5e9e5;
                }
.grass-light .menu-items-drop-down li:hover {
                background-color: #404040
            }
.grass-light .menu-items-drop-down li:hover a {
                    color: #ffff00;
                }
.grass-light .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #545f54
                }
.grass-light .menu-items-drop-down li:hover li a {
                        color: #e26816;
                    }
.grass-light .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.grass-light .menu-items-drop-down li:hover li:hover a {
                        color: #ffff00;
                    }
.grass-light .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #ffff00;
                    }
.grass-light .nav-menu.menu-items:hover a.menu-items-text {
                color: #ffff00;
            }
.grass-light .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #ffff00;
            }
.grass-light .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #ffff00;
                }
.grass-light .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #ffff00;
                }
.mud .menu-dots:hover {
            color: #ffff00;
            background-color: #766146;
        }
.mud .menu-items-arrow {
            fill: #ffffff;
        }
.mud .menu-items-drop-down {
            background-color: #4b3705;
            box-shadow: 1px 2px 5px rgba(140, 116, 84, 0.2);
            border: solid 1px #766146
        }
.mud .menu-items-drop-down li {
                border-bottom: 1px solid #766146
            }
.mud .menu-items-drop-down li a {
                    color: #f0c248;
                }
.mud .menu-items-drop-down li span {
                    color: #777777;
                }
.mud .menu-items-drop-down li:hover {
                background-color: #404040
            }
.mud .menu-items-drop-down li:hover a {
                    color: #ffff00;
                }
.mud .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #766146
                }
.mud .menu-items-drop-down li:hover li a {
                        color: #f0c248;
                    }
.mud .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.mud .menu-items-drop-down li:hover li:hover a {
                        color: #ffff00;
                    }
.mud .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #ffff00;
                    }
.mud .nav-menu.menu-items:hover a.menu-items-text {
                color: #ffff00;
            }
.mud .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #ffff00;
            }
.mud .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #ffff00;
                }
.mud .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #ffff00;
                }
.mud-light .menu-dots:hover {
            color: #8fa61a;
            background-color: #a37b42;
        }
.mud-light .menu-items-arrow {
            fill: #ffffff;
        }
.mud-light .menu-items-drop-down {
            background-color: #fff0bb;
            box-shadow: 1px 2px 5px rgba(178, 143, 90, 0.2);
            border: solid 1px #a37b42
        }
.mud-light .menu-items-drop-down li {
                border-bottom: 1px solid #a37b42
            }
.mud-light .menu-items-drop-down li a {
                    color: #0e55a8;
                }
.mud-light .menu-items-drop-down li span {
                    color: #777777;
                }
.mud-light .menu-items-drop-down li:hover {
                background-color: #404040
            }
.mud-light .menu-items-drop-down li:hover a {
                    color: #8fa61a;
                }
.mud-light .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #a37b42
                }
.mud-light .menu-items-drop-down li:hover li a {
                        color: #0e55a8;
                    }
.mud-light .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.mud-light .menu-items-drop-down li:hover li:hover a {
                        color: #8fa61a;
                    }
.mud-light .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #8fa61a;
                    }
.mud-light .nav-menu.menu-items:hover a.menu-items-text {
                color: #8fa61a;
            }
.mud-light .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #8fa61a;
            }
.mud-light .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #8fa61a;
                }
.mud-light .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #8fa61a;
                }
.illini .menu-dots:hover {
            color: #ffff00;
            background-color: #636466;
        }
.illini .menu-items-arrow {
            fill: #ffffff;
        }
.illini .menu-items-drop-down {
            background-color: #00467f;
            box-shadow: 1px 2px 5px rgba(171, 200, 226, 0.2);
            border: solid 1px #636466
        }
.illini .menu-items-drop-down li {
                border-bottom: 1px solid #636466
            }
.illini .menu-items-drop-down li a {
                    color: #f39c11;
                }
.illini .menu-items-drop-down li span {
                    color: #777777;
                }
.illini .menu-items-drop-down li:hover {
                background-color: #404040
            }
.illini .menu-items-drop-down li:hover a {
                    color: #ffff00;
                }
.illini .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #636466
                }
.illini .menu-items-drop-down li:hover li a {
                        color: #f39c11;
                    }
.illini .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.illini .menu-items-drop-down li:hover li:hover a {
                        color: #ffff00;
                    }
.illini .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #ffff00;
                    }
.illini .nav-menu.menu-items:hover a.menu-items-text {
                color: #ffff00;
            }
.illini .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #ffff00;
            }
.illini .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #ffff00;
                }
.illini .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #ffff00;
                }
.illini-light .menu-dots:hover {
            color: #ffff00;
            background-color: #375d81;
        }
.illini-light .menu-items-arrow {
            fill: #ffffff;
        }
.illini-light .menu-items-drop-down {
            background-color: #e1e6fa;
            box-shadow: 1px 2px 5px rgba(196, 215, 237, 0.2);
            border: solid 1px #375d81
        }
.illini-light .menu-items-drop-down li {
                border-bottom: 1px solid #375d81
            }
.illini-light .menu-items-drop-down li a {
                    color: #b2962c;
                }
.illini-light .menu-items-drop-down li span {
                    color: #777777;
                }
.illini-light .menu-items-drop-down li:hover {
                background-color: #404040
            }
.illini-light .menu-items-drop-down li:hover a {
                    color: #ffff00;
                }
.illini-light .menu-items-drop-down li:hover li {
                    border-bottom: 1px solid #375d81
                }
.illini-light .menu-items-drop-down li:hover li a {
                        color: #b2962c;
                    }
.illini-light .menu-items-drop-down li:hover li:hover {
                    background-color: #404040
                }
.illini-light .menu-items-drop-down li:hover li:hover a {
                        color: #ffff00;
                    }
.illini-light .menu-items-drop-down li:hover .menu-item-right-arrow svg {
                        fill: #ffff00;
                    }
.illini-light .nav-menu.menu-items:hover a.menu-items-text {
                color: #ffff00;
            }
.illini-light .nav-menu.menu-items.menu-expanded a.menu-items-text {
                color: #ffff00;
            }
.illini-light .nav-menu.menu-items:hover .menu-items-arrow svg {
                    fill: #ffff00;
                }
.illini-light .nav-menu.menu-items.menu-expanded .menu-items-arrow svg {
                    fill: #ffff00;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.default a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.default-light a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.sky a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.sky-light a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.grass a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.grass-light a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.mud a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.mud-light a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.illini a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }
.illini-light a.form-input-error {
            color: #ff0000 !important;
            border: none !important;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.radio-div {
    height: 25px;
    width: 100%
}
.radio-div .radio-container.radio-button-container {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        cursor: pointer;
        float: left;
        border-radius: 12px
    }
.radio-div .radio-container.radio-button-container .radio-display {
            width: 16px;
            height: 16px;
            line-height: calc(16px - 1px);
            font-size: 10px;
            text-align: center;
        }
.radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
            font-weight: bold;
        }
.radio-div .radio-container.focus {
        outline: none;
    }
.radio-div .radio-label {
        display: inline-block;
        height: 19px;
        margin-left: 6px;
        vertical-align: top;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap
    }
.radio-div .radio-label .radio-label-text-span {
            line-height: 19px;
            font-size: 13px;
        }
.default .radio-div .radio-container.radio-button-container {
            border: 2px solid #bfbfbf;
            background-color: #bfbfbf;
            color: #000000
        }
.default .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #000000, 0 1px #000000,
                    1px 0 #000000, 0 -1px #000000;
            }
.default .radio-div .radio-container.focus {
            border: 2px solid #8fa61a;
        }
.default .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.default .radio-div .disabled-radio {
            color: #777777 !important;
        }
.default-light .radio-div .radio-container.radio-button-container {
            border: 2px solid #cfcfcf;
            background-color: #cfcfcf;
            color: #000000
        }
.default-light .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #fcfcfc, 0 1px #fcfcfc,
                    1px 0 #fcfcfc, 0 -1px #fcfcfc;
            }
.default-light .radio-div .radio-container.focus {
            border: 2px solid #8fa61a;
        }
.default-light .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.default-light .radio-div .disabled-radio {
            color: #777777 !important;
        }
.sky .radio-div .radio-container.radio-button-container {
            border: 2px solid #c8c8c8;
            background-color: #c8c8c8;
            color: #000000
        }
.sky .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #fcfcfc, 0 1px #fcfcfc,
                    1px 0 #fcfcfc, 0 -1px #fcfcfc;
            }
.sky .radio-div .radio-container.focus {
            border: 2px solid #235635;
        }
.sky .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.sky .radio-div .disabled-radio {
            color: #878787 !important;
        }
.sky-light .radio-div .radio-container.radio-button-container {
            border: 2px solid #c8c8c8;
            background-color: #c8c8c8;
            color: #000000
        }
.sky-light .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #fcfcfc, 0 1px #fcfcfc,
                    1px 0 #fcfcfc, 0 -1px #fcfcfc;
            }
.sky-light .radio-div .radio-container.focus {
            border: 2px solid #235635;
        }
.sky-light .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.sky-light .radio-div .disabled-radio {
            color: #878787 !important;
        }
.grass .radio-div .radio-container.radio-button-container {
            border: 2px solid #9cadb2;
            background-color: #9cadb2;
            color: #000000
        }
.grass .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #667c66, 0 1px #667c66,
                    1px 0 #667c66, 0 -1px #667c66;
            }
.grass .radio-div .radio-container.focus {
            border: 2px solid #ffff00;
        }
.grass .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.grass .radio-div .disabled-radio {
            color: #e5e9e5 !important;
        }
.grass-light .radio-div .radio-container.radio-button-container {
            border: 2px solid #424443;
            background-color: #424443;
            color: #000000
        }
.grass-light .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #babfba, 0 1px #babfba,
                    1px 0 #babfba, 0 -1px #babfba;
            }
.grass-light .radio-div .radio-container.focus {
            border: 2px solid #ffff00;
        }
.grass-light .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.grass-light .radio-div .disabled-radio {
            color: #e5e9e5 !important;
        }
.mud .radio-div .radio-container.radio-button-container {
            border: 2px solid #f2f3f8;
            background-color: #f2f3f8;
            color: #000000
        }
.mud .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #4b3705, 0 1px #4b3705,
                    1px 0 #4b3705, 0 -1px #4b3705;
            }
.mud .radio-div .radio-container.focus {
            border: 2px solid #ffff00;
        }
.mud .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.mud .radio-div .disabled-radio {
            color: #777777 !important;
        }
.mud-light .radio-div .radio-container.radio-button-container {
            border: 2px solid #d6c282;
            background-color: #d6c282;
            color: #000000
        }
.mud-light .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #fff0bb, 0 1px #fff0bb,
                    1px 0 #fff0bb, 0 -1px #fff0bb;
            }
.mud-light .radio-div .radio-container.focus {
            border: 2px solid #8fa61a;
        }
.mud-light .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.mud-light .radio-div .disabled-radio {
            color: #777777 !important;
        }
.illini .radio-div .radio-container.radio-button-container {
            border: 2px solid #f2f3f8;
            background-color: #f2f3f8;
            color: #000000
        }
.illini .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #00467f, 0 1px #00467f,
                    1px 0 #00467f, 0 -1px #00467f;
            }
.illini .radio-div .radio-container.focus {
            border: 2px solid #ffff00;
        }
.illini .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.illini .radio-div .disabled-radio {
            color: #777777 !important;
        }
.illini-light .radio-div .radio-container.radio-button-container {
            border: 2px solid #abc8e2;
            background-color: #abc8e2;
            color: #000000
        }
.illini-light .radio-div .radio-container.radio-button-container .radio-display.check-mark-color {
                text-shadow: -1px 0 #e1e6fa, 0 1px #e1e6fa,
                    1px 0 #e1e6fa, 0 -1px #e1e6fa;
            }
.illini-light .radio-div .radio-container.focus {
            border: 2px solid #ffff00;
        }
.illini-light .radio-div .radio-label .red-star {
                color: #ff0000;
            }
.illini-light .radio-div .disabled-radio {
            color: #777777 !important;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.section-container {
    display: flex;
    margin-bottom: 15px;
    max-width: 1240px
}
.section-container .section-fence {
        border-width: 1px;
        margin-right: 13px;
    }
.section-container .no-bar {
        border: none;
        margin-left: 10px;
    }
.section-container .empty-form-section {
        margin-bottom: 15px;
    }
.form-section-bucket {
    max-width: 394px;
    padding: 0px;
    margin: 0px
}
.form-section-bucket .bucket-header {
        width: 384px;
        height: 40px;
        padding-left: 10px;
        font-family: "open_sansregular";
        font-size: 15px;
        font-weight: 500;
        vertical-align: middle;
        line-height: 40px;
        margin-bottom: 10px
    }
.form-section-bucket .bucket-header .bucket-symbol {
            float: right;
        }
.section-column {
    flex-direction: column;
}
.form-section {
    width: 394px
}
.form-section .form-section-row-2 {
        margin-top: 65px;
    }
.form-section .form-section-row-3 {
        margin-top: 107px;
    }
.form-section .form-section-row-4 {
        margin-top: 137px;
    }
.form-section-header {
    height: 40px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: "open_sansregular";
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
    line-height: 40px
}
.form-section-header .red-star {
        padding-right: 10px;
    }
.form-section-header-owner-telematics {
    height: 40px;
    padding-left: 10px;
    margin-bottom: 0px;
    font-family: "open_sansregular";
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
    line-height: 40px;
}
.grid-section .form-section-header {
        margin-bottom: 0px;
    }
.grid-section .plain-data-table .data-table-header .data-table-cell.data-table-cell-relative.z-n-icon {
                cursor: default;
            }
.grid-section .plain-data-table .data-table-header .data-header-cell {
                cursor: pointer
            }
.grid-section .plain-data-table .data-table-header .data-header-cell .data-table-cell.data-table-cell-relative {
                    flex-grow: 0;
                    padding-right: 5px;
                }
.grid-section .plain-data-table .plain-table-body {
            margin-bottom: 0px;
        }
.grid-section .add-link-container {
        padding-top: 7px;
    }
.form-section-children {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.form-section-child-stretch {
    width: 100%
}
.form-section-child-stretch.mini-grid {
        display: flex;
        flex-direction: column;
    }
.form-section-child-stretch .add-link {
        font-size: 13px;
    }
.form-section-child-stretch.mini-grid {
    width: 100%;
    display: flex;
    flex-direction: column
}
.form-section-child-stretch.mini-grid .add-link {
        font-size: 13px;
    }
.form-sub-section-stretch {
    justify-content: space-between;
}
.form-sub-section {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px
}
.form-sub-section .form-input {
        margin: 0;
        width: 50%;
        margin-right: 7px;
    }
.form-sub-section .section-label {
        width: 50%;
        margin-right: 7px;
        margin: auto;
    }
.form-sub-section .form-input:last-child {
        margin-right: 0px;
    }
.form-sub-section .form-input-id.form-input:first-child {
        width: 160px;
        margin-bottom: 10px;
    }
.form-sub-section .form-input:only-child {
        width: 183px;
    }
.form-sub-section .form-input.density-input {
        width: 185px;
    }
.form-sub-section .form-long-input.form-input:only-child {
        width: 100%;
    }
.form-sub-section .select-form-input-container {
        margin: 0;
        width: 50%;
        margin-right: 7px;
        padding-right: 10px
    }
.form-sub-section .select-form-input-container .select-form-input {
            width: 100%;
        }
.form-sub-section .form-input.textarea-form-input {
        width: 100%;
        height: 110px;
        margin: 0px;
    }
.form-sub-section .select-form-input-container:last-child {
        margin-right: 0px;
    }
.form-sub-section .select-form-input-container:only-child {
        width: 183px;
    }
.form-sub-section .checkbox-div {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }
.form-sub-section .active-checkbox {
        width: 100px;
        margin-left: 24px;
    }
.form-sub-section .color-picker-container {
        margin-bottom: 10px;
    }
.form-sub-section .date-form-input form-input text-form-input {
        margin: 0;
        width: 50%;
        margin-right: 7px;
    }
.default .form-section-header {
        background-color: #404040;
        color: #ffffff
    }
.default .form-section-header .red-star {
            color: #ff0000;
        }
.default .form-section-header-owner-telematics {
        background-color: #404040;
        color: #ffffff;
    }
.default .form-section-bucket .bucket-header {
            background-color: #404040;
            color: #ffffff
        }
.default .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.default .form-section-child-stretch .disabled-link {
            color: #404040;
        }
.default-light .form-section-header {
        background-color: #919191;
        color: #ffffff
    }
.default-light .form-section-header .red-star {
            color: #ff0000;
        }
.default-light .form-section-header-owner-telematics {
        background-color: #919191;
        color: #ffffff;
    }
.default-light .form-section-bucket .bucket-header {
            background-color: #919191;
            color: #ffffff
        }
.default-light .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.default-light .form-section-child-stretch .disabled-link {
            color: #919191;
        }
.sky .form-section-header {
        background-color: #0194d3;
        color: #ffffff
    }
.sky .form-section-header .red-star {
            color: #ff0000;
        }
.sky .form-section-header-owner-telematics {
        background-color: #0194d3;
        color: #ffffff;
    }
.sky .form-section-bucket .bucket-header {
            background-color: #0194d3;
            color: #ffffff
        }
.sky .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.sky .form-section-child-stretch .disabled-link {
            color: #0194d3;
        }
.sky-light .form-section-header {
        background-color: #0194d3;
        color: #ffffff
    }
.sky-light .form-section-header .red-star {
            color: #ff0000;
        }
.sky-light .form-section-header-owner-telematics {
        background-color: #0194d3;
        color: #ffffff;
    }
.sky-light .form-section-bucket .bucket-header {
            background-color: #0194d3;
            color: #ffffff
        }
.sky-light .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.sky-light .form-section-child-stretch .disabled-link {
            color: #0194d3;
        }
.grass .form-section-header {
        background-color: #325132;
        color: #ffffff
    }
.grass .form-section-header .red-star {
            color: #ff0000;
        }
.grass .form-section-header-owner-telematics {
        background-color: #325132;
        color: #ffffff;
    }
.grass .form-section-bucket .bucket-header {
            background-color: #325132;
            color: #ffffff
        }
.grass .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.grass .form-section-child-stretch .disabled-link {
            color: #325132;
        }
.grass-light .form-section-header {
        background-color: #545f54;
        color: #ffffff
    }
.grass-light .form-section-header .red-star {
            color: #ff0000;
        }
.grass-light .form-section-header-owner-telematics {
        background-color: #545f54;
        color: #ffffff;
    }
.grass-light .form-section-bucket .bucket-header {
            background-color: #545f54;
            color: #ffffff
        }
.grass-light .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.grass-light .form-section-child-stretch .disabled-link {
            color: #545f54;
        }
.mud .form-section-header {
        background-color: #766146;
        color: #ffffff
    }
.mud .form-section-header .red-star {
            color: #ff0000;
        }
.mud .form-section-header-owner-telematics {
        background-color: #766146;
        color: #ffffff;
    }
.mud .form-section-bucket .bucket-header {
            background-color: #766146;
            color: #ffffff
        }
.mud .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.mud .form-section-child-stretch .disabled-link {
            color: #766146;
        }
.mud-light .form-section-header {
        background-color: #a37b42;
        color: #ffffff
    }
.mud-light .form-section-header .red-star {
            color: #ff0000;
        }
.mud-light .form-section-header-owner-telematics {
        background-color: #a37b42;
        color: #ffffff;
    }
.mud-light .form-section-bucket .bucket-header {
            background-color: #a37b42;
            color: #ffffff
        }
.mud-light .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.mud-light .form-section-child-stretch .disabled-link {
            color: #a37b42;
        }
.illini .form-section-header {
        background-color: #636466;
        color: #ffffff
    }
.illini .form-section-header .red-star {
            color: #ff0000;
        }
.illini .form-section-header-owner-telematics {
        background-color: #636466;
        color: #ffffff;
    }
.illini .form-section-bucket .bucket-header {
            background-color: #636466;
            color: #ffffff
        }
.illini .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.illini .form-section-child-stretch .disabled-link {
            color: #636466;
        }
.illini-light .form-section-header {
        background-color: #375d81;
        color: #ffffff
    }
.illini-light .form-section-header .red-star {
            color: #ff0000;
        }
.illini-light .form-section-header-owner-telematics {
        background-color: #375d81;
        color: #ffffff;
    }
.illini-light .form-section-bucket .bucket-header {
            background-color: #375d81;
            color: #ffffff
        }
.illini-light .form-section-bucket .bucket-header .red-star {
                color: #ff0000;
            }
.illini-light .form-section-child-stretch .disabled-link {
            color: #375d81;
        }

@media screen and (max-width: 1240px) {
    .section-container {
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1
    }

        .section-container .form-section {
            flex-grow: 1;
            max-width: 394px;
            margin-right: 10px
        }

            .section-container .form-section .form-sub-section {
                flex-direction: column;
                margin-bottom: 0px
            }

                .section-container .form-section .form-sub-section > div {
                    margin-top: 7px;
                    flex-grow: 1
                }

                    .section-container .form-section .form-sub-section > div.mini-grid {
                        margin-top: 0px;
                    }

                .section-container .form-section .form-sub-section .form-input.textarea-form-input {
                    width: auto;
                }
            .section-container .bar.section-fence, .section-container .no-bar.section-fence {
                display: none;
                margin: 0;
            }

        .section-container .bucket.form-section-bucket {
            flex-grow: 1;
            margin-right: 10px
        }

            .section-container .bucket.form-section-bucket .bucket-header {
                flex-grow: 1;
            }
}

@media screen and (max-width: 610px) {
    .section-container {
        flex-direction: column;
    }
}

@media (min-width: 611px) and (max-width: 960px) {
        .section-container .bucket.form-section-bucket {
            max-width: 394px;
        }
}

@media (min-width: 961px) and (max-width: 1240px) {
    .section-container {
        max-width: 1240px;
    }
}


    .select-form-input-container .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-left: 5px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.menu-open {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}
.menu-open .form-input {
        width: 183px;
    }
.menu-open .dropdown-menu {
        width: 189px;
    }
.multi-select-container {
    border-radius: 4px;
    height: 45px;
    margin: 0;
    min-width: 49%;
    margin-right: 8px
}
.multi-select-container:hover {
        cursor: pointer;
    }
.multi-select-container .multi-select-dropdown {
        width: 100%;
    }
.multi-select-container .select-form-input-multi {
        padding: 3px
    }
.multi-select-container .select-form-input-multi .basic-multi-select {
            z-index: 1;
        }
.multi-select-container .select-form-input-multi .input-container {
            display: flex;
            flex-direction: row
        }
.multi-select-container .select-form-input-multi .input-container .expand-contract-arrow {
                margin: 4px 0;
                padding: 2px;
                flex-shrink: 0;
                width: 15px;
                height: 15px;
                border: none;
                cursor: pointer;
                min-width: 11px;
                float: right
            }
.multi-select-container .select-form-input-multi .input-container .expand-contract-arrow .default-svg-icon {
                    width: 15px;
                    height: 15px;
                }
.multi-select-container .select-form-input-multi .input-container .clear-btn-icon {
                margin: 4px 0;
                padding: 2px;
                border: none;
                cursor: pointer
            }
.multi-select-container .select-form-input-multi .input-container .clear-btn-icon .default-svg-icon {
                    width: 15px;
                    height: 15px;
                }
.multi-select-container .select-form-input-multi .input-container:not(.focus):not(:hover) .clear-btn-icon {
                visibility: hidden;
            }
.multi-select-container .select-form-input-multi .input-container .input-placeholder {
                font-family: "open_sansitalic";
                font-size: 13px;
                font-weight: 300;
                line-height: 22px;
                flex-grow: 1;
                min-width: 20px;
                padding: 2px;
                height: 22px;
                border: none;
                outline: none;
                text-overflow: ellipsis
            }
.multi-select-container .select-form-input-multi .input-container .input-placeholder:not(.required) {
                    text-indent: 5px;
                }
.multi-select-container .select-form-input-multi .input-container .selected-string {
                border: none;
                flex-grow: 1;
                font-size: 13px;
                height: 22px;
                line-height: 17px;
                margin: 5px 5px 0 5px;
                min-width: 20px;
                outline: none;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 35px);
            }
.multi-select-container .select-form-input-multi .select-form-sub-header {
            font-weight: bold;
            cursor: default;
            height: 50%;
            display: flex;
            align-items: center;
            padding-top: 5px;
            margin-left: 5px;
        }
.multi-select-container .select-form-input-multi .input-label-container {
            font-size: 10px;
            line-height: 12px;
            min-height: 7px;
            text-indent: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            min-width: 10%;
            max-width: 100%;
        }
.multi-select-container .select-form-input-multi .red-star {
            font-size: 15px;
            line-height: 10px;
            padding-right: 5px;
        }
.multi-select-container .select-form-input-multi .value-selected {
            margin-top: 7px;
            vertical-align: top;
        }
.multi-select-container .dropdown-menu {
        margin-top: -3px;
        position: fixed;
        z-index: 10001;
    }
.multi-select-container .select__control:not(.select__control--is-focused) .css-mohuvp-dummyInput-DummyInput {
                height: 0;
            }
.multi-select-container .select__control:not(.select__control--is-focused) .select__input-container {
                margin: 0;
                padding: 0;
            }
.multi-select-container .select__control .css-mohuvp-dummyInput-DummyInput {
            height: inherit;
        }
.multi-select-container .select__control .select__placeholder {
            font-family: "open_sansitalic"
        }
.multi-select-container .select__control .select__placeholder .default-svg-icon {
                height: 14px;
                width: 14px;
            }
.multi-select-container .select__menu {
        font-size: 13px;
        margin-top: -3px;
        margin-bottom: 0;
        position: relative;
    }
.multi-select-container .select__menu-list {
        max-height: 220px;
    }
.multi-select-container .select__menu-notice {
        text-align: left;
    }
.multi-select-container .select__multi-value {
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 4px;
    }
.multi-select-container .select__multi-value__label {
        padding-left: 2px;
        padding-right: 0;
    }
.multi-select-container .select__multi-value__remove {
        padding-left: 1px;
        padding-right: 1px;
    }
.multi-select-container .select__option:hover {
            cursor: pointer;
        }
.multi-select-container .select__value-container--has-value {
        visibility: hidden;
    }
.multi-select-container:not(:hover) .select__clear-indicator {
            visibility: hidden;
        }
.default .multi-select-container {
        background-color: #bfbfbf;
        color: #000000
    }
.default .multi-select-container .select-form-input-multi {
            border: 2px solid #bfbfbf;
        }
.default .multi-select-container .red-star {
            color: #ff0000;
        }
.default .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.default .multi-select-container .dropdown-menu {
            background-color: #bfbfbf;
            color: #000000;
            border: 2px solid #8fa61a;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #000000;
        }
.default .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.default .multi-select-container .input-label-container {
            color: #000000;
        }
.default .multi-select-container .selected-string {
            color: #000000;
        }
.default .multi-select-container .select__control {
            background-color: #bfbfbf;
            border-color: #bfbfbf;
            color: #000000;
        }
.default .multi-select-container .select__control--is-focused {
            border-color: #bfbfbf;
            box-shadow: unset;
            border-radius: unset;
        }
.default .multi-select-container .select__menu {
            background-color: #bfbfbf;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.default .multi-select-container .select__menu-notice {
            border-top: #404040 1px solid;
            color: #000000;
        }
.default .multi-select-container .select__option {
            border-top: #404040 1px solid
        }
.default .multi-select-container .select__option:hover {
                background-color: #1daaef;
            }
.default .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.default .multi-select-container .select__option--is-selected {
            background-color: #777777;
        }
.default .multi-select-container .select__placeholder {
            color: #000000;
        }
.default .menu-open .select-form-input-multi {
            border: 2px solid #8fa61a;
        }
.default-light .multi-select-container {
        background-color: #cfcfcf;
        color: #000000
    }
.default-light .multi-select-container .select-form-input-multi {
            border: 2px solid #cfcfcf;
        }
.default-light .multi-select-container .red-star {
            color: #ff0000;
        }
.default-light .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.default-light .multi-select-container .dropdown-menu {
            background-color: #cfcfcf;
            color: #000000;
            border: 2px solid #8fa61a;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #fcfcfc;
        }
.default-light .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.default-light .multi-select-container .input-label-container {
            color: #000000;
        }
.default-light .multi-select-container .selected-string {
            color: #000000;
        }
.default-light .multi-select-container .select__control {
            background-color: #cfcfcf;
            border-color: #cfcfcf;
            color: #000000;
        }
.default-light .multi-select-container .select__control--is-focused {
            border-color: #cfcfcf;
            box-shadow: unset;
            border-radius: unset;
        }
.default-light .multi-select-container .select__menu {
            background-color: #cfcfcf;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.default-light .multi-select-container .select__menu-notice {
            border-top: #919191 1px solid;
            color: #000000;
        }
.default-light .multi-select-container .select__option {
            border-top: #919191 1px solid
        }
.default-light .multi-select-container .select__option:hover {
                background-color: #0e55a8;
            }
.default-light .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.default-light .multi-select-container .select__option--is-selected {
            background-color: #777777;
        }
.default-light .multi-select-container .select__placeholder {
            color: #000000;
        }
.default-light .menu-open .select-form-input-multi {
            border: 2px solid #8fa61a;
        }
.sky .multi-select-container {
        background-color: #c8c8c8;
        color: #000000
    }
.sky .multi-select-container .select-form-input-multi {
            border: 2px solid #c8c8c8;
        }
.sky .multi-select-container .red-star {
            color: #ff0000;
        }
.sky .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.sky .multi-select-container .dropdown-menu {
            background-color: #c8c8c8;
            color: #000000;
            border: 2px solid #235635;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #fcfcfc;
        }
.sky .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.sky .multi-select-container .input-label-container {
            color: #000000;
        }
.sky .multi-select-container .selected-string {
            color: #000000;
        }
.sky .multi-select-container .select__control {
            background-color: #c8c8c8;
            border-color: #c8c8c8;
            color: #000000;
        }
.sky .multi-select-container .select__control--is-focused {
            border-color: #c8c8c8;
            box-shadow: unset;
            border-radius: unset;
        }
.sky .multi-select-container .select__menu {
            background-color: #c8c8c8;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.sky .multi-select-container .select__menu-notice {
            border-top: #0194d3 1px solid;
            color: #000000;
        }
.sky .multi-select-container .select__option {
            border-top: #0194d3 1px solid
        }
.sky .multi-select-container .select__option:hover {
                background-color: #01425e;
            }
.sky .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.sky .multi-select-container .select__option--is-selected {
            background-color: #878787;
        }
.sky .multi-select-container .select__placeholder {
            color: #000000;
        }
.sky .menu-open .select-form-input-multi {
            border: 2px solid #235635;
        }
.sky-light .multi-select-container {
        background-color: #c8c8c8;
        color: #000000
    }
.sky-light .multi-select-container .select-form-input-multi {
            border: 2px solid #c8c8c8;
        }
.sky-light .multi-select-container .red-star {
            color: #ff0000;
        }
.sky-light .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.sky-light .multi-select-container .dropdown-menu {
            background-color: #c8c8c8;
            color: #000000;
            border: 2px solid #235635;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #fcfcfc;
        }
.sky-light .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.sky-light .multi-select-container .input-label-container {
            color: #000000;
        }
.sky-light .multi-select-container .selected-string {
            color: #000000;
        }
.sky-light .multi-select-container .select__control {
            background-color: #c8c8c8;
            border-color: #c8c8c8;
            color: #000000;
        }
.sky-light .multi-select-container .select__control--is-focused {
            border-color: #c8c8c8;
            box-shadow: unset;
            border-radius: unset;
        }
.sky-light .multi-select-container .select__menu {
            background-color: #c8c8c8;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.sky-light .multi-select-container .select__menu-notice {
            border-top: #0194d3 1px solid;
            color: #000000;
        }
.sky-light .multi-select-container .select__option {
            border-top: #0194d3 1px solid
        }
.sky-light .multi-select-container .select__option:hover {
                background-color: #01425e;
            }
.sky-light .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.sky-light .multi-select-container .select__option--is-selected {
            background-color: #878787;
        }
.sky-light .multi-select-container .select__placeholder {
            color: #000000;
        }
.sky-light .menu-open .select-form-input-multi {
            border: 2px solid #235635;
        }
.grass .multi-select-container {
        background-color: #9cadb2;
        color: #000000
    }
.grass .multi-select-container .select-form-input-multi {
            border: 2px solid #9cadb2;
        }
.grass .multi-select-container .red-star {
            color: #ff0000;
        }
.grass .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.grass .multi-select-container .dropdown-menu {
            background-color: #9cadb2;
            color: #000000;
            border: 2px solid #ffff00;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #667c66;
        }
.grass .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.grass .multi-select-container .input-label-container {
            color: #000000;
        }
.grass .multi-select-container .selected-string {
            color: #000000;
        }
.grass .multi-select-container .select__control {
            background-color: #9cadb2;
            border-color: #9cadb2;
            color: #000000;
        }
.grass .multi-select-container .select__control--is-focused {
            border-color: #9cadb2;
            box-shadow: unset;
            border-radius: unset;
        }
.grass .multi-select-container .select__menu {
            background-color: #9cadb2;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.grass .multi-select-container .select__menu-notice {
            border-top: #325132 1px solid;
            color: #000000;
        }
.grass .multi-select-container .select__option {
            border-top: #325132 1px solid
        }
.grass .multi-select-container .select__option:hover {
                background-color: #e26816;
            }
.grass .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.grass .multi-select-container .select__option--is-selected {
            background-color: #e5e9e5;
        }
.grass .multi-select-container .select__placeholder {
            color: #000000;
        }
.grass .menu-open .select-form-input-multi {
            border: 2px solid #ffff00;
        }
.grass-light .multi-select-container {
        background-color: #424443;
        color: #000000
    }
.grass-light .multi-select-container .select-form-input-multi {
            border: 2px solid #424443;
        }
.grass-light .multi-select-container .red-star {
            color: #ff0000;
        }
.grass-light .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.grass-light .multi-select-container .dropdown-menu {
            background-color: #424443;
            color: #000000;
            border: 2px solid #ffff00;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #babfba;
        }
.grass-light .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.grass-light .multi-select-container .input-label-container {
            color: #000000;
        }
.grass-light .multi-select-container .selected-string {
            color: #000000;
        }
.grass-light .multi-select-container .select__control {
            background-color: #424443;
            border-color: #424443;
            color: #000000;
        }
.grass-light .multi-select-container .select__control--is-focused {
            border-color: #424443;
            box-shadow: unset;
            border-radius: unset;
        }
.grass-light .multi-select-container .select__menu {
            background-color: #424443;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.grass-light .multi-select-container .select__menu-notice {
            border-top: #545f54 1px solid;
            color: #000000;
        }
.grass-light .multi-select-container .select__option {
            border-top: #545f54 1px solid
        }
.grass-light .multi-select-container .select__option:hover {
                background-color: #e26816;
            }
.grass-light .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.grass-light .multi-select-container .select__option--is-selected {
            background-color: #e5e9e5;
        }
.grass-light .multi-select-container .select__placeholder {
            color: #000000;
        }
.grass-light .menu-open .select-form-input-multi {
            border: 2px solid #ffff00;
        }
.mud .multi-select-container {
        background-color: #f2f3f8;
        color: #000000
    }
.mud .multi-select-container .select-form-input-multi {
            border: 2px solid #f2f3f8;
        }
.mud .multi-select-container .red-star {
            color: #ff0000;
        }
.mud .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.mud .multi-select-container .dropdown-menu {
            background-color: #f2f3f8;
            color: #000000;
            border: 2px solid #ffff00;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #4b3705;
        }
.mud .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.mud .multi-select-container .input-label-container {
            color: #000000;
        }
.mud .multi-select-container .selected-string {
            color: #000000;
        }
.mud .multi-select-container .select__control {
            background-color: #f2f3f8;
            border-color: #f2f3f8;
            color: #000000;
        }
.mud .multi-select-container .select__control--is-focused {
            border-color: #f2f3f8;
            box-shadow: unset;
            border-radius: unset;
        }
.mud .multi-select-container .select__menu {
            background-color: #f2f3f8;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.mud .multi-select-container .select__menu-notice {
            border-top: #766146 1px solid;
            color: #000000;
        }
.mud .multi-select-container .select__option {
            border-top: #766146 1px solid
        }
.mud .multi-select-container .select__option:hover {
                background-color: #f0c248;
            }
.mud .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.mud .multi-select-container .select__option--is-selected {
            background-color: #777777;
        }
.mud .multi-select-container .select__placeholder {
            color: #000000;
        }
.mud .menu-open .select-form-input-multi {
            border: 2px solid #ffff00;
        }
.mud-light .multi-select-container {
        background-color: #d6c282;
        color: #000000
    }
.mud-light .multi-select-container .select-form-input-multi {
            border: 2px solid #d6c282;
        }
.mud-light .multi-select-container .red-star {
            color: #ff0000;
        }
.mud-light .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.mud-light .multi-select-container .dropdown-menu {
            background-color: #d6c282;
            color: #000000;
            border: 2px solid #8fa61a;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #fff0bb;
        }
.mud-light .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.mud-light .multi-select-container .input-label-container {
            color: #000000;
        }
.mud-light .multi-select-container .selected-string {
            color: #000000;
        }
.mud-light .multi-select-container .select__control {
            background-color: #d6c282;
            border-color: #d6c282;
            color: #000000;
        }
.mud-light .multi-select-container .select__control--is-focused {
            border-color: #d6c282;
            box-shadow: unset;
            border-radius: unset;
        }
.mud-light .multi-select-container .select__menu {
            background-color: #d6c282;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.mud-light .multi-select-container .select__menu-notice {
            border-top: #a37b42 1px solid;
            color: #000000;
        }
.mud-light .multi-select-container .select__option {
            border-top: #a37b42 1px solid
        }
.mud-light .multi-select-container .select__option:hover {
                background-color: #0e55a8;
            }
.mud-light .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.mud-light .multi-select-container .select__option--is-selected {
            background-color: #777777;
        }
.mud-light .multi-select-container .select__placeholder {
            color: #000000;
        }
.mud-light .menu-open .select-form-input-multi {
            border: 2px solid #8fa61a;
        }
.illini .multi-select-container {
        background-color: #f2f3f8;
        color: #000000
    }
.illini .multi-select-container .select-form-input-multi {
            border: 2px solid #f2f3f8;
        }
.illini .multi-select-container .red-star {
            color: #ff0000;
        }
.illini .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.illini .multi-select-container .dropdown-menu {
            background-color: #f2f3f8;
            color: #000000;
            border: 2px solid #ffff00;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #00467f;
        }
.illini .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.illini .multi-select-container .input-label-container {
            color: #000000;
        }
.illini .multi-select-container .selected-string {
            color: #000000;
        }
.illini .multi-select-container .select__control {
            background-color: #f2f3f8;
            border-color: #f2f3f8;
            color: #000000;
        }
.illini .multi-select-container .select__control--is-focused {
            border-color: #f2f3f8;
            box-shadow: unset;
            border-radius: unset;
        }
.illini .multi-select-container .select__menu {
            background-color: #f2f3f8;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.illini .multi-select-container .select__menu-notice {
            border-top: #636466 1px solid;
            color: #000000;
        }
.illini .multi-select-container .select__option {
            border-top: #636466 1px solid
        }
.illini .multi-select-container .select__option:hover {
                background-color: #f39c11;
            }
.illini .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.illini .multi-select-container .select__option--is-selected {
            background-color: #777777;
        }
.illini .multi-select-container .select__placeholder {
            color: #000000;
        }
.illini .menu-open .select-form-input-multi {
            border: 2px solid #ffff00;
        }
.illini-light .multi-select-container {
        background-color: #abc8e2;
        color: #000000
    }
.illini-light .multi-select-container .select-form-input-multi {
            border: 2px solid #abc8e2;
        }
.illini-light .multi-select-container .red-star {
            color: #ff0000;
        }
.illini-light .multi-select-container .default-svg-icon {
            fill: #000000;
        }
.illini-light .multi-select-container .dropdown-menu {
            background-color: #abc8e2;
            color: #000000;
            border: 2px solid #ffff00;
            border-radius: 0 0 4px 4px;
            border-top: unset;
            box-shadow: unset;
            border-top: 1px solid #e1e6fa;
        }
.illini-light .multi-select-container .multi-select-dropdown {
            height: 45px;
            position: relative;
        }
.illini-light .multi-select-container .input-label-container {
            color: #000000;
        }
.illini-light .multi-select-container .selected-string {
            color: #000000;
        }
.illini-light .multi-select-container .select__control {
            background-color: #abc8e2;
            border-color: #abc8e2;
            color: #000000;
        }
.illini-light .multi-select-container .select__control--is-focused {
            border-color: #abc8e2;
            box-shadow: unset;
            border-radius: unset;
        }
.illini-light .multi-select-container .select__menu {
            background-color: #abc8e2;
            color: #000000;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: unset;
            box-shadow: unset;
        }
.illini-light .multi-select-container .select__menu-notice {
            border-top: #375d81 1px solid;
            color: #000000;
        }
.illini-light .multi-select-container .select__option {
            border-top: #375d81 1px solid
        }
.illini-light .multi-select-container .select__option:hover {
                background-color: #b2962c;
            }
.illini-light .multi-select-container .select__option--is-focused {
            background-color: inherit;
        }
.illini-light .multi-select-container .select__option--is-selected {
            background-color: #777777;
        }
.illini-light .multi-select-container .select__placeholder {
            color: #000000;
        }
.illini-light .menu-open .select-form-input-multi {
            border: 2px solid #ffff00;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.tabs .tabs-labels {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
}
.tabs .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
        flex-grow: 1;
        min-width: inherit;
        min-height: inherit;
        padding-top: 5px;
        text-align: center;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
.tabs .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label):not(.disabled) {
        cursor: pointer;
    }
.tabs .tabs-labels li.tab-label.disabled {
        opacity: 0.5;
        cursor: default;
    }
.dialog-box-body .tabs-labels {
    line-height: 20px;
}
.default .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #404040
    }
.default .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #262626;
        }
.default .tabs .tabs-labels li.tab-label.active {
            background-color: #000000;
            color: #8fa61a;
            border-bottom: 4px solid #8fa61a;
        }
.default-light .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #919191
    }
.default-light .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #e2e2e2;
        }
.default-light .tabs .tabs-labels li.tab-label.active {
            background-color: #fcfcfc;
            color: #8fa61a;
            border-bottom: 4px solid #8fa61a;
        }
.sky .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #0194d3
    }
.sky .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #b1b1b1;
        }
.sky .tabs .tabs-labels li.tab-label.active {
            background-color: #fcfcfc;
            color: #235635;
            border-bottom: 4px solid #235635;
        }
.sky-light .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #0194d3
    }
.sky-light .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #b1b1b1;
        }
.sky-light .tabs .tabs-labels li.tab-label.active {
            background-color: #fcfcfc;
            color: #235635;
            border-bottom: 4px solid #235635;
        }
.grass .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #325132
    }
.grass .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #424443;
        }
.grass .tabs .tabs-labels li.tab-label.active {
            background-color: #667c66;
            color: #ffff00;
            border-bottom: 4px solid #ffff00;
        }
.grass-light .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #545f54
    }
.grass-light .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #9cadb2;
        }
.grass-light .tabs .tabs-labels li.tab-label.active {
            background-color: #babfba;
            color: #ffff00;
            border-bottom: 4px solid #ffff00;
        }
.mud .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #766146
    }
.mud .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #8c7454;
        }
.mud .tabs .tabs-labels li.tab-label.active {
            background-color: #4b3705;
            color: #ffff00;
            border-bottom: 4px solid #ffff00;
        }
.mud-light .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #a37b42
    }
.mud-light .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #b28f5a;
        }
.mud-light .tabs .tabs-labels li.tab-label.active {
            background-color: #fff0bb;
            color: #8fa61a;
            border-bottom: 4px solid #8fa61a;
        }
.illini .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #636466
    }
.illini .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #abc8e2;
        }
.illini .tabs .tabs-labels li.tab-label.active {
            background-color: #00467f;
            color: #ffff00;
            border-bottom: 4px solid #ffff00;
        }
.illini-light .tabs .tabs-labels li.tab-label {
        color: #ffffff;
        background-color: #375d81
    }
.illini-light .tabs .tabs-labels li.tab-label:hover:not(.active):not(.left-non-label):not(.right-non-label):not(.disabled) {
            background-color: #c4d7ed;
        }
.illini-light .tabs .tabs-labels li.tab-label.active {
            background-color: #e1e6fa;
            color: #ffff00;
            border-bottom: 4px solid #ffff00;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.toolbar {
    height: 50px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: row
}
.toolbar .toolbar-loader:hover,
    .toolbar .toolbar-loader .loader-container,
    .toolbar .toolbar-loader .loader-container > div {
        cursor: default !important;
        background-color: transparent !important;
        position: relative;
    }
.toolbar li {
        min-width: 70px;
        min-height: 50px;
        cursor: pointer;
        display: inline-block
    }
.toolbar li > div {
            width: 70px;
            height: 50px;
        }
.toolbar li .toolbar-item {
            position: relative;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: 23px 7px
        }
.toolbar li .toolbar-item .toolbar-item-icon-container {
                position: relative
            }
.toolbar li .toolbar-item .toolbar-item-icon-container .toolbar-item-icon {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: 23px 7px;
                }
.toolbar li .toolbar-item .toolbar-item-icon-container .toolbar-item-count {
                    position: absolute;
                    right: 0px;
                    margin-top: 4px;
                    margin-right: 2px;
                    color: #ffffff;
                    background-color: #e26816;
                    padding: 3px 4px 3px 4px;
                    font-size: 11px;
                    border-radius: 5px;
                }
.toolbar li .toolbar-item .toolbar-item-text {
                position: absolute;
                bottom: 5px;
                text-align: center;
                width: 50px;
                margin: 0 10px;
                font-size: 10px;
            }
.toolbar li .toolbar-item .toolbar-item-arrow {
                position: absolute;
                bottom: 2px;
                right: 0;
                width: 12px;
                height: 12px;
            }
.toolbar li .toolbar-item.disabled {
                cursor: default;
            }
.toolbar li .toolbar-tool.selected {
            height: 47px
        }
.toolbar li .toolbar-tool.selected .toolbar-item-text {
                bottom: 2px;
            }
.toolbar li .toolbar-menu-content {
            position: relative;
            z-index: 200
        }
.toolbar li .toolbar-menu-content.rtl {
                position: absolute;
                left: auto;
                right: 0
            }
.toolbar li .toolbar-menu-content.rtl .toolbar {
                    position: relative;
                }
.toolbar li .toolbar-menu-content > ul,
            .toolbar li .toolbar-menu-content > div {
                border-radius: 0 0 5px 5px;
            }
.toolbar li .toolbar-menu-content > div {
                padding: 5px;
                font-size: 10px;
            }
.toolbar li .toolbar-menu-content > ul li:first-child {
                border-radius: 0 0 0 5px;
            }
.toolbar li .toolbar-menu-content > ul li:last-child {
                border-radius: 0 0 5px 0;
            }
.toolbar li .toolbar-menu-content .toolbar-close-bar {
                min-width: 10px;
                font-size: 10px;
                text-align: center;
                height: 51px
            }
.toolbar li .toolbar-menu-content .toolbar-close-bar:hover {
                    height: 50px;
                }
.toolbar li .toolbar-menu-content-wrapper {
            position: relative
        }
.toolbar li .toolbar-menu-content-wrapper .toolbar-close-bar {
                position: absolute;
                top: 0;
                right: -10px;
                height: 100%;
                border-radius: 0 0 4px 0
            }
.toolbar li .toolbar-menu-content-wrapper .toolbar-close-bar.rtl {
                    left: -10px;
                    right: auto;
                    border-radius: 0 0 0 4px;
                }
.toolbar li .toolbar-menu-content-wrapper .toolbar-close-bar:hover {
                    height: 100%;
                }
.toolbar li .toolbar-menu.active {
            height: 47px
        }
.toolbar li .toolbar-menu.active .toolbar-item-text {
                bottom: 2px;
            }
.toolbar li .toolbar-menu.active .toolbar-item-arrow {
                bottom: -2px;
            }
.toolbar li .toolbar-menu.disabled {
            cursor: default;
        }
.toolbar .toolbar {
        position: absolute;
    }
.toolbar li > div.expanded .toolbar-item-arrow {
        display: none;
    }
.toolbar li:first-child .toolbar-tool.selected {
        border-radius: 0 0 0 4px;
    }
.default .toolbar {
        color: #ffffff
    }
.default .toolbar li .toolbar-menu-content > ul {
                color: #ffffff;
                background-color: #000000;
                border-bottom: 1px solid #8fa61a;
            }
.default .toolbar li .toolbar-menu-content > div {
                color: #ffffff;
                background-color: #000000;
                border-bottom: 1px solid #8fa61a;
            }
.default .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #8fa61a;
            }
.default .toolbar li .active {
            color: #8fa61a;
            background-color: #000000;
            border-bottom: 3px solid #8fa61a;
        }
.default .toolbar li > div.expanded {
            color: #8fa61a;
            background-color: #000000
        }
.default .toolbar li > div.expanded .active {
                border-bottom: 3px solid #000000;
            }
.default .toolbar li > div.expanded .toolbar-close-bar {
                color: #000000;
                background-color: #8fa61a
            }
.default .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #ffffff;
                    background-color: #262626;
                }
.default .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #8fa61a;
            background-color: #262626;
            color: #8fa61a;
        }
.default .toolbar li:hover {
            background-color: #262626;
        }
.default .toolbar .disabled {
            color: #777777 !important
        }
.default .toolbar .disabled .toolbar-item-text {
                color: #777777 !important;
            }
.default .toolbar .disabled svg {
                fill: #777777 !important
            }
.default .toolbar .disabled svg.disabled-stroke {
                    stroke: #777777 !important;
                }
.default .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.default .toolbar .toolbar-item-arrow {
            fill: #ffffff;
        }
.default-light .toolbar {
        color: #000000
    }
.default-light .toolbar li .toolbar-menu-content > ul {
                color: #000000;
                background-color: #fcfcfc;
                border-bottom: 1px solid #8fa61a;
            }
.default-light .toolbar li .toolbar-menu-content > div {
                color: #000000;
                background-color: #fcfcfc;
                border-bottom: 1px solid #8fa61a;
            }
.default-light .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #8fa61a;
            }
.default-light .toolbar li .active {
            color: #8fa61a;
            background-color: #fcfcfc;
            border-bottom: 3px solid #8fa61a;
        }
.default-light .toolbar li > div.expanded {
            color: #8fa61a;
            background-color: #fcfcfc
        }
.default-light .toolbar li > div.expanded .active {
                border-bottom: 3px solid #fcfcfc;
            }
.default-light .toolbar li > div.expanded .toolbar-close-bar {
                color: #fcfcfc;
                background-color: #8fa61a
            }
.default-light .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #e2e2e2;
                }
.default-light .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #8fa61a;
            background-color: #e2e2e2;
            color: #8fa61a;
        }
.default-light .toolbar li:hover {
            background-color: #e2e2e2;
        }
.default-light .toolbar .disabled {
            color: #777777 !important
        }
.default-light .toolbar .disabled .toolbar-item-text {
                color: #777777 !important;
            }
.default-light .toolbar .disabled svg {
                fill: #777777 !important
            }
.default-light .toolbar .disabled svg.disabled-stroke {
                    stroke: #777777 !important;
                }
.default-light .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.default-light .toolbar .toolbar-item-arrow {
            fill: #000000;
        }
.sky .toolbar {
        color: #000000
    }
.sky .toolbar li .toolbar-menu-content > ul {
                color: #000000;
                background-color: #fcfcfc;
                border-bottom: 1px solid #235635;
            }
.sky .toolbar li .toolbar-menu-content > div {
                color: #000000;
                background-color: #fcfcfc;
                border-bottom: 1px solid #235635;
            }
.sky .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #235635;
            }
.sky .toolbar li .active {
            color: #235635;
            background-color: #fcfcfc;
            border-bottom: 3px solid #235635;
        }
.sky .toolbar li > div.expanded {
            color: #235635;
            background-color: #fcfcfc
        }
.sky .toolbar li > div.expanded .active {
                border-bottom: 3px solid #fcfcfc;
            }
.sky .toolbar li > div.expanded .toolbar-close-bar {
                color: #fcfcfc;
                background-color: #235635
            }
.sky .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #b1b1b1;
                }
.sky .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #235635;
            background-color: #b1b1b1;
            color: #235635;
        }
.sky .toolbar li:hover {
            background-color: #b1b1b1;
        }
.sky .toolbar .disabled {
            color: #878787 !important
        }
.sky .toolbar .disabled .toolbar-item-text {
                color: #878787 !important;
            }
.sky .toolbar .disabled svg {
                fill: #878787 !important
            }
.sky .toolbar .disabled svg.disabled-stroke {
                    stroke: #878787 !important;
                }
.sky .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.sky .toolbar .toolbar-item-arrow {
            fill: #000000;
        }
.sky-light .toolbar {
        color: #000000
    }
.sky-light .toolbar li .toolbar-menu-content > ul {
                color: #000000;
                background-color: #fcfcfc;
                border-bottom: 1px solid #235635;
            }
.sky-light .toolbar li .toolbar-menu-content > div {
                color: #000000;
                background-color: #fcfcfc;
                border-bottom: 1px solid #235635;
            }
.sky-light .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #235635;
            }
.sky-light .toolbar li .active {
            color: #235635;
            background-color: #fcfcfc;
            border-bottom: 3px solid #235635;
        }
.sky-light .toolbar li > div.expanded {
            color: #235635;
            background-color: #fcfcfc
        }
.sky-light .toolbar li > div.expanded .active {
                border-bottom: 3px solid #fcfcfc;
            }
.sky-light .toolbar li > div.expanded .toolbar-close-bar {
                color: #fcfcfc;
                background-color: #235635
            }
.sky-light .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #b1b1b1;
                }
.sky-light .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #235635;
            background-color: #b1b1b1;
            color: #235635;
        }
.sky-light .toolbar li:hover {
            background-color: #b1b1b1;
        }
.sky-light .toolbar .disabled {
            color: #878787 !important
        }
.sky-light .toolbar .disabled .toolbar-item-text {
                color: #878787 !important;
            }
.sky-light .toolbar .disabled svg {
                fill: #878787 !important
            }
.sky-light .toolbar .disabled svg.disabled-stroke {
                    stroke: #878787 !important;
                }
.sky-light .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.sky-light .toolbar .toolbar-item-arrow {
            fill: #000000;
        }
.grass .toolbar {
        color: #ffffff
    }
.grass .toolbar li .toolbar-menu-content > ul {
                color: #ffffff;
                background-color: #667c66;
                border-bottom: 1px solid #ffff00;
            }
.grass .toolbar li .toolbar-menu-content > div {
                color: #ffffff;
                background-color: #667c66;
                border-bottom: 1px solid #ffff00;
            }
.grass .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #ffff00;
            }
.grass .toolbar li .active {
            color: #ffff00;
            background-color: #667c66;
            border-bottom: 3px solid #ffff00;
        }
.grass .toolbar li > div.expanded {
            color: #ffff00;
            background-color: #667c66
        }
.grass .toolbar li > div.expanded .active {
                border-bottom: 3px solid #667c66;
            }
.grass .toolbar li > div.expanded .toolbar-close-bar {
                color: #667c66;
                background-color: #ffff00
            }
.grass .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #ffffff;
                    background-color: #424443;
                }
.grass .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #ffff00;
            background-color: #424443;
            color: #ffff00;
        }
.grass .toolbar li:hover {
            background-color: #424443;
        }
.grass .toolbar .disabled {
            color: #e5e9e5 !important
        }
.grass .toolbar .disabled .toolbar-item-text {
                color: #e5e9e5 !important;
            }
.grass .toolbar .disabled svg {
                fill: #e5e9e5 !important
            }
.grass .toolbar .disabled svg.disabled-stroke {
                    stroke: #e5e9e5 !important;
                }
.grass .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.grass .toolbar .toolbar-item-arrow {
            fill: #ffffff;
        }
.grass-light .toolbar {
        color: #000000
    }
.grass-light .toolbar li .toolbar-menu-content > ul {
                color: #000000;
                background-color: #babfba;
                border-bottom: 1px solid #ffff00;
            }
.grass-light .toolbar li .toolbar-menu-content > div {
                color: #000000;
                background-color: #babfba;
                border-bottom: 1px solid #ffff00;
            }
.grass-light .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #ffff00;
            }
.grass-light .toolbar li .active {
            color: #ffff00;
            background-color: #babfba;
            border-bottom: 3px solid #ffff00;
        }
.grass-light .toolbar li > div.expanded {
            color: #ffff00;
            background-color: #babfba
        }
.grass-light .toolbar li > div.expanded .active {
                border-bottom: 3px solid #babfba;
            }
.grass-light .toolbar li > div.expanded .toolbar-close-bar {
                color: #babfba;
                background-color: #ffff00
            }
.grass-light .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #9cadb2;
                }
.grass-light .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #ffff00;
            background-color: #9cadb2;
            color: #ffff00;
        }
.grass-light .toolbar li:hover {
            background-color: #9cadb2;
        }
.grass-light .toolbar .disabled {
            color: #e5e9e5 !important
        }
.grass-light .toolbar .disabled .toolbar-item-text {
                color: #e5e9e5 !important;
            }
.grass-light .toolbar .disabled svg {
                fill: #e5e9e5 !important
            }
.grass-light .toolbar .disabled svg.disabled-stroke {
                    stroke: #e5e9e5 !important;
                }
.grass-light .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.grass-light .toolbar .toolbar-item-arrow {
            fill: #000000;
        }
.mud .toolbar {
        color: #ffffff
    }
.mud .toolbar li .toolbar-menu-content > ul {
                color: #ffffff;
                background-color: #4b3705;
                border-bottom: 1px solid #ffff00;
            }
.mud .toolbar li .toolbar-menu-content > div {
                color: #ffffff;
                background-color: #4b3705;
                border-bottom: 1px solid #ffff00;
            }
.mud .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #ffff00;
            }
.mud .toolbar li .active {
            color: #ffff00;
            background-color: #4b3705;
            border-bottom: 3px solid #ffff00;
        }
.mud .toolbar li > div.expanded {
            color: #ffff00;
            background-color: #4b3705
        }
.mud .toolbar li > div.expanded .active {
                border-bottom: 3px solid #4b3705;
            }
.mud .toolbar li > div.expanded .toolbar-close-bar {
                color: #4b3705;
                background-color: #ffff00
            }
.mud .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #ffffff;
                    background-color: #8c7454;
                }
.mud .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #ffff00;
            background-color: #8c7454;
            color: #ffff00;
        }
.mud .toolbar li:hover {
            background-color: #8c7454;
        }
.mud .toolbar .disabled {
            color: #777777 !important
        }
.mud .toolbar .disabled .toolbar-item-text {
                color: #777777 !important;
            }
.mud .toolbar .disabled svg {
                fill: #777777 !important
            }
.mud .toolbar .disabled svg.disabled-stroke {
                    stroke: #777777 !important;
                }
.mud .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.mud .toolbar .toolbar-item-arrow {
            fill: #ffffff;
        }
.mud-light .toolbar {
        color: #000000
    }
.mud-light .toolbar li .toolbar-menu-content > ul {
                color: #000000;
                background-color: #fff0bb;
                border-bottom: 1px solid #8fa61a;
            }
.mud-light .toolbar li .toolbar-menu-content > div {
                color: #000000;
                background-color: #fff0bb;
                border-bottom: 1px solid #8fa61a;
            }
.mud-light .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #8fa61a;
            }
.mud-light .toolbar li .active {
            color: #8fa61a;
            background-color: #fff0bb;
            border-bottom: 3px solid #8fa61a;
        }
.mud-light .toolbar li > div.expanded {
            color: #8fa61a;
            background-color: #fff0bb
        }
.mud-light .toolbar li > div.expanded .active {
                border-bottom: 3px solid #fff0bb;
            }
.mud-light .toolbar li > div.expanded .toolbar-close-bar {
                color: #fff0bb;
                background-color: #8fa61a
            }
.mud-light .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #b28f5a;
                }
.mud-light .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #8fa61a;
            background-color: #b28f5a;
            color: #8fa61a;
        }
.mud-light .toolbar li:hover {
            background-color: #b28f5a;
        }
.mud-light .toolbar .disabled {
            color: #777777 !important
        }
.mud-light .toolbar .disabled .toolbar-item-text {
                color: #777777 !important;
            }
.mud-light .toolbar .disabled svg {
                fill: #777777 !important
            }
.mud-light .toolbar .disabled svg.disabled-stroke {
                    stroke: #777777 !important;
                }
.mud-light .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.mud-light .toolbar .toolbar-item-arrow {
            fill: #000000;
        }
.illini .toolbar {
        color: #ffffff
    }
.illini .toolbar li .toolbar-menu-content > ul {
                color: #ffffff;
                background-color: #00467f;
                border-bottom: 1px solid #ffff00;
            }
.illini .toolbar li .toolbar-menu-content > div {
                color: #ffffff;
                background-color: #00467f;
                border-bottom: 1px solid #ffff00;
            }
.illini .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #ffff00;
            }
.illini .toolbar li .active {
            color: #ffff00;
            background-color: #00467f;
            border-bottom: 3px solid #ffff00;
        }
.illini .toolbar li > div.expanded {
            color: #ffff00;
            background-color: #00467f
        }
.illini .toolbar li > div.expanded .active {
                border-bottom: 3px solid #00467f;
            }
.illini .toolbar li > div.expanded .toolbar-close-bar {
                color: #00467f;
                background-color: #ffff00
            }
.illini .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #ffffff;
                    background-color: #abc8e2;
                }
.illini .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #ffff00;
            background-color: #abc8e2;
            color: #ffff00;
        }
.illini .toolbar li:hover {
            background-color: #abc8e2;
        }
.illini .toolbar .disabled {
            color: #777777 !important
        }
.illini .toolbar .disabled .toolbar-item-text {
                color: #777777 !important;
            }
.illini .toolbar .disabled svg {
                fill: #777777 !important
            }
.illini .toolbar .disabled svg.disabled-stroke {
                    stroke: #777777 !important;
                }
.illini .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.illini .toolbar .toolbar-item-arrow {
            fill: #ffffff;
        }
.illini-light .toolbar {
        color: #000000
    }
.illini-light .toolbar li .toolbar-menu-content > ul {
                color: #000000;
                background-color: #e1e6fa;
                border-bottom: 1px solid #ffff00;
            }
.illini-light .toolbar li .toolbar-menu-content > div {
                color: #000000;
                background-color: #e1e6fa;
                border-bottom: 1px solid #ffff00;
            }
.illini-light .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                border-right: 10px solid #ffff00;
            }
.illini-light .toolbar li .active {
            color: #ffff00;
            background-color: #e1e6fa;
            border-bottom: 3px solid #ffff00;
        }
.illini-light .toolbar li > div.expanded {
            color: #ffff00;
            background-color: #e1e6fa
        }
.illini-light .toolbar li > div.expanded .active {
                border-bottom: 3px solid #e1e6fa;
            }
.illini-light .toolbar li > div.expanded .toolbar-close-bar {
                color: #e1e6fa;
                background-color: #ffff00
            }
.illini-light .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #c4d7ed;
                }
.illini-light .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #ffff00;
            background-color: #c4d7ed;
            color: #ffff00;
        }
.illini-light .toolbar li:hover {
            background-color: #c4d7ed;
        }
.illini-light .toolbar .disabled {
            color: #777777 !important
        }
.illini-light .toolbar .disabled .toolbar-item-text {
                color: #777777 !important;
            }
.illini-light .toolbar .disabled svg {
                fill: #777777 !important
            }
.illini-light .toolbar .disabled svg.disabled-stroke {
                    stroke: #777777 !important;
                }
.illini-light .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.illini-light .toolbar .toolbar-item-arrow {
            fill: #000000;
        }
.sky .toolbar {
        color: #ffffff
    }
.sky .toolbar li .toolbar-menu-content > ul {
                    color: #ffffff;
                    background-color: #fcfcfc;
                    border-bottom: 1px solid #235635;
                }
.sky .toolbar li .toolbar-menu-content > div {
                    color: #ffffff;
                    background-color: #fcfcfc;
                    border-bottom: 1px solid #235635;
                }
.sky .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                    border-right: 10px solid #235635;
                }
.sky .toolbar li .toolbar-tool .toolbar-item-text {
                    color: #000000;
                }
.sky .toolbar li .toolbar-button .toolbar-item-text {
                    color: #000000;
                }
.sky .toolbar li .active {
            color: #235635;
            background-color: #fcfcfc;
            border-bottom: 3px solid #235635;
        }
.sky .toolbar li > div.expanded {
            color: #235635;
            background-color: #fcfcfc
        }
.sky .toolbar li > div.expanded .active {
                border-bottom: 3px solid #fcfcfc;
            }
.sky .toolbar li > div.expanded .toolbar-close-bar {
                color: #fcfcfc;
                background-color: #235635
            }
.sky .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #b1b1b1;
                }
.sky .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #235635;
            background-color: #b1b1b1;
            color: #235635;
        }
.sky .toolbar li:hover {
            background-color: #b1b1b1;
            color: #000000
        }
.sky .toolbar li:hover .toolbar-svg-icon {
                fill: #000000;
            }
.sky .toolbar li:hover .toolbar-item-arrow {
                fill: #000000;
            }
.sky .toolbar .disabled {
            color: #c8c8c8 !important
        }
.sky .toolbar .disabled .toolbar-item-text {
                color: #c8c8c8 !important;
            }
.sky .toolbar .disabled svg {
                fill: #c8c8c8 !important;
                stroke: #c8c8c8 !important;
            }
.sky .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.sky .toolbar .toolbar-item-arrow {
            fill: #ffffff;
        }
.sky .toolbar.process-count-tools li {
            color: #000000;
            background-color: #e2e2e2;
        }
.sky-light .toolbar {
        color: #ffffff
    }
.sky-light .toolbar li .toolbar-menu-content > ul {
                    color: #ffffff;
                    background-color: #fcfcfc;
                    border-bottom: 1px solid #235635;
                }
.sky-light .toolbar li .toolbar-menu-content > div {
                    color: #ffffff;
                    background-color: #fcfcfc;
                    border-bottom: 1px solid #235635;
                }
.sky-light .toolbar li .toolbar-menu-content .toolbar-menu-content-wrapper {
                    border-right: 10px solid #235635;
                }
.sky-light .toolbar li .toolbar-tool .toolbar-item-text {
                    color: #000000;
                }
.sky-light .toolbar li .toolbar-button .toolbar-item-text {
                    color: #000000;
                }
.sky-light .toolbar li .active {
            color: #235635;
            background-color: #fcfcfc;
            border-bottom: 3px solid #235635;
        }
.sky-light .toolbar li > div.expanded {
            color: #235635;
            background-color: #fcfcfc
        }
.sky-light .toolbar li > div.expanded .active {
                border-bottom: 3px solid #fcfcfc;
            }
.sky-light .toolbar li > div.expanded .toolbar-close-bar {
                color: #fcfcfc;
                background-color: #235635
            }
.sky-light .toolbar li > div.expanded .toolbar-close-bar:hover {
                    color: #000000;
                    background-color: #b1b1b1;
                }
.sky-light .toolbar li .toolbar-tool.selected {
            border-bottom: 3px solid #235635;
            background-color: #b1b1b1;
            color: #235635;
        }
.sky-light .toolbar li:hover {
            background-color: #b1b1b1;
            color: #000000
        }
.sky-light .toolbar li:hover .toolbar-svg-icon {
                fill: #000000;
            }
.sky-light .toolbar li:hover .toolbar-item-arrow {
                fill: #000000;
            }
.sky-light .toolbar .disabled {
            color: #c8c8c8 !important
        }
.sky-light .toolbar .disabled .toolbar-item-text {
                color: #c8c8c8 !important;
            }
.sky-light .toolbar .disabled svg {
                fill: #c8c8c8 !important;
                stroke: #c8c8c8 !important;
            }
.sky-light .toolbar .disabled .zone-explode {
                opacity: 0.6;
            }
.sky-light .toolbar .toolbar-item-arrow {
            fill: #ffffff;
        }
.sky-light .toolbar.process-count-tools li {
            color: #000000;
            background-color: #e2e2e2;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
div.textarea-form-input {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: inherit
}
div.textarea-form-input textarea {
        resize: none;
        height: inherit;
        min-height: 7px;
        text-overflow: ellipsis;
        overflow: hidden;
        border: none;
        overflow: auto;
        outline: none;
        box-shadow: none;
        width: 100%;
        height: 100%;
    }
div.textarea-form-input div.input-label-container {
        line-height: 7px;
        font-size: 10px;
        min-height: 10px;
        text-indent: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 10%;
        max-width: 100%;
    }
div.textarea-form-input .red-star {
        font-size: 15px;
        line-height: 10px;
        padding-right: 5px;
    }
div.textarea-form-input div.input-container {
        display: flex;
        height: calc(100% - 14px);
    }
.default .textarea-form-input .red-star {
        color: #ff0000;
    }
.default-light .textarea-form-input .red-star {
        color: #ff0000;
    }
.sky .textarea-form-input .red-star {
        color: #ff0000;
    }
.sky-light .textarea-form-input .red-star {
        color: #ff0000;
    }
.grass .textarea-form-input .red-star {
        color: #ff0000;
    }
.grass-light .textarea-form-input .red-star {
        color: #ff0000;
    }
.mud .textarea-form-input .red-star {
        color: #ff0000;
    }
.mud-light .textarea-form-input .red-star {
        color: #ff0000;
    }
.illini .textarea-form-input .red-star {
        color: #ff0000;
    }
.illini-light .textarea-form-input .red-star {
        color: #ff0000;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.quill {
    border-radius: 0px 0px 4px 4px;
    font-family: "open_sansregular"
}
.quill .ql-container {
        height: 300px
    }
.quill .ql-container strong {
            font-weight: bold;
        }
.quill .ql-container em {
            font-style: italic;
        }
.ql-toolbar {
    border-radius: 4px 4px 0px 0px
}
.ql-toolbar span.view-html {
        font-size: 15px;
        font-weight: 600;
    }
div.raw-editor {
    display: none;
}
.show-raw .quill {
        display: none;
    }
.show-raw div.raw-editor {
        display: block;
        margin: 0px;
        width: inherit;
        height: 300px;
        border-radius: 0px 0px 4px 4px;
    }
.default .quill {
            background-color: #bfbfbf;
            border: 2px solid #bfbfbf;
            color: #000000;
        }
.default .ql-toolbar {
            background-color: #bfbfbf;
            border: 2px solid #bfbfbf;
            color: #000000;
        }
.default-light .quill {
            background-color: #cfcfcf;
            border: 2px solid #cfcfcf;
            color: #fcfcfc;
        }
.default-light .ql-toolbar {
            background-color: #cfcfcf;
            border: 2px solid #cfcfcf;
            color: #fcfcfc;
        }
.sky .quill {
            background-color: #c8c8c8;
            border: 2px solid #c8c8c8;
            color: #fcfcfc;
        }
.sky .ql-toolbar {
            background-color: #c8c8c8;
            border: 2px solid #c8c8c8;
            color: #fcfcfc;
        }
.sky-light .quill {
            background-color: #c8c8c8;
            border: 2px solid #c8c8c8;
            color: #fcfcfc;
        }
.sky-light .ql-toolbar {
            background-color: #c8c8c8;
            border: 2px solid #c8c8c8;
            color: #fcfcfc;
        }
.grass .quill {
            background-color: #9cadb2;
            border: 2px solid #9cadb2;
            color: #667c66;
        }
.grass .ql-toolbar {
            background-color: #9cadb2;
            border: 2px solid #9cadb2;
            color: #667c66;
        }
.grass-light .quill {
            background-color: #424443;
            border: 2px solid #424443;
            color: #babfba;
        }
.grass-light .ql-toolbar {
            background-color: #424443;
            border: 2px solid #424443;
            color: #babfba;
        }
.mud .quill {
            background-color: #f2f3f8;
            border: 2px solid #f2f3f8;
            color: #4b3705;
        }
.mud .ql-toolbar {
            background-color: #f2f3f8;
            border: 2px solid #f2f3f8;
            color: #4b3705;
        }
.mud-light .quill {
            background-color: #d6c282;
            border: 2px solid #d6c282;
            color: #fff0bb;
        }
.mud-light .ql-toolbar {
            background-color: #d6c282;
            border: 2px solid #d6c282;
            color: #fff0bb;
        }
.illini .quill {
            background-color: #f2f3f8;
            border: 2px solid #f2f3f8;
            color: #00467f;
        }
.illini .ql-toolbar {
            background-color: #f2f3f8;
            border: 2px solid #f2f3f8;
            color: #00467f;
        }
.illini-light .quill {
            background-color: #abc8e2;
            border: 2px solid #abc8e2;
            color: #e1e6fa;
        }
.illini-light .ql-toolbar {
            background-color: #abc8e2;
            border: 2px solid #abc8e2;
            color: #e1e6fa;
        }
.sky .quill {
            color: #000000;
        }
.sky .ql-toolbar {
            border-bottom: 1px solid #000000;
        }
.sky-light .quill {
            color: #000000;
        }
.sky-light .ql-toolbar {
            border-bottom: 1px solid #000000;
        }


        .tree-view-container .tree-node-container .tree-view-parent {
            display: flex
        }
.tree-view-container .tree-node-container .tree-view-parent .checkbox-div {
                width: 25px;
                margin-top: 0px;
            }
.tree-view-container .tree-node-container .expand-icon svg {
                height: 14px;
                width: 13px;
            }
.tree-view-container .tree-node-container .expand-icon .svg-container {
                cursor: pointer;
                margin-right: 5px;
            }
.tree-view-container .tree-node-container .expand-icon .collapse-button-rotate-90 {
                transform: rotate(-90deg);
            }
.tree-view-container .tree-node-container .expand-icon .collapse-button-rotate-45 {
                transform: rotate(-45deg);
            }
.tree-view-container .tree-node-container .label {
            line-height: 25px;
        }
.tree-view-container .node-child-container {
        margin-left: 40px;
    }

.zero-to-infinite-cont {
    width: 100%;
    display: flex;
    flex-direction: column
}

.zero-to-infinite-cont a {
        margin-top: 5px;
        margin-bottom: 5px;
    }

.zero-to-infinite-cont .child-row {
        display: flex;
        flex-direction: column
    }

.zero-to-infinite-cont .child-row a {
            text-align: right;
        }

.zero-to-infinite-cont .add-link {
        font-family: "open_sansregular";
        font-size: 13px;
    }

.zero-to-infinite-cont .delete-link-container {
        padding-bottom: 10px;
        text-align: right;
    }

.zero-to-infinite-cont .delete-link {
        font-family: "open_sansregular";
        font-size: 13px;
        padding: 3px 0px;
    }

.z-to-n-header {
    & .form-section-header & .form-section-children {
        margin-bottom: 5px;
    }
}

.zero-to-infinite-cont {
    width: 100%;
    display: flex;
    flex-direction: column
}

.zero-to-infinite-cont a {
        margin-top: 5px;
        margin-bottom: 5px;
    }

.zero-to-infinite-cont .child-row {
        display: flex;
        flex-direction: column
    }

.zero-to-infinite-cont .child-row a {
            text-align: right;
        }

.zero-to-infinite-cont .add-link {
        font-family: "open_sansregular";
        font-size: 13px;
    }

.zero-to-infinite-cont .delete-link-container {
        padding-bottom: 10px;
        text-align: right;
    }

.zero-to-infinite-cont .delete-link {
        font-family: "open_sansregular";
        font-size: 13px;
        padding: 3px 0px;
    }

.z-to-n-header {
    & .form-section-header & .form-section-children {
        margin-bottom: 5px;
    }
}

.notifications-err-dialog {
    height: 400px;
    width: 606px
}
.notifications-err-dialog .dialog-box-body {
        position: relative;
        -webkit-user-select: text;
            -ms-user-select: text;
                user-select: text
    }
.notifications-err-dialog .dialog-box-body > div {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            left: 10px;
        }
.notifications-err-dialog .dialog-box-body .err-header {
            font-size: 14px;
            margin-bottom: 25px;
        }
.notifications-err-dialog .dialog-box-body .retry-link {
            margin-top: 20px;
        }
.notifications-err-dialog .dialog-box-body .error-detail-bucket .bucket-header {
                font-size: 13px;
            }
.notifications-err-dialog .dialog-box-body .error-detail-bucket .bucket-content {
                white-space: pre;
            }
.notifications-err-dialog .dialog-box-body .refresh-body {
            display: flex;
            flex-direction: column;
            height: 100%
        }
.notifications-err-dialog .dialog-box-body .refresh-body .refresh-btn-container {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.notification-msg .toastify {
        width: 350px
    }
.notification-msg .toastify.toastify--bottom-right {
            bottom: 1em;
            right: 2em;
        }
.notification-msg .toastify .toastify-content {
            border-radius: 5px;
            border-left-width: 6px;
            border-left-style: solid;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px 0px, rgba(0, 0, 0, 0.05) 0px 2px 15px 0px;
            margin-bottom: 12px;
            min-height: 62px;
            padding: 8px;
            position: relative;
            width: 100%
        }
.notification-msg .toastify .toastify-content .toastify__body {
                font-family: open_sansregular;
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
            }
.notification-msg .toastify .toastify-content .toastify__close {
                background: transparent;
                border-color: currentColor;
                border-color: initial;
                border-image: none;
                border-image: initial;
                border-style: none;
                border-width: medium;
                border-width: initial;
                border: none;
                color: inherit;
                cursor: pointer;
                font-size: 14px;
                font-weight: 700;
                height: 16px;
                opacity: 1;
                outline: none;
                padding: 0px;
                position: absolute;
                right: 2px;
                text-align: center;
                text-decoration: none;
                top: 2px;
                transition: 0.3s ease;
                width: 16px;
                z-index: 100
            }
.notification-msg .toastify .toastify-content .toastify__close svg {
                    width: 16px;
                    height: 16px;
                }
.notification-msg .toastify .toastify-content .toastify__progress {
                opacity: 1;
            }
.default .notification-msg .toastify .toastify-content {
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.7)
        }
.default .notification-msg .toastify .toastify-content:hover {
                background-color: #000000;
            }
.default .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #404040;
                opacity: 1;
            }
.default .notification-msg .toastify .toastify-content--info {
            border-left-color: #1daaef;
        }
.default .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.default .notification-msg .toastify .toastify-content--success {
            border-left-color: #8fa61a;
        }
.default .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.default-light .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: rgba(252, 252, 252, 0.7)
        }
.default-light .notification-msg .toastify .toastify-content:hover {
                background-color: #fcfcfc;
            }
.default-light .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #919191;
                opacity: 1;
            }
.default-light .notification-msg .toastify .toastify-content--info {
            border-left-color: #0e55a8;
        }
.default-light .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.default-light .notification-msg .toastify .toastify-content--success {
            border-left-color: #8fa61a;
        }
.default-light .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.sky .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: rgba(252, 252, 252, 0.7)
        }
.sky .notification-msg .toastify .toastify-content:hover {
                background-color: #fcfcfc;
            }
.sky .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #0194d3;
                opacity: 1;
            }
.sky .notification-msg .toastify .toastify-content--info {
            border-left-color: #01425e;
        }
.sky .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.sky .notification-msg .toastify .toastify-content--success {
            border-left-color: #235635;
        }
.sky .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.sky-light .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: rgba(252, 252, 252, 0.7)
        }
.sky-light .notification-msg .toastify .toastify-content:hover {
                background-color: #fcfcfc;
            }
.sky-light .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #0194d3;
                opacity: 1;
            }
.sky-light .notification-msg .toastify .toastify-content--info {
            border-left-color: #01425e;
        }
.sky-light .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.sky-light .notification-msg .toastify .toastify-content--success {
            border-left-color: #235635;
        }
.sky-light .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.grass .notification-msg .toastify .toastify-content {
            color: #ffffff;
            background-color: rgba(0, 112, 51, 0.7)
        }
.grass .notification-msg .toastify .toastify-content:hover {
                background-color: #667c66;
            }
.grass .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #325132;
                opacity: 1;
            }
.grass .notification-msg .toastify .toastify-content--info {
            border-left-color: #e26816;
        }
.grass .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.grass .notification-msg .toastify .toastify-content--success {
            border-left-color: #ffff00;
        }
.grass .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.grass-light .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: rgba(0, 112, 51, 0.7)
        }
.grass-light .notification-msg .toastify .toastify-content:hover {
                background-color: #babfba;
            }
.grass-light .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #545f54;
                opacity: 1;
            }
.grass-light .notification-msg .toastify .toastify-content--info {
            border-left-color: #e26816;
        }
.grass-light .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.grass-light .notification-msg .toastify .toastify-content--success {
            border-left-color: #ffff00;
        }
.grass-light .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.mud .notification-msg .toastify .toastify-content {
            color: #ffffff;
            background-color: rgba(75, 55, 5, 0.7)
        }
.mud .notification-msg .toastify .toastify-content:hover {
                background-color: #4b3705;
            }
.mud .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #766146;
                opacity: 1;
            }
.mud .notification-msg .toastify .toastify-content--info {
            border-left-color: #f0c248;
        }
.mud .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.mud .notification-msg .toastify .toastify-content--success {
            border-left-color: #ffff00;
        }
.mud .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.mud-light .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: rgba(255, 240, 187, 0.7)
        }
.mud-light .notification-msg .toastify .toastify-content:hover {
                background-color: #fff0bb;
            }
.mud-light .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #a37b42;
                opacity: 1;
            }
.mud-light .notification-msg .toastify .toastify-content--info {
            border-left-color: #0e55a8;
        }
.mud-light .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.mud-light .notification-msg .toastify .toastify-content--success {
            border-left-color: #8fa61a;
        }
.mud-light .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.illini .notification-msg .toastify .toastify-content {
            color: #ffffff;
            background-color: rgba(0, 70, 127, 0.7)
        }
.illini .notification-msg .toastify .toastify-content:hover {
                background-color: #00467f;
            }
.illini .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #636466;
                opacity: 1;
            }
.illini .notification-msg .toastify .toastify-content--info {
            border-left-color: #f39c11;
        }
.illini .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.illini .notification-msg .toastify .toastify-content--success {
            border-left-color: #ffff00;
        }
.illini .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.illini-light .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: rgba(225, 230, 250, 0.7)
        }
.illini-light .notification-msg .toastify .toastify-content:hover {
                background-color: #e1e6fa;
            }
.illini-light .notification-msg .toastify .toastify-content .toastify__progress {
                background-color: #375d81;
                opacity: 1;
            }
.illini-light .notification-msg .toastify .toastify-content--info {
            border-left-color: #b2962c;
        }
.illini-light .notification-msg .toastify .toastify-content--error {
            border-left-color: #ff0000;
        }
.illini-light .notification-msg .toastify .toastify-content--success {
            border-left-color: #ffff00;
        }
.illini-light .notification-msg .toastify .toastify-content--warning {
            border-left-color: #f89406;
        }
.sky .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: #e2e2e2
        }
.sky .notification-msg .toastify .toastify-content:hover {
                background-color: #e2e2e2;
            }
.sky-light .notification-msg .toastify .toastify-content {
            color: #000000;
            background-color: #e2e2e2
        }
.sky-light .notification-msg .toastify .toastify-content:hover {
                background-color: #e2e2e2;
            }

.accordion-item .loader-container {
    z-index: auto;
}

.notification-msg .toastify__body {
    line-height: 20px
}

.notification-msg .toastify__body .api-error-notification .err-msg {
            min-height: 48px;
        }

.notification-msg .toastify__body .api-error-notification .action-links {
            margin-top: 4px
        }

.notification-msg .toastify__body .api-error-notification .action-links * {
                padding: 0 4px;
            }

.notification-msg .toastify__body .api-error-notification .action-links :first-child {
                padding-left: 0;
            }

.api-error-notification ul, .notifications-err-dialog ul {
        list-style: disc !important
    }

.api-error-notification ul li, .notifications-err-dialog ul li {
            margin-left: 17px;
        }

/** available themes:
@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";
@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/dark/main.css";
*/

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */

@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */

@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}

.theme-root {
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300;
}

.default {
        color: #ffffff;
        background-color: #000000
    }

.default a:link {
            color: #1daaef;
        }

.default a:visited {
            color: #1daaef;
        }

.default a:hover {
            color: #8fa61a;
        }

.default a:active {
            color: #8fa61a;
        }

.default a:focus {
            color: #8fa61a;
        }

.default-light {
        color: #ffffff;
        background-color: #fcfcfc
    }

.default-light a:link {
            color: #0e55a8;
        }

.default-light a:visited {
            color: #0e55a8;
        }

.default-light a:hover {
            color: #8fa61a;
        }

.default-light a:active {
            color: #8fa61a;
        }

.default-light a:focus {
            color: #8fa61a;
        }

.sky {
        color: #ffffff;
        background-color: #fcfcfc
    }

.sky a:link {
            color: #01425e;
        }

.sky a:visited {
            color: #01425e;
        }

.sky a:hover {
            color: #235635;
        }

.sky a:active {
            color: #235635;
        }

.sky a:focus {
            color: #235635;
        }

.sky-light {
        color: #ffffff;
        background-color: #fcfcfc
    }

.sky-light a:link {
            color: #01425e;
        }

.sky-light a:visited {
            color: #01425e;
        }

.sky-light a:hover {
            color: #235635;
        }

.sky-light a:active {
            color: #235635;
        }

.sky-light a:focus {
            color: #235635;
        }

.grass {
        color: #ffffff;
        background-color: #667c66
    }

.grass a:link {
            color: #e26816;
        }

.grass a:visited {
            color: #e26816;
        }

.grass a:hover {
            color: #ffff00;
        }

.grass a:active {
            color: #ffff00;
        }

.grass a:focus {
            color: #ffff00;
        }

.grass-light {
        color: #ffffff;
        background-color: #babfba
    }

.grass-light a:link {
            color: #e26816;
        }

.grass-light a:visited {
            color: #e26816;
        }

.grass-light a:hover {
            color: #ffff00;
        }

.grass-light a:active {
            color: #ffff00;
        }

.grass-light a:focus {
            color: #ffff00;
        }

.mud {
        color: #ffffff;
        background-color: #4b3705
    }

.mud a:link {
            color: #f0c248;
        }

.mud a:visited {
            color: #f0c248;
        }

.mud a:hover {
            color: #ffff00;
        }

.mud a:active {
            color: #ffff00;
        }

.mud a:focus {
            color: #ffff00;
        }

.mud-light {
        color: #ffffff;
        background-color: #fff0bb
    }

.mud-light a:link {
            color: #0e55a8;
        }

.mud-light a:visited {
            color: #0e55a8;
        }

.mud-light a:hover {
            color: #8fa61a;
        }

.mud-light a:active {
            color: #8fa61a;
        }

.mud-light a:focus {
            color: #8fa61a;
        }

.illini {
        color: #ffffff;
        background-color: #00467f
    }

.illini a:link {
            color: #f39c11;
        }

.illini a:visited {
            color: #f39c11;
        }

.illini a:hover {
            color: #ffff00;
        }

.illini a:active {
            color: #ffff00;
        }

.illini a:focus {
            color: #ffff00;
        }

.illini-light {
        color: #ffffff;
        background-color: #e1e6fa
    }

.illini-light a:link {
            color: #b2962c;
        }

.illini-light a:visited {
            color: #b2962c;
        }

.illini-light a:hover {
            color: #ffff00;
        }

.illini-light a:active {
            color: #ffff00;
        }

.illini-light a:focus {
            color: #ffff00;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.login-err-animation {
    animation: shake 250ms ease-in-out;
}
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}
.mobile-root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: beige;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: white;
    font-family: Arial, sans-serif;

    display: flex;
    justify-content: center
}
.mobile-root .box-title {
        font-size: 2em;
        font-weight: bold;
    }
.mobile-root .info {
        text-align: center;
        background-color: black;
        position: relative;
        width: 36em;
        height: 18em;
        padding: 2em;
        border-radius: 0.625em;
        margin-top: 7.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1em;
        margin-right: 1em;
    }
.mobile-root .allowedDownload {
        min-width: 40%;
        margin-left: auto;
        margin-right: auto;
        font-size: 140%;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
.mobile-root .downloadLink {
        padding-top: 5px;
        color: #00e !important;
        text-decoration: none;
    }
.mobile-root .version {
        font-weight: bold;
        font-size: 130%;
        height: 2em;
    }
.mobile-root .message {
        font-size: 120%;
        height: 3.5em;
    }
.login-root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: "open_sansregular";
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}
.login-root > .login-page-container {
        display: flex;
        flex-grow: 1
    }
.login-root > .login-page-container .login-box.hidden {
            display: none;
        }
.login-root > .login-page-container .login-box {
            position: relative;
            background-image: none;
            min-width: 350px;
            padding: 20px;
            border-radius: 10px 10px 10px 10px;
            margin: 7.5% auto auto auto
        }
.login-root > .login-page-container .login-box .login-logo-div {
                max-width: 293px;
                max-height: 77px;
                width: 293px;
                height: 77px;
                margin-left: auto;
                margin-right: auto;
            }
.login-root > .login-page-container .login-box .validation-summary-errors {
                width: 100%;
                max-width: 800px;
                line-height: 20px;
                text-align: center
            }
.login-root > .login-page-container .login-box .validation-summary-errors > div {
                    text-align: left;
                    padding-left: 5px
                }
.login-root > .login-page-container .login-box .validation-summary-errors > div ul {
                        list-style: disc;
                        padding-left: 40px;
                        text-align: left;
                    }
.login-root > .login-page-container .login-box .login-eula-container {
                height: 400px;
                margin-top: -80px
            }
.login-root > .login-page-container .login-box .login-eula-container .display-label {
                    font-weight: 1000;
                    margin-bottom: 10px;
                }
.login-root > .login-page-container .login-box .login-eula-container .form-section {
                    width: 500px;
                    margin-bottom: 20px;
                    height: 320px;
                    overflow: auto
                }
.login-root > .login-page-container .login-box .login-eula-container .form-section .form-section-children .privacy-policy-body p {
                                margin-top: 5pt
                            }
.login-root > .login-page-container .login-box .login-eula-container .form-section .form-section-children .privacy-policy-body p strong {
                                    font-weight: 1000;
                                }
.login-root > .login-page-container .login-box .verification-container {
                height: 24em;
                margin-top: -5.4em
            }
.login-root > .login-page-container .login-box .verification-container .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }
.login-root > .login-page-container .login-box .verification-container .form-section {
                    width: 100%;
                    max-width: 500px;
                    height: 23em;
                    overflow: auto
                }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body {
                            text-align: center
                        }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .form-section-child-stretch {
                                flex-direction: row;
                                flex-flow: row wrap;
                            }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .verification-instructions {
                                font-size: 1em;
                                margin-top: 5em;
                                margin-bottom: 3.5em
                            }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .verification-instructions strong {
                                    font-weight: 1000;
                                }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .enrollment-btn {
                                height: 45px;
                                max-width: 180px;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 2em;
                            }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .enrollment-resend-btn {
                                height: 45px;
                                max-width: 140px;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 2em;
                            }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .text-form-input {
                                width: 28%;
                                margin-left: 19%;
                            }
.login-root > .login-page-container .login-box .verification-container .form-section .form-section-children .enrollment-body .verify-btn {
                                height: 45px;
                                width: 28%;
                                margin-top: 0px;
                                margin-right: 2em;
                            }
.login-root > .login-page-container .login-box .enrollment-container {
                height: 40em;
                margin-top: -5.4em
            }
.login-root > .login-page-container .login-box .enrollment-container .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }
.login-root > .login-page-container .login-box .enrollment-container .form-section {
                    max-width: 800px;
                    margin-top: 4em;
                    margin-bottom: 2em;
                    width: 100%;
                    height: 30em;
                    overflow: auto
                }
.login-root > .login-page-container .login-box .enrollment-container .form-section .form-section-children .enrollment-body {
                            font-family: Arial;
                            width: 98%
                        }
.login-root > .login-page-container .login-box .enrollment-container .form-section .form-section-children .enrollment-body p {
                                margin-top: 5px;
                                margin-bottom: 5px;
                            }
.login-root > .login-page-container .login-box .enrollment-container .form-section .form-section-children .enrollment-body strong {
                                font-weight: bold;
                                margin-top: 5px;
                            }
.login-root > .login-page-container .login-box .enrollment-container .form-section .form-section-children .enrollment-body .privacy-section {
                                border: 0.5px solid;
                                padding: 5px
                            }
.login-root > .login-page-container .login-box .enrollment-container .form-section .form-section-children .enrollment-body .privacy-section .privacy-checkbox {
                                    font-weight: bold;
                                    margin-top: 0.7em;
                                }
.login-root > .login-page-container .login-box .enrollment-container .form-section .form-section-children .enrollment-body .privacy-section .privacy-paragraph {
                                    margin-top: 0;
                                    padding-left: 2em;
                                }
.login-root > .login-page-container .login-box .enrollment-container .accept-control {
                    display: flex
                }
.login-root > .login-page-container .login-box .enrollment-container .accept-control .accept-btn {
                        height: 32px;
                        font-size: 1.3em;
                        margin: auto;
                    }
.login-root > .login-page-container .login-box .enrollment-container .private-confirm-modal {
                    width: 40em
                }
.login-root > .login-page-container .login-box .enrollment-container .private-confirm-modal .dialog-box-body {
                        max-width: 40em;
                        max-height: 10em;
                        overflow: hidden;
                    }
.login-root > .login-page-container .login-box .enrollment-complete {
                height: 220px;
                margin-top: -5.4em;
                text-align: center
            }
.login-root > .login-page-container .login-box .enrollment-complete .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }
.login-root > .login-page-container .login-box .enrollment-complete .form-section {
                    max-width: 400px;
                    margin-top: 0.7em;
                    margin-bottom: 0.7em;
                    width: 100%;
                    height: 9em;
                    overflow: auto
                }
.login-root > .login-page-container .login-box .enrollment-complete .form-section .form-section-children .enrollment-body {
                            text-align: center;
                            margin-top: 4em;
                            font-size: 1em
                        }
.login-root > .login-page-container .login-box .enrollment-complete .form-section .form-section-children .enrollment-body .form-section-child-stretch {
                                flex-direction: row;
                                flex-flow: row wrap;
                            }
.login-root > .login-page-container .login-box .login-input-container {
                width: 78%;
                margin-left: auto;
                margin-right: auto
            }
.login-root > .login-page-container .login-box .login-input-container .display-label {
                    margin: 10.5px 10.5px 20px 10.5px;
                    line-height: 16px;
                }
.login-root > .login-page-container .login-box .login-input-container .display-question {
                    margin: 0 10.5px;
                    line-height: 16px;
                }
.login-root > .login-page-container .login-box .login-input-container .select-form-options {
                    margin-top: 0;
                }
.login-root > .login-page-container .login-box .login-options-div {
                margin-bottom: 23px;
                padding: 0 10px;
                display: flex;
                flex-direction: row
            }
.login-root > .login-page-container .login-box .login-options-div .login-remember-me-container,
                .login-root > .login-page-container .login-box .login-options-div .login-forgot-password {
                    flex: 1 1;
                    margin: 1px;
                    overflow: hidden;
                    white-space: nowrap;
                }
.login-root > .login-page-container .login-box .login-options-div .login-forgot-password {
                    line-height: 19px;
                    text-align: right;
                }
.login-root > .login-page-container .login-box .login-center {
                text-align: center;
            }
.login-root > .login-page-container .login-box .login-btn {
                height: 32px;
                min-width: 100px;
                margin: 0 5px 50px 5px;
                border-radius: 4px;
                font-family: "open_sansregular";
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
            }
.default .login-box {
            background-color: #000000;
        }
.default .login-root {
            background-color: #777777
        }
.default .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.default .login-root > .login-page-container .login-box .login-btn {
                    background-color: #1daaef;
                    border: 1px solid #1daaef;
                    color: #ffffff;
                }
.default .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #737373;
                    border: 1px solid #737373;
                }
.default-light .login-box {
            background-color: #fcfcfc;
        }
.default-light .login-root {
            background-color: #777777
        }
.default-light .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.default-light .login-root > .login-page-container .login-box .login-btn {
                    background-color: #0e55a8;
                    border: 1px solid #0e55a8;
                    color: #ffffff;
                }
.default-light .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #b8b8b8;
                    border: 1px solid #b8b8b8;
                }
.sky .login-box {
            background-color: #fcfcfc;
        }
.sky .login-root {
            background-color: #878787
        }
.sky .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.sky .login-root > .login-page-container .login-box .login-btn {
                    background-color: #01425e;
                    border: 1px solid #01425e;
                    color: #ffffff;
                }
.sky .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #838383;
                    border: 1px solid #838383;
                }
.sky-light .login-box {
            background-color: #fcfcfc;
        }
.sky-light .login-root {
            background-color: #878787
        }
.sky-light .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.sky-light .login-root > .login-page-container .login-box .login-btn {
                    background-color: #01425e;
                    border: 1px solid #01425e;
                    color: #ffffff;
                }
.sky-light .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #838383;
                    border: 1px solid #838383;
                }
.grass .login-box {
            background-color: #667c66;
        }
.grass .login-root {
            background-color: #e5e9e5
        }
.grass .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.grass .login-root > .login-page-container .login-box .login-btn {
                    background-color: #e26816;
                    border: 1px solid #e26816;
                    color: #ffffff;
                }
.grass .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #424443;
                    border: 1px solid #424443;
                }
.grass-light .login-box {
            background-color: #babfba;
        }
.grass-light .login-root {
            background-color: #e5e9e5
        }
.grass-light .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.grass-light .login-root > .login-page-container .login-box .login-btn {
                    background-color: #e26816;
                    border: 1px solid #e26816;
                    color: #ffffff;
                }
.grass-light .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #bfbfbf;
                    border: 1px solid #bfbfbf;
                }
.mud .login-box {
            background-color: #4b3705;
        }
.mud .login-root {
            background-color: #777777
        }
.mud .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.mud .login-root > .login-page-container .login-box .login-btn {
                    background-color: #f0c248;
                    border: 1px solid #f0c248;
                    color: #ffffff;
                }
.mud .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #ad8f68;
                    border: 1px solid #ad8f68;
                }
.mud-light .login-box {
            background-color: #fff0bb;
        }
.mud-light .login-root {
            background-color: #777777
        }
.mud-light .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.mud-light .login-root > .login-page-container .login-box .login-btn {
                    background-color: #0e55a8;
                    border: 1px solid #0e55a8;
                    color: #ffffff;
                }
.mud-light .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #735523;
                    border: 1px solid #735523;
                }
.illini .login-box {
            background-color: #00467f;
        }
.illini .login-root {
            background-color: #777777
        }
.illini .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.illini .login-root > .login-page-container .login-box .login-btn {
                    background-color: #f39c11;
                    border: 1px solid #f39c11;
                    color: #ffffff;
                }
.illini .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #3698db;
                    border: 1px solid #3698db;
                }
.illini-light .login-box {
            background-color: #e1e6fa;
        }
.illini-light .login-root {
            background-color: #777777
        }
.illini-light .login-root > .login-page-container .login-box .validation-summary-errors {
                    background-color: #ff0000;
                }
.illini-light .login-root > .login-page-container .login-box .login-btn {
                    background-color: #b2962c;
                    border: 1px solid #b2962c;
                    color: #ffffff;
                }
.illini-light .login-root > .login-page-container .login-box .cancel-btn {
                    background-color: #00467f;
                    border: 1px solid #00467f;
                }

.err-dialog {
    cursor: default;
    box-sizing: content-box;
    white-space: normal
}
.err-dialog .dialog-box-body {
        -webkit-user-select: text !important;
            -ms-user-select: text !important;
                user-select: text !important;
        width: 600px;
        position: relative;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        align-items: stretch
    }
.err-dialog .dialog-box-body .message {
            flex: 1 1 auto;
            align-self: auto;
        }
.err-dialog .dialog-box-body .metadata {
            font-size: smaller;
            min-height: auto;
            flex: auto;
            margin-top: 1em;
            align-self: auto;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.notifications-main-container {
    overflow-x: auto;
    width: 100%;
    padding-top: 13px
}
.notifications-main-container .bucket {
        width: calc(100% - 5px);
        min-height: 15px;
        margin-top: 2px;
        margin-bottom: 9px
    }
.notifications-main-container .bucket .bucket-content .list-item {
                font-size: 13px;
                margin-left: 30px;
                padding-top: 5px;
            }
.notifications-main-container .bucket .bucket-content .report-list {
                display: flex;
                flex-direction: column
            }
.notifications-main-container .bucket .bucket-content .report-list .click-me-link {
                    padding-top: 4px;
                }
.notifications-main-container .notification-bucket-header {
        height: 40px;
        padding-left: 10px;
        font-family: "open_sansregular";
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
        line-height: 40px;
        margin-bottom: 10px;
        max-width: 400px
    }
.notifications-main-container .notification-bucket-header .bucket-symbol {
            float: right;
        }
.notifications-main-container .notification-bucket-header .time-bucket-title {
            display: flex;
        }
.notifications-main-container .list-bucket-header {
        height: 15px;
        font-size: 13px;
    }
.notifications-main-container {
        .default & .new-tag {
            background-color: #8fa61a;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .default-light & .new-tag {
            background-color: #8fa61a;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .sky & .new-tag {
            background-color: #235635;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .sky-light & .new-tag {
            background-color: #235635;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .grass & .new-tag {
            background-color: #ffff00;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .grass-light & .new-tag {
            background-color: #ffff00;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .mud & .new-tag {
            background-color: #ffff00;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .mud-light & .new-tag {
            background-color: #8fa61a;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .illini & .new-tag {
            background-color: #ffff00;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
        .illini-light & .new-tag {
            background-color: #ffff00;
            color: #000;
            font-size: 13px;
            height: 17px;
            margin-left: 8px;
            padding-top: 3px;
            width: 100px;
            text-align: center;
        }
}


    .with-api-result .dialog-container {
        min-height: 75px
    }
.with-api-result .dialog-container .dialog-box-body {
            min-height: 50px;
            -webkit-user-select: auto;
                -ms-user-select: auto;
                    user-select: auto;
        }
.with-api-result .dialog-container .dialog-box-title {
            padding: 0;
            margin: 0;
        }


        .with-new-api-result.dialog-container.dialog-box-body {
            max-height: 450px;
            max-width: 600px;
            height: 450px;
            width: 600px;
            -webkit-user-select: auto;
                -ms-user-select: auto;
                    user-select: auto
        }

.with-new-api-result.dialog-container.dialog-box-body.dialog-box-children {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }

.with-new-api-result.dialog-container.dialog-box-title {
            padding: 0;
            margin: 0;
        }

.detail-block:not(:last-child) {
    margin-bottom: 2em;
}

.detail-item-list {
    display: block;
    list-style-type: disc;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
}

.detail-item:not(:last-child) {
    margin-bottom: 0.5em;
}


        .with-validate-import-result.dialog-container.dialog-box-body {
            max-height: 450px;
            max-width: 600px;
            height: 450px;
            width: 600px;
            -webkit-user-select: auto;
                -ms-user-select: auto;
                    user-select: auto
        }

.with-validate-import-result.dialog-container.dialog-box-body.dialog-box-children {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }

.with-validate-import-result.dialog-container.dialog-box-title {
            padding: 0;
            margin: 0;
        }

.results-container {
    overflow: auto;
    padding-bottom: 2px;
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
}

.import-results-container {
    padding-bottom: 2px;
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
}

.block-list {
    display: block;
    list-style-type: disc;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
}

.block-item:not(:last-child) {
    margin-bottom: 0.5em;
}

.failure-block:not(:last-child) {
    margin-bottom: 2em;
}

.failure-item-list {
    display: block;
    list-style-type: circle;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

hr {
    width: 100%;
    border-left: 0;
    border-right: 0;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.action-panel .module-filter-row {
        display: flex;
        height: 51px;
        min-height: 51px;
        white-space: nowrap;
        align-items: center;
        justify-content: flex-end

        /* rules for when filters on are a separate row from the toggle */
    }
.action-panel .module-filter-row.module-filters {
            align-items: flex-start;
        }
.action-panel .module-filter-row {
        /* rules for when filters are on same row as toggle */
    }
.action-panel .module-filter-row .module-filters {
            max-width: calc(100% - 80px);
            flex-grow: 1
        }
.action-panel .module-filter-row .module-filters .module-filters-inputs > div:first-child:not(:last-child) .form-input {
                            margin: 0 2px 0 2px;
                        }
.action-panel .module-filter-row .module-filters .module-filters-inputs > div:last-child:not(:first-child) .form-input {
                            margin: 0 5px 0 2px;
                        }
.action-panel .module-filter-row .module-filters .module-filters-inputs .form-input {
                    margin: 0 2px;
                }
.action-panel .filter-toggle {
        height: 100%;
        width: 73px;
        padding-right: 10px;
        line-height: 51px;
        font-size: 13px;
        overflow: hidden;
        text-align: right;
        text-overflow: ellipsis;
    }
.action-panel .filter-toggle-with-badge {
        display: flex;
        height: 100%;
        width: 95px;
        padding-right: 8px;
        line-height: 51px;
        font-size: 13px;
        overflow: hidden;
        text-align: left
    }
.action-panel .filter-toggle-with-badge .filter-value-count {
            padding: 2px 5px;
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
            border-radius: 5px 5px 5px 5px;
            height: 28%;
            line-height: 15px;
            margin-top: 15px;
            margin-left: auto;
        }
.action-panel .module-filters-inputs {
        display: flex;
        flex-grow: 1;
        max-width: 100%;
        align-items: center
    }
.action-panel .module-filters-inputs > div {
            flex-grow: 1;
            width: 100%
        }
.action-panel .module-filters-inputs > div:first-child .form-input {
                    margin-left: 10px;
                }
.action-panel .module-filters-inputs > div:last-child .form-input {
                    margin-right: 10px;
                }
.action-panel .module-filters-inputs .form-input {
            margin: 0 5px 8px 0;
        }
.action-panel .module-filters-inputs .filter-115 {
            max-width: 115px;
        }
.default .module-filter-row {
        background-color: #000000
    }
.default .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #404040 !important;
            }
.default .action-panel .filter-value-count {
            background-color: #e26816;
            color: #ffffff;
        }
.default-light .module-filter-row {
        background-color: #fcfcfc
    }
.default-light .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #919191 !important;
            }
.default-light .action-panel .filter-value-count {
            background-color: #e26816;
            color: #ffffff;
        }
.sky .module-filter-row {
        background-color: #fcfcfc
    }
.sky .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #0194d3 !important;
            }
.sky .action-panel .filter-value-count {
            background-color: #01425e;
            color: #ffffff;
        }
.sky-light .module-filter-row {
        background-color: #fcfcfc
    }
.sky-light .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #0194d3 !important;
            }
.sky-light .action-panel .filter-value-count {
            background-color: #01425e;
            color: #ffffff;
        }
.grass .module-filter-row {
        background-color: #667c66
    }
.grass .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #325132 !important;
            }
.grass .action-panel .filter-value-count {
            background-color: #e26816;
            color: #ffffff;
        }
.grass-light .module-filter-row {
        background-color: #babfba
    }
.grass-light .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #545f54 !important;
            }
.grass-light .action-panel .filter-value-count {
            background-color: #e26816;
            color: #ffffff;
        }
.mud .module-filter-row {
        background-color: #4b3705
    }
.mud .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #766146 !important;
            }
.mud .action-panel .filter-value-count {
            background-color: #f39104;
            color: #ffffff;
        }
.mud-light .module-filter-row {
        background-color: #fff0bb
    }
.mud-light .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #a37b42 !important;
            }
.mud-light .action-panel .filter-value-count {
            background-color: #e26816;
            color: #ffffff;
        }
.illini .module-filter-row {
        background-color: #00467f
    }
.illini .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #636466 !important;
            }
.illini .action-panel .filter-value-count {
            background-color: #f39c11;
            color: #ffffff;
        }
.illini-light .module-filter-row {
        background-color: #e1e6fa
    }
.illini-light .module-filter-row .search-loading-container .line-scale-party > div {
                background-color: #375d81 !important;
            }
.illini-light .action-panel .filter-value-count {
            background-color: #f39c11;
            color: #ffffff;
        }

.module-search {
    display: flex;
    flex-grow: 1;
    max-width: 341px
}
.module-search .search-input,
    .module-search .text-form-input {
        flex-grow: 1;
    }
.module-search .text-form-input {
        margin: 8px 10px;
        margin-right: 0;
    }
.module-search .search-loading-container {
        position: relative;
        min-width: 50px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.dialog-container.upload-modal {
    padding-left: 0
}
.dialog-container.upload-modal > .dialog-drag-bar {
        margin: 0;
        height: 45px;
        width: 100%;
    }
.dialog-container.upload-modal > .dialog-box-title {
        margin-left: 5px;
        padding: 5px 5px 10px 10px;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        font-size: 15px;
        font-weight: 300;
        min-width: inherit;
    }
.dialog-container.upload-modal > .dialog-box-body {
        margin-bottom: 0;
        padding-left: 5px;
        overflow-y: hidden;
    }
.dialog-container.upload-modal .upload-modal-body {
        display: flex;
        flex-direction: row;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none
    }
.dialog-container.upload-modal .upload-modal-body .type-template-select {
            width: 295px;
        }
.dialog-container.upload-modal .dialog-box-footer {
        padding: 10px 15px 0 0;
        min-height: 50px;
        display: flex;
        justify-content: flex-end;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none
    }
.dialog-container.upload-modal .dialog-box-footer .file-types {
            flex-grow: 1;
            cursor: default
        }
.dialog-container.upload-modal .dialog-box-footer .file-types div {
                padding: 6px 15px;
                margin-left: -15px;
                width: 265px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
.dialog-container.upload-modal .dialog-box-footer button {
            margin: 5px 4px;
        }
.dialog-container.upload-modal .dialog-box-footer .resize-handle {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 20px;
            width: 20px;
            cursor: nwse-resize;
        }
.default .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.default .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #e26816;
                color: #ffffff;
            }
.default-light .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.default-light .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #e26816;
                color: #ffffff;
            }
.sky .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.sky .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #01425e;
                color: #ffffff;
            }
.sky-light .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.sky-light .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #01425e;
                color: #ffffff;
            }
.grass .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.grass .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #e26816;
                color: #ffffff;
            }
.grass-light .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.grass-light .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #e26816;
                color: #ffffff;
            }
.mud .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.mud .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #f39104;
                color: #ffffff;
            }
.mud-light .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.mud-light .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #e26816;
                color: #ffffff;
            }
.illini .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.illini .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #f39c11;
                color: #ffffff;
            }
.illini-light .dialog-container.upload-modal .dialog-box-footer {
            padding: 10px 15px 0 0;
            min-height: 50px;
            display: flex;
            justify-content: flex-end
        }
.illini-light .dialog-container.upload-modal .dialog-box-footer .file-types div {
                background-color: #f39c11;
                color: #ffffff;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.drag-and-drop-file-uploader-container .files-list-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    min-height: 150px
}
.drag-and-drop-file-uploader-container .files-list-container .files-list-header {
        display: flex;
        flex-direction: row
    }
.drag-and-drop-file-uploader-container .files-list-container .files-list-header .files-count {
            margin-right: 5px;
            padding: 1px 7px;
            border-radius: 5px;
        }
.drag-and-drop-file-uploader-container .files-list-container .files-list-header .clear-link {
            flex-grow: 1;
            margin-left: 20px;
        }
.drag-and-drop-file-uploader-container .files-list-container .files-list-body {
        flex-grow: 1
    }
.drag-and-drop-file-uploader-container .files-list-container .files-list-body .drag-and-drop-file-uploader-accordion-item {
            display: flex;
            flex-direction: row;
            white-space: nowrap;
            vertical-align: center;
            height: 100%;
            cursor: default
        }
.drag-and-drop-file-uploader-container .files-list-container .files-list-body .drag-and-drop-file-uploader-accordion-item .remove-icon .default-svg-icon {
                margin-left: 2px;
                margin-right: 2px;
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
.drag-and-drop-file-uploader-container .files-list-container .files-list-body .drag-and-drop-file-uploader-accordion-item .upload-filename-errs {
                flex-grow: 1;
                height: 100%;
                padding-top: 2px;
                overflow: hidden
            }
.drag-and-drop-file-uploader-container .files-list-container .files-list-body .drag-and-drop-file-uploader-accordion-item .upload-filename-errs div {
                    height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
.drag-and-drop-file-uploader-container .files-list-container .files-list-body .drag-and-drop-file-uploader-accordion-item .upload-filesize {
                padding: 1px 7px 0 7px;
                height: 100%;
            }
.drag-and-drop-file-uploader-container .files-list-container hr {
        width: 100%;
    }
.default .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #e26816;
            color: #ffffff;
        }
.default .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #000000
        }
.default .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #777777 1px solid;
            }
.default .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.default-light .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #e26816;
            color: #ffffff;
        }
.default-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #fcfcfc
        }
.default-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #777777 1px solid;
            }
.default-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.sky .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #01425e;
            color: #ffffff;
        }
.sky .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #fcfcfc
        }
.sky .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #878787 1px solid;
            }
.sky .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.sky-light .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #01425e;
            color: #ffffff;
        }
.sky-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #fcfcfc
        }
.sky-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #878787 1px solid;
            }
.sky-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.grass .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #e26816;
            color: #ffffff;
        }
.grass .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #667c66
        }
.grass .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #e5e9e5 1px solid;
            }
.grass .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.grass-light .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #e26816;
            color: #ffffff;
        }
.grass-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #babfba
        }
.grass-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #e5e9e5 1px solid;
            }
.grass-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.mud .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #f39104;
            color: #ffffff;
        }
.mud .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #4b3705
        }
.mud .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #777777 1px solid;
            }
.mud .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.mud-light .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #e26816;
            color: #ffffff;
        }
.mud-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #fff0bb
        }
.mud-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #777777 1px solid;
            }
.mud-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.illini .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #f39c11;
            color: #ffffff;
        }
.illini .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #00467f
        }
.illini .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #777777 1px solid;
            }
.illini .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }
.illini-light .drag-and-drop-file-uploader-container .files-list-container .files-count {
            background-color: #f39c11;
            color: #ffffff;
        }
.illini-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item {
            background-color: #e1e6fa
        }
.illini-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item.sticky {
                border-bottom: #777777 1px solid;
            }
.illini-light .drag-and-drop-file-uploader-container .files-list-container .drag-and-drop-file-uploader-accordion-item .upload-file-error {
                color: #ff0000;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.drag-and-drop-file-uploader-container {
    flex: auto;
    height: 95%;
    display: flex
}
.drag-and-drop-file-uploader-container .drop-message {
        flex-grow: 1;
        display: flex;
        height: 100%;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-style: dashed;
        border-width: 2px
    }
.drag-and-drop-file-uploader-container .drop-message .default-svg-icon {
            height: 100px;
            width: 100px;
        }
.drag-and-drop-file-uploader-container .drop-message .greyed-text,
        .drag-and-drop-file-uploader-container .drop-message a {
            font-family: open_sansregular;
            font-size: 17px;
            font-weight: 300;
            padding: 3px;
        }
.drag-and-drop-file-uploader-container {
    .add-default-nolink {
        margin-left: 20px;
    }
    .default-button {
        margin-left: auto;
    }
    .product-info-section {
        justify-content: space-between;
    }
}
.default .drag-and-drop-file-uploader-container .drop-message {
            border-color: #bfbfbf
        }
.default .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #777777;
            }
.default .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #bfbfbf;
                }
.default .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #8fa61a;
                }
.default-light .drag-and-drop-file-uploader-container .drop-message {
            border-color: #cfcfcf
        }
.default-light .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #777777;
            }
.default-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #cfcfcf;
                }
.default-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #8fa61a;
                }
.sky .drag-and-drop-file-uploader-container .drop-message {
            border-color: #c8c8c8
        }
.sky .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #878787;
            }
.sky .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #c8c8c8;
                }
.sky .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #235635;
                }
.sky-light .drag-and-drop-file-uploader-container .drop-message {
            border-color: #c8c8c8
        }
.sky-light .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #878787;
            }
.sky-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #c8c8c8;
                }
.sky-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #235635;
                }
.grass .drag-and-drop-file-uploader-container .drop-message {
            border-color: #9cadb2
        }
.grass .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #e5e9e5;
            }
.grass .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #9cadb2;
                }
.grass .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.grass-light .drag-and-drop-file-uploader-container .drop-message {
            border-color: #424443
        }
.grass-light .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #e5e9e5;
            }
.grass-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #424443;
                }
.grass-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.mud .drag-and-drop-file-uploader-container .drop-message {
            border-color: #f2f3f8
        }
.mud .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #777777;
            }
.mud .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #f2f3f8;
                }
.mud .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.mud-light .drag-and-drop-file-uploader-container .drop-message {
            border-color: #d6c282
        }
.mud-light .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #777777;
            }
.mud-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #d6c282;
                }
.mud-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #8fa61a;
                }
.illini .drag-and-drop-file-uploader-container .drop-message {
            border-color: #f2f3f8
        }
.illini .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #777777;
            }
.illini .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #f2f3f8;
                }
.illini .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.illini-light .drag-and-drop-file-uploader-container .drop-message {
            border-color: #abc8e2
        }
.illini-light .drag-and-drop-file-uploader-container .drop-message .greyed-text {
                color: #777777;
            }
.illini-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #box {
                    fill: #abc8e2;
                }
.illini-light .drag-and-drop-file-uploader-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-module .search-user {
    display: flex;
    flex-direction: row;
    align-items: center
}
.import-module .search-user .search-user-input {
        flex-grow: 1
    }
.import-module .search-user .search-user-input .select-form-input-container .search-icon {
                margin: 4px 0;
                margin-left: 2px;
                padding: 2px;
                width: 15px;
                height: 15px;
                border: none;
                cursor: pointer
            }
.import-module .search-user .search-user-input .select-form-input-container .search-icon .default-svg-icon {
                    width: 15px;
                    height: 15px;
                    fill: black;
                }
.import-module .search-user .search-user-input .select-form-input-container .form-input {
                margin: 3px 10px
            }
.import-module .search-user .search-user-input .select-form-input-container .form-input.select-form-input-options {
                    margin: 0 10px;
                }
.import-module .search-user .search-user-input .select-form-input-options .select-form-input-option {
            display: flex;
            flex-direction: row
        }
.import-module .search-user .search-user-input .select-form-input-options .select-form-input-option .add-icon {
                margin: 6px 0;
                margin-left: 2px;
                padding: 2px;
                width: 28px;
                height: 28px;
                border: none;
                cursor: pointer
            }
.import-module .search-user .search-user-input .select-form-input-options .select-form-input-option .add-icon .default-svg-icon {
                    width: 28px;
                    height: 28px;
                    fill: black;
                }
.import-module .search-user .search-user-input .select-form-input-options .select-form-input-option .user-info {
                margin-left: 8px;
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-between
            }
.import-module .search-user .search-user-input .select-form-input-options .select-form-input-option .user-info .loc {
                    font-family: "open_sansitalic";
                    font-size: 11px;
                }
.import-module .search-user .search-user-input .select-form-input-options .select-form-input-option .user-info .title {
                    margin-left: 20px;
                    margin-right: 8px;
                }
.import-module .search-user button {
        margin-left: 75px;
        margin-right: 15px;
    }
.default .import-module .search-user {
        background-color: #404040;
    }
.default-light .import-module .search-user {
        background-color: #919191;
    }
.sky .import-module .search-user {
        background-color: #0194d3;
    }
.sky-light .import-module .search-user {
        background-color: #0194d3;
    }
.grass .import-module .search-user {
        background-color: #325132;
    }
.grass-light .import-module .search-user {
        background-color: #545f54;
    }
.mud .import-module .search-user {
        background-color: #766146;
    }
.mud-light .import-module .search-user {
        background-color: #a37b42;
    }
.illini .import-module .search-user {
        background-color: #636466;
    }
.illini-light .import-module .search-user {
        background-color: #375d81;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.action-panel .filter-tabs.tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column
}
.action-panel .filter-tabs.tabs .tabs-labels {
        height: 40px
    }
.action-panel .filter-tabs.tabs .tabs-labels li.tab-label:not(.right-non-label):not(.left-non-label) {
            flex-grow: inherit;
            min-width: 55px;
            width: auto;
            padding: 0 10px;
            font-size: 13px;
            line-height: 38px;
        }
.action-panel .filter-tabs.tabs .tabs-labels .context-menu-container {
            float: right;
        }
.action-panel .filter-tabs.tabs .tabs-pane {
        flex-grow: 1;
        display: flex;
        flex-direction: column
    }
.action-panel .filter-tabs.tabs .tabs-pane .pane-content {
            flex-grow: 1
        }
.action-panel .filter-tabs.tabs .tabs-pane .pane-content.scroll-y {
                overflow-y: auto;
            }
.action-panel .filter-tabs.tabs .tabs-pane .pane-content .lock-icon {
                height: 35px;
                width: 45px;
                padding-left: 200px;
                padding-top: 50px;
            }
.action-panel .filter-tabs.tabs .tabs-pane .pane-content .lock-icon-text {
                padding-top: 20px;
                text-align: center;
            }
.action-panel .filter-tabs.tabs .tabs-pane .pane-content-loader {
            position: relative;
        }
.action-panel .filter-tabs.tabs .warning-icon {
        margin: 8px;
    }
.default .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.default-light .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.sky .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.sky-light .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.grass .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.grass-light .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.mud .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.mud-light .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.illini .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }
.illini-light .action-panel .filter-tabs .warning-icon {
            fill: #ffff00;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-module .tabs-labels li.tab-label.right-non-label {
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;
    }
.import-module .tabs-labels button {
        margin-right: 5px;
    }
.import-module .tabs-labels .import-filter-tabs-right {
        display: flex;
        flex-flow: row nowrap;
    }
.import-module .tabs-labels .onsite-telematics-status {
        display: flex;
        flex-flow: row nowrap;
        width: 110px;
        padding-top: 3px
    }
.import-module .tabs-labels .onsite-telematics-status .default-svg-icon {
            width: 33px;
            height: 33px;
        }
.import-module .tabs-labels .onsite-telematics-status .st0,
        .import-module .tabs-labels .onsite-telematics-status .st1 {
            stroke-width: 2;
            stroke-miterlimit: 10;
        }
.import-module .tabs-labels .onsite-telematics-status .st1 {
            fill: none;
        }
.import-module .tabs-labels .onsite-telematics-status .onsite-telematics-count {
            line-height: 35px;
            font-size: 16px;
        }
@keyframes blinker {
    90% {
        opacity: 0;
    }
}
.default .onsite-telematics-status .default-svg-icon {
            stroke: #ffffff;
        }
.default .onsite-telematics-status.processing .svg-container svg {
                fill: #8fa61a;
                stroke: #8fa61a
            }
.default .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #8fa61a;
                    stroke: #8fa61a;
                }
.default .onsite-telematics-status.processing .onsite-telematics-count {
                color: #8fa61a;
            }
.default-light .onsite-telematics-status .default-svg-icon {
            stroke: #000000;
        }
.default-light .onsite-telematics-status.processing .svg-container svg {
                fill: #8fa61a;
                stroke: #8fa61a
            }
.default-light .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #8fa61a;
                    stroke: #8fa61a;
                }
.default-light .onsite-telematics-status.processing .onsite-telematics-count {
                color: #8fa61a;
            }
.sky .onsite-telematics-status .default-svg-icon {
            stroke: #000000;
        }
.sky .onsite-telematics-status.processing .svg-container svg {
                fill: #235635;
                stroke: #235635
            }
.sky .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #235635;
                    stroke: #235635;
                }
.sky .onsite-telematics-status.processing .onsite-telematics-count {
                color: #235635;
            }
.sky-light .onsite-telematics-status .default-svg-icon {
            stroke: #000000;
        }
.sky-light .onsite-telematics-status.processing .svg-container svg {
                fill: #235635;
                stroke: #235635
            }
.sky-light .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #235635;
                    stroke: #235635;
                }
.sky-light .onsite-telematics-status.processing .onsite-telematics-count {
                color: #235635;
            }
.grass .onsite-telematics-status .default-svg-icon {
            stroke: #ffffff;
        }
.grass .onsite-telematics-status.processing .svg-container svg {
                fill: #ffff00;
                stroke: #ffff00
            }
.grass .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #ffff00;
                    stroke: #ffff00;
                }
.grass .onsite-telematics-status.processing .onsite-telematics-count {
                color: #ffff00;
            }
.grass-light .onsite-telematics-status .default-svg-icon {
            stroke: #000000;
        }
.grass-light .onsite-telematics-status.processing .svg-container svg {
                fill: #ffff00;
                stroke: #ffff00
            }
.grass-light .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #ffff00;
                    stroke: #ffff00;
                }
.grass-light .onsite-telematics-status.processing .onsite-telematics-count {
                color: #ffff00;
            }
.mud .onsite-telematics-status .default-svg-icon {
            stroke: #ffffff;
        }
.mud .onsite-telematics-status.processing .svg-container svg {
                fill: #ffff00;
                stroke: #ffff00
            }
.mud .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #ffff00;
                    stroke: #ffff00;
                }
.mud .onsite-telematics-status.processing .onsite-telematics-count {
                color: #ffff00;
            }
.mud-light .onsite-telematics-status .default-svg-icon {
            stroke: #000000;
        }
.mud-light .onsite-telematics-status.processing .svg-container svg {
                fill: #8fa61a;
                stroke: #8fa61a
            }
.mud-light .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #8fa61a;
                    stroke: #8fa61a;
                }
.mud-light .onsite-telematics-status.processing .onsite-telematics-count {
                color: #8fa61a;
            }
.illini .onsite-telematics-status .default-svg-icon {
            stroke: #ffffff;
        }
.illini .onsite-telematics-status.processing .svg-container svg {
                fill: #ffff00;
                stroke: #ffff00
            }
.illini .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #ffff00;
                    stroke: #ffff00;
                }
.illini .onsite-telematics-status.processing .onsite-telematics-count {
                color: #ffff00;
            }
.illini-light .onsite-telematics-status .default-svg-icon {
            stroke: #000000;
        }
.illini-light .onsite-telematics-status.processing .svg-container svg {
                fill: #ffff00;
                stroke: #ffff00
            }
.illini-light .onsite-telematics-status.processing .svg-container svg circle {
                    fill: #ffff00;
                    stroke: #ffff00;
                }
.illini-light .onsite-telematics-status.processing .onsite-telematics-count {
                color: #ffff00;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-module .import-type-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.import-module .import-type-accordion-item .type-name {
        margin-left: 6px;
        font-size: 15px;
        display: flex;
        align-items: center
    }
.import-module .import-type-accordion-item .type-name .icon-container {
            margin-right: 10px
        }
.import-module .import-type-accordion-item .type-name .icon-container .default-svg-icon {
                height: 30px;
                width: 30px;
            }
.import-module .import-type-accordion-item .type-name .icon-container.field-boundary {
                margin-right: 20px
            }
.import-module .import-type-accordion-item .type-name .icon-container.field-boundary .default-svg-icon {
                    height: 20px;
                    width: 20px;
                }
.import-module .import-type-accordion-item .context-menu-container {
        margin-right: 5px;
        padding-top: 4px;
        height: 100%
    }
.import-module .import-type-accordion-item .context-menu-container .context-menu {
            height: calc(100% - 8px);
            width: 15px;
            margin: 0;
            line-height: 15px;
            padding-right: 1px
        }
.import-module .import-type-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot {
                visibility: hidden;
            }
.import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
            visibility: visible;
        }
.import-module .import-type-accordion-item .svg-stroke-white {
        stroke: #fff;
    }
.default .import-module .import-type-accordion-item {
        background-color: #000000;
        border-top: 1px solid #bfbfbf;
        border-bottom: 1px solid #bfbfbf
    }
.default .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #8fa61a;
            }
.default .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #404040;
            }
.default-light .import-module .import-type-accordion-item {
        background-color: #fcfcfc;
        border-top: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf
    }
.default-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #8fa61a;
            }
.default-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #919191;
            }
.sky .import-module .import-type-accordion-item {
        background-color: #fcfcfc;
        border-top: 1px solid #c8c8c8;
        border-bottom: 1px solid #c8c8c8
    }
.sky .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #235635;
            }
.sky .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #0194d3;
            }
.sky-light .import-module .import-type-accordion-item {
        background-color: #fcfcfc;
        border-top: 1px solid #c8c8c8;
        border-bottom: 1px solid #c8c8c8
    }
.sky-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #235635;
            }
.sky-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #0194d3;
            }
.grass .import-module .import-type-accordion-item {
        background-color: #667c66;
        border-top: 1px solid #9cadb2;
        border-bottom: 1px solid #9cadb2
    }
.grass .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #ffff00;
            }
.grass .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #325132;
            }
.grass-light .import-module .import-type-accordion-item {
        background-color: #babfba;
        border-top: 1px solid #424443;
        border-bottom: 1px solid #424443
    }
.grass-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #ffff00;
            }
.grass-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #545f54;
            }
.mud .import-module .import-type-accordion-item {
        background-color: #4b3705;
        border-top: 1px solid #f2f3f8;
        border-bottom: 1px solid #f2f3f8
    }
.mud .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #ffff00;
            }
.mud .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #766146;
            }
.mud-light .import-module .import-type-accordion-item {
        background-color: #fff0bb;
        border-top: 1px solid #d6c282;
        border-bottom: 1px solid #d6c282
    }
.mud-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #8fa61a;
            }
.mud-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #a37b42;
            }
.illini .import-module .import-type-accordion-item {
        background-color: #00467f;
        border-top: 1px solid #f2f3f8;
        border-bottom: 1px solid #f2f3f8
    }
.illini .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #ffff00;
            }
.illini .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #636466;
            }
.illini-light .import-module .import-type-accordion-item {
        background-color: #e1e6fa;
        border-top: 1px solid #abc8e2;
        border-bottom: 1px solid #abc8e2
    }
.illini-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                color: #ffff00;
            }
.illini-light .import-module .import-type-accordion-item:hover .context-menu-container .context-menu:hover {
                background-color: #375d81;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-module .import-template-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }
.import-module .import-template-accordion-item .template-name {
            margin-left: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-style: italic;
        }
.import-module .import-template-accordion-item .loader-container {
            z-index: inherit
        }
.import-module .import-template-accordion-item .loader-container .line-scale-pulse-out-rapid {
                max-height: 25px
            }
.import-module .import-template-accordion-item .loader-container .line-scale-pulse-out-rapid > div {
                    max-height: 20px;
                }
.import-module .import-template-accordion-item .context-menu-container {
            margin-right: 5px;
            padding-top: 2px;
            height: 100%
        }
.import-module .import-template-accordion-item .context-menu-container .context-menu {
                height: calc(100% - 4px);
                width: 15px;
                margin: 0;
                line-height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: space-around
            }
.import-module .import-template-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
.import-module .import-template-accordion-item .context-menu-container .context-menu .menu-dot {
                    line-height: 6px;
                    margin-bottom: -2px;
                }
.import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                visibility: visible;
            }
.default .import-module .import-template-accordion-item {
            background-color: #262626;
            border-bottom: 1px solid #000000
        }
.default .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #8fa61a;
                }
.default-light .import-module .import-template-accordion-item {
            background-color: #e2e2e2;
            border-bottom: 1px solid #fcfcfc
        }
.default-light .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #8fa61a;
                }
.sky .import-module .import-template-accordion-item {
            background-color: #b1b1b1;
            border-bottom: 1px solid #fcfcfc
        }
.sky .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #235635;
                }
.sky-light .import-module .import-template-accordion-item {
            background-color: #b1b1b1;
            border-bottom: 1px solid #fcfcfc
        }
.sky-light .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #235635;
                }
.grass .import-module .import-template-accordion-item {
            background-color: #424443;
            border-bottom: 1px solid #667c66
        }
.grass .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.grass-light .import-module .import-template-accordion-item {
            background-color: #9cadb2;
            border-bottom: 1px solid #babfba
        }
.grass-light .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.mud .import-module .import-template-accordion-item {
            background-color: #8c7454;
            border-bottom: 1px solid #4b3705
        }
.mud .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.mud-light .import-module .import-template-accordion-item {
            background-color: #b28f5a;
            border-bottom: 1px solid #fff0bb
        }
.mud-light .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #8fa61a;
                }
.illini .import-module .import-template-accordion-item {
            background-color: #abc8e2;
            border-bottom: 1px solid #00467f
        }
.illini .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.illini-light .import-module .import-template-accordion-item {
            background-color: #c4d7ed;
            border-bottom: 1px solid #e1e6fa
        }
.illini-light .import-module .import-template-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-module .import-file-info-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px;
        font-weight: 300
    }
.import-module .import-file-info-accordion-item .spacer {
            min-width: 5px;
            max-width: 5px;
            margin: 3px 10px 3px 18px;
        }
.import-module .import-file-info-accordion-item .file-info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-width: 30px
        }
.import-module .import-file-info-accordion-item .file-info .file-name {
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
.import-module .import-file-info-accordion-item .file-info .file-upload-dt {
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
.import-module .import-file-info-accordion-item .import-info {
            margin-left: 15px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end
        }
.import-module .import-file-info-accordion-item .import-info .point-count {
                font-size: 11px;
                height: 20px;
            }
.import-module .import-file-info-accordion-item .import-info .import-status {
                font-size: 12px;
            }
.import-module .import-file-info-accordion-item .context-menu-container {
            padding-left: 5px;
            padding-top: 2px;
            height: 100%
        }
.import-module .import-file-info-accordion-item .context-menu-container .context-menu {
                width: 15px;
                margin: 0;
                line-height: 15px;
                padding-right: 1px
            }
.import-module .import-file-info-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
.import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    visibility: visible;
                }
.default .import-module {
        background-color: #000000
    }
.default .import-module .import-file-info-accordion-item {
            background-color: #404040;
            border-bottom: 1px solid #000000
        }
.default .import-module .import-file-info-accordion-item.selected {
                background-color: #737373
            }
.default .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .import-module .import-file-info-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #8fa61a;
                }
.default-light .import-module {
        background-color: #fcfcfc
    }
.default-light .import-module .import-file-info-accordion-item {
            background-color: #919191;
            border-bottom: 1px solid #fcfcfc
        }
.default-light .import-module .import-file-info-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .import-module .import-file-info-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #8fa61a;
                }
.sky .import-module {
        background-color: #fcfcfc
    }
.sky .import-module .import-file-info-accordion-item {
            background-color: #0194d3;
            border-bottom: 1px solid #fcfcfc
        }
.sky .import-module .import-file-info-accordion-item.selected {
                background-color: #838383
            }
.sky .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .import-module .import-file-info-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #235635;
                }
.sky-light .import-module {
        background-color: #fcfcfc
    }
.sky-light .import-module .import-file-info-accordion-item {
            background-color: #0194d3;
            border-bottom: 1px solid #fcfcfc
        }
.sky-light .import-module .import-file-info-accordion-item.selected {
                background-color: #838383
            }
.sky-light .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .import-module .import-file-info-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #235635;
                }
.grass .import-module {
        background-color: #667c66
    }
.grass .import-module .import-file-info-accordion-item {
            background-color: #325132;
            border-bottom: 1px solid #667c66
        }
.grass .import-module .import-file-info-accordion-item.selected {
                background-color: #424443
            }
.grass .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .import-module .import-file-info-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.grass-light .import-module {
        background-color: #babfba
    }
.grass-light .import-module .import-file-info-accordion-item {
            background-color: #545f54;
            border-bottom: 1px solid #babfba
        }
.grass-light .import-module .import-file-info-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .import-module .import-file-info-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.mud .import-module {
        background-color: #4b3705
    }
.mud .import-module .import-file-info-accordion-item {
            background-color: #766146;
            border-bottom: 1px solid #4b3705
        }
.mud .import-module .import-file-info-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .import-module .import-file-info-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.mud-light .import-module {
        background-color: #fff0bb
    }
.mud-light .import-module .import-file-info-accordion-item {
            background-color: #a37b42;
            border-bottom: 1px solid #fff0bb
        }
.mud-light .import-module .import-file-info-accordion-item.selected {
                background-color: #735523
            }
.mud-light .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .import-module .import-file-info-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #8fa61a;
                }
.illini .import-module {
        background-color: #00467f
    }
.illini .import-module .import-file-info-accordion-item {
            background-color: #636466;
            border-bottom: 1px solid #00467f
        }
.illini .import-module .import-file-info-accordion-item.selected {
                background-color: #3698db
            }
.illini .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .import-module .import-file-info-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }
.illini-light .import-module {
        background-color: #e1e6fa
    }
.illini-light .import-module .import-file-info-accordion-item {
            background-color: #375d81;
            border-bottom: 1px solid #e1e6fa
        }
.illini-light .import-module .import-file-info-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .import-module .import-file-info-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .import-module .import-file-info-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .import-module .import-file-info-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .import-module .import-file-info-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    color: #ffff00;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-module .import-accordion-item {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
}
.default .import-module .import-accordion-item {
        color: #ffffff;
    }
.default-light .import-module .import-accordion-item {
        color: #000000;
    }
.sky .import-module .import-accordion-item {
        color: #000000;
    }
.sky-light .import-module .import-accordion-item {
        color: #000000;
    }
.grass .import-module .import-accordion-item {
        color: #ffffff;
    }
.grass-light .import-module .import-accordion-item {
        color: #000000;
    }
.mud .import-module .import-accordion-item {
        color: #ffffff;
    }
.mud-light .import-module .import-accordion-item {
        color: #000000;
    }
.illini .import-module .import-accordion-item {
        color: #ffffff;
    }
.illini-light .import-module .import-accordion-item {
        color: #000000;
    }

.action-panel .import-module .import-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column
}
.action-panel .import-module .import-list .module-loader {
        width: 446px;
        height: 100%;
    }
.action-panel .import-module .import-list .module-filter-row {
        justify-content: unset;
    }
.action-panel .import-module .import-list .module-filters-inputs {
        max-width: 301px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-wizard-progress-bar.single-step {
    margin-top: 9px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    display: flex;
    justify-content: center
}
.import-wizard-progress-bar.single-step .progress-stop-container {
        display: flex;
        flex-flow: row nowrap;
        width: 100%
    }
.import-wizard-progress-bar.single-step .progress-stop-container .progress-stop {
        }
.import-wizard-progress-bar.single-step .progress-stop-container .progress-stop-label {
            position: static;
            font-size: 16px;
            margin-left: 45px;
            min-width: 195px;
            margin-left: 10px;
            margin-top: 4px;
        }
.import-wizard-progress-bar {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    width: 85%;
    margin-top: 15px;
    display: flex;
    justify-content: space-around
}
.import-wizard-progress-bar .progress-section-container {
        display: flex;
        position: relative
    }
.import-wizard-progress-bar .progress-section-container .progress-section {
            margin-top: 5px;
            height: 14px;
            width: 170px;
        }
.import-wizard-progress-bar .progress-stop-container {
        display: flex;
        flex-flow: column nowrap;
        z-index: 2;
        margin-left: -5px;
        margin-right: -5px
    }
.import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-symbol {
                max-width: 25px;
                min-width: 25px;
                width: 25px;
                border-radius: 50%;
                max-height: 25px;
                min-height: 25px;
                height: 25px;
                font-size: large
            }
.import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-symbol .progress-stop-label {
                    margin-top: 5px;
                }
.import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-active,
            .import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-complete {
                border-radius: 50%
            }
.import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-active .progress-active-symbol,
                .import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-active .progress-complete-symbol,
                .import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-complete .progress-active-symbol,
                .import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-complete .progress-complete-symbol {
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    position: relative;
                    left: 2px;
                    top: 2px;
                    text-align: center;
                    line-height: 19px;
                    font-size: 15px;
                }
.import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-active .progress-complete-symbol, .import-wizard-progress-bar .progress-stop-container .progress-stop .progress-stop-complete .progress-complete-symbol {
                    line-height: 20px;
                    font-size: 16px;
                }
.import-wizard-progress-bar .progress-stop-container .progress-stop-label {
            position: absolute;
            width: 70px;
            top: 32px;
            right: -28px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
        }
.default .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #bfbfbf;
            }
.default .import-wizard-progress-bar .progress-section {
                color: #8fa61a;
                background-color: #bfbfbf;
            }
.default .import-wizard-progress-bar .progress-active-symbol {
                background-color: #8fa61a;
            }
.default .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #8fa61a;
            }
.default .import-wizard-progress-bar .progress-section-complete {
                background-color: #8fa61a;
            }
.default .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #8fa61a;
        }
.default-light .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #cfcfcf;
            }
.default-light .import-wizard-progress-bar .progress-section {
                color: #8fa61a;
                background-color: #cfcfcf;
            }
.default-light .import-wizard-progress-bar .progress-active-symbol {
                background-color: #8fa61a;
            }
.default-light .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #8fa61a;
            }
.default-light .import-wizard-progress-bar .progress-section-complete {
                background-color: #8fa61a;
            }
.default-light .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #8fa61a;
        }
.sky .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #c8c8c8;
            }
.sky .import-wizard-progress-bar .progress-section {
                color: #235635;
                background-color: #c8c8c8;
            }
.sky .import-wizard-progress-bar .progress-active-symbol {
                background-color: #235635;
            }
.sky .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #235635;
            }
.sky .import-wizard-progress-bar .progress-section-complete {
                background-color: #235635;
            }
.sky .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #235635;
        }
.sky-light .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #c8c8c8;
            }
.sky-light .import-wizard-progress-bar .progress-section {
                color: #235635;
                background-color: #c8c8c8;
            }
.sky-light .import-wizard-progress-bar .progress-active-symbol {
                background-color: #235635;
            }
.sky-light .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #235635;
            }
.sky-light .import-wizard-progress-bar .progress-section-complete {
                background-color: #235635;
            }
.sky-light .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #235635;
        }
.grass .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #9cadb2;
            }
.grass .import-wizard-progress-bar .progress-section {
                color: #ffff00;
                background-color: #9cadb2;
            }
.grass .import-wizard-progress-bar .progress-active-symbol {
                background-color: #ffff00;
            }
.grass .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #ffff00;
            }
.grass .import-wizard-progress-bar .progress-section-complete {
                background-color: #ffff00;
            }
.grass .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #ffff00;
        }
.grass-light .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #424443;
            }
.grass-light .import-wizard-progress-bar .progress-section {
                color: #ffff00;
                background-color: #424443;
            }
.grass-light .import-wizard-progress-bar .progress-active-symbol {
                background-color: #ffff00;
            }
.grass-light .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #ffff00;
            }
.grass-light .import-wizard-progress-bar .progress-section-complete {
                background-color: #ffff00;
            }
.grass-light .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #ffff00;
        }
.mud .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #f2f3f8;
            }
.mud .import-wizard-progress-bar .progress-section {
                color: #ffff00;
                background-color: #f2f3f8;
            }
.mud .import-wizard-progress-bar .progress-active-symbol {
                background-color: #ffff00;
            }
.mud .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #ffff00;
            }
.mud .import-wizard-progress-bar .progress-section-complete {
                background-color: #ffff00;
            }
.mud .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #ffff00;
        }
.mud-light .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #d6c282;
            }
.mud-light .import-wizard-progress-bar .progress-section {
                color: #8fa61a;
                background-color: #d6c282;
            }
.mud-light .import-wizard-progress-bar .progress-active-symbol {
                background-color: #8fa61a;
            }
.mud-light .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #8fa61a;
            }
.mud-light .import-wizard-progress-bar .progress-section-complete {
                background-color: #8fa61a;
            }
.mud-light .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #8fa61a;
        }
.illini .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #f2f3f8;
            }
.illini .import-wizard-progress-bar .progress-section {
                color: #ffff00;
                background-color: #f2f3f8;
            }
.illini .import-wizard-progress-bar .progress-active-symbol {
                background-color: #ffff00;
            }
.illini .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #ffff00;
            }
.illini .import-wizard-progress-bar .progress-section-complete {
                background-color: #ffff00;
            }
.illini .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #ffff00;
        }
.illini-light .import-wizard-progress-bar .progress-stop-symbol {
                background-color: #abc8e2;
            }
.illini-light .import-wizard-progress-bar .progress-section {
                color: #ffff00;
                background-color: #abc8e2;
            }
.illini-light .import-wizard-progress-bar .progress-active-symbol {
                background-color: #ffff00;
            }
.illini-light .import-wizard-progress-bar .progress-complete-symbol {
                background-color: #ffff00;
            }
.illini-light .import-wizard-progress-bar .progress-section-complete {
                background-color: #ffff00;
            }
.illini-light .import-wizard-progress-bar:not(.single-step) .progress-label-active {
            color: #ffff00;
        }

.import-wizard-header {
    min-height: 45px;
    max-height: 115px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center
}
.import-wizard-header .import-wizard-header-action {
        width: 90%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
.import-wizard-header .dialog-box-body {
        width: 400px;
        height: 125px;
        line-height: 17px
    }
.import-wizard-header .dialog-box-body .size-info {
            padding-left: 25px
        }
.import-wizard-header .dialog-box-body .size-info :first-child {
                padding-top: 15px;
            }
.import-wizard-header .dialog-box-body .size-info :last-child {
                padding-bottom: 15px;
            }
.import-wizard-header .single-step .progress-section-container .progress-stop-container .progress-stop-label {
                    font-size: 15px;
                }


    .attribute-edit-modal .dialog-box-body {
        padding: 0;
    }
.attribute-edit-modal .edit-modal-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start
    }
.attribute-edit-modal .edit-modal-row .select-form-input-container {
            margin: 0px
        }
.attribute-edit-modal .edit-modal-row .select-form-input-container .select-form-input {
                width: unset;
                margin: 5px;
            }
.attribute-edit-modal .edit-modal-row .auto-search {
            width: 97%;
        }
& .dialog-box-body .dialog-box-children {
        display: flex;
        flex-direction: column;
    }
.crop-edit-modal {
    min-width: 450px;
    width: 450px;
    min-height: 253px;
    max-height: 350px;
    overflow-y: auto
}
.crop-edit-modal .cbv-inputs {
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start
    }
.crop-edit-modal .cbv-inputs .select-form-input-container {
            margin: 0px;
            width: 49%
        }
.crop-edit-modal .cbv-inputs .select-form-input-container .select-form-input {
                width: unset;
                margin: 5px;
            }
.crop-edit-modal .cbv-inputs .text-form-input:not(.select-form-input) {
            width: 45%;
            margin: 4px;
        }
.crop-edit-modal .product-table {
        min-height: 40px;
    }
.layer-edit-modal .edit-modal-row {
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start
    }
.layer-edit-modal .edit-modal-row .text-form-input:not(.select-form-input):not(.date-form-input) {
            min-width: 93%;
        }
.layer-edit-modal .edit-modal-row .select-form-input-container {
            top: 5px;
            left: 5px
        }
.layer-edit-modal .edit-modal-row .select-form-input-container .select-form-input-options {
                margin-left: 5px;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-filter {
    margin: 6px 0 10px 15px;
    width: 93%;
    font-size: 12px
}
.import-filter .import-filter-title {
        width: 100%;
        margin: 0;
        padding-top: 4px;
        padding-bottom: 3px;
        font-size: 13px;
    }
.import-filter .import-filter-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 700;
    }
.import-filter .import-filter-body {
        min-height: 112px;
        max-height: 112px;
        height: 112px;
        overflow: auto
    }
.import-filter .import-filter-body .import-cell-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
.import-filter .import-filter-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        line-height: 23px;
        cursor: pointer
    }
.import-filter .import-filter-item .filter-link-container {
            text-align: center
        }
.import-filter .import-filter-item .filter-link-container .filter-link:link,
            .import-filter .import-filter-item .filter-link-container .filter-link:hover,
            .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                margin-right: 25px;
            }
.import-filter .import-filter-item .filter-link-container.import-cell-10 .filter-link:link,
            .import-filter .import-filter-item .filter-link-container.import-cell-10 .filter-link:hover,
            .import-filter .import-filter-item .filter-link-container.import-cell-10 .filter-link:visited {
                margin-right: 0;
            }
.import-filter .import-filter-item .import-filter-cell, .import-filter .import-filter-header .import-filter-cell {
            text-align: center;
        }
.import-filter .import-cell-3 {
        width: 3%;
        min-width: 3%;
        max-width: 3%;
    }
.import-filter .import-cell-10 {
        width: 7%;
        min-width: 7%;
        max-width: 7%;
    }
.import-filter .import-cell-15 {
        width: 15%;
        min-width: 15%;
        max-width: 15%;
    }
.import-filter .import-cell-20 {
        width: 20%;
        min-width: 20%;
        max-width: 20%;
    }
.import-filter .import-cell-25 {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
    }
.import-filter .import-cell-30 {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
.import-filter .import-cell-40 {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
    }
.import-filter .import-cell-45 {
        width: 45%;
        min-width: 45%;
        max-width: 45%;
    }
.import-filter .import-cell-60 {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
    }
.import-filter .import-cell-70 {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
    }
.import-filter .context-menu {
        margin: 0px !important;
    }
.import-filter .menu-dots {
        height: 22px;
        line-height: 13px;
        width: 12px
    }
.import-filter .menu-dots .menu-dot {
            height: 4px;
        }
.default .import-filter {
            background-color: #000000;
            color: #ffffff
        }
.default .import-filter .import-filter-title {
                background-color: #404040;
            }
.default .import-filter .import-filter-header {
                border-bottom: 1px solid #8fa61a
            }
.default .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.default .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #ffffff;
                    }
.default .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #ffffff;
                    }
.default .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #ffffff;
                    }
.default .import-filter .import-filter-item.selected {
                background-color: #8fa61a
            }
.default .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #8fa61a;
                }
.default .import-filter .import-filter-item.selected .context-menu {
                    background-color: #8fa61a;
                }
.default .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #000000;
                }
.default .no-match {
            color: #ff0000;
        }
.default .equipment-info-no-match {
            color: #ffffff;
        }
.default-light .import-filter {
            background-color: #ffffff;
            color: #000000
        }
.default-light .import-filter .import-filter-title {
                background-color: #919191;
            }
.default-light .import-filter .import-filter-header {
                border-bottom: 1px solid #8fa61a
            }
.default-light .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.default-light .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #000000;
                    }
.default-light .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #000000;
                    }
.default-light .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #000000;
                    }
.default-light .import-filter .import-filter-item.selected {
                background-color: #8fa61a
            }
.default-light .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #8fa61a;
                }
.default-light .import-filter .import-filter-item.selected .context-menu {
                    background-color: #8fa61a;
                }
.default-light .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #fcfcfc;
                }
.default-light .no-match {
            color: #ff0000;
        }
.default-light .equipment-info-no-match {
            color: #ffffff;
        }
.sky .import-filter {
            background-color: #ffffff;
            color: #000000
        }
.sky .import-filter .import-filter-title {
                background-color: #0194d3;
            }
.sky .import-filter .import-filter-header {
                border-bottom: 1px solid #235635
            }
.sky .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.sky .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #000000;
                    }
.sky .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #000000;
                    }
.sky .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #000000;
                    }
.sky .import-filter .import-filter-item.selected {
                background-color: #235635
            }
.sky .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #235635;
                }
.sky .import-filter .import-filter-item.selected .context-menu {
                    background-color: #235635;
                }
.sky .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #fcfcfc;
                }
.sky .no-match {
            color: #ff0000;
        }
.sky .equipment-info-no-match {
            color: #ffffff;
        }
.sky-light .import-filter {
            background-color: #ffffff;
            color: #000000
        }
.sky-light .import-filter .import-filter-title {
                background-color: #0194d3;
            }
.sky-light .import-filter .import-filter-header {
                border-bottom: 1px solid #235635
            }
.sky-light .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.sky-light .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #000000;
                    }
.sky-light .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #000000;
                    }
.sky-light .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #000000;
                    }
.sky-light .import-filter .import-filter-item.selected {
                background-color: #235635
            }
.sky-light .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #235635;
                }
.sky-light .import-filter .import-filter-item.selected .context-menu {
                    background-color: #235635;
                }
.sky-light .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #fcfcfc;
                }
.sky-light .no-match {
            color: #ff0000;
        }
.sky-light .equipment-info-no-match {
            color: #ffffff;
        }
.grass .import-filter {
            background-color: #000000;
            color: #ffffff
        }
.grass .import-filter .import-filter-title {
                background-color: #325132;
            }
.grass .import-filter .import-filter-header {
                border-bottom: 1px solid #ffff00
            }
.grass .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.grass .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #ffffff;
                    }
.grass .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #ffffff;
                    }
.grass .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #ffffff;
                    }
.grass .import-filter .import-filter-item.selected {
                background-color: #ffff00
            }
.grass .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #ffff00;
                }
.grass .import-filter .import-filter-item.selected .context-menu {
                    background-color: #ffff00;
                }
.grass .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #667c66;
                }
.grass .no-match {
            color: #ff0000;
        }
.grass .equipment-info-no-match {
            color: #ffffff;
        }
.grass-light .import-filter {
            background-color: #ffffff;
            color: #000000
        }
.grass-light .import-filter .import-filter-title {
                background-color: #545f54;
            }
.grass-light .import-filter .import-filter-header {
                border-bottom: 1px solid #ffff00
            }
.grass-light .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.grass-light .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #000000;
                    }
.grass-light .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #000000;
                    }
.grass-light .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #000000;
                    }
.grass-light .import-filter .import-filter-item.selected {
                background-color: #ffff00
            }
.grass-light .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #ffff00;
                }
.grass-light .import-filter .import-filter-item.selected .context-menu {
                    background-color: #ffff00;
                }
.grass-light .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #babfba;
                }
.grass-light .no-match {
            color: #ff0000;
        }
.grass-light .equipment-info-no-match {
            color: #ffffff;
        }
.mud .import-filter {
            background-color: #000000;
            color: #ffffff
        }
.mud .import-filter .import-filter-title {
                background-color: #766146;
            }
.mud .import-filter .import-filter-header {
                border-bottom: 1px solid #ffff00
            }
.mud .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.mud .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #ffffff;
                    }
.mud .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #ffffff;
                    }
.mud .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #ffffff;
                    }
.mud .import-filter .import-filter-item.selected {
                background-color: #ffff00
            }
.mud .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #ffff00;
                }
.mud .import-filter .import-filter-item.selected .context-menu {
                    background-color: #ffff00;
                }
.mud .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #4b3705;
                }
.mud .no-match {
            color: #ff0000;
        }
.mud .equipment-info-no-match {
            color: #ffffff;
        }
.mud-light .import-filter {
            background-color: #ffffff;
            color: #000000
        }
.mud-light .import-filter .import-filter-title {
                background-color: #a37b42;
            }
.mud-light .import-filter .import-filter-header {
                border-bottom: 1px solid #8fa61a
            }
.mud-light .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.mud-light .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #000000;
                    }
.mud-light .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #000000;
                    }
.mud-light .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #000000;
                    }
.mud-light .import-filter .import-filter-item.selected {
                background-color: #8fa61a
            }
.mud-light .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #8fa61a;
                }
.mud-light .import-filter .import-filter-item.selected .context-menu {
                    background-color: #8fa61a;
                }
.mud-light .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #fff0bb;
                }
.mud-light .no-match {
            color: #ff0000;
        }
.mud-light .equipment-info-no-match {
            color: #ffffff;
        }
.illini .import-filter {
            background-color: #000000;
            color: #ffffff
        }
.illini .import-filter .import-filter-title {
                background-color: #636466;
            }
.illini .import-filter .import-filter-header {
                border-bottom: 1px solid #ffff00
            }
.illini .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.illini .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #ffffff;
                    }
.illini .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #ffffff;
                    }
.illini .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #ffffff;
                    }
.illini .import-filter .import-filter-item.selected {
                background-color: #ffff00
            }
.illini .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #ffff00;
                }
.illini .import-filter .import-filter-item.selected .context-menu {
                    background-color: #ffff00;
                }
.illini .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #00467f;
                }
.illini .no-match {
            color: #ff0000;
        }
.illini .equipment-info-no-match {
            color: #ffffff;
        }
.illini-light .import-filter {
            background-color: #ffffff;
            color: #000000
        }
.illini-light .import-filter .import-filter-title {
                background-color: #375d81;
            }
.illini-light .import-filter .import-filter-header {
                border-bottom: 1px solid #ffff00
            }
.illini-light .import-filter .import-filter-header .required .red-star {
                        color: #ff0000;
                    }
.illini-light .import-filter .import-filter-item .filter-link-container .filter-link:link {
                        color: #000000;
                    }
.illini-light .import-filter .import-filter-item .filter-link-container .filter-link:hover {
                        color: #000000;
                    }
.illini-light .import-filter .import-filter-item .filter-link-container .filter-link:visited {
                        color: #000000;
                    }
.illini-light .import-filter .import-filter-item.selected {
                background-color: #ffff00
            }
.illini-light .import-filter .import-filter-item.selected .import-filter-item-delete {
                    background-color: #ffff00;
                }
.illini-light .import-filter .import-filter-item.selected .context-menu {
                    background-color: #ffff00;
                }
.illini-light .import-filter .import-filter-item.selected .context-menu:hover {
                    background-color: #e1e6fa;
                }
.illini-light .no-match {
            color: #ff0000;
        }
.illini-light .equipment-info-no-match {
            color: #ffffff;
        }

.equipment-filter .form-input.select-form-input-options {
    margin-left: 5px;
    margin-right: 5px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.product-blend-modal.equation-blend-modal {
    min-width: 1025px;
    width: 1025px;
    max-width: 1025px
}
.product-blend-modal.equation-blend-modal .blend-type .tabs-labels {
            width: 33%;
        }
.product-blend-modal {
    min-width: 1050px;
    width: 1050px;
    max-width: 1050px;

    min-height: 640px
}
.product-blend-modal .dialog-box-body {
        margin-top: 0px;
    }
.product-blend-modal .load-custom-blend {
        text-align: right;
        padding-right: 10px;
        display: inline;
        position: relative;
        float: right;
        top: 15px
    }
.product-blend-modal .load-custom-blend .separator {
            padding-left: 10px;
            padding-right: 10px;
        }
.product-blend-modal .load-custom-blend .load-custom-blend-modal {
            display: flex;
            flex-flow: column nowrap
        }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-header {
                font-weight: 700;
            }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-row {
                display: flex;
                flex-flow: row nowrap;
                width: 400px;
                height: 25px;
                line-height: 25px
            }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-row .blend-table-name {
                    width: 35%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-row .blend-table-ga {
                    width: 52%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-row .blend-table-custom {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-row .blend-table-chemical {
                    width: 87%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 5px;
                }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-row .remove-product-icon .svg-container .default-svg-icon {
                            width: 18px;
                        }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-body {
                max-height: 500px;
                overflow-y: auto;
                overflow-x: hidden
            }
.product-blend-modal .load-custom-blend .load-custom-blend-modal .load-blend-table-body .load-blend-table-row {
                    cursor: pointer;
                }
.product-blend-modal .blend-type {
        width: 100%;
        margin-top: 0px
    }
.product-blend-modal .blend-type .tabs-labels {
            width: 50%;
            margin-bottom: 0px
        }
.product-blend-modal .blend-type .tabs-labels .tab-label {
                line-height: 28px;
            }
.product-blend-modal .product-selection {
        padding-top: 10px
    }
.product-blend-modal .product-selection .product-filter-bar {
            min-width: 100%
        }
.product-blend-modal .product-selection .product-filter-bar .product-filters-toggle {
                display: block;
                padding-left: 3px;
                min-height: 20px;
            }
.product-blend-modal .product-selection .product-filter-bar .product-filters {
                display: flex;
                flex-flow: row nowrap;
            }
.product-blend-modal .product-selection .product-add {
            width: 55%;
            display: flex;
            flex-flow: row nowrap
        }
.product-blend-modal .product-selection .product-add .product-select-input {
                width: 100%;
            }
.product-blend-modal .product-selection .product-add .product-add-nolink-section {
                display: flex;
                flex-flow: row nowrap;
                min-width: 50%
            }
.product-blend-modal .product-selection .product-add .product-add-nolink-section .product-add-nolink {
                    flex-grow: 1;
                    margin-top: 20px;
                    margin-left: 20px;
                }
.product-blend-modal .product-blend-table {
        margin-top: 5px;
        height: 340px;
        max-height: 340px;
        margin-bottom: 20px;
        justify-content: flex-start;
        overflow-y: auto
    }
.product-blend-modal .product-blend-table .product-blend-body {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            margin-bottom: 40px;
            width: 100%
        }
.product-blend-modal .product-blend-table .product-blend-body .guaranteed-analysis {
                line-height: 25px;
            }
.product-blend-modal .product-blend-table .product-blend-body .guaranteed-analysis-with-name {
                line-height: 15px;
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip]::before {
                /* needed */
                content: attr(data-tooltip);
                position: absolute;
                opacity: 0;
                /* customizable */
                white-space: pre-line;
                display: inline;
                top: 35px;
                left: 50%;
                transform: translate(-50%, -25px);
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip]:hover::before {
                /* needed */
                opacity: 1;
                /* customizable */
                box-shadow: 1px 1px 3px #222222;
                border-radius: 2px;
                font-size: 12px;
                line-height: 15px;
                margin: auto;
                margin-top: 20px;
                min-width: 100px;
                padding: 4px;
                transition-delay: 0.75s;
                z-index: 10;
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip]:not([data-tooltip-persistent])::before {
                pointer-events: none;
                min-width: 80px;
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip] {
                position: relative;
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip-ga]::before {
                /* needed */
                content: attr(data-tooltip-ga);
                position: absolute;
                opacity: 0;

                /* customizable */
                white-space: pre-line;
                display: inline;
                top: 25px;
                left: 50%;
                transform: translate(-50%, -25px);
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip-ga]:hover::before {
                /* needed */
                opacity: 1;

                /* customizable */
                box-shadow: 1px 1px 3px #222222;
                border-radius: 2px;
                font-size: 12px;
                line-height: 13px;
                margin: auto;
                margin-top: 15px;
                min-width: 100px;
                padding: 0px 3px 1px 3px;
                transition-delay: 0.75s;
                z-index: 10;
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip-ga]:not([data-tooltip-ga-persistent])::before {
                pointer-events: none;
                min-width: 120px;
                max-height: 70px;
                width: max-content;
                max-width: 250px;
            }
.product-blend-modal .product-blend-table .product-blend-body [data-tooltip-ga] {
                position: relative;
            }
.product-blend-modal .product-blend-table .product-blend-body > .product-blend-item .form-input {
                    height: 22px;
                }
.product-blend-modal .product-blend-table .product-blend-body > .product-blend-item .form-input:not(.select-form-input) input {
                        text-align: center;
                    }
.product-blend-modal .product-blend-table .product-blend-body > .product-blend-item .select-form-input-options {
                    text-align: left;
                }
.product-blend-modal .product-blend-table .product-blend-body .ga-pf-section {
                margin-left: 4%;
                margin-bottom: 20px;
                max-width: 25%;
                min-width: 25%;
                text-align: center;
                width: 25%
            }
.product-blend-modal .product-blend-table .product-blend-body .ga-pf-section p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 16px;
                }
.product-blend-modal .product-blend-table .product-blend-body .ga-pf-section-blend {
                margin-bottom: 27px;
                min-height: 55px;
                text-align: center
            }
.product-blend-modal .product-blend-table .product-blend-body .ga-pf-section-blend p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 16px;
                }
.product-blend-modal .product-blend-table .product-blend-header-margin {
            margin-top: 15px;
        }
.product-blend-modal .product-blend-table .product-blend-header {
            font-weight: 700;
            min-height: 30px !important;
            line-height: 30px;
            height: 30px
        }
.product-blend-modal .product-blend-table .product-blend-header .checkbox-div {
                margin-left: 11px;
                margin-top: 3px;
            }
.product-blend-modal .product-blend-table .carrier-header {
            height: 25px !important;
            line-height: 25px !important;
            min-height: 25px !important
        }
.product-blend-modal .product-blend-table .carrier-header .product-blend-column {
                line-height: 25px !important;
            }
.product-blend-modal .product-blend-table .product-blend-item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            min-width: 100%;
            min-height: 40px
        }
.product-blend-modal .product-blend-table .product-blend-item .remove-product-icon {
                cursor: pointer;
                margin-top: 9px
            }
.product-blend-modal .product-blend-table .product-blend-item .remove-product-icon .default-svg-icon {
                    height: 22px;
                    width: 22px;
                }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column {
                min-width: 5%;
                text-align: center;
                white-space: nowrap;
                line-height: 40px
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column .tall {
                    line-height: 40px;
                }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column .expand-contract-arrow {
                    position: relative;
                    top: -10px;
                }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column .lock-mix-checkbox {
                    margin-left: 128px;
                }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.xsmall {
                min-width: 4%;
                width: 4%;
                max-width: 4%;
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.small {
                min-width: 8%;
                width: 8%;
                max-width: 8%;
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.med {
                min-width: 10%;
                width: 10%;
                max-width: 10%;
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.large {
                min-width: 12%;
                width: 12%;
                max-width: 12%;
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.xlarge {
                min-width: 16%;
                width: 16%;
                max-width: 16%;
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.xxlarge {
                min-width: 19%;
                width: 19%;
                max-width: 19%;
            }
.product-blend-modal .product-blend-table .product-blend-item .product-blend-column.xxxlarge {
                min-width: 25%;
                width: 25%;
                max-width: 25%;
            }
.product-blend-modal .product-blend-table .field-avg-rate {
            line-height: 10px;
            width: 59.5%;
            display: flex
        }
.product-blend-modal .product-blend-table .field-avg-rate .field-avg-rate-label {
                width: 19%;
            }
.product-blend-modal .product-blend-table .field-avg-rate .field-avg-rate-value {
                text-align: center;
                width: 10%;
            }
.product-blend-modal .product-blend-table .min-max-settings {
            display: column;
            width: 24%;
            line-height: 10px
        }
.product-blend-modal .product-blend-table .min-max-settings .min-max-lock-bar {
                display: flex;
                flex-flow: row nowrap
            }
.product-blend-modal .product-blend-table .min-max-settings .min-max-lock-bar .min-max-lock-label {
                    margin-top: 4px;
                }
.product-blend-modal .product-blend-table .min-max-settings .min-max-lock-bar .min-lock-checkbox {
                    margin-left: 25px;
                    max-width: 30px;
                }
.product-blend-modal .product-blend-table .min-max-settings .min-max-lock-bar .max-lock-checkbox {
                    margin-left: 40px;
                    max-width: 30px;
                }
.product-blend-modal .product-blend-table .settings-row {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
            min-width: 100%;
        }
.product-blend-modal .product-blend-table .minimum-settings {
            display: flex;
            justify-content: flex-end;
            flex-flow: row wrap;
            width: 100%
        }
.product-blend-modal .product-blend-table .minimum-settings .select-form-input-container {
                width: 23%;
            }
.product-blend-modal .product-blend-table .switch-rate-input .form-input {
                float: right;
                margin-right: 118px;
                width: 10%;
            }
.product-blend-modal .equation-credit-bucket .bucket-header {
            display: flex;
            flex-flow: row nowrap;
            height: 40px;
            line-height: 40px
        }
.product-blend-modal .equation-credit-bucket .bucket-header .apply-credits-section {
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                line-height: 40px;
                padding-left: 25px;
                cursor: default
            }
.product-blend-modal .equation-credit-bucket .bucket-header .apply-credits-section .credits-not-applied {
                    flex-grow: 1;
                    text-align: right;
                    font-size: 12px;
                }
.product-blend-modal .equation-credit-bucket .equation-credits-body {
            display: flex;
            flex-flow: column nowrap;
            width: 100%
        }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section {
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                min-height: 100px
            }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section .rec-credit-section {
                    width: 30%;
                    flex-flow: column nowrap;
                    justify-content: space-between;
                    margin-right: 30px;
                    max-height: 200px;
                    overflow-y: auto
                }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section .rec-credit-section .credit-section-header {
                        font-weight: bold;
                        font-size: 14px;
                        text-decoration: underline;
                        line-height: 30px;
                    }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section .rec-credit-section.flat-rate-section {
                    max-width: 26%;
                    margin-right: 75px
                }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section .rec-credit-section.flat-rate-section .credit-section-body {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        line-height: 50px;
                        height: 50px
                    }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section .rec-credit-section.flat-rate-section .credit-section-body .credit-checkbox {
                            margin-top: 20px;
                            max-width: 30px;
                        }
.product-blend-modal .equation-credit-bucket .equation-credits-body .credit-options-section .rec-credit-section.flat-rate-section .credit-section-body .form-input {
                            flex-grow: 1;
                        }
.product-blend-modal .carrier-item {
        line-height: 40px
    }
.product-blend-modal .carrier-item .form-input {
            line-height: 20px;
        }
.product-blend-modal .carrier-item .expand-contract-arrow {
            top: 0px !important;
        }
.product-blend-modal .product-blend-totals .product-blend-column {
            line-height: 48px !important;
        }
.product-blend-modal .product-blend-totals .form-input {
            height: 35px;
            line-height: 20px;
        }
.product-blend-modal .product-blend-totals .checkbox-div {
            margin-top: 15px;
            margin-left: 10px;
        }
.product-blend-modal .product-blend-totals .text-form-input:not(.select-form-input) input {
                text-align: center;
            }
.product-blend-modal .product-blend-totals .select-form-input-container {
            text-align: left;
        }
.product-blend-modal .custom-product-builder .property-bar {
            display: flex;
            flex-flow: row nowrap;
            width: 100%
        }
.product-blend-modal .custom-product-builder .property-bar .property-input {
                width: 15.5%;
                justify-content: flex-start;
            }
.product-blend-modal .custom-product-builder .property-bar .property-label {
                line-height: 55px;
                margin-left: 5px;
            }
.product-blend-modal .custom-product-builder .property-bar .custom-product-controls {
                width: 70%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                line-height: 50px;
                text-align: right;
            }
.product-blend-modal .custom-product-builder .property-bar .add-custom-product-nolink {
                margin-right: 20px;
            }
.product-blend-modal .custom-product-builder .property-bar .clear-custom-product-nolink {
                margin-left: 15px;
                margin-right: 15px;
            }
.product-blend-modal .custom-product-builder .nutrient-bar {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            justify-content: space-between
        }
.product-blend-modal .custom-product-builder .nutrient-bar .nutrient-input {
                min-width: 7.5%;
            }
.product-blend-modal .product-blend-save {
        display: flex;
        flex-flow: column nowrap;
        width: 50%
    }
.product-blend-modal .product-blend-save .blend-save-message {
            font-size: 12px;
            margin-left: 5px;
        }
.product-blend-modal .product-blend-save .blend-save-row {
            display: flex;
            flex-flow: row nowrap;
            width: 100%
        }
.product-blend-modal .product-blend-save .blend-save-row .blend-save-input {
                width: 50%;
            }
.product-blend-modal .product-blend-save .blend-save-row .blend-save-nolink {
                margin-top: 20px;
                margin-left: 10px;
            }
.product-blend-modal .blending-validation-error-msg {
        margin-top: 20px;
    }
.product-blend-modal .blending-validation-reset-msg {
        margin-top: 20px;
        text-align: center;
        font-weight: bold;
    }
.product-blend-modal.equation-blend-modal .equation-product-blend-table .product-blend-column.xsmall {
            min-width: 4%;
            width: 4%;
            max-width: 4%;
        }
.product-blend-modal.equation-blend-modal .equation-product-blend-table .product-blend-column.xxlarge {
            min-width: 23%;
            width: 23%;
            max-width: 23%;
        }
.product-blend-modal.equation-blend-modal .equation-product-blend-table .product-blend-column.small {
            min-width: 7%;
            width: 7%;
            max-width: 7%;
        }
.product-blend-modal.equation-blend-modal .equation-product-blend-table .product-blend-column.med {
            min-width: 8%;
            width: 8%;
            max-width: 8%;
        }
.product-blend-modal.equation-blend-modal.lime-blend-modal {
    min-width: 1125px;
    width: 1125px;
    max-width: 1125px
}
.product-blend-modal.equation-blend-modal.lime-blend-modal .equation-product-blend-table .product-blend-column.xxlarge {
            min-width: 16%;
            width: 16%;
            max-width: 16%;
        }
.product-blend-modal.equation-blend-modal.lime-blend-modal .equation-product-blend-table .field-avg-rate {
            width: 61%
        }
.product-blend-modal.equation-blend-modal.lime-blend-modal .equation-product-blend-table .field-avg-rate .field-avg-rate-label {
                width: 16%;
            }
.product-blend-modal.equation-blend-modal.lime-blend-modal .product-blend-table .min-max-settings {
        width: 30%
    }
.product-blend-modal.equation-blend-modal.lime-blend-modal .product-blend-table .min-max-settings .min-max-lock-bar .min-lock-checkbox {
                margin-left: 24px;
            }
.product-blend-modal.equation-blend-modal.lime-blend-modal .product-blend-table .min-max-settings .min-max-lock-bar .max-lock-checkbox {
                margin-left: 46px;
            }
.trim-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.default .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.default .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #000000;
            }
.default .product-blend-modal .product-blend-table {
            border-top: 1px solid #404040;
            border-bottom: 1px solid #404040;
        }
.default .product-blend-modal .equation-product-blend-table {
            background-color: #404040;
        }
.default .product-blend-modal .selected-custom-blend {
            background-color: #8fa61a;
        }
.default .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.default .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.default .product-blend-modal .custom-product-controls {
            color: #777777;
        }
.default .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.default .product-blend-modal [data-tooltip]:hover::before {
            background: #bfbfbf;
            color: #000000;
        }
.default .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #bfbfbf;
            color: #000000;
        }
.default-light .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.default-light .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #fcfcfc;
            }
.default-light .product-blend-modal .product-blend-table {
            border-top: 1px solid #919191;
            border-bottom: 1px solid #919191;
        }
.default-light .product-blend-modal .equation-product-blend-table {
            background-color: #919191;
        }
.default-light .product-blend-modal .selected-custom-blend {
            background-color: #8fa61a;
        }
.default-light .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.default-light .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.default-light .product-blend-modal .custom-product-controls {
            color: #777777;
        }
.default-light .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.default-light .product-blend-modal [data-tooltip]:hover::before {
            background: #cfcfcf;
            color: #000000;
        }
.default-light .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #cfcfcf;
            color: #000000;
        }
.sky .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.sky .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #fcfcfc;
            }
.sky .product-blend-modal .product-blend-table {
            border-top: 1px solid #0194d3;
            border-bottom: 1px solid #0194d3;
        }
.sky .product-blend-modal .equation-product-blend-table {
            background-color: #0194d3;
        }
.sky .product-blend-modal .selected-custom-blend {
            background-color: #235635;
        }
.sky .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.sky .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.sky .product-blend-modal .custom-product-controls {
            color: #878787;
        }
.sky .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.sky .product-blend-modal [data-tooltip]:hover::before {
            background: #c8c8c8;
            color: #000000;
        }
.sky .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #c8c8c8;
            color: #000000;
        }
.sky-light .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.sky-light .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #fcfcfc;
            }
.sky-light .product-blend-modal .product-blend-table {
            border-top: 1px solid #0194d3;
            border-bottom: 1px solid #0194d3;
        }
.sky-light .product-blend-modal .equation-product-blend-table {
            background-color: #0194d3;
        }
.sky-light .product-blend-modal .selected-custom-blend {
            background-color: #235635;
        }
.sky-light .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.sky-light .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.sky-light .product-blend-modal .custom-product-controls {
            color: #878787;
        }
.sky-light .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.sky-light .product-blend-modal [data-tooltip]:hover::before {
            background: #c8c8c8;
            color: #000000;
        }
.sky-light .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #c8c8c8;
            color: #000000;
        }
.grass .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.grass .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #667c66;
            }
.grass .product-blend-modal .product-blend-table {
            border-top: 1px solid #325132;
            border-bottom: 1px solid #325132;
        }
.grass .product-blend-modal .equation-product-blend-table {
            background-color: #325132;
        }
.grass .product-blend-modal .selected-custom-blend {
            background-color: #ffff00;
        }
.grass .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.grass .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.grass .product-blend-modal .custom-product-controls {
            color: #e5e9e5;
        }
.grass .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.grass .product-blend-modal [data-tooltip]:hover::before {
            background: #9cadb2;
            color: #000000;
        }
.grass .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #9cadb2;
            color: #000000;
        }
.grass-light .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.grass-light .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #babfba;
            }
.grass-light .product-blend-modal .product-blend-table {
            border-top: 1px solid #545f54;
            border-bottom: 1px solid #545f54;
        }
.grass-light .product-blend-modal .equation-product-blend-table {
            background-color: #545f54;
        }
.grass-light .product-blend-modal .selected-custom-blend {
            background-color: #ffff00;
        }
.grass-light .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.grass-light .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.grass-light .product-blend-modal .custom-product-controls {
            color: #e5e9e5;
        }
.grass-light .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.grass-light .product-blend-modal [data-tooltip]:hover::before {
            background: #424443;
            color: #000000;
        }
.grass-light .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #424443;
            color: #000000;
        }
.mud .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.mud .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #4b3705;
            }
.mud .product-blend-modal .product-blend-table {
            border-top: 1px solid #766146;
            border-bottom: 1px solid #766146;
        }
.mud .product-blend-modal .equation-product-blend-table {
            background-color: #766146;
        }
.mud .product-blend-modal .selected-custom-blend {
            background-color: #ffff00;
        }
.mud .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.mud .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.mud .product-blend-modal .custom-product-controls {
            color: #777777;
        }
.mud .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.mud .product-blend-modal [data-tooltip]:hover::before {
            background: #f2f3f8;
            color: #000000;
        }
.mud .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #f2f3f8;
            color: #000000;
        }
.mud-light .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.mud-light .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #fff0bb;
            }
.mud-light .product-blend-modal .product-blend-table {
            border-top: 1px solid #a37b42;
            border-bottom: 1px solid #a37b42;
        }
.mud-light .product-blend-modal .equation-product-blend-table {
            background-color: #a37b42;
        }
.mud-light .product-blend-modal .selected-custom-blend {
            background-color: #8fa61a;
        }
.mud-light .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.mud-light .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.mud-light .product-blend-modal .custom-product-controls {
            color: #777777;
        }
.mud-light .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.mud-light .product-blend-modal [data-tooltip]:hover::before {
            background: #d6c282;
            color: #000000;
        }
.mud-light .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #d6c282;
            color: #000000;
        }
.illini .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.illini .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #00467f;
            }
.illini .product-blend-modal .product-blend-table {
            border-top: 1px solid #636466;
            border-bottom: 1px solid #636466;
        }
.illini .product-blend-modal .equation-product-blend-table {
            background-color: #636466;
        }
.illini .product-blend-modal .selected-custom-blend {
            background-color: #ffff00;
        }
.illini .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.illini .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.illini .product-blend-modal .custom-product-controls {
            color: #777777;
        }
.illini .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.illini .product-blend-modal [data-tooltip]:hover::before {
            background: #f2f3f8;
            color: #000000;
        }
.illini .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #f2f3f8;
            color: #000000;
        }
.illini-light .product-blend-modal .blend-type-tabs {
            border-top: none !important
        }
.illini-light .product-blend-modal .blend-type-tabs .tab-label:not(.active) {
                border-right: 1px solid #e1e6fa;
            }
.illini-light .product-blend-modal .product-blend-table {
            border-top: 1px solid #375d81;
            border-bottom: 1px solid #375d81;
        }
.illini-light .product-blend-modal .equation-product-blend-table {
            background-color: #375d81;
        }
.illini-light .product-blend-modal .selected-custom-blend {
            background-color: #ffff00;
        }
.illini-light .product-blend-modal .invalid-rate {
            color: #ff0000;
        }
.illini-light .product-blend-modal .carrier-disabled {
            opacity: 0.4;
        }
.illini-light .product-blend-modal .custom-product-controls {
            color: #777777;
        }
.illini-light .product-blend-modal .credits-not-applied {
            color: #f89406;
        }
.illini-light .product-blend-modal [data-tooltip]:hover::before {
            background: #abc8e2;
            color: #000000;
        }
.illini-light .product-blend-modal [data-tooltip-ga]:hover::before {
            background: #abc8e2;
            color: #000000;
        }


    .product-mixes-table .product-row {
        margin: 10px;
        margin-left: 20px;
    }
.product-mixes-table .mix-title {
        margin-top: 10px;
        font-weight: 900;
        font-size: 14px;
    }
.product-mixes-table .product-menu {
        margin-top: 13px;
        padding-left: 15px
    }
.product-mixes-table .product-menu .context-menu .menu-dot {
            color: #404040 !important;
        }
.product-mixes-table .product-menu:hover .context-menu .menu-dot {
                color: #8fce00 !important;
            }
.product-mixes-table .rate-input {
        width: 20%;
        min-width: 20%;
        padding-top: 8px;
    }
.product-mixes-table .rate-unit {
        margin-top: 25px;
    }
.product-mixes-table .text-form-input {
        height: 22px;
        margin-right: 7px;
    }
.product-mixes-table .form-input:not(.select-form-input) input {
        text-align: center;
    }
.product-mixes-table .product-mixes-rows {
        display: flex;
        flex-direction: column nowrap;
        width: 100%;
    }
.product-mixes-table .product-name-column {
        min-width: 208px;
        width: 208px;
        margin-left: 8px;
        overflow-wrap: break-word;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.add-customer-link {
    line-height: 40px;
    height: 40px;
    min-height: 40px;
    font-size: 10pt;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}
.add-customer-link a {
        vertical-align: top;
    }
.add-customer-link .default-svg-icon {
        height: 15px;
        width: 15px;
        float: left;
        margin: 12px 12px 0 16px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
.default .add-customer-link {
        background-color: #404040 !important;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid transparent
    }
.default .add-customer-link a:focus {
            color: #1daaef;
        }
.default .add-customer-link a:active {
            color: #1daaef;
        }
.default .add-customer-link a:hover {
            color: #1daaef;
        }
.default-light .add-customer-link {
        background-color: #919191 !important;
        border-top: 1px solid #cfcfcf;
        border-left: 1px solid transparent
    }
.default-light .add-customer-link a:focus {
            color: #0e55a8;
        }
.default-light .add-customer-link a:active {
            color: #0e55a8;
        }
.default-light .add-customer-link a:hover {
            color: #0e55a8;
        }
.sky .add-customer-link {
        background-color: #0194d3 !important;
        border-top: 1px solid #c8c8c8;
        border-left: 1px solid transparent
    }
.sky .add-customer-link a:focus {
            color: #01425e;
        }
.sky .add-customer-link a:active {
            color: #01425e;
        }
.sky .add-customer-link a:hover {
            color: #01425e;
        }
.sky-light .add-customer-link {
        background-color: #0194d3 !important;
        border-top: 1px solid #c8c8c8;
        border-left: 1px solid transparent
    }
.sky-light .add-customer-link a:focus {
            color: #01425e;
        }
.sky-light .add-customer-link a:active {
            color: #01425e;
        }
.sky-light .add-customer-link a:hover {
            color: #01425e;
        }
.grass .add-customer-link {
        background-color: #325132 !important;
        border-top: 1px solid #9cadb2;
        border-left: 1px solid transparent
    }
.grass .add-customer-link a:focus {
            color: #e26816;
        }
.grass .add-customer-link a:active {
            color: #e26816;
        }
.grass .add-customer-link a:hover {
            color: #e26816;
        }
.grass-light .add-customer-link {
        background-color: #545f54 !important;
        border-top: 1px solid #424443;
        border-left: 1px solid transparent
    }
.grass-light .add-customer-link a:focus {
            color: #e26816;
        }
.grass-light .add-customer-link a:active {
            color: #e26816;
        }
.grass-light .add-customer-link a:hover {
            color: #e26816;
        }
.mud .add-customer-link {
        background-color: #766146 !important;
        border-top: 1px solid #f2f3f8;
        border-left: 1px solid transparent
    }
.mud .add-customer-link a:focus {
            color: #f0c248;
        }
.mud .add-customer-link a:active {
            color: #f0c248;
        }
.mud .add-customer-link a:hover {
            color: #f0c248;
        }
.mud-light .add-customer-link {
        background-color: #a37b42 !important;
        border-top: 1px solid #d6c282;
        border-left: 1px solid transparent
    }
.mud-light .add-customer-link a:focus {
            color: #0e55a8;
        }
.mud-light .add-customer-link a:active {
            color: #0e55a8;
        }
.mud-light .add-customer-link a:hover {
            color: #0e55a8;
        }
.illini .add-customer-link {
        background-color: #636466 !important;
        border-top: 1px solid #f2f3f8;
        border-left: 1px solid transparent
    }
.illini .add-customer-link a:focus {
            color: #f39c11;
        }
.illini .add-customer-link a:active {
            color: #f39c11;
        }
.illini .add-customer-link a:hover {
            color: #f39c11;
        }
.illini-light .add-customer-link {
        background-color: #375d81 !important;
        border-top: 1px solid #abc8e2;
        border-left: 1px solid transparent
    }
.illini-light .add-customer-link a:focus {
            color: #b2962c;
        }
.illini-light .add-customer-link a:active {
            color: #b2962c;
        }
.illini-light .add-customer-link a:hover {
            color: #b2962c;
        }
.sky .add-customer-link a {
            font-weight: bold
        }
.sky .add-customer-link a:focus {
                color: #01425e;
            }
.sky .add-customer-link a:active {
                color: #01425e;
            }
.sky .add-customer-link a:hover {
                color: #01425e;
            }
.sky .add-customer-link .default-svg-icon {
            color: #01425e;
        }
.sky-light .add-customer-link a {
            font-weight: bold
        }
.sky-light .add-customer-link a:focus {
                color: #01425e;
            }
.sky-light .add-customer-link a:active {
                color: #01425e;
            }
.sky-light .add-customer-link a:hover {
                color: #01425e;
            }
.sky-light .add-customer-link .default-svg-icon {
            color: #01425e;
        }

.batch-edit-details.blownup {
    width: 321px
}
.batch-edit-details.blownup .dialog-box-body {
        overflow: visible;
    }
.batch-edit-details.blownup .multi-select-container {
        margin: 10px;
        padding-right: 0;
        width: auto;
        width: initial
    }
.batch-edit-details.blownup .multi-select-container .select-form-input-multi.form-input {
            margin: 0;
        }
.batch-edit-details.blownup .multi-select-container .dropdown-menu {
            width: 277px;
        }
.batch-edit-details.blownup .multi-select-container.menu-open .form-input {
                width: auto;
                width: initial;
            }
.batch-edit-details.blownup .dialog-box-children > a {
        display: inline-block;
        margin: 10px 20px 20px;
    }

.action-panel .context-menu.customer-context-menu {
    margin-top: 7px;
    height: 40px;
    line-height: 24px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.field-module .customer-accordion-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }
.field-module .customer-accordion-item .spacer {
            flex: 1 1 auto;
        }
.field-module .customer-accordion-item.is-active-inactive {
            font-style: italic;
        }
.field-module .customer-accordion-item .clip-overflow {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
.field-module .customer-accordion-item .customer-enrolled-lock-icon {
            height: 10px;
            width: 10px;
            padding-top: 1px;
        }
.field-module .customer-accordion-item .customer-info {
            cursor: pointer;
            flex-grow: 1;
            padding: 8px 0 0 12px;
            max-width: 260px
        }
.field-module .customer-accordion-item .customer-info .customer-name-container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap
            }
.field-module .customer-accordion-item .customer-info .customer-name-container .customer-name {
                    line-height: 20px;
                    padding-right: 5px;
                }
.field-module .customer-accordion-item .customer-info .customer-name-container .selected-field-count {
                    border-radius: 5px;
                    font-family: "open_sansregular";
                    font-size: 13px;
                    font-weight: 300;
                    margin-left: 8px;
                    padding: 3px 7px;
                    position: relative;
                    display: inline-block;
                }
.field-module .customer-accordion-item .customer-info .customer-city-state {
                font-size: 12px;
                line-height: 20px;
            }
.field-module .customer-accordion-item .field-count {
            width: 50px;
        }
.field-module .customer-accordion-item .total-acres {
            width: 70px;
        }
.field-module .customer-accordion-item .field-count,
        .field-module .customer-accordion-item .total-acres {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 3px 0 3px
        }
.field-module .customer-accordion-item .field-count > div, .field-module .customer-accordion-item .total-acres > div {
                text-align: center
            }
.field-module .customer-accordion-item .field-count > div:first-child, .field-module .customer-accordion-item .total-acres > div:first-child {
                    line-height: 21px;
                }
.field-module .customer-accordion-item .field-count .units, .field-module .customer-accordion-item .total-acres .units {
                font-family: open_sansitalic;
                font-size: 11px;
                line-height: 14px;
                text-align: center;
            }
.field-module .customer-accordion-item .customer-context-menu-container {
            width: 23px;
        }
.default .customer-accordion-item {
        background-color: #000000;
        border-top: 1px solid #bfbfbf;
        border-bottom: 1px solid #000000;
        color: #ffffff
    }
.default .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #bfbfbf;
        }
.default .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #e26816;
            color: #ffffff;
        }
.default .customer-accordion-item:hover .context-menu .menu-dot {
            color: #8fa61a;
        }
.default .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #404040
        }
.default .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #404040
        }
.default .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #8fa61a;
            }
.default .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #8fa61a;
            }
.default .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #bfbfbf;
    }
.default-light .customer-accordion-item {
        background-color: #fcfcfc;
        border-top: 1px solid #cfcfcf;
        border-bottom: 1px solid #fcfcfc;
        color: #000000
    }
.default-light .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #cfcfcf;
        }
.default-light .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #e26816;
            color: #ffffff;
        }
.default-light .customer-accordion-item:hover .context-menu .menu-dot {
            color: #8fa61a;
        }
.default-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #919191
        }
.default-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #919191
        }
.default-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #8fa61a;
            }
.default-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #8fa61a;
            }
.default-light .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #cfcfcf;
    }
.sky .customer-accordion-item {
        background-color: #fcfcfc;
        border-top: 1px solid #c8c8c8;
        border-bottom: 1px solid #fcfcfc;
        color: #000000
    }
.sky .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #c8c8c8;
        }
.sky .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #01425e;
            color: #ffffff;
        }
.sky .customer-accordion-item:hover .context-menu .menu-dot {
            color: #235635;
        }
.sky .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #0194d3
        }
.sky .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #0194d3
        }
.sky .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #235635;
            }
.sky .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #235635;
            }
.sky .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #c8c8c8;
    }
.sky-light .customer-accordion-item {
        background-color: #fcfcfc;
        border-top: 1px solid #c8c8c8;
        border-bottom: 1px solid #fcfcfc;
        color: #000000
    }
.sky-light .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #c8c8c8;
        }
.sky-light .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #01425e;
            color: #ffffff;
        }
.sky-light .customer-accordion-item:hover .context-menu .menu-dot {
            color: #235635;
        }
.sky-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #0194d3
        }
.sky-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #0194d3
        }
.sky-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #235635;
            }
.sky-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #235635;
            }
.sky-light .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #c8c8c8;
    }
.grass .customer-accordion-item {
        background-color: #667c66;
        border-top: 1px solid #9cadb2;
        border-bottom: 1px solid #667c66;
        color: #ffffff
    }
.grass .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #9cadb2;
        }
.grass .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #e26816;
            color: #ffffff;
        }
.grass .customer-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.grass .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #325132
        }
.grass .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #325132
        }
.grass .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00;
            }
.grass .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00;
            }
.grass .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #9cadb2;
    }
.grass-light .customer-accordion-item {
        background-color: #babfba;
        border-top: 1px solid #424443;
        border-bottom: 1px solid #babfba;
        color: #000000
    }
.grass-light .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #424443;
        }
.grass-light .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #e26816;
            color: #ffffff;
        }
.grass-light .customer-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.grass-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #545f54
        }
.grass-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #545f54
        }
.grass-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00;
            }
.grass-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00;
            }
.grass-light .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #424443;
    }
.mud .customer-accordion-item {
        background-color: #4b3705;
        border-top: 1px solid #f2f3f8;
        border-bottom: 1px solid #4b3705;
        color: #ffffff
    }
.mud .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #f2f3f8;
        }
.mud .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #f39104;
            color: #ffffff;
        }
.mud .customer-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.mud .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #766146
        }
.mud .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #766146
        }
.mud .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00;
            }
.mud .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00;
            }
.mud .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #f2f3f8;
    }
.mud-light .customer-accordion-item {
        background-color: #fff0bb;
        border-top: 1px solid #d6c282;
        border-bottom: 1px solid #fff0bb;
        color: #000000
    }
.mud-light .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #d6c282;
        }
.mud-light .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #e26816;
            color: #ffffff;
        }
.mud-light .customer-accordion-item:hover .context-menu .menu-dot {
            color: #8fa61a;
        }
.mud-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #a37b42
        }
.mud-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #a37b42
        }
.mud-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #8fa61a;
            }
.mud-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #8fa61a;
            }
.mud-light .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #d6c282;
    }
.illini .customer-accordion-item {
        background-color: #00467f;
        border-top: 1px solid #f2f3f8;
        border-bottom: 1px solid #00467f;
        color: #ffffff
    }
.illini .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #f2f3f8;
        }
.illini .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #f39c11;
            color: #ffffff;
        }
.illini .customer-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.illini .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #636466
        }
.illini .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #636466
        }
.illini .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00;
            }
.illini .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00;
            }
.illini .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #f2f3f8;
    }
.illini-light .customer-accordion-item {
        background-color: #e1e6fa;
        border-top: 1px solid #abc8e2;
        border-bottom: 1px solid #e1e6fa;
        color: #000000
    }
.illini-light .customer-accordion-item.is-expanded {
            border-bottom: 1px solid #abc8e2;
        }
.illini-light .customer-accordion-item .customer-name-container .selected-field-count {
            background-color: #f39c11;
            color: #ffffff;
        }
.illini-light .customer-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.illini-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #375d81
        }
.illini-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #375d81
        }
.illini-light .customer-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00;
            }
.illini-light .customer-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00;
            }
.illini-light .accordion-item .customer-accordion-item.is-last {
        border-bottom: 1px solid #abc8e2;
    }

.action-panel .context-menu.field-context-menu {
    margin-top: 4px;
    height: 38px;
    line-height: 21px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.field-module .field-accordion-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}
.field-module .field-accordion-item .clip-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
.field-module .field-accordion-item .field-selected-column {
        width: 6px;
        padding-left: 12px;
        vertical-align: middle;
        padding-top: 7px
    }
.field-module .field-accordion-item .field-selected-column .field-selected-mark {
            width: 6px;
            height: 30px;
        }
.field-module .field-accordion-item .field-info {
        cursor: pointer;
        flex: 200 1 auto;
        padding: 4px 0 0 12px;
        max-width: 275px
    }
.field-module .field-accordion-item .field-info .field-name {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            line-height: 18px;
        }
.field-module .field-accordion-item .field-info .field-farm-acres {
            font-size: 12px;
            line-height: 20px;
            display: flex;
        }
.field-module .field-accordion-item .rec-count,
    .field-module .field-accordion-item .event-count {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        padding: 0 3px;
        width: 42px
    }
.field-module .field-accordion-item .rec-count > div, .field-module .field-accordion-item .event-count > div {
            text-align: center
        }
.field-module .field-accordion-item .rec-count > div:first-child, .field-module .field-accordion-item .event-count > div:first-child {
                line-height: 18px;
            }
.field-module .field-accordion-item .rec-count .units, .field-module .field-accordion-item .event-count .units {
            font-family: open_sansitalic;
            font-size: 11px;
            line-height: 14px;
            text-align: center;
        }
.field-module .field-accordion-item .field-context-menu-container {
        width: 23px
    }
.field-module .field-accordion-item .field-context-menu-container .field-context-menu.menu-dots {
            margin-top: 3px;
        }
.default .field-accordion-item {
        background-color: #404040;
        color: #ffffff;
        border-bottom: 1px solid #000000
    }
.default .field-accordion-item .context-menu .menu-dot {
            color: #404040 !important;
        }
.default .field-accordion-item .field-selected-mark {
            background-color: #bfbfbf;
        }
.default .field-accordion-item:hover {
            background-color: #262626
        }
.default .field-accordion-item:hover .context-menu .menu-dot {
                color: #8fa61a !important;
            }
.default .field-accordion-item.selected {
            background-color: #737373
        }
.default .field-accordion-item.selected .field-selected-mark {
                background-color: #8fa61a;
            }
.default .field-accordion-item.selected .context-menu .menu-dot {
                color: #737373 !important;
            }
.default .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #404040
        }
.default .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #404040
        }
.default .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #8fa61a !important;
            }
.default .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #8fa61a !important;
            }
.default-light .field-accordion-item {
        background-color: #919191;
        color: #000000;
        border-bottom: 1px solid #fcfcfc
    }
.default-light .field-accordion-item .context-menu .menu-dot {
            color: #919191 !important;
        }
.default-light .field-accordion-item .field-selected-mark {
            background-color: #cfcfcf;
        }
.default-light .field-accordion-item:hover {
            background-color: #e2e2e2
        }
.default-light .field-accordion-item:hover .context-menu .menu-dot {
                color: #8fa61a !important;
            }
.default-light .field-accordion-item.selected {
            background-color: #b8b8b8
        }
.default-light .field-accordion-item.selected .field-selected-mark {
                background-color: #8fa61a;
            }
.default-light .field-accordion-item.selected .context-menu .menu-dot {
                color: #b8b8b8 !important;
            }
.default-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #919191
        }
.default-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #919191
        }
.default-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #8fa61a !important;
            }
.default-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #8fa61a !important;
            }
.sky .field-accordion-item {
        background-color: #0194d3;
        color: #000000;
        border-bottom: 1px solid #fcfcfc
    }
.sky .field-accordion-item .context-menu .menu-dot {
            color: #0194d3 !important;
        }
.sky .field-accordion-item .field-selected-mark {
            background-color: #c8c8c8;
        }
.sky .field-accordion-item:hover {
            background-color: #b1b1b1
        }
.sky .field-accordion-item:hover .context-menu .menu-dot {
                color: #235635 !important;
            }
.sky .field-accordion-item.selected {
            background-color: #838383
        }
.sky .field-accordion-item.selected .field-selected-mark {
                background-color: #235635;
            }
.sky .field-accordion-item.selected .context-menu .menu-dot {
                color: #838383 !important;
            }
.sky .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #0194d3
        }
.sky .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #0194d3
        }
.sky .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #235635 !important;
            }
.sky .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #235635 !important;
            }
.sky-light .field-accordion-item {
        background-color: #0194d3;
        color: #000000;
        border-bottom: 1px solid #fcfcfc
    }
.sky-light .field-accordion-item .context-menu .menu-dot {
            color: #0194d3 !important;
        }
.sky-light .field-accordion-item .field-selected-mark {
            background-color: #c8c8c8;
        }
.sky-light .field-accordion-item:hover {
            background-color: #b1b1b1
        }
.sky-light .field-accordion-item:hover .context-menu .menu-dot {
                color: #235635 !important;
            }
.sky-light .field-accordion-item.selected {
            background-color: #838383
        }
.sky-light .field-accordion-item.selected .field-selected-mark {
                background-color: #235635;
            }
.sky-light .field-accordion-item.selected .context-menu .menu-dot {
                color: #838383 !important;
            }
.sky-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #0194d3
        }
.sky-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #0194d3
        }
.sky-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #235635 !important;
            }
.sky-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #235635 !important;
            }
.grass .field-accordion-item {
        background-color: #325132;
        color: #ffffff;
        border-bottom: 1px solid #667c66
    }
.grass .field-accordion-item .context-menu .menu-dot {
            color: #325132 !important;
        }
.grass .field-accordion-item .field-selected-mark {
            background-color: #9cadb2;
        }
.grass .field-accordion-item:hover {
            background-color: #424443
        }
.grass .field-accordion-item:hover .context-menu .menu-dot {
                color: #ffff00 !important;
            }
.grass .field-accordion-item.selected {
            background-color: #424443
        }
.grass .field-accordion-item.selected .field-selected-mark {
                background-color: #ffff00;
            }
.grass .field-accordion-item.selected .context-menu .menu-dot {
                color: #424443 !important;
            }
.grass .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #325132
        }
.grass .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #325132
        }
.grass .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00 !important;
            }
.grass .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00 !important;
            }
.grass-light .field-accordion-item {
        background-color: #545f54;
        color: #000000;
        border-bottom: 1px solid #babfba
    }
.grass-light .field-accordion-item .context-menu .menu-dot {
            color: #545f54 !important;
        }
.grass-light .field-accordion-item .field-selected-mark {
            background-color: #424443;
        }
.grass-light .field-accordion-item:hover {
            background-color: #9cadb2
        }
.grass-light .field-accordion-item:hover .context-menu .menu-dot {
                color: #ffff00 !important;
            }
.grass-light .field-accordion-item.selected {
            background-color: #bfbfbf
        }
.grass-light .field-accordion-item.selected .field-selected-mark {
                background-color: #ffff00;
            }
.grass-light .field-accordion-item.selected .context-menu .menu-dot {
                color: #bfbfbf !important;
            }
.grass-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #545f54
        }
.grass-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #545f54
        }
.grass-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00 !important;
            }
.grass-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00 !important;
            }
.mud .field-accordion-item {
        background-color: #766146;
        color: #ffffff;
        border-bottom: 1px solid #4b3705
    }
.mud .field-accordion-item .context-menu .menu-dot {
            color: #766146 !important;
        }
.mud .field-accordion-item .field-selected-mark {
            background-color: #f2f3f8;
        }
.mud .field-accordion-item:hover {
            background-color: #8c7454
        }
.mud .field-accordion-item:hover .context-menu .menu-dot {
                color: #ffff00 !important;
            }
.mud .field-accordion-item.selected {
            background-color: #ad8f68
        }
.mud .field-accordion-item.selected .field-selected-mark {
                background-color: #ffff00;
            }
.mud .field-accordion-item.selected .context-menu .menu-dot {
                color: #ad8f68 !important;
            }
.mud .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #766146
        }
.mud .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #766146
        }
.mud .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00 !important;
            }
.mud .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00 !important;
            }
.mud-light .field-accordion-item {
        background-color: #a37b42;
        color: #000000;
        border-bottom: 1px solid #fff0bb
    }
.mud-light .field-accordion-item .context-menu .menu-dot {
            color: #a37b42 !important;
        }
.mud-light .field-accordion-item .field-selected-mark {
            background-color: #d6c282;
        }
.mud-light .field-accordion-item:hover {
            background-color: #b28f5a
        }
.mud-light .field-accordion-item:hover .context-menu .menu-dot {
                color: #8fa61a !important;
            }
.mud-light .field-accordion-item.selected {
            background-color: #735523
        }
.mud-light .field-accordion-item.selected .field-selected-mark {
                background-color: #8fa61a;
            }
.mud-light .field-accordion-item.selected .context-menu .menu-dot {
                color: #735523 !important;
            }
.mud-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #a37b42
        }
.mud-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #a37b42
        }
.mud-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #8fa61a !important;
            }
.mud-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #8fa61a !important;
            }
.illini .field-accordion-item {
        background-color: #636466;
        color: #ffffff;
        border-bottom: 1px solid #00467f
    }
.illini .field-accordion-item .context-menu .menu-dot {
            color: #636466 !important;
        }
.illini .field-accordion-item .field-selected-mark {
            background-color: #f2f3f8;
        }
.illini .field-accordion-item:hover {
            background-color: #abc8e2
        }
.illini .field-accordion-item:hover .context-menu .menu-dot {
                color: #ffff00 !important;
            }
.illini .field-accordion-item.selected {
            background-color: #3698db
        }
.illini .field-accordion-item.selected .field-selected-mark {
                background-color: #ffff00;
            }
.illini .field-accordion-item.selected .context-menu .menu-dot {
                color: #3698db !important;
            }
.illini .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #636466
        }
.illini .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #636466
        }
.illini .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00 !important;
            }
.illini .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00 !important;
            }
.illini-light .field-accordion-item {
        background-color: #375d81;
        color: #000000;
        border-bottom: 1px solid #e1e6fa
    }
.illini-light .field-accordion-item .context-menu .menu-dot {
            color: #375d81 !important;
        }
.illini-light .field-accordion-item .field-selected-mark {
            background-color: #abc8e2;
        }
.illini-light .field-accordion-item:hover {
            background-color: #c4d7ed
        }
.illini-light .field-accordion-item:hover .context-menu .menu-dot {
                color: #ffff00 !important;
            }
.illini-light .field-accordion-item.selected {
            background-color: #00467f
        }
.illini-light .field-accordion-item.selected .field-selected-mark {
                background-color: #ffff00;
            }
.illini-light .field-accordion-item.selected .context-menu .menu-dot {
                color: #00467f !important;
            }
.illini-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover {
            background-color: #375d81
        }
.illini-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded {
            background-color: #375d81
        }
.illini-light .field-accordion-item .context-menu.customer-context-menu.menu-dots:hover .menu-dot {
                color: #ffff00 !important;
            }
.illini-light .field-accordion-item .context-menu.customer-context-menu.menu-expanded .menu-dot {
                color: #ffff00 !important;
            }
.sky .field-accordion-item {
        background-color: #c8c8c8
    }
.sky .field-accordion-item.selected {
            color: #ffffff;
        }
.sky-light .field-accordion-item {
        background-color: #c8c8c8
    }
.sky-light .field-accordion-item.selected {
            color: #ffffff;
        }

.infinite-scroll-trigger {
    display: flex;
    flex-flow: column;
    height: 100%;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.move-fields-modal .mf-row {
        display: flex;
    }
.move-fields-modal .mf-col {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 250px;
        padding: 0 10px
    }
.move-fields-modal .mf-col .auto-search {
            padding-top: 10px;
        }
.move-fields-modal .mf-header {
        font-size: 15px;
        line-height: 24px;
        text-align: center;
    }
.move-fields-modal .mf-content {
        height: 150px;
        margin-left: 15px;
    }
.move-fields-modal .mf-field-list-item {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
.move-fields-modal .select-form-input-container {
        width: 300px;
    }
.default .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #ffffff;
        }
.default .move-fields-modal .mf-header {
            border-bottom: solid 1px #ffffff;
        }
.default-light .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #000000;
        }
.default-light .move-fields-modal .mf-header {
            border-bottom: solid 1px #000000;
        }
.sky .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #000000;
        }
.sky .move-fields-modal .mf-header {
            border-bottom: solid 1px #000000;
        }
.sky-light .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #000000;
        }
.sky-light .move-fields-modal .mf-header {
            border-bottom: solid 1px #000000;
        }
.grass .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #ffffff;
        }
.grass .move-fields-modal .mf-header {
            border-bottom: solid 1px #ffffff;
        }
.grass-light .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #000000;
        }
.grass-light .move-fields-modal .mf-header {
            border-bottom: solid 1px #000000;
        }
.mud .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #ffffff;
        }
.mud .move-fields-modal .mf-header {
            border-bottom: solid 1px #ffffff;
        }
.mud-light .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #000000;
        }
.mud-light .move-fields-modal .mf-header {
            border-bottom: solid 1px #000000;
        }
.illini .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #ffffff;
        }
.illini .move-fields-modal .mf-header {
            border-bottom: solid 1px #ffffff;
        }
.illini-light .move-fields-modal .mf-col:last-child {
            border-left: solid 1px #000000;
        }
.illini-light .move-fields-modal .mf-header {
            border-bottom: solid 1px #000000;
        }


    .agvance-search-option .first-line {
        font-size: 13px;
        padding-left: 5px;
        height: 50%
    }
.agvance-search-option .first-line span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
.agvance-search-option .second-line {
        padding-left: 5px;
        font-size: 11px;
        font-family: "open_sansitalic"
    }
.agvance-search-option .second-line span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

.combine-fields-modal {
    width: 30rem
}
.combine-fields-modal .combine-field-list {
        margin: 1rem 0 1rem 1rem;
    }
.combine-fields-modal .new-field-details {
        width: 15rem;
    }
.combine-fields-modal .combine-fields-warning {
        margin-top: 1rem;
        font-weight: bold;
    }
.combine-fields-modal .combine-fields-error {
        margin-top: 1rem;
        font-weight: bold;
        color: red;
    }


    .field-module .field-filter-tabs {
        flex-grow: 1;
        display: flex;
        flex-direction: column
    }
.field-module .field-filter-tabs .tabs {
            flex-grow: 1;
            display: flex;
            flex-direction: column
        }
.field-module .field-filter-tabs .tabs .tabs-labels {
                height: 36px;
                min-height: 36px;
                padding-bottom: 4px
            }
.field-module .field-filter-tabs .tabs .tabs-labels li.tab-label {
                    flex-grow: inherit;
                    width: 75px;
                }
.field-module .field-filter-tabs .tabs .tabs-labels li.tab-label:not(.right-non-label) {
                    line-height: 38px;
                    padding-top: 0px;
                    font-size: 13px;
                }
.field-module .field-filter-tabs .tabs .tabs-labels li.tab-label.left-non-label {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row-reverse;
                }
.field-module .field-filter-tabs .tabs .tabs-labels li.tab-label.right-non-label {
                    width: 25px;
                }
.field-module .field-filter-tabs .tabs .tabs-labels .context-menu-container {
                    float: right;
                }
.field-module .field-filter-tabs .tabs .tabs-pane {
                flex-grow: 1;
                display: flex;
                flex-direction: column
            }
.field-module .field-filter-tabs .tabs .tabs-pane .field-accordion-container {
                    flex-grow: 1;
                }

.field-filter.blownup {
    width: 321px
}
.field-filter.blownup .dialog-box-body {
        overflow: visible;
    }
.field-filter.blownup .filter-location .form-input {
        }
.field-filter.blownup .multi-select-container {
        margin-top: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        padding-right: 0;
        width: auto;
        width: initial
    }
.field-filter.blownup .multi-select-container .select-form-input-multi.form-input {
            margin: 0;
        }
.field-filter.blownup .multi-select-container .dropdown-menu {
            width: 277px;
        }
.field-filter.blownup .multi-select-container.menu-open .form-input {
                width: auto;
                width: initial;
            }
.field-filter.blownup .dialog-box-children > a {
        display: inline-block;
        margin: 10px 20px 20px;
    }

.field-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: 15px
}
.field-list .field-list-fill {
        flex-grow: 1;
    }
.field-list .displayed {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
.field-list .not-displayed {
        display: none;
    }
.field-list .module-filter-row {
        justify-content: flex-start;
    }
.field-list .module-search {
        max-width: 341px;
    }
.field-list .module-filters {
        height: auto;
        min-height: auto;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.title-bar {
    min-height: 60px;
    padding: 4px 5px 0 15px
}
.title-bar .first-line {
        font-size: 11pt;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
    }
.title-bar .second-line {
        font-size: 10pt;
        font-style: italic;
        height: 18px;
        line-height: 18px;
    }
.title-bar .third-line {
        font-size: 9pt;
        height: 18px;
        line-height: 18px;
    }
.default .title-bar {
        background-color: #000000;
        border-bottom: 1px solid #bfbfbf;
        color: #ffffff;
    }
.default-light .title-bar {
        background-color: #fcfcfc;
        border-bottom: 1px solid #cfcfcf;
        color: #000000;
    }
.sky .title-bar {
        background-color: #fcfcfc;
        border-bottom: 1px solid #c8c8c8;
        color: #000000;
    }
.sky-light .title-bar {
        background-color: #fcfcfc;
        border-bottom: 1px solid #c8c8c8;
        color: #000000;
    }
.grass .title-bar {
        background-color: #667c66;
        border-bottom: 1px solid #9cadb2;
        color: #ffffff;
    }
.grass-light .title-bar {
        background-color: #babfba;
        border-bottom: 1px solid #424443;
        color: #000000;
    }
.mud .title-bar {
        background-color: #4b3705;
        border-bottom: 1px solid #f2f3f8;
        color: #ffffff;
    }
.mud-light .title-bar {
        background-color: #fff0bb;
        border-bottom: 1px solid #d6c282;
        color: #000000;
    }
.illini .title-bar {
        background-color: #00467f;
        border-bottom: 1px solid #f2f3f8;
        color: #ffffff;
    }
.illini-light .title-bar {
        background-color: #e1e6fa;
        border-bottom: 1px solid #abc8e2;
        color: #000000;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.information-menu {
    min-width: 60px;
    height: 100%;
    display: inline-block
}
.information-menu .information-menu-tab {
        height: 80px;
        width: 100%;
        cursor: pointer
    }
.information-menu .information-menu-tab .information-menu-tab-text {
            font-size: 10pt;
            text-align: center;
            width: 100%;
            -webkit-user-select: none;
                -ms-user-select: none;
                    user-select: none;
        }
.information-menu .side-menu-icon {
        height: 37px;
        width: 37px;
        margin: 11px 11px 7px;
    }
.default .information-menu {
        background-color: #000000
    }
.default .information-menu .active-tab {
            background-color: #404040;
            color: #8fa61a
        }
.default .information-menu .active-tab .side-menu-icon {
                fill: #8fa61a;
            }
.default .information-menu .general-tab.error {
            color: #ff0000
        }
.default .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.default-light .information-menu {
        background-color: #fcfcfc
    }
.default-light .information-menu .active-tab {
            background-color: #919191;
            color: #8fa61a
        }
.default-light .information-menu .active-tab .side-menu-icon {
                fill: #8fa61a;
            }
.default-light .information-menu .general-tab.error {
            color: #ff0000
        }
.default-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.sky .information-menu {
        background-color: #fcfcfc
    }
.sky .information-menu .active-tab {
            background-color: #0194d3;
            color: #235635
        }
.sky .information-menu .active-tab .side-menu-icon {
                fill: #235635;
            }
.sky .information-menu .general-tab.error {
            color: #ff0000
        }
.sky .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.sky-light .information-menu {
        background-color: #fcfcfc
    }
.sky-light .information-menu .active-tab {
            background-color: #0194d3;
            color: #235635
        }
.sky-light .information-menu .active-tab .side-menu-icon {
                fill: #235635;
            }
.sky-light .information-menu .general-tab.error {
            color: #ff0000
        }
.sky-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.grass .information-menu {
        background-color: #667c66
    }
.grass .information-menu .active-tab {
            background-color: #325132;
            color: #ffff00
        }
.grass .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.grass .information-menu .general-tab.error {
            color: #ff0000
        }
.grass .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.grass-light .information-menu {
        background-color: #babfba
    }
.grass-light .information-menu .active-tab {
            background-color: #545f54;
            color: #ffff00
        }
.grass-light .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.grass-light .information-menu .general-tab.error {
            color: #ff0000
        }
.grass-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.mud .information-menu {
        background-color: #4b3705
    }
.mud .information-menu .active-tab {
            background-color: #766146;
            color: #ffff00
        }
.mud .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.mud .information-menu .general-tab.error {
            color: #ff0000
        }
.mud .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.mud-light .information-menu {
        background-color: #fff0bb
    }
.mud-light .information-menu .active-tab {
            background-color: #a37b42;
            color: #8fa61a
        }
.mud-light .information-menu .active-tab .side-menu-icon {
                fill: #8fa61a;
            }
.mud-light .information-menu .general-tab.error {
            color: #ff0000
        }
.mud-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.illini .information-menu {
        background-color: #00467f
    }
.illini .information-menu .active-tab {
            background-color: #636466;
            color: #ffff00
        }
.illini .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.illini .information-menu .general-tab.error {
            color: #ff0000
        }
.illini .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.illini-light .information-menu {
        background-color: #e1e6fa
    }
.illini-light .information-menu .active-tab {
            background-color: #375d81;
            color: #ffff00
        }
.illini-light .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.illini-light .information-menu .general-tab.error {
            color: #ff0000
        }
.illini-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.sky .information-menu .active-tab {
        background-color: #e2e2e2;
    }
.sky-light .information-menu .active-tab {
        background-color: #e2e2e2;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.information-detail-container {
    padding-top: 16px;
    flex: 1 1;
    width: 84%;

    overflow: auto
}
.information-detail-container .information-detail {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between
    }
.information-detail-container .information-detail .detail-n-list {
            width: 100%;
            margin-top: 15px;
            height: 40px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center
        }
.information-detail-container .information-detail .detail-n-list .trashcan-icon-container {
                cursor: pointer;
                width: 28px;
                height: 28px
            }
.information-detail-container .information-detail .detail-n-list .trashcan-icon-container .trashcan-icon {
                    width: 27px;
                    height: 27px;
                }
.information-detail-container .information-detail .detail-n-list .data-container {
                display: flex;
                flex-flow: column nowrap;
                padding-left: 10px
            }
.information-detail-container .information-detail .detail-n-list .data-container .first-row {
                    font-weight: 700;
                }
.information-detail-container .information-detail .detail-n-list .data-container .second-row {
                    font-size: 12px;
                    line-height: 17px;
                }
.information-detail-container .information-detail .form-input {
            margin: 4px 8px 4px 0px;
        }
.information-detail-container .information-detail .select-form-options {
            margin-top: -8px;
            float: left;
        }
.information-detail-container .information-detail .multi-select-container {
            width: 53%;
            margin-top: 5px;
            margin-bottom: 7px
        }
.information-detail-container .information-detail .multi-select-container .form-input {
                padding-bottom: 0px;
                position: relative;
                top: -2px;
            }
.information-detail-container .information-detail .menu-open .dropdown-menu {
                position: absolute;
                margin-top: -7px;
            }
.information-detail-container .information-detail .detail-header {
            width: 100%;
            padding-left: 5px;
            padding-top: 10px;
        }
.information-detail-container .information-detail .detail-bucket .bucket-content {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
            }
.information-detail-container .information-detail .checkbox-div {
            margin-top: 2px;
            margin-right: 0;
            max-width: 46%;
            font-size: 13px;
        }
.information-detail-container .information-detail .high-risk-land {
            margin-top: 14px;
            margin-bottom: 4px;
            margin-left: 0px;
            width: 100%;
        }
.information-detail-container .information-detail .text-form-input:not(.date-form-input) {
            width: 43%;
            max-width: 43%;
        }
.information-detail-container .information-detail .select-form-input-container {
            width: 48%;
            max-width: 48%
        }
.information-detail-container .information-detail .select-form-input-container .select-form-input {
                width: 90%;
                max-width: 90%;
            }
.information-detail-container .information-detail .insurance-per-crop {
            display: flex;
            flex-flow: row wrap
        }
.information-detail-container .information-detail .insurance-per-crop .select-form-input-container {
                width: 32%;
                max-width: 32%
            }
.information-detail-container .information-detail .insurance-per-crop .select-form-input-container .select-form-input {
                    width: 85%;
                    max-width: 85%;
                }
.information-detail-container .information-detail .date-form-input-container {
            width: 48%;
            max-width: 48%
        }
.information-detail-container .information-detail .date-form-input-container .date-form-input input {
                width: 75%;
            }
.information-detail-container .information-detail .textarea-form-input {
            max-width: 91%;
            height: 75px;
        }
.information-detail-container .information-detail .general-info-bucket {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between
        }
.information-detail-container .information-detail .general-info-bucket .text-form-input:not(.date-form-input) {
                width: 50%;
                max-width: 50%;
            }
.information-detail-container .information-detail .general-info-bucket .select-form-input-container {
                width: 55%;
                max-width: 55%
            }
.information-detail-container .information-detail .general-info-bucket .select-form-input-container .select-form-input {
                    width: 91%;
                    max-width: 91%;
                }
.information-detail-container .information-detail .general-info-bucket .select-form-input-container {
                width: 55%;
                max-width: 55%
            }
.information-detail-container .information-detail .general-info-bucket .select-form-input-container .select-form-input {
                    width: 91%;
                    max-width: 91%;
                }
.information-detail-container .information-detail .general-info-bucket .select-form-input-container {
                margin-bottom: 3px;
                margin-top: 3px;
            }
.information-detail-container .information-detail .general-info-bucket .select-form-input,
            .information-detail-container .information-detail .general-info-bucket .select-form-input-options {
                margin: 0;
            }
.information-detail-container .information-detail .general-info-bucket .checkbox-div {
                width: 40%;
                max-width: 40%;
            }
.information-detail-container .information-detail .listAddNoLink {
            margin-top: 5px;
            margin-right: 100px;
        }
.default .information-detail-container {
        background-color: #404040
    }
.default .information-detail-container .label-error {
            color: #ff0000;
        }
.default .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.default .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #ffffff;
                }
.default-light .information-detail-container {
        background-color: #919191
    }
.default-light .information-detail-container .label-error {
            color: #ff0000;
        }
.default-light .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.default-light .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #000000;
                }
.sky .information-detail-container {
        background-color: #0194d3
    }
.sky .information-detail-container .label-error {
            color: #ff0000;
        }
.sky .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.sky .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #000000;
                }
.sky-light .information-detail-container {
        background-color: #0194d3
    }
.sky-light .information-detail-container .label-error {
            color: #ff0000;
        }
.sky-light .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.sky-light .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #000000;
                }
.grass .information-detail-container {
        background-color: #325132
    }
.grass .information-detail-container .label-error {
            color: #ff0000;
        }
.grass .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.grass .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #ffffff;
                }
.grass-light .information-detail-container {
        background-color: #545f54
    }
.grass-light .information-detail-container .label-error {
            color: #ff0000;
        }
.grass-light .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.grass-light .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #000000;
                }
.mud .information-detail-container {
        background-color: #766146
    }
.mud .information-detail-container .label-error {
            color: #ff0000;
        }
.mud .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.mud .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #ffffff;
                }
.mud-light .information-detail-container {
        background-color: #a37b42
    }
.mud-light .information-detail-container .label-error {
            color: #ff0000;
        }
.mud-light .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.mud-light .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #000000;
                }
.illini .information-detail-container {
        background-color: #636466
    }
.illini .information-detail-container .label-error {
            color: #ff0000;
        }
.illini .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.illini .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #ffffff;
                }
.illini-light .information-detail-container {
        background-color: #375d81
    }
.illini-light .information-detail-container .label-error {
            color: #ff0000;
        }
.illini-light .information-detail-container .information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.illini-light .information-detail-container .information-detail .detail-n-list .trashcan-icon {
                    fill: #000000;
                }
.sky .information-detail-container {
        background-color: #e2e2e2;
    }
.sky-light .information-detail-container {
        background-color: #e2e2e2;
    }


    .field-boundary-transfer .modal-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
.field-boundary-transfer .dialog-window {
        position: absolute !important
    }
.field-boundary-transfer .dialog-window .dialog-box-children .field-boundary-transfer-action-description {
                font-style: italic;
                padding-bottom: 15px;
                text-align: center;
                width: 260px;
            }
.field-boundary-transfer .dialog-window .dialog-box-children .field-boundary-transfer-radio-group {
                align-items: center;
                display: flex;
                flex-direction: row;
                grid-gap: 25px;
                gap: 25px;
                justify-content: center
            }
.field-boundary-transfer .dialog-window .dialog-box-children .field-boundary-transfer-radio-group .radio-div {
                    width: unset;
                }

.information-button-bar {
    max-height: 60px;
    min-height: 60px;
    width: 100%;
    text-align: center;
    padding-top: 15px
}
.information-button-bar .default-button {
        margin: 9px;
    }

.field-information {
    flex: 100 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap
}
.field-information .field-information-content {
        flex-grow: 100;
        height: 0;
        display: flex;
        flex-flow: row nowrap
    }
.field-information .field-information-content .content-table-container.with-api-result {
            display: flex;
            flex-grow: 1;
        }


    .field-resurface-modal .dialog-container {
        width: 30%
    }
.field-resurface-modal .dialog-container .resurfaceMessage {
            padding: 0 0 1.5rem 0;
        }
.field-resurface-modal .dialog-container .resurfaceEventList {
            margin: 0.5rem 1rem 0.75rem 0
        }
.field-resurface-modal .dialog-container .resurfaceEventList .resurfaceEventGroupHeader {
                margin-bottom: 0.5rem;
            }
.field-resurface-modal .dialog-container .resurfaceEventList .resurfaceEventGroupList {
                margin-left: 1rem;
                margin-bottom: 0.5rem;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0
}
.rec-event-info .item-container .rec-event-accordion-item {
            position: static;
            height: 40px;
        }
.rec-event-info .item-container.batch-strip .spacer {
                display: none;
            }
.rec-event-info .item-container.batch-rec-event-strip {
            cursor: pointer;
        }
.rec-event-info .tabs.information-tabs {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        min-height: 0
    }
.rec-event-info .tabs.information-tabs .loader-container {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.rec-event-info .tabs.information-tabs > .tabs-labels {
            flex-direction: column;
            overflow: hidden;
            min-width: 60px
        }
.rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label,
            .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.right-non-label {
                padding: 0;
                flex-grow: inherit;
                border-bottom: none;
                height: 80px;
                min-height: 80px;
                width: 60px
            }
.rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .default-svg-icon, .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.right-non-label .default-svg-icon {
                    height: 32px;
                    width: 32px;
                }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label {
                padding: 12px 0 6px 0;
                height: 62px;
                width: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                font-size: 12px;
                position: relative
            }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label .remove-btn {
                    position: absolute;
                    right: 3px;
                    top: 5px
                }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label .remove-btn .default-svg-icon {
                        height: 10px;
                        width: 10px;
                    }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label.add-item {
                    padding: 0;
                    height: 80px;
                    justify-content: flex-start;
                    align-items: center
                }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label.add-item .menu-dots {
                        margin-top: 15px;
                        height: 25px;
                        width: 25px;
                        background-color: transparent
                    }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label.add-item .menu-dots .svg-container .default-svg-icon {
                                height: 25px;
                                width: 25px;
                                margin-bottom: 10px;
                            }
.rec-event-info .tabs.information-tabs > .tabs-labels .rec-event-info-tab-label.add-item .menu-item {
                        font-size: 12px;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane {
            flex-grow: 1;
            min-width: 50px;
            display: flex;
            flex-direction: column
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form {
                flex-grow: 1;
                overflow-y: auto;
                overflow-x: hidden;
                height: 10px
            }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .form-input {
                    margin: 5px 3px
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .form-input.select-form-input-options {
                        margin: 0 3px;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .multi-select-container {
                    margin: 5px 3px
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .multi-select-container .form-input {
                        margin: 0;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .multi-select-container.menu-open .form-input {
                    width: unset;
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket,
                .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket-header,
                .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket-content {
                    width: inherit;
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket {
                    padding: 0 5px;
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket-header {
                    padding-left: 5px;
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row {
                    width: 100%;
                    display: flex;
                    flex-direction: row
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > * {
                        flex-grow: 1;
                        width: 20px;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row .width-40 {
                        width: 40%;
                        flex-grow: 0;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row .width-50 {
                        width: 50%;
                        flex-grow: 0;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row .width-25 {
                        width: 25%;
                        flex-grow: 0;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row .width-20 {
                        width: 20%;
                        flex-grow: 0;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row .width-30 {
                        width: 30%;
                        flex-grow: 0;
                    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row.half {
                    width: 50%;
                }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row.col-3 {
                    width: 33%;
                }
.rec-event-info .save-cancel-btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px;
        min-height: 34px;
        overflow: hidden
    }
.rec-event-info .save-cancel-btns .default-button {
            margin: 0 7px;
        }
.rec-event-info .equation-rec-loader {
        height: calc(100% - 110px);
        top: 50px !important;
    }
.error-field-list {
    padding-top: 15px;
}
.default .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #000000;
        }
.default .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #737373
        }
.default .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #737373;
            }
.default .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #000000
            }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #000000
                }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #404040;
                        color: #8fa61a
                    }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #8fa61a;
                        }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff
                    }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #ffffff;
                        }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #ffffff;
                    }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.default .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.default .rec-event-info .rec-event-info-form {
            background-color: #404040
        }
.default .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.default .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #ffffff;
            }
.default-light .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #fcfcfc;
        }
.default-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #b8b8b8
        }
.default-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #b8b8b8;
            }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #fcfcfc
            }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #fcfcfc
                }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #919191;
                        color: #8fa61a
                    }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #8fa61a;
                        }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #000000
                    }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #000000;
                        }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #000000;
                    }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.default-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.default-light .rec-event-info .rec-event-info-form {
            background-color: #919191
        }
.default-light .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.default-light .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #000000;
            }
.sky .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #fcfcfc;
        }
.sky .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #838383
        }
.sky .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #838383;
            }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #fcfcfc
            }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #fcfcfc
                }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #0194d3;
                        color: #235635
                    }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #235635;
                        }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #000000
                    }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #000000;
                        }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #000000;
                    }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.sky .rec-event-info .rec-event-info-form {
            background-color: #0194d3
        }
.sky .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.sky .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #000000;
            }
.sky-light .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #fcfcfc;
        }
.sky-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #838383
        }
.sky-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #838383;
            }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #fcfcfc
            }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #fcfcfc
                }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #0194d3;
                        color: #235635
                    }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #235635;
                        }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #000000
                    }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #000000;
                        }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #000000;
                    }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.sky-light .rec-event-info .rec-event-info-form {
            background-color: #0194d3
        }
.sky-light .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.sky-light .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #000000;
            }
.grass .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #667c66;
        }
.grass .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #424443
        }
.grass .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #424443;
            }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #667c66
            }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #667c66
                }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #325132;
                        color: #ffff00
                    }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #ffff00;
                        }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff
                    }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #ffffff;
                        }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #ffffff;
                    }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.grass .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.grass .rec-event-info .rec-event-info-form {
            background-color: #325132
        }
.grass .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.grass .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #ffffff;
            }
.grass-light .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #babfba;
        }
.grass-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #bfbfbf
        }
.grass-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #bfbfbf;
            }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #babfba
            }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #babfba
                }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #545f54;
                        color: #ffff00
                    }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #ffff00;
                        }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #000000
                    }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #000000;
                        }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #000000;
                    }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.grass-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.grass-light .rec-event-info .rec-event-info-form {
            background-color: #545f54
        }
.grass-light .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.grass-light .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #000000;
            }
.mud .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #4b3705;
        }
.mud .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #ad8f68
        }
.mud .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #ad8f68;
            }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #4b3705
            }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #4b3705
                }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #766146;
                        color: #ffff00
                    }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #ffff00;
                        }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff
                    }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #ffffff;
                        }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #ffffff;
                    }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.mud .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.mud .rec-event-info .rec-event-info-form {
            background-color: #766146
        }
.mud .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.mud .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #ffffff;
            }
.mud-light .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #fff0bb;
        }
.mud-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #735523
        }
.mud-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #735523;
            }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #fff0bb
            }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #fff0bb
                }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #a37b42;
                        color: #8fa61a
                    }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #8fa61a;
                        }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #000000
                    }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #000000;
                        }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #000000;
                    }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.mud-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.mud-light .rec-event-info .rec-event-info-form {
            background-color: #a37b42
        }
.mud-light .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.mud-light .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #000000;
            }
.illini .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #00467f;
        }
.illini .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #3698db
        }
.illini .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #3698db;
            }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #00467f
            }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #00467f
                }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #636466;
                        color: #ffff00
                    }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #ffff00;
                        }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff
                    }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #ffffff;
                        }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #ffffff;
                    }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.illini .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.illini .rec-event-info .rec-event-info-form {
            background-color: #636466
        }
.illini .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.illini .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #ffffff;
            }
.illini-light .rec-event-info .item-container:not(.batch-rec-event-strip) .rec-event-accordion-item {
            background-color: #e1e6fa;
        }
.illini-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item {
            background-color: #00467f
        }
.illini-light .rec-event-info .item-container.batch-rec-event-strip .rec-event-accordion-item:hover {
                background-color: #00467f;
            }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels {
                background-color: #e1e6fa
            }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label {
                    background-color: #e1e6fa
                }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
                        background-color: #375d81;
                        color: #ffff00
                    }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active .default-svg-icon {
                            fill: #ffff00;
                        }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) {
                        color: #000000
                    }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label:not(.active) .default-svg-icon {
                            fill: #000000;
                        }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .remove-btn .default-svg-icon {
                        fill: #000000;
                    }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error {
                        color: #ff0000
                    }
.illini-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label .rec-event-info-tab-label.has-error .default-svg-icon {
                            fill: #ff0000;
                        }
.illini-light .rec-event-info .rec-event-info-form {
            background-color: #375d81
        }
.illini-light .rec-event-info .rec-event-info-form .select-form-input-error .text-form-input {
                /* TODO: enable just applying `form-input-error` to `<SelectInput />` */
                border: 2px solid #ff0000;
            }
.illini-light .rec-event-info .rec-event-info-form .bucket-arrow-icon {
                fill: #000000;
            }
.sky .rec-event-info .rec-event-info-form {
            background-color: #e2e2e2;
        }
.sky .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
            background-color: #e2e2e2;
        }
.sky-light .rec-event-info .rec-event-info-form {
            background-color: #e2e2e2;
        }
.sky-light .rec-event-info .tabs.information-tabs > .tabs-labels li.tab-label.active {
            background-color: #e2e2e2;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.scouting-template-dialog {
    flex-flow: column nowrap;
    left: 35%;
    max-width: 60em;
    min-height: 47em;
    min-width: 60em;
    position: fixed !important;
    width: 60em
}
.scouting-template-dialog .dialog-box-body {
        margin-top: 0em;
    }
.scouting-template-dialog .section-container {
        margin-bottom: 0;
    }
.scouting-template-dialog .scouting-template-content {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
.scouting-template-dialog .scouting-template-top-bar {
        width: 100%;
        display: flex;
        flex-flow: row nowrap
    }
.scouting-template-dialog .scouting-template-top-bar .reset-container {
            margin-right: 1em;
            width: 50%;
            text-align: right;
            line-height: 3em;
        }
.scouting-template-dialog .scouting-template-top-bar .allow-photo-container {
            margin-top: 1.2em;
            margin-left: 0.2em;
        }
.scouting-template-dialog .bucket {
        display: flex;
        flex-flow: column nowrap
    }
.scouting-template-dialog .bucket .bucket-header {
            height: 35px;
            line-height: 35px;
            margin-bottom: 5px;
            display: flex;
        }
.scouting-template-dialog .bucket .bucket-content {
            max-width: 100%;
        }
.scouting-template-dialog .bucket .main-bucket-content {
            width: 100%;
        }
.scouting-template-dialog .bucket .photos-checkbox {
            padding-left: 2em;
            padding-top: 0.5em;
            margin-top: 1.2em;
        }
.scouting-template-dialog .bucket .template-input-row {
            display: flex
        }
.scouting-template-dialog .bucket .template-input-row .template-input-item {
                display: flex;
                flex-flow: row nowrap;
                margin-right: 1em;
                max-width: 32%
            }
.scouting-template-dialog .bucket .template-input-row .template-input-item .checkbox-div {
                    width: 2em;
                    padding-top: 1.5em;
                }
.scouting-template-dialog .bucket .template-input-row .template-input-item .template-input-numeric,
                .scouting-template-dialog .bucket .template-input-row .template-input-item .template-input-text {
                    width: 160px;
                    padding-right: 11px;
                }
.scouting-template-dialog .bucket .template-input-row .template-input-item .select-form-input-container {
                    max-width: 184px;
                }
.scouting-template-dialog .bucket .template-input-row .no-toggle {
                margin-left: 2em;
                max-width: 28%;
            }
.scouting-template-dialog .bucket .child-row {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }
.scouting-template-dialog .bucket .delete-link-container {
            padding-right: 1em;
            padding-bottom: 0;
        }
.scouting-template-dialog .bucket .add-link-container {
            padding-top: 0.5em;
        }
.scouting-template-dialog .bucket .scouting-template-observations {
            display: flex;
            flex-flow: column nowrap
        }
.scouting-template-dialog .bucket .scouting-template-observations .template-observation-header {
                font-weight: bold;
                padding-bottom: 1em;
            }
.scouting-template-dialog .bucket .scouting-template-observations .no-toggle {
                margin-left: 2em;
            }
.default .scouting-template-dialog .bucket-header {
            background-color: #404040;
            color: #ffffff;
        }
.default .scouting-template-dialog .child-row {
            border-top: 2px solid #777777;
            border-bottom: 2px solid #777777;
        }
.default-light .scouting-template-dialog .bucket-header {
            background-color: #919191;
            color: #ffffff;
        }
.default-light .scouting-template-dialog .child-row {
            border-top: 2px solid #777777;
            border-bottom: 2px solid #777777;
        }
.sky .scouting-template-dialog .bucket-header {
            background-color: #0194d3;
            color: #ffffff;
        }
.sky .scouting-template-dialog .child-row {
            border-top: 2px solid #878787;
            border-bottom: 2px solid #878787;
        }
.sky-light .scouting-template-dialog .bucket-header {
            background-color: #0194d3;
            color: #ffffff;
        }
.sky-light .scouting-template-dialog .child-row {
            border-top: 2px solid #878787;
            border-bottom: 2px solid #878787;
        }
.grass .scouting-template-dialog .bucket-header {
            background-color: #325132;
            color: #ffffff;
        }
.grass .scouting-template-dialog .child-row {
            border-top: 2px solid #e5e9e5;
            border-bottom: 2px solid #e5e9e5;
        }
.grass-light .scouting-template-dialog .bucket-header {
            background-color: #545f54;
            color: #ffffff;
        }
.grass-light .scouting-template-dialog .child-row {
            border-top: 2px solid #e5e9e5;
            border-bottom: 2px solid #e5e9e5;
        }
.mud .scouting-template-dialog .bucket-header {
            background-color: #766146;
            color: #ffffff;
        }
.mud .scouting-template-dialog .child-row {
            border-top: 2px solid #777777;
            border-bottom: 2px solid #777777;
        }
.mud-light .scouting-template-dialog .bucket-header {
            background-color: #a37b42;
            color: #ffffff;
        }
.mud-light .scouting-template-dialog .child-row {
            border-top: 2px solid #777777;
            border-bottom: 2px solid #777777;
        }
.illini .scouting-template-dialog .bucket-header {
            background-color: #636466;
            color: #ffffff;
        }
.illini .scouting-template-dialog .child-row {
            border-top: 2px solid #777777;
            border-bottom: 2px solid #777777;
        }
.illini-light .scouting-template-dialog .bucket-header {
            background-color: #375d81;
            color: #ffffff;
        }
.illini-light .scouting-template-dialog .child-row {
            border-top: 2px solid #777777;
            border-bottom: 2px solid #777777;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info-form .event-zone-info {
        display: flex;
        align-items: center;
        padding: 15px 20px 10px 5px
    }
.rec-event-info-form .event-zone-info div {
            vertical-align: middle;
        }
.rec-event-info-form .event-zone-info .no-zone-msg {
            font-size: 14px;
            padding-left: 15px;
        }
.rec-event-info-form .event-zone-info .zone-info-heading {
            flex-grow: 1;
            display: flex;
            align-items: center;
            height: 24px
        }
.rec-event-info-form .event-zone-info .zone-info-heading .default-svg-icon {
                height: 15px;
                width: 15px;
                margin-left: 5px;
                margin-right: 12px;
            }
.rec-event-info-form .event-zone-info .zone-info-heading .zone-name {
                font-size: 14px;
                font-weight: 700;
            }
.rec-event-info-form .event-zone-info .zone-info-heading .zone-size {
                padding-left: 5px;
                font-size: 14px;
                font-weight: 300;
            }
.rec-event-info-form .event-zone-info .copy-btn,
        .rec-event-info-form .event-zone-info .clear-btn {
            flex-grow: 0;
            cursor: pointer
        }
.rec-event-info-form .event-zone-info .copy-btn .default-svg-icon, .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                height: 22px;
                width: 22px;
                margin-left: 12px;
                margin-right: 4px;
            }
.rec-event-info-form .event-zone-info .copy-equation-btn {
            margin-right: 80px;
        }
.rec-event-info-form .event-zone-info .dialog-window {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.rec-event-info-form .event-zone-info .copy-zone-modal {
            position: fixed !important;
            width: 385px;
            line-height: 17px
        }
.rec-event-info-form .event-zone-info .copy-zone-modal .copy-zone-msg {
                margin-bottom: 10px;
            }
.rec-event-info-form .event-zone-info .modal-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.rec-event-info-form .zone-legend-heading {
        margin-left: 10px;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 300;
    }
.rec-event-info-form .event-zone-classbreaks-legend {
        display: flex;
        align-items: center;
        padding: 2px 10px 2px 2px;
        margin: 0 8px;
        cursor: pointer
    }
.rec-event-info-form .event-zone-classbreaks-legend .event-zone-legend-color {
            flex-shrink: 0;
            height: 20px;
            width: 20px;
            margin: 0 7px;
            padding: 2px;
        }
.rec-event-info-form .event-zone-classbreaks-legend .event-zone-legend-label {
            display: flex;
            min-width: 10px;
            line-height: 20px
        }
.rec-event-info-form .event-zone-classbreaks-legend .event-zone-legend-label span {
                min-width: 10px;
                white-space: nowrap
            }
.rec-event-info-form .event-zone-classbreaks-legend .event-zone-legend-label span.label-size {
                    flex-shrink: 0;
                    margin-left: 5px;
                }
.rec-event-info-form .event-zone-classbreaks-legend .event-zone-legend-label span.label-text {
                    flex-shrink: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
.default .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #bfbfbf;
            }
.default .rec-event-info-form .event-zone-info .copy-btn {
                color: #1daaef
            }
.default .rec-event-info-form .event-zone-info .clear-btn {
                color: #1daaef
            }
.default .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #1daaef;
                }
.default .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #1daaef;
                }
.default .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #262626;
            }
.default .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #737373;
            }
.default-light .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #cfcfcf;
            }
.default-light .rec-event-info-form .event-zone-info .copy-btn {
                color: #0e55a8
            }
.default-light .rec-event-info-form .event-zone-info .clear-btn {
                color: #0e55a8
            }
.default-light .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #0e55a8;
                }
.default-light .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #0e55a8;
                }
.default-light .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #e2e2e2;
            }
.default-light .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #b8b8b8;
            }
.sky .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #c8c8c8;
            }
.sky .rec-event-info-form .event-zone-info .copy-btn {
                color: #01425e
            }
.sky .rec-event-info-form .event-zone-info .clear-btn {
                color: #01425e
            }
.sky .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #01425e;
                }
.sky .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #01425e;
                }
.sky .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #b1b1b1;
            }
.sky .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #838383;
            }
.sky-light .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #c8c8c8;
            }
.sky-light .rec-event-info-form .event-zone-info .copy-btn {
                color: #01425e
            }
.sky-light .rec-event-info-form .event-zone-info .clear-btn {
                color: #01425e
            }
.sky-light .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #01425e;
                }
.sky-light .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #01425e;
                }
.sky-light .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #b1b1b1;
            }
.sky-light .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #838383;
            }
.grass .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #9cadb2;
            }
.grass .rec-event-info-form .event-zone-info .copy-btn {
                color: #e26816
            }
.grass .rec-event-info-form .event-zone-info .clear-btn {
                color: #e26816
            }
.grass .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #e26816;
                }
.grass .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #e26816;
                }
.grass .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #424443;
            }
.grass .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #424443;
            }
.grass-light .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #424443;
            }
.grass-light .rec-event-info-form .event-zone-info .copy-btn {
                color: #e26816
            }
.grass-light .rec-event-info-form .event-zone-info .clear-btn {
                color: #e26816
            }
.grass-light .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #e26816;
                }
.grass-light .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #e26816;
                }
.grass-light .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #9cadb2;
            }
.grass-light .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #bfbfbf;
            }
.mud .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #f2f3f8;
            }
.mud .rec-event-info-form .event-zone-info .copy-btn {
                color: #f0c248
            }
.mud .rec-event-info-form .event-zone-info .clear-btn {
                color: #f0c248
            }
.mud .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #f0c248;
                }
.mud .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #f0c248;
                }
.mud .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #8c7454;
            }
.mud .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #ad8f68;
            }
.mud-light .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #d6c282;
            }
.mud-light .rec-event-info-form .event-zone-info .copy-btn {
                color: #0e55a8
            }
.mud-light .rec-event-info-form .event-zone-info .clear-btn {
                color: #0e55a8
            }
.mud-light .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #0e55a8;
                }
.mud-light .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #0e55a8;
                }
.mud-light .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #b28f5a;
            }
.mud-light .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #735523;
            }
.illini .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #f2f3f8;
            }
.illini .rec-event-info-form .event-zone-info .copy-btn {
                color: #f39c11
            }
.illini .rec-event-info-form .event-zone-info .clear-btn {
                color: #f39c11
            }
.illini .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #f39c11;
                }
.illini .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #f39c11;
                }
.illini .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #abc8e2;
            }
.illini .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #3698db;
            }
.illini-light .rec-event-info-form .event-zone-info .no-zone-msg {
                color: #abc8e2;
            }
.illini-light .rec-event-info-form .event-zone-info .copy-btn {
                color: #b2962c
            }
.illini-light .rec-event-info-form .event-zone-info .clear-btn {
                color: #b2962c
            }
.illini-light .rec-event-info-form .event-zone-info .copy-btn .default-svg-icon {
                    fill: #b2962c;
                }
.illini-light .rec-event-info-form .event-zone-info .clear-btn .default-svg-icon {
                    fill: #b2962c;
                }
.illini-light .rec-event-info-form .event-zone-classbreaks-legend.event-zone-legend-active {
                background-color: #c4d7ed;
            }
.illini-light .rec-event-info-form .event-zone-classbreaks-legend:hover {
                background-color: #00467f;
            }

.recommendation-dialog {
    flex-flow: column nowrap;
    max-width: 60em;
    min-height: 30em;
    min-width: 40em;
    position: fixed !important;
    width: 40em;
    left: 35%
}
.recommendation-dialog .textarea-form-input {
        width: 40em;
        height: 350px;
    }
.recommendation-dialog .recommendation-content {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
.recommendation-dialog .recommendation-top-bar {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
    }

.field-boundary-overlap-option-description {
    margin: 12px 20px;
}

.field-boundary-overlap .dialog-box-body {
    max-width: 420px !important;
}

.field-boundary-overlap-description {
    padding-bottom: 15px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.action-panel .field-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column
}
.action-panel .field-module .module-loader {
        width: 446px;
        height: 100%;
    }
.default .action-panel .field-module {
        background-color: #000000;
    }
.default-light .action-panel .field-module {
        background-color: #fcfcfc;
    }
.sky .action-panel .field-module {
        background-color: #fcfcfc;
    }
.sky-light .action-panel .field-module {
        background-color: #fcfcfc;
    }
.grass .action-panel .field-module {
        background-color: #667c66;
    }
.grass-light .action-panel .field-module {
        background-color: #babfba;
    }
.mud .action-panel .field-module {
        background-color: #4b3705;
    }
.mud-light .action-panel .field-module {
        background-color: #fff0bb;
    }
.illini .action-panel .field-module {
        background-color: #00467f;
    }
.illini-light .action-panel .field-module {
        background-color: #e1e6fa;
    }


    .import-validation-modal .error-result {
        display: flex;
        flex-flow: row wrap;
        width: 375px;
        padding: 5px 0
    }
.import-validation-modal .error-result .error-context {
            max-width: 357px;
            margin-bottom: 5px;
            cursor: pointer;
        }
.import-validation-modal .error-result .nested-grid-expand-button {
            height: 18px;
            cursor: pointer;
        }
.import-validation-modal .error-result ul {
            width: 100%;
            padding-left: 18px
        }
.import-validation-modal .error-result ul li {
                margin: 5px 0;
            }
.import-validation-modal .error-result .expand-collapse-icon {
            width: 18px;
            height: 18px;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-append-modal .append-list-section {
        margin-bottom: 18px;
        min-height: 150px
    }
.import-append-modal .append-list-section .append-event-list-title {
            font-size: 13px;
            padding-bottom: 3px;
        }
.import-append-modal .append-list-section .append-row {
            display: flex;
            flex-flow: row nowrap;
            font-size: 12px;
            text-align: center;
            padding-top: 1px;
            padding-bottom: 1px;
            cursor: pointer;
            line-height: 16px
        }
.import-append-modal .append-list-section .append-row .append-cell {
                min-width: 120px;
                max-width: 120px;
                width: 120px;
                padding-left: 5px;
                padding-right: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
.import-append-modal .append-list-section .append-header-row {
            border-bottom: 1px solid
        }
.import-append-modal .append-list-section .append-header-row .append-cell {
                font-weight: bold;
            }
.default .selected-row {
            background-color: #8fa61a;
        }
.default .error-text {
            color: #ff0000;
        }
.default-light .selected-row {
            background-color: #8fa61a;
        }
.default-light .error-text {
            color: #ff0000;
        }
.sky .selected-row {
            background-color: #235635;
        }
.sky .error-text {
            color: #ff0000;
        }
.sky-light .selected-row {
            background-color: #235635;
        }
.sky-light .error-text {
            color: #ff0000;
        }
.grass .selected-row {
            background-color: #ffff00;
        }
.grass .error-text {
            color: #ff0000;
        }
.grass-light .selected-row {
            background-color: #ffff00;
        }
.grass-light .error-text {
            color: #ff0000;
        }
.mud .selected-row {
            background-color: #ffff00;
        }
.mud .error-text {
            color: #ff0000;
        }
.mud-light .selected-row {
            background-color: #8fa61a;
        }
.mud-light .error-text {
            color: #ff0000;
        }
.illini .selected-row {
            background-color: #ffff00;
        }
.illini .error-text {
            color: #ff0000;
        }
.illini-light .selected-row {
            background-color: #ffff00;
        }
.illini-light .error-text {
            color: #ff0000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-wizard-panel {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: space-between;
    height: calc(100% - 165px)
}
.import-wizard-panel .add-customer {
        cursor: pointer;
        height: 100%;
        text-align: start;
        line-height: 45px;
        margin-left: 5px;
    }
.import-wizard-panel .files-panel {
        width: 100%;
        flex: 1 1;
        height: 10px;
        overflow: auto;
    }
.import-wizard-panel .edit-modal-container .dialog-window {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.import-wizard-panel .edit-modal-container .edit-field-modal-body-message {
            margin-top: 15px;
            margin-bottom: 15px;
            margin-left: 10px;
        }
.import-wizard-panel .edit-modal-container .edit-field-modal-footer {
            font-size: 10px;
            text-align: right;
        }
.import-wizard-panel .edit-modal-container .modal-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.import-wizard-panel .edit-modal-container .dialog-container {
            position: fixed !important;
        }
.import-wizard-panel .edit-modal-container .edit-field-area-warning {
            margin-left: 40%;
        }
.import-wizard-panel .lock-icon {
        height: 35px;
        width: 45px;
        padding-left: 200px;
        padding-top: 50px;
    }
.import-wizard-panel .lock-icon-text {
        padding-top: 20px;
        text-align: center;
    }
.import-wizard-panel .fields-panel,
    .import-wizard-panel .events-panel {
        display: flex;
        flex-flow: column nowrap;
        height: 10px;
        flex: 1 1
    }
.import-wizard-panel .fields-panel .fields-section,
        .import-wizard-panel .fields-panel .events-section,
        .import-wizard-panel .events-panel .fields-section,
        .import-wizard-panel .events-panel .events-section {
            overflow-y: auto;
            overflow-x: hidden;
            flex-grow: 1;
            height: 10px;
        }
.import-wizard-panel .fields-panel .events-single-step-section, .import-wizard-panel .events-panel .events-single-step-section {
            flex-grow: 1;
            height: 10px
        }
.import-wizard-panel .fields-panel .events-single-step-section .bucket, .import-wizard-panel .events-panel .events-single-step-section .bucket {
                margin: 0;
                padding-left: 0px;
                text-align: left;
                display: flex;
                flex-flow: column nowrap;
                flex-grow: 1;
                height: 100%
            }
.import-wizard-panel .fields-panel .events-single-step-section .bucket .bucket-header, .import-wizard-panel .events-panel .events-single-step-section .bucket .bucket-header {
                    padding-bottom: 5px;
                    padding-top: 10px;
                }
.import-wizard-panel .fields-panel .events-single-step-section .bucket .bucket-content, .import-wizard-panel .events-panel .events-single-step-section .bucket .bucket-content {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
.import-wizard-panel .fields-panel .import-field-section-bucket, .import-wizard-panel .events-panel .import-field-section-bucket {
            margin: 0;
            padding: 0;
            min-height: 30px
        }
.import-wizard-panel .fields-panel .import-field-section-bucket .fields-section-header, .import-wizard-panel .events-panel .import-field-section-bucket .fields-section-header {
                padding-left: 15px;
            }
.import-wizard-panel .fields-panel .import-field-section-bucket .import-field-section-header, .import-wizard-panel .events-panel .import-field-section-bucket .import-field-section-header {
                height: 30px;
                font-size: 13px;
                line-height: 30px
            }
.import-wizard-panel .fields-panel .import-field-section-bucket .import-field-section-header .create-events-header, .import-wizard-panel .events-panel .import-field-section-bucket .import-field-section-header .create-events-header {
                    padding-left: 15px;
                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content {
                display: flex;
                flex-flow: column;
                width: 100%
            }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row {
                    font-size: 15px;
                    width: 100%
                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .field-selected-column, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .field-selected-column {
                        width: 6px;
                        padding-left: 15px;
                        padding-right: 10px;
                        vertical-align: middle;
                        padding-top: 9px
                    }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .field-selected-column .field-selected-div, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .field-selected-column .field-selected-div {
                            width: 5px;
                            height: 35px;
                        }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip {
                        display: flex;
                        justify-content: space-between;
                        font-size: 15px;
                        width: 100%;
                        height: 54px;
                        min-height: 54px;
                        max-height: 54px;
                        line-height: 54px
                    }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content {
                            display: flex;
                            flex-flow: column nowrap;
                            flex: 1 1;
                            justify-content: flex-start
                        }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row {
                                display: flex;
                                flex-direction: row
                            }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row > div, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row > div {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .farm-field-text,
                                .import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .customer-name,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .farm-field-text,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .customer-name {
                                    flex: 1 1;
                                    width: 100px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .feature-count,
                                .import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .season,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .feature-count,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .season {
                                    padding: 0 10px;
                                    text-align: right;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .farm-field-text,
                                .import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .season,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .farm-field-text,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .season {
                                    line-height: 30px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .customer-name,
                                .import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .feature-count,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .customer-name,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .feature-count {
                                    line-height: 17px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .farm-field-text, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .farm-field-text {
                                    font-size: 13px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .customer-name,
                                .import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .feature-count,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .customer-name,
                                .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .feature-count {
                                    font-size: 12px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .season, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content .field-strip-content-row .season {
                                    font-size: 11px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols {
                            display: flex;
                            flex-direction: row;
                            flex: 1 1;
                            justify-content: flex-start
                        }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section {
                                display: flex;
                                flex-direction: column
                            }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section > div, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section > div {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section:first-child, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section:first-child {
                                    flex: 1 1;
                                    width: 100px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section .farm-field-text, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section .farm-field-text {
                                    line-height: 30px;
                                    font-size: 13px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section .customer-name, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section .customer-name {
                                    line-height: 15px;
                                    font-size: 12px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section .feature-count, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip .field-strip-content-cols .field-strip-content-section .feature-count {
                                    font-size: 12px;
                                    line-height: 55px;
                                    text-align: right;
                                    padding: 0 10px;
                                }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip:hover, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .ifb-strip:hover {
                        cursor: pointer;
                    }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .field-context-menu.menu-dots, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .field-context-menu.menu-dots {
                        margin-top: 8px;
                        margin-right: 10px;
                    }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-around;
                        font-size: 12px;
                        width: 100%;
                        height: 46px;
                        line-height: 46px
                    }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-selected-column, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-selected-column {
                            padding-left: 30px
                        }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-selected-column .field-selected-div, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-selected-column .field-selected-div {
                                height: 28px;
                            }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-context-menu.menu-dots, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-context-menu.menu-dots {
                            margin-top: 8px;
                        }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-context-menu .menu-item, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .field-context-menu .menu-item {
                                font-size: 12px;
                            }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .file-name, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .file-name {
                            flex: 1 1;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
.import-wizard-panel .fields-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .file-feature-count, .import-wizard-panel .events-panel .import-field-section-bucket .field-section-content .ifb-row .file-strip .file-feature-count {
                            font-size: 12px;
                            padding: 0 10px;
                            line-height: 45px;
                            text-align: right;
                        }
.import-wizard-panel .fields-panel .field-checkbox-options, .import-wizard-panel .events-panel .field-checkbox-options {
            display: flex;
        }
.import-wizard-panel .fields-panel .checkbox-div, .import-wizard-panel .events-panel .checkbox-div {
            margin: 10px 0 3px 15px;
            width: 96%;
        }
.import-wizard-panel .field-boundary-panel {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        height: 10px
    }
.import-wizard-panel .field-boundary-panel .field-boundary-context-menu .menu-items-drop-down .menu-item a {
                font-size: 12px;
            }
.import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-dots {
                margin-top: 10px;
            }
.import-wizard-panel .field-boundary-panel .ignore-farm-checkbox {
            padding: 10px;
        }
.import-wizard-panel .field-boundary-panel .bucket {
            margin: 0;
            padding-left: 0px;
            text-align: left;
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1
        }
.import-wizard-panel .field-boundary-panel .bucket .bucket-content {
                display: block;
                overflow-y: auto;
                overflow-x: hidden;
            }
.import-wizard-panel .field-boundary-panel .import-field-boundary-header {
            display: flex;
            justify-content: space-between;
            min-height: 20px;
        }
.import-wizard-panel .field-boundary-panel .column {
            width: 72px;
            padding-right: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 13px;
        }
.import-wizard-panel .field-boundary-panel .column1 {
            width: 130px;
            padding-right: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 13px;
        }
.import-wizard-panel .field-boundary-panel .acres-column {
            width: 50px;
            padding-right: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 13px;
        }
.import-wizard-panel .field-boundary-panel .space {
            width: 30px;
        }
.import-wizard-panel .field-boundary-panel .ifb-row {
            font-size: 15px;
            width: 100%;
            min-height: 55px
        }
.import-wizard-panel .field-boundary-panel .ifb-row .ifb-strip {
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                width: 100%;
                height: 54px;
                min-height: 54px;
                max-height: 54px;
                line-height: 54px
            }
.import-wizard-panel .field-boundary-panel .ifb-row .ifb-strip .field-selected-column {
                    width: 6px;
                    vertical-align: middle;
                    padding: 12px 10px 0 22px
                }
.import-wizard-panel .field-boundary-panel .ifb-row .ifb-strip .field-selected-column .field-selected-div {
                        width: 5px;
                        height: 30px;
                    }
.import-wizard-panel .field-boundary-panel .ifb-row .ifb-strip:hover {
                cursor: pointer;
            }
.import-wizard-panel .field-boundary-panel .ifb-row .ifb-file {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                min-height: 40px;
                padding-left: 40px;
                margin-top: 5px;
                width: calc(100% - 40px)
            }
.import-wizard-panel .field-boundary-panel .ifb-row .ifb-file .ifb-file-name {
                    width: 80%;
                    font-size: 12px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    line-height: 40px;
                }
.import-wizard-panel .field-boundary-panel .ifb-row.selected {
            min-height: 101px;
        }
.default .import-wizard-panel {
            background-color: #404040
        }
.default .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #000000;
                    border-top: 1px solid #404040;
                    border-bottom: 1px solid #404040;
                }
.default .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #000000;
                    border-top: 1px solid #404040;
                    border-bottom: 1px solid #404040;
                }
.default .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #404040;
                    border-top: 1px solid #404040;
                    border-bottom: 1px solid #404040;
                }
.default .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #404040;
                    border-top: 1px solid #404040;
                    border-bottom: 1px solid #404040;
                }
.default .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #000000;
                    border-top: 1px solid #bfbfbf;
                    border-bottom: 1px solid #bfbfbf
                }
.default .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #000000;
                    border-top: 1px solid #bfbfbf;
                    border-bottom: 1px solid #bfbfbf
                }
.default .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #000000;
                        }
.default .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #000000;
                        }
.default .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #404040
                    }
.default .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #404040
                    }
.default .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #404040
                    }
.default .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #404040
                    }
.default .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #bfbfbf;
                    }
.default .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #bfbfbf;
                    }
.default .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #262626;
                    cursor: pointer;
                }
.default .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #262626;
                    cursor: pointer;
                }
.default .import-wizard-panel .fields-panel .selected {
                    background-color: #777777
                }
.default .import-wizard-panel .events-panel .selected {
                    background-color: #777777
                }
.default .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.default .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.default .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.default .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.default .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #8fa61a !important;
                        }
.default .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #8fa61a !important;
                        }
.default .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.default .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.default .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.default .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.default .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.default .import-wizard-panel .fields-panel .file-strip {
                    background-color: #404040;
                    border-bottom: 1px solid #bfbfbf
                }
.default .import-wizard-panel .events-panel .file-strip {
                    background-color: #404040;
                    border-bottom: 1px solid #bfbfbf
                }
.default .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #404040;
                        }
.default .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #404040;
                        }
.default .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #000000
                    }
.default .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #000000
                    }
.default .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #000000
                    }
.default .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #000000
                    }
.default .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .fields-panel .import-event-single-step-header, .default .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.default .import-wizard-panel .fields-panel .column,
                .default .import-wizard-panel .fields-panel .farm-field-column,
                .default .import-wizard-panel .events-panel .column,
                .default .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.default .import-wizard-panel .fields-panel .customer-column, .default .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.default .import-wizard-panel .fields-panel .season-column, .default .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.default .import-wizard-panel .fields-panel .space, .default .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #000000;
                    border-top: 1px solid #bfbfbf;
                    border-bottom: 1px solid #bfbfbf
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #bfbfbf;
                        }
.default .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #bfbfbf;
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #777777;
                        }
.default .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #000000;
                        }
.default .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #404040
                }
.default .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #404040
                }
.default .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #8fa61a;
                    }
.default .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #8fa61a;
                    }
.default .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #262626
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #e26816;
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #777777
                }
.default .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #8fa61a;
                    }
.default-light .import-wizard-panel {
            background-color: #919191
        }
.default-light .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #fcfcfc;
                    border-top: 1px solid #919191;
                    border-bottom: 1px solid #919191;
                }
.default-light .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #fcfcfc;
                    border-top: 1px solid #919191;
                    border-bottom: 1px solid #919191;
                }
.default-light .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #919191;
                    border-top: 1px solid #919191;
                    border-bottom: 1px solid #919191;
                }
.default-light .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #919191;
                    border-top: 1px solid #919191;
                    border-bottom: 1px solid #919191;
                }
.default-light .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #cfcfcf;
                    border-bottom: 1px solid #cfcfcf
                }
.default-light .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #cfcfcf;
                    border-bottom: 1px solid #cfcfcf
                }
.default-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.default-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.default-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #919191
                    }
.default-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #919191
                    }
.default-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #919191
                    }
.default-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #919191
                    }
.default-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #cfcfcf;
                    }
.default-light .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #cfcfcf;
                    }
.default-light .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #e2e2e2;
                    cursor: pointer;
                }
.default-light .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #e2e2e2;
                    cursor: pointer;
                }
.default-light .import-wizard-panel .fields-panel .selected {
                    background-color: #777777
                }
.default-light .import-wizard-panel .events-panel .selected {
                    background-color: #777777
                }
.default-light .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.default-light .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.default-light .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.default-light .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.default-light .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #8fa61a !important;
                        }
.default-light .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #8fa61a !important;
                        }
.default-light .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.default-light .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.default-light .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.default-light .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.default-light .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.default-light .import-wizard-panel .fields-panel .file-strip {
                    background-color: #919191;
                    border-bottom: 1px solid #cfcfcf
                }
.default-light .import-wizard-panel .events-panel .file-strip {
                    background-color: #919191;
                    border-bottom: 1px solid #cfcfcf
                }
.default-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #919191;
                        }
.default-light .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #919191;
                        }
.default-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #fcfcfc
                    }
.default-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fcfcfc
                    }
.default-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #fcfcfc
                    }
.default-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fcfcfc
                    }
.default-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .fields-panel .import-event-single-step-header, .default-light .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.default-light .import-wizard-panel .fields-panel .column,
                .default-light .import-wizard-panel .fields-panel .farm-field-column,
                .default-light .import-wizard-panel .events-panel .column,
                .default-light .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.default-light .import-wizard-panel .fields-panel .customer-column, .default-light .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.default-light .import-wizard-panel .fields-panel .season-column, .default-light .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.default-light .import-wizard-panel .fields-panel .space, .default-light .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #cfcfcf;
                    border-bottom: 1px solid #cfcfcf
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #cfcfcf;
                        }
.default-light .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #cfcfcf;
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #777777;
                        }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.default-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #919191
                }
.default-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #919191
                }
.default-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #8fa61a;
                    }
.default-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #8fa61a;
                    }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #e2e2e2
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #e26816;
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #777777
                }
.default-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #8fa61a;
                    }
.sky .import-wizard-panel {
            background-color: #0194d3
        }
.sky .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #fcfcfc;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #fcfcfc;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #0194d3;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #0194d3;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #c8c8c8;
                    border-bottom: 1px solid #c8c8c8
                }
.sky .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #c8c8c8;
                    border-bottom: 1px solid #c8c8c8
                }
.sky .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.sky .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.sky .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #0194d3
                    }
.sky .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #0194d3
                    }
.sky .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #0194d3
                    }
.sky .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #0194d3
                    }
.sky .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #c8c8c8;
                    }
.sky .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #c8c8c8;
                    }
.sky .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #b1b1b1;
                    cursor: pointer;
                }
.sky .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #b1b1b1;
                    cursor: pointer;
                }
.sky .import-wizard-panel .fields-panel .selected {
                    background-color: #878787
                }
.sky .import-wizard-panel .events-panel .selected {
                    background-color: #878787
                }
.sky .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.sky .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.sky .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.sky .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.sky .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #235635 !important;
                        }
.sky .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #235635 !important;
                        }
.sky .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.sky .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.sky .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.sky .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.sky .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.sky .import-wizard-panel .fields-panel .file-strip {
                    background-color: #0194d3;
                    border-bottom: 1px solid #c8c8c8
                }
.sky .import-wizard-panel .events-panel .file-strip {
                    background-color: #0194d3;
                    border-bottom: 1px solid #c8c8c8
                }
.sky .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #0194d3;
                        }
.sky .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #0194d3;
                        }
.sky .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #fcfcfc
                    }
.sky .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fcfcfc
                    }
.sky .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #fcfcfc
                    }
.sky .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fcfcfc
                    }
.sky .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .fields-panel .import-event-single-step-header, .sky .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.sky .import-wizard-panel .fields-panel .column,
                .sky .import-wizard-panel .fields-panel .farm-field-column,
                .sky .import-wizard-panel .events-panel .column,
                .sky .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.sky .import-wizard-panel .fields-panel .customer-column, .sky .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.sky .import-wizard-panel .fields-panel .season-column, .sky .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.sky .import-wizard-panel .fields-panel .space, .sky .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #c8c8c8;
                    border-bottom: 1px solid #c8c8c8
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #c8c8c8;
                        }
.sky .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #c8c8c8;
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #878787;
                        }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.sky .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #0194d3
                }
.sky .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #0194d3
                }
.sky .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #235635;
                    }
.sky .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #235635;
                    }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #b1b1b1
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #235635;
                        }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #01425e;
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #878787
                }
.sky .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #235635;
                    }
.sky-light .import-wizard-panel {
            background-color: #0194d3
        }
.sky-light .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #fcfcfc;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky-light .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #fcfcfc;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky-light .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #0194d3;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky-light .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #0194d3;
                    border-top: 1px solid #0194d3;
                    border-bottom: 1px solid #0194d3;
                }
.sky-light .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #c8c8c8;
                    border-bottom: 1px solid #c8c8c8
                }
.sky-light .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #c8c8c8;
                    border-bottom: 1px solid #c8c8c8
                }
.sky-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.sky-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.sky-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #0194d3
                    }
.sky-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #0194d3
                    }
.sky-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #0194d3
                    }
.sky-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #0194d3
                    }
.sky-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #c8c8c8;
                    }
.sky-light .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #c8c8c8;
                    }
.sky-light .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #b1b1b1;
                    cursor: pointer;
                }
.sky-light .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #b1b1b1;
                    cursor: pointer;
                }
.sky-light .import-wizard-panel .fields-panel .selected {
                    background-color: #878787
                }
.sky-light .import-wizard-panel .events-panel .selected {
                    background-color: #878787
                }
.sky-light .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.sky-light .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.sky-light .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.sky-light .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.sky-light .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #235635 !important;
                        }
.sky-light .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #235635 !important;
                        }
.sky-light .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.sky-light .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.sky-light .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.sky-light .import-wizard-panel .fields-panel .file-strip {
                    background-color: #0194d3;
                    border-bottom: 1px solid #c8c8c8
                }
.sky-light .import-wizard-panel .events-panel .file-strip {
                    background-color: #0194d3;
                    border-bottom: 1px solid #c8c8c8
                }
.sky-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #0194d3;
                        }
.sky-light .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #0194d3;
                        }
.sky-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #fcfcfc
                    }
.sky-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fcfcfc
                    }
.sky-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #fcfcfc
                    }
.sky-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fcfcfc
                    }
.sky-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .fields-panel .import-event-single-step-header, .sky-light .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.sky-light .import-wizard-panel .fields-panel .column,
                .sky-light .import-wizard-panel .fields-panel .farm-field-column,
                .sky-light .import-wizard-panel .events-panel .column,
                .sky-light .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.sky-light .import-wizard-panel .fields-panel .customer-column, .sky-light .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.sky-light .import-wizard-panel .fields-panel .season-column, .sky-light .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.sky-light .import-wizard-panel .fields-panel .space, .sky-light .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #fcfcfc;
                    border-top: 1px solid #c8c8c8;
                    border-bottom: 1px solid #c8c8c8
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #c8c8c8;
                        }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #c8c8c8;
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #878787;
                        }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fcfcfc;
                        }
.sky-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #0194d3
                }
.sky-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #0194d3
                }
.sky-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #235635;
                    }
.sky-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #235635;
                    }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #b1b1b1
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #235635;
                        }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #01425e;
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #878787
                }
.sky-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #235635;
                    }
.grass .import-wizard-panel {
            background-color: #325132
        }
.grass .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #667c66;
                    border-top: 1px solid #325132;
                    border-bottom: 1px solid #325132;
                }
.grass .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #667c66;
                    border-top: 1px solid #325132;
                    border-bottom: 1px solid #325132;
                }
.grass .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #325132;
                    border-top: 1px solid #325132;
                    border-bottom: 1px solid #325132;
                }
.grass .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #325132;
                    border-top: 1px solid #325132;
                    border-bottom: 1px solid #325132;
                }
.grass .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #667c66;
                    border-top: 1px solid #9cadb2;
                    border-bottom: 1px solid #9cadb2
                }
.grass .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #667c66;
                    border-top: 1px solid #9cadb2;
                    border-bottom: 1px solid #9cadb2
                }
.grass .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #667c66;
                        }
.grass .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #667c66;
                        }
.grass .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #325132
                    }
.grass .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #325132
                    }
.grass .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #325132
                    }
.grass .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #325132
                    }
.grass .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #9cadb2;
                    }
.grass .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #9cadb2;
                    }
.grass .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #424443;
                    cursor: pointer;
                }
.grass .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #424443;
                    cursor: pointer;
                }
.grass .import-wizard-panel .fields-panel .selected {
                    background-color: #e5e9e5
                }
.grass .import-wizard-panel .events-panel .selected {
                    background-color: #e5e9e5
                }
.grass .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.grass .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.grass .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.grass .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.grass .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.grass .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.grass .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.grass .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.grass .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.grass .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.grass .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.grass .import-wizard-panel .fields-panel .file-strip {
                    background-color: #325132;
                    border-bottom: 1px solid #9cadb2
                }
.grass .import-wizard-panel .events-panel .file-strip {
                    background-color: #325132;
                    border-bottom: 1px solid #9cadb2
                }
.grass .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #325132;
                        }
.grass .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #325132;
                        }
.grass .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #667c66
                    }
.grass .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #667c66
                    }
.grass .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #667c66
                    }
.grass .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #667c66
                    }
.grass .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .fields-panel .import-event-single-step-header, .grass .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.grass .import-wizard-panel .fields-panel .column,
                .grass .import-wizard-panel .fields-panel .farm-field-column,
                .grass .import-wizard-panel .events-panel .column,
                .grass .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.grass .import-wizard-panel .fields-panel .customer-column, .grass .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.grass .import-wizard-panel .fields-panel .season-column, .grass .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.grass .import-wizard-panel .fields-panel .space, .grass .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #667c66;
                    border-top: 1px solid #9cadb2;
                    border-bottom: 1px solid #9cadb2
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #9cadb2;
                        }
.grass .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #9cadb2;
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #e5e9e5;
                        }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #667c66;
                        }
.grass .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #325132
                }
.grass .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #325132
                }
.grass .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #ffff00;
                    }
.grass .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #ffff00;
                    }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #424443
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #e26816;
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #e5e9e5
                }
.grass .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #ffff00;
                    }
.grass-light .import-wizard-panel {
            background-color: #545f54
        }
.grass-light .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #babfba;
                    border-top: 1px solid #545f54;
                    border-bottom: 1px solid #545f54;
                }
.grass-light .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #babfba;
                    border-top: 1px solid #545f54;
                    border-bottom: 1px solid #545f54;
                }
.grass-light .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #545f54;
                    border-top: 1px solid #545f54;
                    border-bottom: 1px solid #545f54;
                }
.grass-light .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #545f54;
                    border-top: 1px solid #545f54;
                    border-bottom: 1px solid #545f54;
                }
.grass-light .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #babfba;
                    border-top: 1px solid #424443;
                    border-bottom: 1px solid #424443
                }
.grass-light .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #babfba;
                    border-top: 1px solid #424443;
                    border-bottom: 1px solid #424443
                }
.grass-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #babfba;
                        }
.grass-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #babfba;
                        }
.grass-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #545f54
                    }
.grass-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #545f54
                    }
.grass-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #545f54
                    }
.grass-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #545f54
                    }
.grass-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #424443;
                    }
.grass-light .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #424443;
                    }
.grass-light .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #9cadb2;
                    cursor: pointer;
                }
.grass-light .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #9cadb2;
                    cursor: pointer;
                }
.grass-light .import-wizard-panel .fields-panel .selected {
                    background-color: #e5e9e5
                }
.grass-light .import-wizard-panel .events-panel .selected {
                    background-color: #e5e9e5
                }
.grass-light .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.grass-light .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.grass-light .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.grass-light .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.grass-light .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.grass-light .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.grass-light .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.grass-light .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.grass-light .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.grass-light .import-wizard-panel .fields-panel .file-strip {
                    background-color: #545f54;
                    border-bottom: 1px solid #424443
                }
.grass-light .import-wizard-panel .events-panel .file-strip {
                    background-color: #545f54;
                    border-bottom: 1px solid #424443
                }
.grass-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #545f54;
                        }
.grass-light .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #545f54;
                        }
.grass-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #babfba
                    }
.grass-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #babfba
                    }
.grass-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #babfba
                    }
.grass-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #babfba
                    }
.grass-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .fields-panel .import-event-single-step-header, .grass-light .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.grass-light .import-wizard-panel .fields-panel .column,
                .grass-light .import-wizard-panel .fields-panel .farm-field-column,
                .grass-light .import-wizard-panel .events-panel .column,
                .grass-light .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.grass-light .import-wizard-panel .fields-panel .customer-column, .grass-light .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.grass-light .import-wizard-panel .fields-panel .season-column, .grass-light .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.grass-light .import-wizard-panel .fields-panel .space, .grass-light .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #babfba;
                    border-top: 1px solid #424443;
                    border-bottom: 1px solid #424443
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #424443;
                        }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #424443;
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #e5e9e5;
                        }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #babfba;
                        }
.grass-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #545f54
                }
.grass-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #545f54
                }
.grass-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #ffff00;
                    }
.grass-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #ffff00;
                    }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #9cadb2
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #ffff00;
                        }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #e26816;
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #e5e9e5
                }
.grass-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #ffff00;
                    }
.mud .import-wizard-panel {
            background-color: #766146
        }
.mud .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #4b3705;
                    border-top: 1px solid #766146;
                    border-bottom: 1px solid #766146;
                }
.mud .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #4b3705;
                    border-top: 1px solid #766146;
                    border-bottom: 1px solid #766146;
                }
.mud .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #766146;
                    border-top: 1px solid #766146;
                    border-bottom: 1px solid #766146;
                }
.mud .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #766146;
                    border-top: 1px solid #766146;
                    border-bottom: 1px solid #766146;
                }
.mud .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #4b3705;
                    border-top: 1px solid #f2f3f8;
                    border-bottom: 1px solid #f2f3f8
                }
.mud .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #4b3705;
                    border-top: 1px solid #f2f3f8;
                    border-bottom: 1px solid #f2f3f8
                }
.mud .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #4b3705;
                        }
.mud .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #4b3705;
                        }
.mud .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #766146
                    }
.mud .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #766146
                    }
.mud .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #766146
                    }
.mud .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #766146
                    }
.mud .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #f2f3f8;
                    }
.mud .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #f2f3f8;
                    }
.mud .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #8c7454;
                    cursor: pointer;
                }
.mud .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #8c7454;
                    cursor: pointer;
                }
.mud .import-wizard-panel .fields-panel .selected {
                    background-color: #777777
                }
.mud .import-wizard-panel .events-panel .selected {
                    background-color: #777777
                }
.mud .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.mud .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.mud .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.mud .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.mud .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.mud .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.mud .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.mud .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.mud .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.mud .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.mud .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.mud .import-wizard-panel .fields-panel .file-strip {
                    background-color: #766146;
                    border-bottom: 1px solid #f2f3f8
                }
.mud .import-wizard-panel .events-panel .file-strip {
                    background-color: #766146;
                    border-bottom: 1px solid #f2f3f8
                }
.mud .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #766146;
                        }
.mud .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #766146;
                        }
.mud .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #4b3705
                    }
.mud .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #4b3705
                    }
.mud .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #4b3705
                    }
.mud .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #4b3705
                    }
.mud .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .fields-panel .import-event-single-step-header, .mud .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.mud .import-wizard-panel .fields-panel .column,
                .mud .import-wizard-panel .fields-panel .farm-field-column,
                .mud .import-wizard-panel .events-panel .column,
                .mud .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.mud .import-wizard-panel .fields-panel .customer-column, .mud .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.mud .import-wizard-panel .fields-panel .season-column, .mud .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.mud .import-wizard-panel .fields-panel .space, .mud .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #4b3705;
                    border-top: 1px solid #f2f3f8;
                    border-bottom: 1px solid #f2f3f8
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #f2f3f8;
                        }
.mud .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #f2f3f8;
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #777777;
                        }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #4b3705;
                        }
.mud .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #766146
                }
.mud .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #766146
                }
.mud .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #ffff00;
                    }
.mud .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #ffff00;
                    }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #8c7454
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #ffff00;
                        }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #f39104;
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #777777
                }
.mud .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #ffff00;
                    }
.mud-light .import-wizard-panel {
            background-color: #a37b42
        }
.mud-light .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #fff0bb;
                    border-top: 1px solid #a37b42;
                    border-bottom: 1px solid #a37b42;
                }
.mud-light .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #fff0bb;
                    border-top: 1px solid #a37b42;
                    border-bottom: 1px solid #a37b42;
                }
.mud-light .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #a37b42;
                    border-top: 1px solid #a37b42;
                    border-bottom: 1px solid #a37b42;
                }
.mud-light .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #a37b42;
                    border-top: 1px solid #a37b42;
                    border-bottom: 1px solid #a37b42;
                }
.mud-light .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #fff0bb;
                    border-top: 1px solid #d6c282;
                    border-bottom: 1px solid #d6c282
                }
.mud-light .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #fff0bb;
                    border-top: 1px solid #d6c282;
                    border-bottom: 1px solid #d6c282
                }
.mud-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fff0bb;
                        }
.mud-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fff0bb;
                        }
.mud-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #a37b42
                    }
.mud-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #a37b42
                    }
.mud-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #a37b42
                    }
.mud-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #a37b42
                    }
.mud-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #d6c282;
                    }
.mud-light .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #d6c282;
                    }
.mud-light .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #b28f5a;
                    cursor: pointer;
                }
.mud-light .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #b28f5a;
                    cursor: pointer;
                }
.mud-light .import-wizard-panel .fields-panel .selected {
                    background-color: #777777
                }
.mud-light .import-wizard-panel .events-panel .selected {
                    background-color: #777777
                }
.mud-light .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.mud-light .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.mud-light .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.mud-light .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.mud-light .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #8fa61a !important;
                        }
.mud-light .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #8fa61a !important;
                        }
.mud-light .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.mud-light .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.mud-light .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.mud-light .import-wizard-panel .fields-panel .file-strip {
                    background-color: #a37b42;
                    border-bottom: 1px solid #d6c282
                }
.mud-light .import-wizard-panel .events-panel .file-strip {
                    background-color: #a37b42;
                    border-bottom: 1px solid #d6c282
                }
.mud-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #a37b42;
                        }
.mud-light .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #a37b42;
                        }
.mud-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #fff0bb
                    }
.mud-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fff0bb
                    }
.mud-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #fff0bb
                    }
.mud-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #fff0bb
                    }
.mud-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .fields-panel .import-event-single-step-header, .mud-light .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.mud-light .import-wizard-panel .fields-panel .column,
                .mud-light .import-wizard-panel .fields-panel .farm-field-column,
                .mud-light .import-wizard-panel .events-panel .column,
                .mud-light .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.mud-light .import-wizard-panel .fields-panel .customer-column, .mud-light .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.mud-light .import-wizard-panel .fields-panel .season-column, .mud-light .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.mud-light .import-wizard-panel .fields-panel .space, .mud-light .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #fff0bb;
                    border-top: 1px solid #d6c282;
                    border-bottom: 1px solid #d6c282
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #d6c282;
                        }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #d6c282;
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #777777;
                        }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #fff0bb;
                        }
.mud-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #a37b42
                }
.mud-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #a37b42
                }
.mud-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #b28f5a
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #8fa61a;
                        }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #e26816;
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #777777
                }
.mud-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #8fa61a;
                    }
.illini .import-wizard-panel {
            background-color: #636466
        }
.illini .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #00467f;
                    border-top: 1px solid #636466;
                    border-bottom: 1px solid #636466;
                }
.illini .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #00467f;
                    border-top: 1px solid #636466;
                    border-bottom: 1px solid #636466;
                }
.illini .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #636466;
                    border-top: 1px solid #636466;
                    border-bottom: 1px solid #636466;
                }
.illini .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #636466;
                    border-top: 1px solid #636466;
                    border-bottom: 1px solid #636466;
                }
.illini .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #00467f;
                    border-top: 1px solid #f2f3f8;
                    border-bottom: 1px solid #f2f3f8
                }
.illini .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #00467f;
                    border-top: 1px solid #f2f3f8;
                    border-bottom: 1px solid #f2f3f8
                }
.illini .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #00467f;
                        }
.illini .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #00467f;
                        }
.illini .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #636466
                    }
.illini .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #636466
                    }
.illini .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #636466
                    }
.illini .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #636466
                    }
.illini .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #f2f3f8;
                    }
.illini .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #f2f3f8;
                    }
.illini .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #abc8e2;
                    cursor: pointer;
                }
.illini .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #abc8e2;
                    cursor: pointer;
                }
.illini .import-wizard-panel .fields-panel .selected {
                    background-color: #777777
                }
.illini .import-wizard-panel .events-panel .selected {
                    background-color: #777777
                }
.illini .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.illini .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.illini .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.illini .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.illini .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.illini .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.illini .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.illini .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.illini .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.illini .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.illini .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.illini .import-wizard-panel .fields-panel .file-strip {
                    background-color: #636466;
                    border-bottom: 1px solid #f2f3f8
                }
.illini .import-wizard-panel .events-panel .file-strip {
                    background-color: #636466;
                    border-bottom: 1px solid #f2f3f8
                }
.illini .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #636466;
                        }
.illini .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #636466;
                        }
.illini .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #00467f
                    }
.illini .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #00467f
                    }
.illini .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #00467f
                    }
.illini .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #00467f
                    }
.illini .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .fields-panel .import-event-single-step-header, .illini .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.illini .import-wizard-panel .fields-panel .column,
                .illini .import-wizard-panel .fields-panel .farm-field-column,
                .illini .import-wizard-panel .events-panel .column,
                .illini .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.illini .import-wizard-panel .fields-panel .customer-column, .illini .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.illini .import-wizard-panel .fields-panel .season-column, .illini .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.illini .import-wizard-panel .fields-panel .space, .illini .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #00467f;
                    border-top: 1px solid #f2f3f8;
                    border-bottom: 1px solid #f2f3f8
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #f2f3f8;
                        }
.illini .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #f2f3f8;
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #777777;
                        }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #00467f;
                        }
.illini .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #636466
                }
.illini .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #636466
                }
.illini .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #ffff00;
                    }
.illini .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #ffff00;
                    }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #abc8e2
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #f39c11;
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #777777
                }
.illini .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #ffff00;
                    }
.illini-light .import-wizard-panel {
            background-color: #375d81
        }
.illini-light .import-wizard-panel .fields-panel .import-field-section-header {
                    background-color: #e1e6fa;
                    border-top: 1px solid #375d81;
                    border-bottom: 1px solid #375d81;
                }
.illini-light .import-wizard-panel .events-panel .import-field-section-header {
                    background-color: #e1e6fa;
                    border-top: 1px solid #375d81;
                    border-bottom: 1px solid #375d81;
                }
.illini-light .import-wizard-panel .fields-panel .create-events-section-header {
                    background-color: #375d81;
                    border-top: 1px solid #375d81;
                    border-bottom: 1px solid #375d81;
                }
.illini-light .import-wizard-panel .events-panel .create-events-section-header {
                    background-color: #375d81;
                    border-top: 1px solid #375d81;
                    border-bottom: 1px solid #375d81;
                }
.illini-light .import-wizard-panel .fields-panel .ifb-strip {
                    background-color: #e1e6fa;
                    border-top: 1px solid #abc8e2;
                    border-bottom: 1px solid #abc8e2
                }
.illini-light .import-wizard-panel .events-panel .ifb-strip {
                    background-color: #e1e6fa;
                    border-top: 1px solid #abc8e2;
                    border-bottom: 1px solid #abc8e2
                }
.illini-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #e1e6fa;
                        }
.illini-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #e1e6fa;
                        }
.illini-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover {
                        background-color: #375d81
                    }
.illini-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #375d81
                    }
.illini-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover {
                        background-color: #375d81
                    }
.illini-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded {
                        background-color: #375d81
                    }
.illini-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .fields-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .events-panel .ifb-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .fields-panel .field-selected-column .field-selected-div {
                        background-color: #abc8e2;
                    }
.illini-light .import-wizard-panel .events-panel .field-selected-column .field-selected-div {
                        background-color: #abc8e2;
                    }
.illini-light .import-wizard-panel .fields-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .fields-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .events-panel .ifb-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .events-panel .file-strip:hover .field-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .fields-panel .file-strip:hover {
                    background-color: #c4d7ed;
                    cursor: pointer;
                }
.illini-light .import-wizard-panel .events-panel .file-strip:hover {
                    background-color: #c4d7ed;
                    cursor: pointer;
                }
.illini-light .import-wizard-panel .fields-panel .selected {
                    background-color: #777777
                }
.illini-light .import-wizard-panel .events-panel .selected {
                    background-color: #777777
                }
.illini-light .import-wizard-panel .fields-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.illini-light .import-wizard-panel .fields-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.illini-light .import-wizard-panel .events-panel .selected .farm-field-text {
                        color: rgb(0, 255, 255);
                    }
.illini-light .import-wizard-panel .events-panel .selected .feature-count {
                        color: rgb(0, 255, 255);
                    }
.illini-light .import-wizard-panel .fields-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.illini-light .import-wizard-panel .events-panel .selected .ifb-strip .field-selected-div {
                            background-color: #ffff00 !important;
                        }
.illini-light .import-wizard-panel .fields-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.illini-light .import-wizard-panel .events-panel .nomatch .farm-field-text {
                        color: rgb(255, 0, 0);
                    }
.illini-light .import-wizard-panel .events-panel .nomatch .feature-count {
                        color: rgb(255, 0, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .events-panel .matched .farm-field-text {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .events-panel .matched .feature-count {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .events-panel .matched .customer-column {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .events-panel .matched .farm-field-column {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .events-panel .matched .season-column {
                        color: rgb(50, 250, 0);
                    }
.illini-light .import-wizard-panel .fields-panel .file-strip {
                    background-color: #375d81;
                    border-bottom: 1px solid #abc8e2
                }
.illini-light .import-wizard-panel .events-panel .file-strip {
                    background-color: #375d81;
                    border-bottom: 1px solid #abc8e2
                }
.illini-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #375d81;
                        }
.illini-light .import-wizard-panel .events-panel .file-strip .field-context-menu:not(.menu-expanded) .menu-dot {
                            color: #375d81;
                        }
.illini-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover {
                        background-color: #e1e6fa
                    }
.illini-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #e1e6fa
                    }
.illini-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover {
                        background-color: #e1e6fa
                    }
.illini-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded {
                        background-color: #e1e6fa
                    }
.illini-light .import-wizard-panel .fields-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .fields-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .events-panel .file-strip .field-context-menu:hover .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .events-panel .file-strip .field-context-menu.menu-expanded .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .fields-panel .import-event-single-step-header, .illini-light .import-wizard-panel .events-panel .import-event-single-step-header {
                    display: flex;
                    justify-content: space-between;
                    min-height: 20px;
                }
.illini-light .import-wizard-panel .fields-panel .column,
                .illini-light .import-wizard-panel .fields-panel .farm-field-column,
                .illini-light .import-wizard-panel .events-panel .column,
                .illini-light .import-wizard-panel .events-panel .farm-field-column {
                    width: 72px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.illini-light .import-wizard-panel .fields-panel .customer-column, .illini-light .import-wizard-panel .events-panel .customer-column {
                    width: 130px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.illini-light .import-wizard-panel .fields-panel .season-column, .illini-light .import-wizard-panel .events-panel .season-column {
                    width: 50px;
                    padding-right: 0px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 13px;
                }
.illini-light .import-wizard-panel .fields-panel .space, .illini-light .import-wizard-panel .events-panel .space {
                    width: 13px;
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip {
                    background-color: #e1e6fa;
                    border-top: 1px solid #abc8e2;
                    border-bottom: 1px solid #abc8e2
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip .field-selected-column .field-selected-div {
                            background-color: #abc8e2;
                        }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-file {
                    border-bottom: 1px solid #abc8e2;
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected:not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #777777;
                        }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip:not(.selected):not(:hover) .field-boundary-context-menu:not(.menu-expanded) .menu-dot {
                            color: #e1e6fa;
                        }
.illini-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover {
                    background-color: #375d81
                }
.illini-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded {
                    background-color: #375d81
                }
.illini-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu:hover .menu-dot {
                        color: #ffff00;
                    }
.illini-light .import-wizard-panel .field-boundary-panel .field-boundary-context-menu.menu-expanded .menu-dot {
                        color: #ffff00;
                    }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover {
                    background-color: #c4d7ed
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip:hover .field-boundary-context-menu .menu-dot {
                            color: #ffff00;
                        }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip.spatial-conflict {
                    color: #ffff00;
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip.no-match {
                    color: #ff0000;
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip.editing {
                    color: #f39c11;
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected {
                    background-color: #777777
                }
.illini-light .import-wizard-panel .field-boundary-panel .ifb-strip.selected .field-selected-div {
                        background-color: #ffff00;
                    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.import-wizard-footer {
    display: flex;
    justify-content: center;
    padding-top: 2px;
    min-height: 50px
}
.import-wizard-footer button {
        margin: 7px;
    }
.default .import-wizard-footer {
            background-color: #000000;
            border-top: 1px solid #bfbfbf;
        }
.default-light .import-wizard-footer {
            background-color: #fcfcfc;
            border-top: 1px solid #cfcfcf;
        }
.sky .import-wizard-footer {
            background-color: #fcfcfc;
            border-top: 1px solid #c8c8c8;
        }
.sky-light .import-wizard-footer {
            background-color: #fcfcfc;
            border-top: 1px solid #c8c8c8;
        }
.grass .import-wizard-footer {
            background-color: #667c66;
            border-top: 1px solid #9cadb2;
        }
.grass-light .import-wizard-footer {
            background-color: #babfba;
            border-top: 1px solid #424443;
        }
.mud .import-wizard-footer {
            background-color: #4b3705;
            border-top: 1px solid #f2f3f8;
        }
.mud-light .import-wizard-footer {
            background-color: #fff0bb;
            border-top: 1px solid #d6c282;
        }
.illini .import-wizard-footer {
            background-color: #00467f;
            border-top: 1px solid #f2f3f8;
        }
.illini-light .import-wizard-footer {
            background-color: #e1e6fa;
            border-top: 1px solid #abc8e2;
        }

.import-wizard {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    position: relative;
    height: 10px
}
.import-wizard .loader-container {
        z-index: 10000;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.action-panel .import-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.toolbar-svg-icon {
    position: absolute;
    height: 30px;
    left: 0;
    right: 0;
    top: 3px;
    width: 70px;
}
.toolbar-item-icon .toolbar-svg-icon {
    height: 28px;
    top: 4px;
}
svg image {
    cursor: pointer;
}
.default .toolbar-svg-icon {
            fill: #ffffff;
            stroke: #ffffff
        }
.default .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.default .expanded .toolbar-svg-icon {
            fill: #8fa61a;
        }
.default .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #ffffff;
        }
.default .active .toolbar-svg-icon {
            fill: #8fa61a !important;
            stroke: #8fa61a !important
        }
.default .selected .toolbar-svg-icon {
            fill: #8fa61a !important;
            stroke: #8fa61a !important
        }
.default .active .toolbar-svg-icon.no-stroke, .default .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.default-light .toolbar-svg-icon {
            fill: #000000;
            stroke: #000000
        }
.default-light .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.default-light .expanded .toolbar-svg-icon {
            fill: #8fa61a;
        }
.default-light .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.default-light .active .toolbar-svg-icon {
            fill: #8fa61a !important;
            stroke: #8fa61a !important
        }
.default-light .selected .toolbar-svg-icon {
            fill: #8fa61a !important;
            stroke: #8fa61a !important
        }
.default-light .active .toolbar-svg-icon.no-stroke, .default-light .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.sky .toolbar-svg-icon {
            fill: #000000;
            stroke: #000000
        }
.sky .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.sky .expanded .toolbar-svg-icon {
            fill: #235635;
        }
.sky .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.sky .active .toolbar-svg-icon {
            fill: #235635 !important;
            stroke: #235635 !important
        }
.sky .selected .toolbar-svg-icon {
            fill: #235635 !important;
            stroke: #235635 !important
        }
.sky .active .toolbar-svg-icon.no-stroke, .sky .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.sky-light .toolbar-svg-icon {
            fill: #000000;
            stroke: #000000
        }
.sky-light .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.sky-light .expanded .toolbar-svg-icon {
            fill: #235635;
        }
.sky-light .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.sky-light .active .toolbar-svg-icon {
            fill: #235635 !important;
            stroke: #235635 !important
        }
.sky-light .selected .toolbar-svg-icon {
            fill: #235635 !important;
            stroke: #235635 !important
        }
.sky-light .active .toolbar-svg-icon.no-stroke, .sky-light .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.grass .toolbar-svg-icon {
            fill: #ffffff;
            stroke: #ffffff
        }
.grass .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.grass .expanded .toolbar-svg-icon {
            fill: #ffff00;
        }
.grass .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #ffffff;
        }
.grass .active .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.grass .selected .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.grass .active .toolbar-svg-icon.no-stroke, .grass .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.grass-light .toolbar-svg-icon {
            fill: #000000;
            stroke: #000000
        }
.grass-light .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.grass-light .expanded .toolbar-svg-icon {
            fill: #ffff00;
        }
.grass-light .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.grass-light .active .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.grass-light .selected .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.grass-light .active .toolbar-svg-icon.no-stroke, .grass-light .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.mud .toolbar-svg-icon {
            fill: #ffffff;
            stroke: #ffffff
        }
.mud .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.mud .expanded .toolbar-svg-icon {
            fill: #ffff00;
        }
.mud .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #ffffff;
        }
.mud .active .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.mud .selected .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.mud .active .toolbar-svg-icon.no-stroke, .mud .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.mud-light .toolbar-svg-icon {
            fill: #000000;
            stroke: #000000
        }
.mud-light .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.mud-light .expanded .toolbar-svg-icon {
            fill: #8fa61a;
        }
.mud-light .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.mud-light .active .toolbar-svg-icon {
            fill: #8fa61a !important;
            stroke: #8fa61a !important
        }
.mud-light .selected .toolbar-svg-icon {
            fill: #8fa61a !important;
            stroke: #8fa61a !important
        }
.mud-light .active .toolbar-svg-icon.no-stroke, .mud-light .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.illini .toolbar-svg-icon {
            fill: #ffffff;
            stroke: #ffffff
        }
.illini .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.illini .expanded .toolbar-svg-icon {
            fill: #ffff00;
        }
.illini .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #ffffff;
        }
.illini .active .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.illini .selected .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.illini .active .toolbar-svg-icon.no-stroke, .illini .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.illini-light .toolbar-svg-icon {
            fill: #000000;
            stroke: #000000
        }
.illini-light .toolbar-svg-icon.no-stroke {
                stroke: none;
            }
.illini-light .expanded .toolbar-svg-icon {
            fill: #ffff00;
        }
.illini-light .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.illini-light .active .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.illini-light .selected .toolbar-svg-icon {
            fill: #ffff00 !important;
            stroke: #ffff00 !important
        }
.illini-light .active .toolbar-svg-icon.no-stroke, .illini-light .selected .toolbar-svg-icon.no-stroke {
                stroke: none !important;
            }
.sky .toolbar-svg-icon {
            fill: #ffffff;
        }
.sky .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }
.sky-light .toolbar-svg-icon {
            fill: #ffffff;
        }
.sky-light .expanded .toolbar-item-icon .toolbar-svg-icon {
            fill: #000000;
        }


    .import-zones-modal-form .inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center
    }
.import-zones-modal-form .inputs .select-form-input-container {
            width: 250px;
        }
.import-zones-modal-form .inputs .checkbox-input {
            margin-top: 10px;
            width: 150px;
        }
.import-zones-modal-form .form-input {
        margin: 20px 0px 10px 0px;
    }
.import-zones-modal-form .nbr-zones-msg {
        min-height: 28px
    }
.import-zones-modal-form .nbr-zones-msg.error {
            color: red;
            font-weight: bold;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.map-toolbar {
    min-height: 50px;
    display: flex;
    flex-direction: row
}
.map-toolbar .primary {
        flex-grow: 1;
    }
.map-toolbar .queue-status-info {
        font-size: 32px;
        line-height: 35px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
    }
.map-toolbar .panel-modal .dialog-window {
            position: absolute !important;
            width: auto;
            height: auto;
        }
.map-toolbar .panel-modal .dialog-container {
            position: fixed !important;
        }
.map-tools {
    min-height: 50px;
    display: flex;
    flex-direction: row
}
.map-tools.action-panel-is-collapsed {
        padding-left: 40px;
    }
.map-tools .text-form-input {
        flex-grow: 1;
        margin: 8px 5px 3px 0px;
    }
.map-tools .tabs-labels {
        line-height: 20px;
        font-size: 11px;
    }
.map-tools .measure-tools {
        width: 300px
    }
.map-tools .measure-tools .measure-pane-content {
            display: flex;
            flex-direction: row
        }
.map-tools .measure-tools .measure-pane-content .select-form-input-container {
                flex-grow: 1;
                margin: 0 5px 0 0;
                min-width: 125px;
                max-width: 125px
            }
.map-tools .measure-tools .measure-pane-content .select-form-input-container .select-form-options > div {
                    margin-left: 0;
                }
.map-tools .measure-tools .measure-pane-content .select-form-input-container > div {
                    margin-right: 0;
                }
.map-tools .measure-tools .measure-pane-content .measure-info {
                cursor: text;
                flex-grow: 2;
                font-size: 14px;
                line-height: 50px;
                overflow: hidden;
                padding-left: 5px;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
.map-tools .measure-tools .clear-measure {
            width: 100%;
            text-align: right;
            font-size: 11px;
            margin-top: -10px;
        }
.map-tools .search-tools {
        width: 340px
    }
.map-tools .search-tools .search-pane-content {
            display: flex;
            flex-direction: row
        }
.map-tools .search-tools .search-pane-content .arcgisSearch {
                margin: 8px 0 5px 0
            }
.map-tools .search-tools .search-pane-content .arcgisSearch .searchInput {
                    width: 250px
                }
.map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                        font-family: "open_sansitalic";
                        font-size: 13px;
                        font-weight: 300;
                    }
.map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                        font-family: "open_sansitalic";
                        font-size: 13px;
                        font-weight: 300;
                    }
.map-tools .search-tools .search-pane-content .arcgisSearch li {
                    padding: 8px 14px;
                    cursor: pointer;
                    min-height: inherit;
                    min-width: inherit
                }
.map-tools .search-tools .search-pane-content .arcgisSearch li strong {
                        font-weight: bold;
                    }
.map-tools .search-tools .search-pane-content .esri-search {
                margin: 8px 0 5px 0;
                justify-content: start;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                width: 100%
            }
.map-tools .search-tools .search-pane-content .esri-search .esri-input {
                    padding-left: 1em;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px
                }
.map-tools .search-tools .search-pane-content .esri-search .esri-input:-ms-input-placeholder {
                        font-family: "open_sansitalic";
                        font-size: 13px;
                        font-weight: 300;
                    }
.map-tools .search-tools .search-pane-content .esri-search .esri-input::placeholder {
                        font-family: "open_sansitalic";
                        font-size: 13px;
                        font-weight: 300;
                    }
.map-tools .search-tools .search-pane-content .esri-search .esri-menu {
                    border-radius: 5px;
                }
.map-tools .search-tools .search-pane-content .esri-search .esri-search__submit-button {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
.map-tools .search-tools .search-pane-content .esri-search li {
                    padding: 8px 14px;
                    cursor: pointer;
                    min-height: inherit;
                    min-width: inherit
                }
.map-tools .search-tools .search-pane-content .esri-search li strong {
                        font-weight: bold;
                    }
.map-tools .search-tools .search-pane-content .search-city {
                margin-left: 9px;
            }
.map-tools .search-tools .search-pane-content .auto-search-pane-context {
                width: 302px
            }
.map-tools .search-tools .search-pane-content .auto-search-pane-context:-ms-input-placeholder {
                    font-family: "open_sansitalic";
                    font-size: 13px;
                    font-weight: 300;
                }
.map-tools .search-tools .search-pane-content .auto-search-pane-context::placeholder {
                    font-family: "open_sansitalic";
                    font-size: 13px;
                    font-weight: 300;
                }
.map-tools .search-tools .search-pane-content .search-loader {
                height: 61px;
                width: 330px;
            }
.map-tools .search-tools .search-pane-content .text-form-input {
                width: 132.5px;
            }
.map-tools .search-tools .search-pane-content .text-form-input:last-child {
                margin-right: 0;
            }
.map-tools .search-tools .search-pane-content .select-form-input-container {
                flex-grow: 1;
                margin: 0 5px 0 0;
                width: 72.25px
            }
.map-tools .search-tools .search-pane-content .select-form-input-container .text-form-input {
                    width: 72.25px;
                }
.map-tools .search-tools .search-pane-content .select-form-input-container > div {
                    margin-left: 0;
                    margin-right: 0;
                }
.map-tools .search-tools .search-pane-content > div:last-child {
                margin-right: 0;
            }
.dialog-container.import-confirmation .dialog-box-body {
        padding-bottom: 30px
    }
.dialog-container.import-confirmation .dialog-box-body.restricted {
            max-width: 550px;
        }
.default .map-tools {
        background-color: #404040
    }
.default .map-tools .searchInput {
            background-color: #bfbfbf !important;
        }
.default .map-tools .searchBtn {
            background-color: #bfbfbf !important;
        }
.default .map-tools .searchClear {
            background-color: #bfbfbf !important;
        }
.default .map-tools .searchBtn:hover {
            background-color: #262626 !important;
        }
.default .map-tools .arcgisSearch :focus {
            outline-color: #8fa61a;
        }
.default .map-tools .esri-search {
            background-color: #000000;
        }
.default .map-tools .esri-search :focus {
            outline-color: #8fa61a;
        }
.default .map-tools .measure-info {
            color: #e26816;
        }
.default .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.default .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.default .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #000000;
            }
.default .map-tools .esri-search .esri-input {
                background-color: #bfbfbf
            }
.default .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.default .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.default .map-tools .esri-search .esri-menu__list {
                background-color: #bfbfbf;
            }
.default .map-tools .esri-search button {
                background-color: #bfbfbf;
            }
.default .map-tools .esri-search li:hover {
                color: #8fa61a;
                background-color: #000000;
            }
.default-light .map-tools {
        background-color: #919191
    }
.default-light .map-tools .searchInput {
            background-color: #cfcfcf !important;
        }
.default-light .map-tools .searchBtn {
            background-color: #cfcfcf !important;
        }
.default-light .map-tools .searchClear {
            background-color: #cfcfcf !important;
        }
.default-light .map-tools .searchBtn:hover {
            background-color: #e2e2e2 !important;
        }
.default-light .map-tools .arcgisSearch :focus {
            outline-color: #8fa61a;
        }
.default-light .map-tools .esri-search {
            background-color: #fcfcfc;
        }
.default-light .map-tools .esri-search :focus {
            outline-color: #8fa61a;
        }
.default-light .map-tools .measure-info {
            color: #e26816;
        }
.default-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.default-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.default-light .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #fcfcfc;
            }
.default-light .map-tools .esri-search .esri-input {
                background-color: #cfcfcf
            }
.default-light .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.default-light .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.default-light .map-tools .esri-search .esri-menu__list {
                background-color: #cfcfcf;
            }
.default-light .map-tools .esri-search button {
                background-color: #cfcfcf;
            }
.default-light .map-tools .esri-search li:hover {
                color: #8fa61a;
                background-color: #fcfcfc;
            }
.sky .map-tools {
        background-color: #0194d3
    }
.sky .map-tools .searchInput {
            background-color: #c8c8c8 !important;
        }
.sky .map-tools .searchBtn {
            background-color: #c8c8c8 !important;
        }
.sky .map-tools .searchClear {
            background-color: #c8c8c8 !important;
        }
.sky .map-tools .searchBtn:hover {
            background-color: #b1b1b1 !important;
        }
.sky .map-tools .arcgisSearch :focus {
            outline-color: #235635;
        }
.sky .map-tools .esri-search {
            background-color: #fcfcfc;
        }
.sky .map-tools .esri-search :focus {
            outline-color: #235635;
        }
.sky .map-tools .measure-info {
            color: #01425e;
        }
.sky .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.sky .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.sky .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #fcfcfc;
            }
.sky .map-tools .esri-search .esri-input {
                background-color: #c8c8c8
            }
.sky .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.sky .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.sky .map-tools .esri-search .esri-menu__list {
                background-color: #c8c8c8;
            }
.sky .map-tools .esri-search button {
                background-color: #c8c8c8;
            }
.sky .map-tools .esri-search li:hover {
                color: #235635;
                background-color: #fcfcfc;
            }
.sky-light .map-tools {
        background-color: #0194d3
    }
.sky-light .map-tools .searchInput {
            background-color: #c8c8c8 !important;
        }
.sky-light .map-tools .searchBtn {
            background-color: #c8c8c8 !important;
        }
.sky-light .map-tools .searchClear {
            background-color: #c8c8c8 !important;
        }
.sky-light .map-tools .searchBtn:hover {
            background-color: #b1b1b1 !important;
        }
.sky-light .map-tools .arcgisSearch :focus {
            outline-color: #235635;
        }
.sky-light .map-tools .esri-search {
            background-color: #fcfcfc;
        }
.sky-light .map-tools .esri-search :focus {
            outline-color: #235635;
        }
.sky-light .map-tools .measure-info {
            color: #01425e;
        }
.sky-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.sky-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.sky-light .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #fcfcfc;
            }
.sky-light .map-tools .esri-search .esri-input {
                background-color: #c8c8c8
            }
.sky-light .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.sky-light .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.sky-light .map-tools .esri-search .esri-menu__list {
                background-color: #c8c8c8;
            }
.sky-light .map-tools .esri-search button {
                background-color: #c8c8c8;
            }
.sky-light .map-tools .esri-search li:hover {
                color: #235635;
                background-color: #fcfcfc;
            }
.grass .map-tools {
        background-color: #325132
    }
.grass .map-tools .searchInput {
            background-color: #9cadb2 !important;
        }
.grass .map-tools .searchBtn {
            background-color: #9cadb2 !important;
        }
.grass .map-tools .searchClear {
            background-color: #9cadb2 !important;
        }
.grass .map-tools .searchBtn:hover {
            background-color: #424443 !important;
        }
.grass .map-tools .arcgisSearch :focus {
            outline-color: #ffff00;
        }
.grass .map-tools .esri-search {
            background-color: #667c66;
        }
.grass .map-tools .esri-search :focus {
            outline-color: #ffff00;
        }
.grass .map-tools .measure-info {
            color: #e26816;
        }
.grass .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.grass .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.grass .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #667c66;
            }
.grass .map-tools .esri-search .esri-input {
                background-color: #9cadb2
            }
.grass .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.grass .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.grass .map-tools .esri-search .esri-menu__list {
                background-color: #9cadb2;
            }
.grass .map-tools .esri-search button {
                background-color: #9cadb2;
            }
.grass .map-tools .esri-search li:hover {
                color: #ffff00;
                background-color: #667c66;
            }
.grass-light .map-tools {
        background-color: #545f54
    }
.grass-light .map-tools .searchInput {
            background-color: #424443 !important;
        }
.grass-light .map-tools .searchBtn {
            background-color: #424443 !important;
        }
.grass-light .map-tools .searchClear {
            background-color: #424443 !important;
        }
.grass-light .map-tools .searchBtn:hover {
            background-color: #9cadb2 !important;
        }
.grass-light .map-tools .arcgisSearch :focus {
            outline-color: #ffff00;
        }
.grass-light .map-tools .esri-search {
            background-color: #babfba;
        }
.grass-light .map-tools .esri-search :focus {
            outline-color: #ffff00;
        }
.grass-light .map-tools .measure-info {
            color: #e26816;
        }
.grass-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.grass-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.grass-light .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #babfba;
            }
.grass-light .map-tools .esri-search .esri-input {
                background-color: #424443
            }
.grass-light .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.grass-light .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.grass-light .map-tools .esri-search .esri-menu__list {
                background-color: #424443;
            }
.grass-light .map-tools .esri-search button {
                background-color: #424443;
            }
.grass-light .map-tools .esri-search li:hover {
                color: #ffff00;
                background-color: #babfba;
            }
.mud .map-tools {
        background-color: #766146
    }
.mud .map-tools .searchInput {
            background-color: #f2f3f8 !important;
        }
.mud .map-tools .searchBtn {
            background-color: #f2f3f8 !important;
        }
.mud .map-tools .searchClear {
            background-color: #f2f3f8 !important;
        }
.mud .map-tools .searchBtn:hover {
            background-color: #8c7454 !important;
        }
.mud .map-tools .arcgisSearch :focus {
            outline-color: #ffff00;
        }
.mud .map-tools .esri-search {
            background-color: #4b3705;
        }
.mud .map-tools .esri-search :focus {
            outline-color: #ffff00;
        }
.mud .map-tools .measure-info {
            color: #f39104;
        }
.mud .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.mud .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.mud .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #4b3705;
            }
.mud .map-tools .esri-search .esri-input {
                background-color: #f2f3f8
            }
.mud .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.mud .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.mud .map-tools .esri-search .esri-menu__list {
                background-color: #f2f3f8;
            }
.mud .map-tools .esri-search button {
                background-color: #f2f3f8;
            }
.mud .map-tools .esri-search li:hover {
                color: #ffff00;
                background-color: #4b3705;
            }
.mud-light .map-tools {
        background-color: #a37b42
    }
.mud-light .map-tools .searchInput {
            background-color: #d6c282 !important;
        }
.mud-light .map-tools .searchBtn {
            background-color: #d6c282 !important;
        }
.mud-light .map-tools .searchClear {
            background-color: #d6c282 !important;
        }
.mud-light .map-tools .searchBtn:hover {
            background-color: #b28f5a !important;
        }
.mud-light .map-tools .arcgisSearch :focus {
            outline-color: #8fa61a;
        }
.mud-light .map-tools .esri-search {
            background-color: #fff0bb;
        }
.mud-light .map-tools .esri-search :focus {
            outline-color: #8fa61a;
        }
.mud-light .map-tools .measure-info {
            color: #e26816;
        }
.mud-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.mud-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.mud-light .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #fff0bb;
            }
.mud-light .map-tools .esri-search .esri-input {
                background-color: #d6c282
            }
.mud-light .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.mud-light .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.mud-light .map-tools .esri-search .esri-menu__list {
                background-color: #d6c282;
            }
.mud-light .map-tools .esri-search button {
                background-color: #d6c282;
            }
.mud-light .map-tools .esri-search li:hover {
                color: #8fa61a;
                background-color: #fff0bb;
            }
.illini .map-tools {
        background-color: #636466
    }
.illini .map-tools .searchInput {
            background-color: #f2f3f8 !important;
        }
.illini .map-tools .searchBtn {
            background-color: #f2f3f8 !important;
        }
.illini .map-tools .searchClear {
            background-color: #f2f3f8 !important;
        }
.illini .map-tools .searchBtn:hover {
            background-color: #abc8e2 !important;
        }
.illini .map-tools .arcgisSearch :focus {
            outline-color: #ffff00;
        }
.illini .map-tools .esri-search {
            background-color: #00467f;
        }
.illini .map-tools .esri-search :focus {
            outline-color: #ffff00;
        }
.illini .map-tools .measure-info {
            color: #f39c11;
        }
.illini .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.illini .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.illini .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #00467f;
            }
.illini .map-tools .esri-search .esri-input {
                background-color: #f2f3f8
            }
.illini .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.illini .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.illini .map-tools .esri-search .esri-menu__list {
                background-color: #f2f3f8;
            }
.illini .map-tools .esri-search button {
                background-color: #f2f3f8;
            }
.illini .map-tools .esri-search li:hover {
                color: #ffff00;
                background-color: #00467f;
            }
.illini-light .map-tools {
        background-color: #375d81
    }
.illini-light .map-tools .searchInput {
            background-color: #abc8e2 !important;
        }
.illini-light .map-tools .searchBtn {
            background-color: #abc8e2 !important;
        }
.illini-light .map-tools .searchClear {
            background-color: #abc8e2 !important;
        }
.illini-light .map-tools .searchBtn:hover {
            background-color: #c4d7ed !important;
        }
.illini-light .map-tools .arcgisSearch :focus {
            outline-color: #ffff00;
        }
.illini-light .map-tools .esri-search {
            background-color: #e1e6fa;
        }
.illini-light .map-tools .esri-search :focus {
            outline-color: #ffff00;
        }
.illini-light .map-tools .measure-info {
            color: #f39c11;
        }
.illini-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput:-ms-input-placeholder {
                    color: #000000;
                }
.illini-light .map-tools .search-tools .search-pane-content .arcgisSearch .searchInput::placeholder {
                    color: #000000;
                }
.illini-light .map-tools .search-tools .search-pane-content .arcgisSearch li:hover {
                background-color: #e1e6fa;
            }
.illini-light .map-tools .esri-search .esri-input {
                background-color: #abc8e2
            }
.illini-light .map-tools .esri-search .esri-input:-ms-input-placeholder {
                    color: #000000;
                }
.illini-light .map-tools .esri-search .esri-input::placeholder {
                    color: #000000;
                }
.illini-light .map-tools .esri-search .esri-menu__list {
                background-color: #abc8e2;
            }
.illini-light .map-tools .esri-search button {
                background-color: #abc8e2;
            }
.illini-light .map-tools .esri-search li:hover {
                color: #ffff00;
                background-color: #e1e6fa;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.dijitReset {
    margin: 0;
    border: 0;
    padding: 0;
    font: inherit;
    line-height: normal;
    color: inherit;
}
.dijitPopup {
    position: absolute;
    background-color: transparent;
    margin: 0;
    border: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
}
.dijitMenuTable {
    border-collapse: separate;
    border-spacing: 0 0;
    padding: 0;
    border-width: 0;
    background-color: transparent;
}
.dijitMenu {
    border-radius: 5px
}
.dijitMenu .dijitMenuItem td {
        padding: 0 0 0 5px;
        line-height: 24px;
        border-width: 1px 0 1px 0;
        border-style: solid;
    }
.dijitMenu .dijitMenuItem td:first-child {
        border-radius: 4px 0 0 4px;
        border-width: 1px 0 1px 1px;
    }
.dijitMenu .dijitMenuItem td:last-child {
        border-radius: 0 4px 4px 0;
        border-width: 1px 1px 1px 0;
        max-width: 5px;
    }
.dijitMenuItem {
    border-radius: 5px;
    text-align: left;
    white-space: nowrap;
    padding: 0.1em 0.2em;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
td.dijitMenuItemIconCell {
    padding: 2px;
    margin: 0 0 0 4px;
}
.dijitInline {
    display: inline-block;
    border: 0;
    padding: 0;
    vertical-align: top;
}
.dijitMenuItemLabel {
    font-family: "open_sansregular" !important;
    font-size: 12px;
    vertical-align: middle;
}
.default .dijitMenuItem {
        background-color: #000000;
        color: #1daaef
    }
.default .dijitMenuItem td {
            border-color: #404040;
        }
.default .dijitMenuItem.dijitMenuItemHover {
            background-color: #404040;
            color: #8fa61a
        }
.default .dijitMenuItem.dijitMenuItemHover td {
                border-color: #000000;
            }
.default-light .dijitMenuItem {
        background-color: #fcfcfc;
        color: #0e55a8
    }
.default-light .dijitMenuItem td {
            border-color: #919191;
        }
.default-light .dijitMenuItem.dijitMenuItemHover {
            background-color: #919191;
            color: #8fa61a
        }
.default-light .dijitMenuItem.dijitMenuItemHover td {
                border-color: #fcfcfc;
            }
.sky .dijitMenuItem {
        background-color: #fcfcfc;
        color: #01425e
    }
.sky .dijitMenuItem td {
            border-color: #0194d3;
        }
.sky .dijitMenuItem.dijitMenuItemHover {
            background-color: #0194d3;
            color: #235635
        }
.sky .dijitMenuItem.dijitMenuItemHover td {
                border-color: #fcfcfc;
            }
.sky-light .dijitMenuItem {
        background-color: #fcfcfc;
        color: #01425e
    }
.sky-light .dijitMenuItem td {
            border-color: #0194d3;
        }
.sky-light .dijitMenuItem.dijitMenuItemHover {
            background-color: #0194d3;
            color: #235635
        }
.sky-light .dijitMenuItem.dijitMenuItemHover td {
                border-color: #fcfcfc;
            }
.grass .dijitMenuItem {
        background-color: #667c66;
        color: #e26816
    }
.grass .dijitMenuItem td {
            border-color: #325132;
        }
.grass .dijitMenuItem.dijitMenuItemHover {
            background-color: #325132;
            color: #ffff00
        }
.grass .dijitMenuItem.dijitMenuItemHover td {
                border-color: #667c66;
            }
.grass-light .dijitMenuItem {
        background-color: #babfba;
        color: #e26816
    }
.grass-light .dijitMenuItem td {
            border-color: #545f54;
        }
.grass-light .dijitMenuItem.dijitMenuItemHover {
            background-color: #545f54;
            color: #ffff00
        }
.grass-light .dijitMenuItem.dijitMenuItemHover td {
                border-color: #babfba;
            }
.mud .dijitMenuItem {
        background-color: #4b3705;
        color: #f0c248
    }
.mud .dijitMenuItem td {
            border-color: #766146;
        }
.mud .dijitMenuItem.dijitMenuItemHover {
            background-color: #766146;
            color: #ffff00
        }
.mud .dijitMenuItem.dijitMenuItemHover td {
                border-color: #4b3705;
            }
.mud-light .dijitMenuItem {
        background-color: #fff0bb;
        color: #0e55a8
    }
.mud-light .dijitMenuItem td {
            border-color: #a37b42;
        }
.mud-light .dijitMenuItem.dijitMenuItemHover {
            background-color: #a37b42;
            color: #8fa61a
        }
.mud-light .dijitMenuItem.dijitMenuItemHover td {
                border-color: #fff0bb;
            }
.illini .dijitMenuItem {
        background-color: #00467f;
        color: #f39c11
    }
.illini .dijitMenuItem td {
            border-color: #636466;
        }
.illini .dijitMenuItem.dijitMenuItemHover {
            background-color: #636466;
            color: #ffff00
        }
.illini .dijitMenuItem.dijitMenuItemHover td {
                border-color: #00467f;
            }
.illini-light .dijitMenuItem {
        background-color: #e1e6fa;
        color: #b2962c
    }
.illini-light .dijitMenuItem td {
            border-color: #375d81;
        }
.illini-light .dijitMenuItem.dijitMenuItemHover {
            background-color: #375d81;
            color: #ffff00
        }
.illini-light .dijitMenuItem.dijitMenuItemHover td {
                border-color: #e1e6fa;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.map-div {
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
    height: 100%;
}
.esri-view-root .esri-view-surface--inset-outline:focus::after {
        outline: none !important;
    }
.zoom-container {
    position: absolute;
    left: auto;
    top: 5px;
    right: 5px;
    z-index: 10;
    border-radius: 5px;
    cursor: pointer
}
.zoom-container > div {
        height: 38px;
        width: 24px;
        margin: 0 5px;
        background-position: center;
        background-repeat: no-repeat;
    }
.zoom-container .svg-container {
        height: 100%
    }
.zoom-container .svg-container svg {
            margin: 7px 0;
        }
@keyframes slim {
    0% {
        width: 50px;
    }

    to {
        width: 0px;
    }
}
@keyframes widen {
    0% {
        width: 0px;
    }

    to {
        width: 50px;
    }
}
.basemap-select-container {
    position: absolute;
    border-radius: 5px;
    padding: 5px;
    right: 5px;
    bottom: 25px;
    z-index: 10;
    cursor: pointer
}
.basemap-select-container .basemap-thumbnail-enter {
        animation: widen 250ms ease-in;
    }
.basemap-select-container .basemap-thumbnail-leave {
        animation: slim 250ms ease-in;
    }
.basemap-select-container img {
        height: 50px;
        width: 50px;
        border-radius: 5px;
        float: left;
        margin-left: 2px;
        object-fit: cover;
        object-position: center;
    }
.basemap-select-container img:first-child {
        margin-left: 0;
    }
svg text {
    pointer-events: none;
}
.esri-view .esri-view-surface[data-cursor="move"],
.esri-view .esri-view-surface[data-cursor="copy"] {
    cursor: pointer;
}
.default .zoom-container {
            background-color: #000000
        }
.default .zoom-container .zoom-plus {
                border-bottom: #737373 solid 1px;
            }
.default .basemap-select-container {
            background-color: #737373
        }
.default .basemap-select-container img {
                border: 2px solid #262626;
            }
.default-light .zoom-container {
            background-color: #fcfcfc
        }
.default-light .zoom-container .zoom-plus {
                border-bottom: #b8b8b8 solid 1px;
            }
.default-light .basemap-select-container {
            background-color: #b8b8b8
        }
.default-light .basemap-select-container img {
                border: 2px solid #e2e2e2;
            }
.sky .zoom-container {
            background-color: #fcfcfc
        }
.sky .zoom-container .zoom-plus {
                border-bottom: #838383 solid 1px;
            }
.sky .basemap-select-container {
            background-color: #838383
        }
.sky .basemap-select-container img {
                border: 2px solid #b1b1b1;
            }
.sky-light .zoom-container {
            background-color: #fcfcfc
        }
.sky-light .zoom-container .zoom-plus {
                border-bottom: #838383 solid 1px;
            }
.sky-light .basemap-select-container {
            background-color: #838383
        }
.sky-light .basemap-select-container img {
                border: 2px solid #b1b1b1;
            }
.grass .zoom-container {
            background-color: #667c66
        }
.grass .zoom-container .zoom-plus {
                border-bottom: #424443 solid 1px;
            }
.grass .basemap-select-container {
            background-color: #424443
        }
.grass .basemap-select-container img {
                border: 2px solid #424443;
            }
.grass-light .zoom-container {
            background-color: #babfba
        }
.grass-light .zoom-container .zoom-plus {
                border-bottom: #bfbfbf solid 1px;
            }
.grass-light .basemap-select-container {
            background-color: #bfbfbf
        }
.grass-light .basemap-select-container img {
                border: 2px solid #9cadb2;
            }
.mud .zoom-container {
            background-color: #4b3705
        }
.mud .zoom-container .zoom-plus {
                border-bottom: #ad8f68 solid 1px;
            }
.mud .basemap-select-container {
            background-color: #ad8f68
        }
.mud .basemap-select-container img {
                border: 2px solid #8c7454;
            }
.mud-light .zoom-container {
            background-color: #fff0bb
        }
.mud-light .zoom-container .zoom-plus {
                border-bottom: #735523 solid 1px;
            }
.mud-light .basemap-select-container {
            background-color: #735523
        }
.mud-light .basemap-select-container img {
                border: 2px solid #b28f5a;
            }
.illini .zoom-container {
            background-color: #00467f
        }
.illini .zoom-container .zoom-plus {
                border-bottom: #3698db solid 1px;
            }
.illini .basemap-select-container {
            background-color: #3698db
        }
.illini .basemap-select-container img {
                border: 2px solid #abc8e2;
            }
.illini-light .zoom-container {
            background-color: #e1e6fa
        }
.illini-light .zoom-container .zoom-plus {
                border-bottom: #00467f solid 1px;
            }
.illini-light .basemap-select-container {
            background-color: #00467f
        }
.illini-light .basemap-select-container img {
                border: 2px solid #c4d7ed;
            }

.map-container {
    flex-grow: 1;
    background-color: transparent;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative
}
.map-container.gray-vector-basemap {
        background-color: #eee;
    }
.map-container.topo-vector-basemap {
        background-color: #fff;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.linked-map-div {
    flex-grow: 1
}
.linked-map-div .esri-popup__main-container {
        width: 9.5em
    }
.linked-map-div .esri-popup__main-container .esri-popup__header {
            display: none;
        }
.linked-map-div .esri-popup__main-container .esri-popup__footer {
            display: none;
        }
.linked-map-div .esri-popup__main-container .esri-popup__content {
            width: 9em;
            margin-top: 0.5em;
        }
.linked-map-div .esri-popup__pointer {
        display: none;
    }
.default .linked-map-div .esri-popup__main-container {
            background-color: #000000;
            color: #ffffff;
        }
.default-light .linked-map-div .esri-popup__main-container {
            background-color: #fcfcfc;
            color: #000000;
        }
.sky .linked-map-div .esri-popup__main-container {
            background-color: #fcfcfc;
            color: #000000;
        }
.sky-light .linked-map-div .esri-popup__main-container {
            background-color: #fcfcfc;
            color: #000000;
        }
.grass .linked-map-div .esri-popup__main-container {
            background-color: #667c66;
            color: #ffffff;
        }
.grass-light .linked-map-div .esri-popup__main-container {
            background-color: #babfba;
            color: #000000;
        }
.mud .linked-map-div .esri-popup__main-container {
            background-color: #4b3705;
            color: #ffffff;
        }
.mud-light .linked-map-div .esri-popup__main-container {
            background-color: #fff0bb;
            color: #000000;
        }
.illini .linked-map-div .esri-popup__main-container {
            background-color: #00467f;
            color: #ffffff;
        }
.illini-light .linked-map-div .esri-popup__main-container {
            background-color: #e1e6fa;
            color: #000000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rx-file-import-container {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .drag-and-drop-file-uploader-container {
        height: 300px;
    }
}
.rx-file-import-container .drop-message {
        flex-grow: 1;
        padding: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-style: dashed;
        border-width: 2px
    }
.rx-file-import-container .drop-message .default-svg-icon {
            height: 100px;
            width: 100px;
        }
.rx-file-import-container .drop-message .greyed-text,
        .rx-file-import-container .drop-message a {
            font-family: open_sansregular;
            font-size: 14px;
            font-weight: 300;
            padding: 3px;
        }
.rx-file-import-container {
    .files-list-container {
        min-height: 300px;
    }
    .files-list-body {
        font-size: 12px;
    }
    .add-default-nolink {
        margin-left: 20px;
    }
    .default-button {
        margin-left: auto;
    }
    .product-info-section {
        justify-content: space-between;
        .rate-column-name-container {
            width: 200px;
        }
    }
    .rx-file-import-error {
        margin-top: 5px;
        color: red;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
.default .rx-file-import-container .drop-message {
            border-color: #bfbfbf
        }
.default .rx-file-import-container .drop-message .greyed-text {
                color: #777777;
            }
.default .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #bfbfbf;
                }
.default .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #8fa61a;
                }
.default .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #404040 !important;
        }
    }
.default-light .rx-file-import-container .drop-message {
            border-color: #cfcfcf
        }
.default-light .rx-file-import-container .drop-message .greyed-text {
                color: #777777;
            }
.default-light .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #cfcfcf;
                }
.default-light .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #8fa61a;
                }
.default-light .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #919191 !important;
        }
    }
.sky .rx-file-import-container .drop-message {
            border-color: #c8c8c8
        }
.sky .rx-file-import-container .drop-message .greyed-text {
                color: #878787;
            }
.sky .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #c8c8c8;
                }
.sky .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #235635;
                }
.sky .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #0194d3 !important;
        }
    }
.sky-light .rx-file-import-container .drop-message {
            border-color: #c8c8c8
        }
.sky-light .rx-file-import-container .drop-message .greyed-text {
                color: #878787;
            }
.sky-light .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #c8c8c8;
                }
.sky-light .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #235635;
                }
.sky-light .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #0194d3 !important;
        }
    }
.grass .rx-file-import-container .drop-message {
            border-color: #9cadb2
        }
.grass .rx-file-import-container .drop-message .greyed-text {
                color: #e5e9e5;
            }
.grass .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #9cadb2;
                }
.grass .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.grass .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #325132 !important;
        }
    }
.grass-light .rx-file-import-container .drop-message {
            border-color: #424443
        }
.grass-light .rx-file-import-container .drop-message .greyed-text {
                color: #e5e9e5;
            }
.grass-light .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #424443;
                }
.grass-light .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.grass-light .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #545f54 !important;
        }
    }
.mud .rx-file-import-container .drop-message {
            border-color: #f2f3f8
        }
.mud .rx-file-import-container .drop-message .greyed-text {
                color: #777777;
            }
.mud .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #f2f3f8;
                }
.mud .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.mud .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #766146 !important;
        }
    }
.mud-light .rx-file-import-container .drop-message {
            border-color: #d6c282
        }
.mud-light .rx-file-import-container .drop-message .greyed-text {
                color: #777777;
            }
.mud-light .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #d6c282;
                }
.mud-light .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #8fa61a;
                }
.mud-light .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #a37b42 !important;
        }
    }
.illini .rx-file-import-container .drop-message {
            border-color: #f2f3f8
        }
.illini .rx-file-import-container .drop-message .greyed-text {
                color: #777777;
            }
.illini .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #f2f3f8;
                }
.illini .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.illini .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #636466 !important;
        }
    }
.illini-light .rx-file-import-container .drop-message {
            border-color: #abc8e2
        }
.illini-light .rx-file-import-container .drop-message .greyed-text {
                color: #777777;
            }
.illini-light .rx-file-import-container .drop-message .default-svg-icon #box {
                    fill: #abc8e2;
                }
.illini-light .rx-file-import-container .drop-message .default-svg-icon #arrow {
                    fill: #ffff00;
                }
.illini-light .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #375d81 !important;
        }
    }
.sky .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #e2e2e2 !important;
        }
    }
.sky-light .rx-file-import-container {
        .drag-and-drop-file-uploader-accordion-item {
            background-color: #e2e2e2 !important;
        }
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-module .rec-event-accordion-item, .rec-module .rec-event-accordion-item, .layer-module .rec-event-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px
    }
.event-module .rec-event-accordion-item .spacer, .rec-module .rec-event-accordion-item .spacer, .layer-module .rec-event-accordion-item .spacer {
            min-width: 5px;
            max-width: 5px;
            margin: 5px 10px 5px 13px;
        }
.event-module .rec-event-accordion-item .rec-event-info, .rec-module .rec-event-accordion-item .rec-event-info, .layer-module .rec-event-accordion-item .rec-event-info {
            flex-grow: 1;
            min-width: 35px;
            padding: 2px;
            display: flex;
            flex-direction: column;
            justify-content: space-between
        }
.event-module .rec-event-accordion-item .rec-event-info .rec-event-name,
            .event-module .rec-event-accordion-item .rec-event-info .rec-event-summary,
            .rec-module .rec-event-accordion-item .rec-event-info .rec-event-name,
            .rec-module .rec-event-accordion-item .rec-event-info .rec-event-summary,
            .layer-module .rec-event-accordion-item .rec-event-info .rec-event-name,
            .layer-module .rec-event-accordion-item .rec-event-info .rec-event-summary {
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 18px;
                white-space: nowrap;
            }
.event-module .rec-event-accordion-item .rec-event-status, .rec-module .rec-event-accordion-item .rec-event-status, .layer-module .rec-event-accordion-item .rec-event-status {
            margin-left: 10px;
            padding-bottom: 4px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            max-width: 30%
        }
.event-module .rec-event-accordion-item .rec-event-status .status, .rec-module .rec-event-accordion-item .rec-event-status .status, .layer-module .rec-event-accordion-item .rec-event-status .status {
                width: 100%;
                height: 15px;
                font-size: 12px;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap
            }
.event-module .rec-event-accordion-item .rec-event-status .status.event-id, .rec-module .rec-event-accordion-item .rec-event-status .status.event-id, .layer-module .rec-event-accordion-item .rec-event-status .status.event-id {
                    font-size: 13px;
                    height: 16px;
                }
.event-module .rec-event-accordion-item .context-menu-container, .rec-module .rec-event-accordion-item .context-menu-container, .layer-module .rec-event-accordion-item .context-menu-container {
            padding-left: 5px;
            height: calc(100% - 5px)
        }
.event-module .rec-event-accordion-item .context-menu-container .context-menu, .rec-module .rec-event-accordion-item .context-menu-container .context-menu, .layer-module .rec-event-accordion-item .context-menu-container .context-menu {
                width: 15px;
                height: 100%;
                margin: 0;
                padding-right: 1px;
                padding-top: 5px
            }
.event-module .rec-event-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot, .rec-module .rec-event-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot, .layer-module .rec-event-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
.event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot, .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot, .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                    visibility: visible;
                }
.default .event-module {
        background-color: #000000;
        color: #ffffff
    }
.default .layer-module {
        background-color: #000000;
        color: #ffffff
    }
.default .rec-module {
        background-color: #000000;
        color: #ffffff
    }
.default .event-module .rec-event-accordion-item {
            background-color: #404040;
        }
.default .layer-module .rec-event-accordion-item {
            background-color: #404040;
        }
.default .rec-module .rec-event-accordion-item {
            background-color: #404040;
        }
.default .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #000000;
            color: #ffffff
        }
.default .event-module .field-accordion-item {
            border-bottom: 1px solid #000000;
            color: #ffffff
        }
.default .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #000000;
            color: #ffffff
        }
.default .layer-module .field-accordion-item {
            border-bottom: 1px solid #000000;
            color: #ffffff
        }
.default .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #000000;
            color: #ffffff
        }
.default .rec-module .field-accordion-item {
            border-bottom: 1px solid #000000;
            color: #ffffff
        }
.default .event-module .rec-event-accordion-item.selected {
                background-color: #737373
            }
.default .event-module .field-accordion-item.selected {
                background-color: #737373
            }
.default .layer-module .rec-event-accordion-item.selected {
                background-color: #737373
            }
.default .layer-module .field-accordion-item.selected {
                background-color: #737373
            }
.default .rec-module .rec-event-accordion-item.selected {
                background-color: #737373
            }
.default .rec-module .field-accordion-item.selected {
                background-color: #737373
            }
.default .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .event-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .layer-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .rec-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default .event-module .rec-event-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .event-module .field-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .layer-module .rec-event-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .layer-module .field-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .rec-module .rec-event-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .rec-module .field-accordion-item .spacer {
                background-color: #bfbfbf;
            }
.default .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #262626;
                }
.default .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .event-module {
        background-color: #fcfcfc;
        color: #000000
    }
.default-light .layer-module {
        background-color: #fcfcfc;
        color: #000000
    }
.default-light .rec-module {
        background-color: #fcfcfc;
        color: #000000
    }
.default-light .event-module .rec-event-accordion-item {
            background-color: #919191;
        }
.default-light .layer-module .rec-event-accordion-item {
            background-color: #919191;
        }
.default-light .rec-module .rec-event-accordion-item {
            background-color: #919191;
        }
.default-light .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.default-light .event-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.default-light .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.default-light .layer-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.default-light .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.default-light .rec-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.default-light .event-module .rec-event-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .event-module .field-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .layer-module .rec-event-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .layer-module .field-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .rec-module .rec-event-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .rec-module .field-accordion-item.selected {
                background-color: #b8b8b8
            }
.default-light .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .event-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .layer-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .rec-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.default-light .event-module .rec-event-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .event-module .field-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .layer-module .rec-event-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .layer-module .field-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .rec-module .rec-event-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .rec-module .field-accordion-item .spacer {
                background-color: #cfcfcf;
            }
.default-light .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #e2e2e2;
                }
.default-light .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.sky .event-module {
        background-color: #fcfcfc;
        color: #000000
    }
.sky .layer-module {
        background-color: #fcfcfc;
        color: #000000
    }
.sky .rec-module {
        background-color: #fcfcfc;
        color: #000000
    }
.sky .event-module .rec-event-accordion-item {
            background-color: #0194d3;
        }
.sky .layer-module .rec-event-accordion-item {
            background-color: #0194d3;
        }
.sky .rec-module .rec-event-accordion-item {
            background-color: #0194d3;
        }
.sky .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky .event-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky .layer-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky .rec-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky .event-module .rec-event-accordion-item.selected {
                background-color: #838383
            }
.sky .event-module .field-accordion-item.selected {
                background-color: #838383
            }
.sky .layer-module .rec-event-accordion-item.selected {
                background-color: #838383
            }
.sky .layer-module .field-accordion-item.selected {
                background-color: #838383
            }
.sky .rec-module .rec-event-accordion-item.selected {
                background-color: #838383
            }
.sky .rec-module .field-accordion-item.selected {
                background-color: #838383
            }
.sky .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .event-module .field-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .layer-module .field-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .rec-module .field-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky .event-module .rec-event-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .event-module .field-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .layer-module .rec-event-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .layer-module .field-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .rec-module .rec-event-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .rec-module .field-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .event-module {
        background-color: #fcfcfc;
        color: #000000
    }
.sky-light .layer-module {
        background-color: #fcfcfc;
        color: #000000
    }
.sky-light .rec-module {
        background-color: #fcfcfc;
        color: #000000
    }
.sky-light .event-module .rec-event-accordion-item {
            background-color: #0194d3;
        }
.sky-light .layer-module .rec-event-accordion-item {
            background-color: #0194d3;
        }
.sky-light .rec-module .rec-event-accordion-item {
            background-color: #0194d3;
        }
.sky-light .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky-light .event-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky-light .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky-light .layer-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky-light .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky-light .rec-module .field-accordion-item {
            border-bottom: 1px solid #fcfcfc;
            color: #000000
        }
.sky-light .event-module .rec-event-accordion-item.selected {
                background-color: #838383
            }
.sky-light .event-module .field-accordion-item.selected {
                background-color: #838383
            }
.sky-light .layer-module .rec-event-accordion-item.selected {
                background-color: #838383
            }
.sky-light .layer-module .field-accordion-item.selected {
                background-color: #838383
            }
.sky-light .rec-module .rec-event-accordion-item.selected {
                background-color: #838383
            }
.sky-light .rec-module .field-accordion-item.selected {
                background-color: #838383
            }
.sky-light .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .event-module .field-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .layer-module .field-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .rec-module .field-accordion-item.selected .spacer {
                    background-color: #235635;
                }
.sky-light .event-module .rec-event-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .event-module .field-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .layer-module .rec-event-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .layer-module .field-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .rec-module .rec-event-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .rec-module .field-accordion-item .spacer {
                background-color: #c8c8c8;
            }
.sky-light .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b1b1b1;
                }
.sky-light .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.grass .event-module {
        background-color: #667c66;
        color: #ffffff
    }
.grass .layer-module {
        background-color: #667c66;
        color: #ffffff
    }
.grass .rec-module {
        background-color: #667c66;
        color: #ffffff
    }
.grass .event-module .rec-event-accordion-item {
            background-color: #325132;
        }
.grass .layer-module .rec-event-accordion-item {
            background-color: #325132;
        }
.grass .rec-module .rec-event-accordion-item {
            background-color: #325132;
        }
.grass .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #667c66;
            color: #ffffff
        }
.grass .event-module .field-accordion-item {
            border-bottom: 1px solid #667c66;
            color: #ffffff
        }
.grass .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #667c66;
            color: #ffffff
        }
.grass .layer-module .field-accordion-item {
            border-bottom: 1px solid #667c66;
            color: #ffffff
        }
.grass .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #667c66;
            color: #ffffff
        }
.grass .rec-module .field-accordion-item {
            border-bottom: 1px solid #667c66;
            color: #ffffff
        }
.grass .event-module .rec-event-accordion-item.selected {
                background-color: #424443
            }
.grass .event-module .field-accordion-item.selected {
                background-color: #424443
            }
.grass .layer-module .rec-event-accordion-item.selected {
                background-color: #424443
            }
.grass .layer-module .field-accordion-item.selected {
                background-color: #424443
            }
.grass .rec-module .rec-event-accordion-item.selected {
                background-color: #424443
            }
.grass .rec-module .field-accordion-item.selected {
                background-color: #424443
            }
.grass .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .event-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .layer-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .rec-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass .event-module .rec-event-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .event-module .field-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .layer-module .rec-event-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .layer-module .field-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .rec-module .rec-event-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .rec-module .field-accordion-item .spacer {
                background-color: #9cadb2;
            }
.grass .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #424443;
                }
.grass .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .event-module {
        background-color: #babfba;
        color: #000000
    }
.grass-light .layer-module {
        background-color: #babfba;
        color: #000000
    }
.grass-light .rec-module {
        background-color: #babfba;
        color: #000000
    }
.grass-light .event-module .rec-event-accordion-item {
            background-color: #545f54;
        }
.grass-light .layer-module .rec-event-accordion-item {
            background-color: #545f54;
        }
.grass-light .rec-module .rec-event-accordion-item {
            background-color: #545f54;
        }
.grass-light .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #babfba;
            color: #000000
        }
.grass-light .event-module .field-accordion-item {
            border-bottom: 1px solid #babfba;
            color: #000000
        }
.grass-light .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #babfba;
            color: #000000
        }
.grass-light .layer-module .field-accordion-item {
            border-bottom: 1px solid #babfba;
            color: #000000
        }
.grass-light .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #babfba;
            color: #000000
        }
.grass-light .rec-module .field-accordion-item {
            border-bottom: 1px solid #babfba;
            color: #000000
        }
.grass-light .event-module .rec-event-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .event-module .field-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .layer-module .rec-event-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .layer-module .field-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .rec-module .rec-event-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .rec-module .field-accordion-item.selected {
                background-color: #bfbfbf
            }
.grass-light .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .event-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .layer-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .rec-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.grass-light .event-module .rec-event-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .event-module .field-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .layer-module .rec-event-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .layer-module .field-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .rec-module .rec-event-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .rec-module .field-accordion-item .spacer {
                background-color: #424443;
            }
.grass-light .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #9cadb2;
                }
.grass-light .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .event-module {
        background-color: #4b3705;
        color: #ffffff
    }
.mud .layer-module {
        background-color: #4b3705;
        color: #ffffff
    }
.mud .rec-module {
        background-color: #4b3705;
        color: #ffffff
    }
.mud .event-module .rec-event-accordion-item {
            background-color: #766146;
        }
.mud .layer-module .rec-event-accordion-item {
            background-color: #766146;
        }
.mud .rec-module .rec-event-accordion-item {
            background-color: #766146;
        }
.mud .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #4b3705;
            color: #ffffff
        }
.mud .event-module .field-accordion-item {
            border-bottom: 1px solid #4b3705;
            color: #ffffff
        }
.mud .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #4b3705;
            color: #ffffff
        }
.mud .layer-module .field-accordion-item {
            border-bottom: 1px solid #4b3705;
            color: #ffffff
        }
.mud .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #4b3705;
            color: #ffffff
        }
.mud .rec-module .field-accordion-item {
            border-bottom: 1px solid #4b3705;
            color: #ffffff
        }
.mud .event-module .rec-event-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .event-module .field-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .layer-module .rec-event-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .layer-module .field-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .rec-module .rec-event-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .rec-module .field-accordion-item.selected {
                background-color: #ad8f68
            }
.mud .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .event-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .layer-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .rec-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.mud .event-module .rec-event-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .event-module .field-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .layer-module .rec-event-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .layer-module .field-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .rec-module .rec-event-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .rec-module .field-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.mud .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #8c7454;
                }
.mud .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud-light .event-module {
        background-color: #fff0bb;
        color: #000000
    }
.mud-light .layer-module {
        background-color: #fff0bb;
        color: #000000
    }
.mud-light .rec-module {
        background-color: #fff0bb;
        color: #000000
    }
.mud-light .event-module .rec-event-accordion-item {
            background-color: #a37b42;
        }
.mud-light .layer-module .rec-event-accordion-item {
            background-color: #a37b42;
        }
.mud-light .rec-module .rec-event-accordion-item {
            background-color: #a37b42;
        }
.mud-light .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #fff0bb;
            color: #000000
        }
.mud-light .event-module .field-accordion-item {
            border-bottom: 1px solid #fff0bb;
            color: #000000
        }
.mud-light .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #fff0bb;
            color: #000000
        }
.mud-light .layer-module .field-accordion-item {
            border-bottom: 1px solid #fff0bb;
            color: #000000
        }
.mud-light .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #fff0bb;
            color: #000000
        }
.mud-light .rec-module .field-accordion-item {
            border-bottom: 1px solid #fff0bb;
            color: #000000
        }
.mud-light .event-module .rec-event-accordion-item.selected {
                background-color: #735523
            }
.mud-light .event-module .field-accordion-item.selected {
                background-color: #735523
            }
.mud-light .layer-module .rec-event-accordion-item.selected {
                background-color: #735523
            }
.mud-light .layer-module .field-accordion-item.selected {
                background-color: #735523
            }
.mud-light .rec-module .rec-event-accordion-item.selected {
                background-color: #735523
            }
.mud-light .rec-module .field-accordion-item.selected {
                background-color: #735523
            }
.mud-light .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .event-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .layer-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .rec-module .field-accordion-item.selected .spacer {
                    background-color: #8fa61a;
                }
.mud-light .event-module .rec-event-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .event-module .field-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .layer-module .rec-event-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .layer-module .field-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .rec-module .rec-event-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .rec-module .field-accordion-item .spacer {
                background-color: #d6c282;
            }
.mud-light .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #b28f5a;
                }
.mud-light .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.illini .event-module {
        background-color: #00467f;
        color: #ffffff
    }
.illini .layer-module {
        background-color: #00467f;
        color: #ffffff
    }
.illini .rec-module {
        background-color: #00467f;
        color: #ffffff
    }
.illini .event-module .rec-event-accordion-item {
            background-color: #636466;
        }
.illini .layer-module .rec-event-accordion-item {
            background-color: #636466;
        }
.illini .rec-module .rec-event-accordion-item {
            background-color: #636466;
        }
.illini .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #00467f;
            color: #ffffff
        }
.illini .event-module .field-accordion-item {
            border-bottom: 1px solid #00467f;
            color: #ffffff
        }
.illini .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #00467f;
            color: #ffffff
        }
.illini .layer-module .field-accordion-item {
            border-bottom: 1px solid #00467f;
            color: #ffffff
        }
.illini .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #00467f;
            color: #ffffff
        }
.illini .rec-module .field-accordion-item {
            border-bottom: 1px solid #00467f;
            color: #ffffff
        }
.illini .event-module .rec-event-accordion-item.selected {
                background-color: #3698db
            }
.illini .event-module .field-accordion-item.selected {
                background-color: #3698db
            }
.illini .layer-module .rec-event-accordion-item.selected {
                background-color: #3698db
            }
.illini .layer-module .field-accordion-item.selected {
                background-color: #3698db
            }
.illini .rec-module .rec-event-accordion-item.selected {
                background-color: #3698db
            }
.illini .rec-module .field-accordion-item.selected {
                background-color: #3698db
            }
.illini .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .event-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .layer-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .rec-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini .event-module .rec-event-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .event-module .field-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .layer-module .rec-event-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .layer-module .field-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .rec-module .rec-event-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .rec-module .field-accordion-item .spacer {
                background-color: #f2f3f8;
            }
.illini .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #abc8e2;
                }
.illini .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .event-module {
        background-color: #e1e6fa;
        color: #000000
    }
.illini-light .layer-module {
        background-color: #e1e6fa;
        color: #000000
    }
.illini-light .rec-module {
        background-color: #e1e6fa;
        color: #000000
    }
.illini-light .event-module .rec-event-accordion-item {
            background-color: #375d81;
        }
.illini-light .layer-module .rec-event-accordion-item {
            background-color: #375d81;
        }
.illini-light .rec-module .rec-event-accordion-item {
            background-color: #375d81;
        }
.illini-light .event-module .rec-event-accordion-item {
            border-bottom: 1px solid #e1e6fa;
            color: #000000
        }
.illini-light .event-module .field-accordion-item {
            border-bottom: 1px solid #e1e6fa;
            color: #000000
        }
.illini-light .layer-module .rec-event-accordion-item {
            border-bottom: 1px solid #e1e6fa;
            color: #000000
        }
.illini-light .layer-module .field-accordion-item {
            border-bottom: 1px solid #e1e6fa;
            color: #000000
        }
.illini-light .rec-module .rec-event-accordion-item {
            border-bottom: 1px solid #e1e6fa;
            color: #000000
        }
.illini-light .rec-module .field-accordion-item {
            border-bottom: 1px solid #e1e6fa;
            color: #000000
        }
.illini-light .event-module .rec-event-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .event-module .field-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .layer-module .rec-event-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .layer-module .field-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .rec-module .rec-event-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .rec-module .field-accordion-item.selected {
                background-color: #00467f
            }
.illini-light .event-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .event-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .layer-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .layer-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .rec-module .rec-event-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .rec-module .field-accordion-item.selected .spacer {
                    background-color: #ffff00;
                }
.illini-light .event-module .rec-event-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .event-module .field-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .layer-module .rec-event-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .layer-module .field-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .rec-module .rec-event-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .rec-module .field-accordion-item .spacer {
                background-color: #abc8e2;
            }
.illini-light .event-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .event-module .field-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .layer-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .layer-module .field-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .rec-module .rec-event-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .rec-module .field-accordion-item:hover:not(.selected) {
                    background-color: #c4d7ed;
                }
.illini-light .event-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .layer-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .rec-module .rec-event-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.sky .event-module .accordion-item .rec-event-accordion-item {
            background-color: #c8c8c8
        }
.sky .layer-module .accordion-item .rec-event-accordion-item {
            background-color: #c8c8c8
        }
.sky .rec-module .accordion-item .rec-event-accordion-item {
            background-color: #c8c8c8
        }
.sky .event-module .accordion-item .rec-event-accordion-item.selected {
                background-color: #838383;
                color: #ffffff;
            }
.sky .layer-module .accordion-item .rec-event-accordion-item.selected {
                background-color: #838383;
                color: #ffffff;
            }
.sky .rec-module .accordion-item .rec-event-accordion-item.selected {
                background-color: #838383;
                color: #ffffff;
            }
.sky-light .event-module .accordion-item .rec-event-accordion-item {
            background-color: #c8c8c8
        }
.sky-light .layer-module .accordion-item .rec-event-accordion-item {
            background-color: #c8c8c8
        }
.sky-light .rec-module .accordion-item .rec-event-accordion-item {
            background-color: #c8c8c8
        }
.sky-light .event-module .accordion-item .rec-event-accordion-item.selected {
                background-color: #838383;
                color: #ffffff;
            }
.sky-light .layer-module .accordion-item .rec-event-accordion-item.selected {
                background-color: #838383;
                color: #ffffff;
            }
.sky-light .rec-module .accordion-item .rec-event-accordion-item.selected {
                background-color: #838383;
                color: #ffffff;
            }


    .event-list .module-filters .select-form-input-container {
        width: 180px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-module .field-accordion-item, .layer-module .field-accordion-item, .rec-module .field-accordion-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }
.event-module .field-accordion-item .has-selected,
        .event-module .field-accordion-item .has-no-selected,
        .layer-module .field-accordion-item .has-selected,
        .layer-module .field-accordion-item .has-no-selected,
        .rec-module .field-accordion-item .has-selected,
        .rec-module .field-accordion-item .has-no-selected {
            min-width: 5px;
            height: 38px;
            margin: 8px 0 8px 8px;
        }
.event-module .field-accordion-item .left-column, .layer-module .field-accordion-item .left-column, .rec-module .field-accordion-item .left-column {
            min-width: 25px;
            flex-grow: 1;
            margin: 9px 5px 5px 12px;
            display: flex;
            flex-direction: column
        }
.event-module .field-accordion-item .left-column .field-name-and-item-count, .layer-module .field-accordion-item .left-column .field-name-and-item-count, .rec-module .field-accordion-item .left-column .field-name-and-item-count {
                height: 20px;
                padding-top: 1px;
                padding-bottom: 1px;
                display: flex;
                flex-direction: row
            }
.event-module .field-accordion-item .left-column .field-name-and-item-count .field-name, .layer-module .field-accordion-item .left-column .field-name-and-item-count .field-name, .rec-module .field-accordion-item .left-column .field-name-and-item-count .field-name {
                    font-size: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
.event-module .field-accordion-item .left-column .field-name-and-item-count .selected-item-count, .layer-module .field-accordion-item .left-column .field-name-and-item-count .selected-item-count, .rec-module .field-accordion-item .left-column .field-name-and-item-count .selected-item-count {
                    margin-left: 10px;
                    padding: 2px 7px;
                    font-family: "open_sansregular";
                    font-size: 13px;
                    font-weight: 300;
                    border-radius: 5px 5px 5px 5px;
                }
.event-module .field-accordion-item .left-column .cust-farm-size, .layer-module .field-accordion-item .left-column .cust-farm-size, .rec-module .field-accordion-item .left-column .cust-farm-size {
                font-size: 12px;
                max-width: calc(100% - 15px);
                padding-top: 5px;
                height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
.event-module .field-accordion-item .right-columns, .layer-module .field-accordion-item .right-columns, .rec-module .field-accordion-item .right-columns {
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end
        }
.event-module .field-accordion-item .right-columns .rec-event-count, .layer-module .field-accordion-item .right-columns .rec-event-count, .rec-module .field-accordion-item .right-columns .rec-event-count {
                margin: 9px 20px 7px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-size: 14px;
                width: 32px
            }
.event-module .field-accordion-item .right-columns .rec-event-count .count, .layer-module .field-accordion-item .right-columns .rec-event-count .count, .rec-module .field-accordion-item .right-columns .rec-event-count .count {
                    font-size: 11px;
                    font-family: "open_sansitalic";
                }
.event-module .field-accordion-item .context-menu-container, .layer-module .field-accordion-item .context-menu-container, .rec-module .field-accordion-item .context-menu-container {
            margin-right: 7px;
            padding-top: 7px;
            height: 100%
        }
.event-module .field-accordion-item .context-menu-container .context-menu, .layer-module .field-accordion-item .context-menu-container .context-menu, .rec-module .field-accordion-item .context-menu-container .context-menu {
                height: calc(100% - 14px);
                width: 15px;
                margin: 0;
                line-height: 25px;
                padding-right: 1px
            }
.event-module .field-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot, .layer-module .field-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot, .rec-module .field-accordion-item .context-menu-container .context-menu:not(.expanded) .menu-dot {
                    visibility: hidden;
                }
.event-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot, .layer-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot, .rec-module .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                visibility: visible;
            }
.default .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #bfbfbf;
            }
.default .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #bfbfbf;
            }
.default .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #bfbfbf;
            }
.default .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #bfbfbf;
            }
.default .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #bfbfbf;
            }
.default .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #bfbfbf;
            }
.default .event-module .accordion-item .field-accordion-item {
                background-color: #000000;
                border-top: 1px solid #bfbfbf
            }
.default .layer-module .accordion-item .field-accordion-item {
                background-color: #000000;
                border-top: 1px solid #bfbfbf
            }
.default .rec-module .accordion-item .field-accordion-item {
                background-color: #000000;
                border-top: 1px solid #bfbfbf
            }
.default .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.default .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.default .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.default .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #000000;
                }
.default .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #000000;
                }
.default .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #000000;
                }
.default .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #404040;
                    }
.default .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #404040;
                    }
.default .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #404040;
                    }
.default-light .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .event-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #cfcfcf
            }
.default-light .layer-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #cfcfcf
            }
.default-light .rec-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #cfcfcf
            }
.default-light .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.default-light .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.default-light .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.default-light .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.default-light .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.default-light .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.default-light .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default-light .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default-light .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.default-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #919191;
                    }
.default-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #919191;
                    }
.default-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #919191;
                    }
.sky .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .event-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #c8c8c8
            }
.sky .layer-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #c8c8c8
            }
.sky .rec-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #c8c8c8
            }
.sky .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #235635;
                }
.sky .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #235635;
                }
.sky .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #235635;
                }
.sky .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.sky .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.sky .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.sky .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #0194d3;
                    }
.sky .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #0194d3;
                    }
.sky .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #0194d3;
                    }
.sky-light .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .event-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #c8c8c8
            }
.sky-light .layer-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #c8c8c8
            }
.sky-light .rec-module .accordion-item .field-accordion-item {
                background-color: #fcfcfc;
                border-top: 1px solid #c8c8c8
            }
.sky-light .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #235635;
                }
.sky-light .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #235635;
                }
.sky-light .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #235635;
                }
.sky-light .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.sky-light .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.sky-light .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fcfcfc;
                }
.sky-light .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky-light .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky-light .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #235635;
                    }
.sky-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #0194d3;
                    }
.sky-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #0194d3;
                    }
.sky-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #0194d3;
                    }
.grass .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #9cadb2;
            }
.grass .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #9cadb2;
            }
.grass .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #9cadb2;
            }
.grass .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #9cadb2;
            }
.grass .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #9cadb2;
            }
.grass .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #9cadb2;
            }
.grass .event-module .accordion-item .field-accordion-item {
                background-color: #667c66;
                border-top: 1px solid #9cadb2
            }
.grass .layer-module .accordion-item .field-accordion-item {
                background-color: #667c66;
                border-top: 1px solid #9cadb2
            }
.grass .rec-module .accordion-item .field-accordion-item {
                background-color: #667c66;
                border-top: 1px solid #9cadb2
            }
.grass .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.grass .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.grass .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.grass .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #667c66;
                }
.grass .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #667c66;
                }
.grass .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #667c66;
                }
.grass .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #325132;
                    }
.grass .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #325132;
                    }
.grass .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #325132;
                    }
.grass-light .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #424443;
            }
.grass-light .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #424443;
            }
.grass-light .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #424443;
            }
.grass-light .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #424443;
            }
.grass-light .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #424443;
            }
.grass-light .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #424443;
            }
.grass-light .event-module .accordion-item .field-accordion-item {
                background-color: #babfba;
                border-top: 1px solid #424443
            }
.grass-light .layer-module .accordion-item .field-accordion-item {
                background-color: #babfba;
                border-top: 1px solid #424443
            }
.grass-light .rec-module .accordion-item .field-accordion-item {
                background-color: #babfba;
                border-top: 1px solid #424443
            }
.grass-light .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.grass-light .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.grass-light .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.grass-light .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #babfba;
                }
.grass-light .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #babfba;
                }
.grass-light .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #babfba;
                }
.grass-light .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass-light .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass-light .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.grass-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #545f54;
                    }
.grass-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #545f54;
                    }
.grass-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #545f54;
                    }
.mud .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .event-module .accordion-item .field-accordion-item {
                background-color: #4b3705;
                border-top: 1px solid #f2f3f8
            }
.mud .layer-module .accordion-item .field-accordion-item {
                background-color: #4b3705;
                border-top: 1px solid #f2f3f8
            }
.mud .rec-module .accordion-item .field-accordion-item {
                background-color: #4b3705;
                border-top: 1px solid #f2f3f8
            }
.mud .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.mud .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.mud .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.mud .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #4b3705;
                }
.mud .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #4b3705;
                }
.mud .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #4b3705;
                }
.mud .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39104;
                    color: #ffffff;
                }
.mud .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39104;
                    color: #ffffff;
                }
.mud .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39104;
                    color: #ffffff;
                }
.mud .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.mud .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #766146;
                    }
.mud .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #766146;
                    }
.mud .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #766146;
                    }
.mud-light .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .event-module .accordion-item .field-accordion-item {
                background-color: #fff0bb;
                border-top: 1px solid #d6c282
            }
.mud-light .layer-module .accordion-item .field-accordion-item {
                background-color: #fff0bb;
                border-top: 1px solid #d6c282
            }
.mud-light .rec-module .accordion-item .field-accordion-item {
                background-color: #fff0bb;
                border-top: 1px solid #d6c282
            }
.mud-light .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.mud-light .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.mud-light .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #8fa61a;
                }
.mud-light .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fff0bb;
                }
.mud-light .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fff0bb;
                }
.mud-light .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #fff0bb;
                }
.mud-light .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.mud-light .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.mud-light .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.mud-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #8fa61a;
                    }
.mud-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #a37b42;
                    }
.mud-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #a37b42;
                    }
.mud-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #a37b42;
                    }
.illini .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .event-module .accordion-item .field-accordion-item {
                background-color: #00467f;
                border-top: 1px solid #f2f3f8
            }
.illini .layer-module .accordion-item .field-accordion-item {
                background-color: #00467f;
                border-top: 1px solid #f2f3f8
            }
.illini .rec-module .accordion-item .field-accordion-item {
                background-color: #00467f;
                border-top: 1px solid #f2f3f8
            }
.illini .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.illini .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.illini .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.illini .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #00467f;
                }
.illini .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #00467f;
                }
.illini .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #00467f;
                }
.illini .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #636466;
                    }
.illini .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #636466;
                    }
.illini .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #636466;
                    }
.illini-light .event-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .event-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .layer-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .layer-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .rec-module .accordion-item:last-child .field-accordion-item {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .rec-module .accordion-item.has-children .field-accordion-item {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .event-module .accordion-item .field-accordion-item {
                background-color: #e1e6fa;
                border-top: 1px solid #abc8e2
            }
.illini-light .layer-module .accordion-item .field-accordion-item {
                background-color: #e1e6fa;
                border-top: 1px solid #abc8e2
            }
.illini-light .rec-module .accordion-item .field-accordion-item {
                background-color: #e1e6fa;
                border-top: 1px solid #abc8e2
            }
.illini-light .event-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.illini-light .layer-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.illini-light .rec-module .accordion-item .field-accordion-item .has-selected {
                    background-color: #ffff00;
                }
.illini-light .event-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #e1e6fa;
                }
.illini-light .layer-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #e1e6fa;
                }
.illini-light .rec-module .accordion-item .field-accordion-item .has-no-selected {
                    background-color: #e1e6fa;
                }
.illini-light .event-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini-light .layer-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini-light .rec-module .accordion-item .field-accordion-item .left-column .selected-item-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu .menu-dot {
                        color: #ffff00;
                    }
.illini-light .event-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #375d81;
                    }
.illini-light .layer-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #375d81;
                    }
.illini-light .rec-module .accordion-item .field-accordion-item:hover .context-menu-container .context-menu:hover {
                        background-color: #375d81;
                    }

.action-panel .event-module .event-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column
}
.action-panel .event-module .event-list .module-loader {
        width: 446px;
        height: 100%;
    }
.action-panel .event-module .event-list .lock-icon {
        height: 35px;
        width: 45px;
        padding-left: 200px;
        padding-top: 50px;
    }
.action-panel .event-module .event-list .lock-icon-text {
        padding-top: 20px;
        text-align: center;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.yield-calibration-dialog {
    height: 550px;
    max-height: 550px;
    min-width: 1000px
}
.yield-calibration-dialog .calibrated {
        width: 80%
    }
.yield-calibration-dialog .calibrated .checkbox-label {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
.yield-calibration-dialog .calibrated-legend {
        margin-left: 9em;
        display: flex;
        position: absolute;
        bottom: 1em
    }
.yield-calibration-dialog .calibrated-legend .calibrated-legend-symbol {
            width: 20px;
            height: 20px;
            margin-right: 0.5em;
        }
.yield-calibration-dialog .calibrated-legend .calibrated-legend-label {
            line-height: 20px;
        }
.yield-calibration-dialog .no-bucket-header {
        margin: 0;
        padding: 0;
        height: 0;
    }
.yield-calibration-dialog .yield-calibration-select-loads {
        padding-right: 10px
    }
.yield-calibration-dialog .yield-calibration-select-loads > .bucket-content {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 433px;
        }
.yield-calibration-dialog .yield-calibration-select-loads .select-load-panel {
            min-height: 375px
        }
.yield-calibration-dialog .yield-calibration-select-loads .select-load-panel .bucket {
                margin: 0;
            }
.yield-calibration-dialog .yield-calibration-select-loads .select-load-panel .customer-field-name,
            .yield-calibration-dialog .yield-calibration-select-loads .select-load-panel .equipment-profile-name {
                font-size: 13px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 92%;
                width: 92%;
                margin-left: 42px;
            }
.yield-calibration-dialog .yield-calibration-select-loads .select-load-panel .customer-field-name {
                height: 15px !important;
            }
.yield-calibration-dialog .yield-calibration-select-loads .select-load-panel .equipment-profile-name {
                font-weight: 700;
                height: auto !important;
                margin-left: 20px;
            }
.yield-calibration-dialog .yield-calibration-select-loads .select-load-panel .customer-field-bucket .checkbox-div {
                    margin-left: 41px;
                }
.yield-calibration-dialog .yield-calibration-title {
        border-bottom: 1px;
        border-bottom-style: solid;
        font-size: 14px;
        display: flex;
        padding-bottom: 5px;
        height: 15px !important
    }
.yield-calibration-dialog .yield-calibration-title .bucket-symbol {
            display: none;
        }
.yield-calibration-dialog .yield-calibration-title .checkbox-div,
        .yield-calibration-dialog .yield-calibration-title span {
            width: 67%;
        }
.yield-calibration-dialog .yield-calibration-title .reset-link {
            font-size: 13px;
            text-align: right;
            width: 34%;
        }
.yield-calibration-dialog .yield-calibration-select-loads {
        min-height: 450px;
        border-right: 1px;
        border-right-style: solid;
        width: 450px
    }
.yield-calibration-dialog .yield-calibration-select-loads .bucket-content {
            display: block
        }
.yield-calibration-dialog .yield-calibration-select-loads .bucket-content .bucket.non-collapsible {
                margin-bottom: 5px;
            }
.yield-calibration-dialog .yield-calibration-select-loads .checkbox-div {
            height: 22px;
            padding: 2px;
            border-radius: 3px;
        }
.yield-calibration-dialog .yield-calibration-averages-tables {
        max-height: 200px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
.yield-calibration-dialog .yield-calibration-averages {
        position: absolute;
        top: 50px;
        right: 20px;
        min-width: 500px;
        width: 500px;
        margin: 6px 0
    }
.yield-calibration-dialog .yield-calibration-averages .crop-total-yield-label {
            padding: 5px 0;
        }
.yield-calibration-dialog .yield-calibration-averages .simple-table > div {
                font-size: 13px;
            }
.yield-calibration-dialog .yield-calibration-results-table {
        max-height: 200px;
        font-size: 13px;
    }
.yield-calibration-dialog .yield-calibration-averages-input {
        z-index: 1000;
        margin-top: 0
    }
.yield-calibration-dialog .yield-calibration-averages-input .radio-div {
            height: 50px;
            margin-top: 1em
        }
.yield-calibration-dialog .yield-calibration-averages-input .radio-div .radio-label {
                height: 50px;
                width: 35em;
            }
.yield-calibration-dialog .yield-calibration-averages-input .radio-div .radio-button-container {
                margin-top: 23px;
            }
.yield-calibration-dialog .yield-calibration-averages-input .input-row {
            width: 100%;
            display: flex;
            flex-direction: row
        }
.yield-calibration-dialog .yield-calibration-averages-input .input-row > * {
                flex-grow: 1;
                width: 20px;
                margin: 3px 2px;
                max-width: 11em;
            }
.yield-calibration-dialog .yield-calibration-averages-input .input-row > .select-form-input-container .select-form-input.form-input.text-form-input {
                margin: 0px;
            }
.yield-calibration-dialog .yield-calibration-averages-input .label {
            margin-top: 25px;
            max-width: 11em;
        }
.yield-calibration-dialog .yield-calibration-averages-input .no-grow {
            flex-grow: 0;
            margin-top: 1em;
        }
.yield-calibration-dialog .yield-calibration-averages-input .override-checkbox {
            flex-grow: 0.8;
            margin-top: 1em;
        }
.yield-calibration-dialog .yield-calibration-averages-input .yield-calibration-form-input {
            max-width: 125px;
        }
.yield-calibration-dialog .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        margin-left: 10px;
    }
.yield-calibration-dialog .harvest-event-checkbox {
        margin: auto;
        padding-left: 5px;
    }
.yield-calibration-dialog .select-bales-type {
        margin-right: 8px;
    }
.default .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #8fa61a;
            }
.default .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #8fa61a;
        }
.default .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #ffffff;
        }
.default .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #ffffff;
        }
.default .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #ffffff;
        }
.default-light .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #8fa61a;
            }
.default-light .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #8fa61a;
        }
.default-light .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #000000;
        }
.default-light .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #000000;
        }
.default-light .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #000000;
        }
.sky .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #235635;
            }
.sky .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #235635;
        }
.sky .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #000000;
        }
.sky .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #000000;
        }
.sky .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #000000;
        }
.sky-light .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #235635;
            }
.sky-light .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #235635;
        }
.sky-light .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #000000;
        }
.sky-light .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #000000;
        }
.sky-light .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #000000;
        }
.grass .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #ffff00;
            }
.grass .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #ffff00;
        }
.grass .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #ffffff;
        }
.grass .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #ffffff;
        }
.grass .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #ffffff;
        }
.grass-light .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #ffff00;
            }
.grass-light .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #ffff00;
        }
.grass-light .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #000000;
        }
.grass-light .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #000000;
        }
.grass-light .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #000000;
        }
.mud .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #ffff00;
            }
.mud .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #ffff00;
        }
.mud .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #ffffff;
        }
.mud .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #ffffff;
        }
.mud .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #ffffff;
        }
.mud-light .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #8fa61a;
            }
.mud-light .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #8fa61a;
        }
.mud-light .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #000000;
        }
.mud-light .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #000000;
        }
.mud-light .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #000000;
        }
.illini .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #ffff00;
            }
.illini .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #ffff00;
        }
.illini .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #ffffff;
        }
.illini .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #ffffff;
        }
.illini .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #ffffff;
        }
.illini-light .yield-calibration-dialog .calibrated .checkbox-label {
                background-color: #ffff00;
            }
.illini-light .yield-calibration-dialog .calibrated-legend-symbol {
            background-color: #ffff00;
        }
.illini-light .yield-calibration-dialog .yield-calibration-title {
            border-bottom-color: #000000;
        }
.illini-light .yield-calibration-dialog .yield-calibration-select-loads {
            border-right-color: #000000;
        }
.illini-light .yield-calibration-dialog .yield-calibration-averages-tables {
            border-bottom-color: #000000;
        }

.merge-events-modal {
    overflow: none;
    padding: 10px;
}

.event-list-item {
    display: block;
}

.event-list-box {
    padding-top: 10px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info-form.sampling-tissue-zone-info {
    display: flex;
    flex-direction: column
}
.rec-event-info-form.sampling-tissue-zone-info .select-form-input-container {
        max-width: 300px
    }
.rec-event-info-form.sampling-tissue-zone-info .select-form-input-container .select-letter-icon {
            height: 17px;
            min-width: 17px;
            line-height: 17px;
            font-size: 12px;
            border-radius: 50%;
            margin: 0 5px;
            padding: 0 5px;
            text-align: center;
            box-shadow: none;
            border: 1px solid black;
        }
.rec-event-info-form.sampling-tissue-zone-info .display-layer-select-input {
        margin-left: 5px;
        flex: 0 0 75%;
    }
.rec-event-info-form.sampling-tissue-zone-info .sample-zone-info-layer-controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center
    }
.rec-event-info-form.sampling-tissue-zone-info .sample-zone-info-layer-controls .surface-clear {
            padding: 2px 2px 0 4px;
            cursor: pointer;
            pointer-events: auto;
            flex: 0 0 25%;
            text-align: -webkit-center;
            max-width: 60px;
        }
.rec-event-info-form.sampling-tissue-zone-info .sample-zone-info-layer-controls .context-menu-container {
            flex: 0 0 25%;
            text-align: -webkit-center
        }
.rec-event-info-form.sampling-tissue-zone-info .sample-zone-info-layer-controls .context-menu-container .menu-dots {
                float: none;
                max-width: 60px;
            }
.rec-event-info-form.sampling-tissue-zone-info .zone-legend-container {
        flex-shrink: 0;
        max-height: 230px;
        overflow-x: hidden;
        overflow-y: auto
    }
.rec-event-info-form.sampling-tissue-zone-info .zone-legend-container .zone-legend-heading-container {
            margin: 10px 0 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between
        }
.rec-event-info-form.sampling-tissue-zone-info .zone-legend-container .zone-legend-heading-container .svg-container {
                cursor: pointer
            }
.rec-event-info-form.sampling-tissue-zone-info .zone-legend-container .zone-legend-heading-container .svg-container .default-svg-icon {
                    height: 15px;
                    width: 15px;
                    margin: 0 10px;
                }
.rec-event-info-form.sampling-tissue-zone-info .zone-legend-container .event-zone-classbreaks-legend {
            cursor: default;
        }
.rec-event-info-form.sampling-tissue-zone-info .zone-legend-container .interpolation-cb {
            margin: 13px 10px 0 10px;
        }
.default .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.default-light .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.sky .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.sky-light .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.grass .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.grass-light .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.mud .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.mud-light .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.illini .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }
.illini-light .rec-event-info-form.sampling-zone-info .event-zone-classbreaks-legend:hover {
            background-color: inherit;
        }

.event-filter-form {
    display: flex;
    flex-direction: column
}
.event-filter-form .expand-icon {
        display: none;
    }
.event-filter-form .bucket-header {
        font-size: 13px;
    }
.event-filter-form .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        margin-left: 10px;
    }
.event-filter-form .checkbox-child {
        display: flex;
        align-items: center;
    }
.event-filter-form .checkbox-parent {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;
    }
.event-filter-form .filter-form-table {
        width: 90%;
        line-height: 25px;
        text-align: right
    }
.event-filter-form .filter-form-table th,
        .event-filter-form .filter-form-table td {
            width: 15%;
        }
.event-filter-form .filter-form-table th.label-col,
        .event-filter-form .filter-form-table td.label-col {
            width: 45%;
            padding-left: 25px;
            text-align: left;
        }
.event-filter-form .tree-view-container .node-child-container {
        margin-left: 25px;
    }
.event-filter-form .filter-tree-view {
        width: 90%;
        position: relative;
        left: 5px;
    }
.event-filter-form .filter-row {
        display: flex;
        width: 90%;
        justify-content: space-between;
    }
.event-filter-form .filter-row title {
            text-transform: capitalize;
        }
.event-filter-form .filter-row {
        align-items: center;
    }


    .ec-data-event-container .ec-data-layer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        width: 95%
    }
.ec-data-event-container .ec-data-layer .ec-data-layer-controls {
            width: 100%;
            display: flex
        }
.ec-data-event-container .ec-data-layer .ec-data-layer-controls .ec-data-radio-btn-grp {
                flex-grow: 1;
                line-height: 40px
            }
.ec-data-event-container .ec-data-layer .ec-data-layer-controls .ec-data-radio-btn-grp .radio-button-container {
                    margin-top: 9px;
                }
.ec-data-event-container .ec-data-layer .ec-data-layer-controls .ec-data-radio-btn-grp .radio-label {
                    height: 40px;
                }
.ec-data-event-container .ec-data-layer .ec-data-layer-controls .ec-data-radio-btn-grp .radio-label-text-span {
                    font-size: 14px;
                }
.ec-data-event-container .ec-data-layer .ec-data-layer-class-breaks {
            width: 75%
        }
.ec-data-event-container .ec-data-layer .ec-data-layer-class-breaks .break-row {
                display: flex;
                margin-left: 30px;
                font-size: 14px;
                padding-top: 10px;
                line-height: 20px;
            }
.ec-data-event-container .ec-data-layer .ec-data-layer-class-breaks .symbolBox {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }


    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        margin-left: 10px;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .harvest-event-checkbox {
        margin: auto;
        padding-left: 5px;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .select-bales-type .select-form-input.form-input.text-form-input {
            margin: 5px 0px 5px 3px;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .harvest-select-cont {
        width: 49%;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .harvest-text-cont {
        width: 44%;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .col-3 {
        width: 35%;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .harvest-event-container .feed-quality-3 {
        max-width: 29%;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-application-field-data .input-row {
        width: 100%;
        display: flex;
        flex-direction: row
    }
.event-application-field-data .input-row > * {
            flex-grow: 1;
            width: 20px;
        }
.event-application-form {
    padding: 5px
}
.event-application-form .add-mix {
        text-align: right;
        padding-top: 15px;
        padding-right: 15px;
        top: 0px;
    }
.event-application-form .product-blend-list {
        display: flex;
        flex-flow: row wrap;
        padding: 10px
    }
.event-application-form .product-blend-list .product-blend-row {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px dashed
        }
.event-application-form .product-blend-list .product-blend-row .mix-information {
                display: flex;
                flex-flow: row wrap;
                min-width: 60%;
                justify-content: flex-start
            }
.event-application-form .product-blend-list .product-blend-row .mix-information .mix-title {
                    font-size: 15px;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-weight: 700;
                    width: 100%;
                    padding-bottom: 4px;
                }
.event-application-form .product-blend-list .product-blend-row .mix-information .mix-product-list {
                    display: flex;
                    flex-flow: column nowrap;
                    padding-top: 10px
                }
.event-application-form .product-blend-list .product-blend-row .mix-information .mix-product-list .product-row {
                        padding-left: 20px;
                        padding-bottom: 10px;
                    }
.event-application-form .product-blend-list .product-blend-row .rate-information {
                display: flex;
                min-width: 120px;
                justify-content: flex-end;
                line-height: 40px
            }
.event-application-form .product-blend-list .product-blend-row .rate-information .target-rate {
                    padding-right: 3px;
                    min-width: 40px;
                }
.event-application-form .product-blend-list .product-blend-row .rate-information .target-rate.is-locked {
                    padding-right: 3px;
                    min-width: 70px
                }
.event-application-form .product-blend-list .product-blend-row .rate-information .target-rate.is-locked .form-input {
                        height: 25px
                    }
.event-application-form .product-blend-list .product-blend-row .rate-information .target-rate.is-locked .form-input input {
                            text-align: center;
                        }
.event-application-form .product-blend-list .product-blend-row .rate-information .target-rate-unit {
                    min-width: 40px;
                }
.default .event-application-field-data {
            background-color: #404040;
        }
.default-light .event-application-field-data {
            background-color: #919191;
        }
.sky .event-application-field-data {
            background-color: #0194d3;
        }
.sky-light .event-application-field-data {
            background-color: #0194d3;
        }
.grass .event-application-field-data {
            background-color: #325132;
        }
.grass-light .event-application-field-data {
            background-color: #545f54;
        }
.mud .event-application-field-data {
            background-color: #766146;
        }
.mud-light .event-application-field-data {
            background-color: #a37b42;
        }
.illini .event-application-field-data {
            background-color: #636466;
        }
.illini-light .event-application-field-data {
            background-color: #375d81;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info-summary {
    padding: 10px;
    display: flex;
    flex-flow: row wrap
}
.rec-event-info-summary .field-totals {
        min-width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
.rec-event-info-summary .summary-table {
        display: flex;
        flex-flow: column wrap;
        min-width: 100%;
        padding-top: 10px;
        padding-bottom: 10px
    }
.rec-event-info-summary .summary-table .summary-table-header,
        .rec-event-info-summary .summary-table .summary-row {
            display: flex;
            flex-flow: row nowrap;
            min-width: 100%;
            max-width: 100%;
            height: 22px;
        }
.rec-event-info-summary .summary-table .summary-table-header {
            font-style: italic;
            font-weight: 700;
        }
.rec-event-info-summary .summary-table .summary-table-body {
            display: flex;
            flex-flow: row wrap;
            min-width: 100%;
            max-width: 100%;
            padding-top: 5px;
        }
.rec-event-info-summary .summary-table .summary-column {
            min-width: 25%;
            width: 25%;
            max-width: 25%;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
.rec-event-info-summary .summary-table .summary-column-wide {
            min-width: 49%;
            width: 49%;
            max-width: 49%;
            text-align: left;
        }
.rec-event-info-summary .summary-table .summary-table-totals {
            display: flex;
            flex-flow: row wrap;
            margin-top: 10px
        }
.rec-event-info-summary .summary-table .summary-table-totals .total-row {
                display: flex;
                flex-flow: row nowrap;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                font-size: 13px;
                justify-content: flex-end
            }
.rec-event-info-summary .summary-table .summary-table-totals .total-row .total-row-label {
                    min-width: 20%;
                    width: 20%;
                    max-width: 20%;
                }
.rec-event-info-summary .summary-table .summary-table-totals .total-row .total-row-value {
                    min-width: 20%;
                    width: 20%;
                    max-width: 20%;
                    text-align: right;
                }
.default .rec-event-info-summary {
            background-color: #404040
        }
.default .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #737373;
                border-bottom: 1px solid #737373;
            }
.default-light .rec-event-info-summary {
            background-color: #919191
        }
.default-light .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #b8b8b8;
                border-bottom: 1px solid #b8b8b8;
            }
.sky .rec-event-info-summary {
            background-color: #0194d3
        }
.sky .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #838383;
                border-bottom: 1px solid #838383;
            }
.sky-light .rec-event-info-summary {
            background-color: #0194d3
        }
.sky-light .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #838383;
                border-bottom: 1px solid #838383;
            }
.grass .rec-event-info-summary {
            background-color: #325132
        }
.grass .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #424443;
                border-bottom: 1px solid #424443;
            }
.grass-light .rec-event-info-summary {
            background-color: #545f54
        }
.grass-light .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #bfbfbf;
                border-bottom: 1px solid #bfbfbf;
            }
.mud .rec-event-info-summary {
            background-color: #766146
        }
.mud .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #ad8f68;
                border-bottom: 1px solid #ad8f68;
            }
.mud-light .rec-event-info-summary {
            background-color: #a37b42
        }
.mud-light .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #735523;
                border-bottom: 1px solid #735523;
            }
.illini .rec-event-info-summary {
            background-color: #636466
        }
.illini .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #3698db;
                border-bottom: 1px solid #3698db;
            }
.illini-light .rec-event-info-summary {
            background-color: #375d81
        }
.illini-light .rec-event-info-summary .summary-table-body {
                border-top: 1px solid #00467f;
                border-bottom: 1px solid #00467f;
            }
.sky .rec-event-info-summary {
            background-color: #e2e2e2;
        }
.sky-light .rec-event-info-summary {
            background-color: #e2e2e2;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-sample-soil-form .dialog-container.sample-soil-depth-modal {
    width: 350px
}
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;
        min-height: 100px
    }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .err-msg-detail {
            margin-top: 25px;
            padding-left: 10px;
            padding-right: 10px;
        }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .select-form-input-container {
            width: 110px;
            margin: 5px;
        }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table {
            margin: 20px 40px
        }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr {
                font-size: 14px;
                font-weight: 500;
                padding-bottom: 5px;
            }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr,
            .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center
            }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .form-input, .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .form-input {
                    margin: 5px
                }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .form-input .input-container,
                    .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .form-input input,
                    .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .form-input .input-container,
                    .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .form-input input {
                        height: 100%;
                        text-align: center;
                        text-indent: 0;
                    }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr > div, .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row > div {
                    flex-grow: 1;
                    width: 10px;
                }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .remove-icon, .event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .remove-icon {
                    cursor: pointer;
                }
.event-sample-soil-form .dialog-container.sample-soil-depth-modal .dialog-box-body .add-link {
            display: block;
            margin-top: 15px;
            width: 120px;
        }
.default .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #262626;
        }
.default .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.default-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #e2e2e2;
        }
.default-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.sky .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b1b1b1;
        }
.sky .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.sky-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b1b1b1;
        }
.sky-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.grass .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #424443;
        }
.grass .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.grass-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #9cadb2;
        }
.grass-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.mud .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #8c7454;
        }
.mud .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.mud-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b28f5a;
        }
.mud-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.illini .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #abc8e2;
        }
.illini .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.illini-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #c4d7ed;
        }
.illini-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-sample-soil-form .dialog-container.place-grid-modal {
    height: 300px
}
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0
    }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .radio-btn-groups {
            margin: 10px 20px
        }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .radio-btn-groups .radio-grp {
                display: flex;
                flex-direction: row;
                padding-top: 2px;
                font-size: 14px
            }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .radio-btn-groups .radio-grp .radio-grp-lbl {
                    width: 85px;
                }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .radio-btn-groups .radio-grp .radio-div {
                    width: 105px;
                }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .dimensions .dim-input {
                display: flex;
                flex-direction: row;
                margin-left: 20px;
                width: 250px
            }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .dimensions .dim-input .select-form-input-container {
                    margin: 0;
                }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .dimensions .dim-input > * {
                    flex-grow: 1;
                    min-width: 10px;
                }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .dimensions .out-of-range-msg {
                margin: 5px 10px;
                font-weight: 600;
            }
.event-sample-soil-form .dialog-container.place-grid-modal .dialog-box-body .instructions {
            margin: 20px 20px 0 15px;
            font-style: italic;
        }
.default .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.default-light .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.sky .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.sky-light .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.grass .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.grass-light .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.mud .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.mud-light .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.illini .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }
.illini-light .rec-event-info .dialog-container.place-grid-modal .dialog-box-body .out-of-range-msg {
                color: #f89406;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl {
    left: 0;
    right: 0;
    top: 0;
    height: calc(100% - 30px);
    overflow-x: auto;
    overflow-y: auto;
    position: relative
}
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .sticky,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .sticky-all {
        position: absolute;
        top: auto;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header {
        min-height: 0;
        :not(.sticky) {
            visibility: hidden;
            z-index: 0;
        }
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .row {
        display: flex;
        flex-direction: row;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell {
        font-weight: 400;
        padding-top: 16px;
        text-align: center
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.sticky-col,
        .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.sticky {
            z-index: 9;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.sticky-all {
            z-index: 10;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell {
        z-index: 0
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell.sticky-col {
            z-index: 5;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell {
        flex-grow: 1;
        height: 49px;
        min-width: 55px;
        box-sizing: border-box
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.prod-rating, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer.prod-rating, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell.prod-rating {
            min-width: 135px;
            overflow: visible
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.prod-rating.fixed-container, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer.prod-rating.fixed-container, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell.prod-rating.fixed-container {
                z-index: 10;
            }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell .body-text, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer .body-text, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell .body-text {
            text-align: center;
            padding-top: 18px;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .select-form-input-container {
        margin: 0;
        width: 135px
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .select-form-input-container .form-input {
            margin: 2px;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .select-form-input-container .select-form-input-options {
            margin-top: 0;
            margin-bottom: 0;
        }
.default .sample-point-tbl {
        background-color: #404040
    }
.default .sample-point-tbl .header-cell {
            background-color: #000000;
        }
.default .sample-point-tbl .header-cell {
            background-color: #000000;
            border-bottom: 1px solid #bfbfbf
        }
.default .sample-point-tbl .body-cell {
            background-color: #000000;
            border-bottom: 1px solid #bfbfbf
        }
.default .sample-point-tbl .header-cell.selected {
                background-color: #8fa61a;
            }
.default .sample-point-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.default .sample-point-tbl .sticky-col {
            background-color: #262626;
        }
.default .sample-point-tbl .sticky-all {
            background-color: #262626;
        }
.default-light .sample-point-tbl {
        background-color: #919191
    }
.default-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
        }
.default-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #cfcfcf
        }
.default-light .sample-point-tbl .body-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #cfcfcf
        }
.default-light .sample-point-tbl .header-cell.selected {
                background-color: #8fa61a;
            }
.default-light .sample-point-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.default-light .sample-point-tbl .sticky-col {
            background-color: #e2e2e2;
        }
.default-light .sample-point-tbl .sticky-all {
            background-color: #e2e2e2;
        }
.sky .sample-point-tbl {
        background-color: #0194d3
    }
.sky .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
        }
.sky .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky .sample-point-tbl .body-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky .sample-point-tbl .header-cell.selected {
                background-color: #235635;
            }
.sky .sample-point-tbl .body-cell.selected {
                background-color: #235635;
            }
.sky .sample-point-tbl .sticky-col {
            background-color: #b1b1b1;
        }
.sky .sample-point-tbl .sticky-all {
            background-color: #b1b1b1;
        }
.sky-light .sample-point-tbl {
        background-color: #0194d3
    }
.sky-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
        }
.sky-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky-light .sample-point-tbl .body-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky-light .sample-point-tbl .header-cell.selected {
                background-color: #235635;
            }
.sky-light .sample-point-tbl .body-cell.selected {
                background-color: #235635;
            }
.sky-light .sample-point-tbl .sticky-col {
            background-color: #b1b1b1;
        }
.sky-light .sample-point-tbl .sticky-all {
            background-color: #b1b1b1;
        }
.grass .sample-point-tbl {
        background-color: #325132
    }
.grass .sample-point-tbl .header-cell {
            background-color: #667c66;
        }
.grass .sample-point-tbl .header-cell {
            background-color: #667c66;
            border-bottom: 1px solid #9cadb2
        }
.grass .sample-point-tbl .body-cell {
            background-color: #667c66;
            border-bottom: 1px solid #9cadb2
        }
.grass .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.grass .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.grass .sample-point-tbl .sticky-col {
            background-color: #424443;
        }
.grass .sample-point-tbl .sticky-all {
            background-color: #424443;
        }
.grass-light .sample-point-tbl {
        background-color: #545f54
    }
.grass-light .sample-point-tbl .header-cell {
            background-color: #babfba;
        }
.grass-light .sample-point-tbl .header-cell {
            background-color: #babfba;
            border-bottom: 1px solid #424443
        }
.grass-light .sample-point-tbl .body-cell {
            background-color: #babfba;
            border-bottom: 1px solid #424443
        }
.grass-light .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.grass-light .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.grass-light .sample-point-tbl .sticky-col {
            background-color: #9cadb2;
        }
.grass-light .sample-point-tbl .sticky-all {
            background-color: #9cadb2;
        }
.mud .sample-point-tbl {
        background-color: #766146
    }
.mud .sample-point-tbl .header-cell {
            background-color: #4b3705;
        }
.mud .sample-point-tbl .header-cell {
            background-color: #4b3705;
            border-bottom: 1px solid #f2f3f8
        }
.mud .sample-point-tbl .body-cell {
            background-color: #4b3705;
            border-bottom: 1px solid #f2f3f8
        }
.mud .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.mud .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.mud .sample-point-tbl .sticky-col {
            background-color: #8c7454;
        }
.mud .sample-point-tbl .sticky-all {
            background-color: #8c7454;
        }
.mud-light .sample-point-tbl {
        background-color: #a37b42
    }
.mud-light .sample-point-tbl .header-cell {
            background-color: #fff0bb;
        }
.mud-light .sample-point-tbl .header-cell {
            background-color: #fff0bb;
            border-bottom: 1px solid #d6c282
        }
.mud-light .sample-point-tbl .body-cell {
            background-color: #fff0bb;
            border-bottom: 1px solid #d6c282
        }
.mud-light .sample-point-tbl .header-cell.selected {
                background-color: #8fa61a;
            }
.mud-light .sample-point-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.mud-light .sample-point-tbl .sticky-col {
            background-color: #b28f5a;
        }
.mud-light .sample-point-tbl .sticky-all {
            background-color: #b28f5a;
        }
.illini .sample-point-tbl {
        background-color: #636466
    }
.illini .sample-point-tbl .header-cell {
            background-color: #00467f;
        }
.illini .sample-point-tbl .header-cell {
            background-color: #00467f;
            border-bottom: 1px solid #f2f3f8
        }
.illini .sample-point-tbl .body-cell {
            background-color: #00467f;
            border-bottom: 1px solid #f2f3f8
        }
.illini .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.illini .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.illini .sample-point-tbl .sticky-col {
            background-color: #abc8e2;
        }
.illini .sample-point-tbl .sticky-all {
            background-color: #abc8e2;
        }
.illini-light .sample-point-tbl {
        background-color: #375d81
    }
.illini-light .sample-point-tbl .header-cell {
            background-color: #e1e6fa;
        }
.illini-light .sample-point-tbl .header-cell {
            background-color: #e1e6fa;
            border-bottom: 1px solid #abc8e2
        }
.illini-light .sample-point-tbl .body-cell {
            background-color: #e1e6fa;
            border-bottom: 1px solid #abc8e2
        }
.illini-light .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.illini-light .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.illini-light .sample-point-tbl .sticky-col {
            background-color: #c4d7ed;
        }
.illini-light .sample-point-tbl .sticky-all {
            background-color: #c4d7ed;
        }
.sky .sample-point-tbl .body-cell.selected {
        color: #ffffff;
    }
.sky-light .sample-point-tbl .body-cell.selected {
        color: #ffffff;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    position: relative
}
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl {
        overflow: auto;
        position: relative
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container {
            margin: 0;
            width: 70px
        }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .input-container {
                margin-top: 5px;
            }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .form-input {
                border-radius: 0;
                height: 30px;
                margin: 0;
                min-width: 70px;
                padding: 0;

                .default-svg-icon {
                    height: 10px;
                    width: 10px;
                }
            }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .form-input .expand-contract-arrow,
                .rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .form-input .clear-btn-icon {
                    height: 10px;
                    padding: 0;
                    width: 10px;
                }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .form-input input {
                    font-size: 11px;
                    height: 16px;
                    min-width: 45px;
                    text-align: center;
                    text-indent: 0;
                }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .form-input svg {
                    height: 10px;
                    margin: 0;
                    padding: 0;
                    width: 10px;
                }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .form-input .input-label-container {
                    width: 40px;
                }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .sample-results-tbl .select-form-input-container .select-form-input-options {
                margin-top: 0;
                margin-bottom: 0;
                text-align: left;
            }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .measure {
        display: inline-block;
        min-width: 100px;
        max-height: 100px;
        visibility: hidden;
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .attribute-name {
        font-weight: 400;
        visibility: hidden;
        z-index: 2
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .attribute-name.sticky {
            position: absolute;
            visibility: visible;
        }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .header-cell {
        height: 24px;
        padding: 10px 5px 0 5px
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .header-cell.sticky-col,
        .rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .header-cell.sticky {
            position: absolute;
            z-index: 10;
        }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .body-cell {
        height: 25px;
        padding: 5px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .prod-rating {
        min-width: 70px;
        overflow: visible;
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .attribute-val-cols {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        margin: 3px;
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .row {
        display: flex;
        flex-direction: row;
        text-align: center
    }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .row > div:not(.attribute-name) {
            flex-grow: 1;
            min-width: 70px;
        }
.rec-event-info
    .tabs.information-tabs
    > .tabs-pane
    .rec-event-info-form
    .sample-results-tbl-container .row.sticky {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 10;
        }
.default .sample-results-tbl {
        background-color: #000000
    }
.default .sample-results-tbl .header-cell {
            background-color: #000000;
            border-bottom: 1px solid #777777;
        }
.default .sample-results-tbl .sticky-col {
            background-color: #262626;
        }
.default .sample-results-tbl .body-cell {
            border-bottom: 1px solid #777777
        }
.default .sample-results-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.default .sample-results-tbl .attribute-name {
            background-color: #262626;
            border-bottom: 1px solid #777777;
        }
.default-light .sample-results-tbl {
        background-color: #fcfcfc
    }
.default-light .sample-results-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #777777;
        }
.default-light .sample-results-tbl .sticky-col {
            background-color: #e2e2e2;
        }
.default-light .sample-results-tbl .body-cell {
            border-bottom: 1px solid #777777
        }
.default-light .sample-results-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.default-light .sample-results-tbl .attribute-name {
            background-color: #e2e2e2;
            border-bottom: 1px solid #777777;
        }
.sky .sample-results-tbl {
        background-color: #fcfcfc
    }
.sky .sample-results-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #878787;
        }
.sky .sample-results-tbl .sticky-col {
            background-color: #b1b1b1;
        }
.sky .sample-results-tbl .body-cell {
            border-bottom: 1px solid #878787
        }
.sky .sample-results-tbl .body-cell.selected {
                background-color: #235635;
            }
.sky .sample-results-tbl .attribute-name {
            background-color: #b1b1b1;
            border-bottom: 1px solid #878787;
        }
.sky-light .sample-results-tbl {
        background-color: #fcfcfc
    }
.sky-light .sample-results-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #878787;
        }
.sky-light .sample-results-tbl .sticky-col {
            background-color: #b1b1b1;
        }
.sky-light .sample-results-tbl .body-cell {
            border-bottom: 1px solid #878787
        }
.sky-light .sample-results-tbl .body-cell.selected {
                background-color: #235635;
            }
.sky-light .sample-results-tbl .attribute-name {
            background-color: #b1b1b1;
            border-bottom: 1px solid #878787;
        }
.grass .sample-results-tbl {
        background-color: #667c66
    }
.grass .sample-results-tbl .header-cell {
            background-color: #667c66;
            border-bottom: 1px solid #e5e9e5;
        }
.grass .sample-results-tbl .sticky-col {
            background-color: #424443;
        }
.grass .sample-results-tbl .body-cell {
            border-bottom: 1px solid #e5e9e5
        }
.grass .sample-results-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.grass .sample-results-tbl .attribute-name {
            background-color: #424443;
            border-bottom: 1px solid #e5e9e5;
        }
.grass-light .sample-results-tbl {
        background-color: #babfba
    }
.grass-light .sample-results-tbl .header-cell {
            background-color: #babfba;
            border-bottom: 1px solid #e5e9e5;
        }
.grass-light .sample-results-tbl .sticky-col {
            background-color: #9cadb2;
        }
.grass-light .sample-results-tbl .body-cell {
            border-bottom: 1px solid #e5e9e5
        }
.grass-light .sample-results-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.grass-light .sample-results-tbl .attribute-name {
            background-color: #9cadb2;
            border-bottom: 1px solid #e5e9e5;
        }
.mud .sample-results-tbl {
        background-color: #4b3705
    }
.mud .sample-results-tbl .header-cell {
            background-color: #4b3705;
            border-bottom: 1px solid #777777;
        }
.mud .sample-results-tbl .sticky-col {
            background-color: #8c7454;
        }
.mud .sample-results-tbl .body-cell {
            border-bottom: 1px solid #777777
        }
.mud .sample-results-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.mud .sample-results-tbl .attribute-name {
            background-color: #8c7454;
            border-bottom: 1px solid #777777;
        }
.mud-light .sample-results-tbl {
        background-color: #fff0bb
    }
.mud-light .sample-results-tbl .header-cell {
            background-color: #fff0bb;
            border-bottom: 1px solid #777777;
        }
.mud-light .sample-results-tbl .sticky-col {
            background-color: #b28f5a;
        }
.mud-light .sample-results-tbl .body-cell {
            border-bottom: 1px solid #777777
        }
.mud-light .sample-results-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.mud-light .sample-results-tbl .attribute-name {
            background-color: #b28f5a;
            border-bottom: 1px solid #777777;
        }
.illini .sample-results-tbl {
        background-color: #00467f
    }
.illini .sample-results-tbl .header-cell {
            background-color: #00467f;
            border-bottom: 1px solid #777777;
        }
.illini .sample-results-tbl .sticky-col {
            background-color: #abc8e2;
        }
.illini .sample-results-tbl .body-cell {
            border-bottom: 1px solid #777777
        }
.illini .sample-results-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.illini .sample-results-tbl .attribute-name {
            background-color: #abc8e2;
            border-bottom: 1px solid #777777;
        }
.illini-light .sample-results-tbl {
        background-color: #e1e6fa
    }
.illini-light .sample-results-tbl .header-cell {
            background-color: #e1e6fa;
            border-bottom: 1px solid #777777;
        }
.illini-light .sample-results-tbl .sticky-col {
            background-color: #c4d7ed;
        }
.illini-light .sample-results-tbl .body-cell {
            border-bottom: 1px solid #777777
        }
.illini-light .sample-results-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.illini-light .sample-results-tbl .attribute-name {
            background-color: #c4d7ed;
            border-bottom: 1px solid #777777;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-sample-soil-form .dialog-container.trace-points-modal .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0
    }
.event-sample-soil-form .dialog-container.trace-points-modal .dialog-box-body > div {
            display: flex;
            flex-direction: column;
            margin: 15px 20px
        }
.event-sample-soil-form .dialog-container.trace-points-modal .dialog-box-body > div .trace-progress-options {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
.event-sample-soil-form .dialog-container.trace-points-modal .dialog-box-body > div .trace-instructions {
                padding-top: 10px;
                font-style: italic;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-sample-soil-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 60px);
    overflow: hidden;
    padding-top: 5px
}
.event-sample-soil-form .method-selection {
        margin: 0 9px 9px 9px
    }
.event-sample-soil-form .method-selection.tabs .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
                padding-top: 12px;
            }
.event-sample-soil-form .method-selection.tabs .tabs-pane {
                padding: 8px
            }
.event-sample-soil-form .method-selection.tabs .tabs-pane .manual-method-steps,
                .event-sample-soil-form .method-selection.tabs .tabs-pane .grid-method-steps {
                    display: flex;
                    flex-direction: row-reverse;
                }
.event-sample-soil-form .sample-points {
        height: calc(100% - 30px);
        padding: 0 10px 0 10px
    }
.event-sample-soil-form .sample-points .table-controls {
            display: flex;
            flex-direction: row;
            min-height: 25px;
            max-height: 25px;
            justify-content: space-between;
            line-height: 18px;
            padding-bottom: 5px
        }
.event-sample-soil-form .sample-points .table-controls .checkbox-div {
                width: auto;
                width: initial;
            }
.event-sample-soil-form .with-results {
        height: calc(100% - 30px);
    }
.event-sample-soil-form .no-results {
        height: calc(100% - 105px);
    }
.event-sample-soil-form .panel-modal .dialog-window,
        .event-sample-soil-form .panel-modal .modal-overlay {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.event-sample-soil-form .panel-modal .dialog-container {
            position: fixed !important;
            width: 385px;
            line-height: 17px;
        }
.dialog-container.sample-soil-depth-modal {
    width: 350px
}
.dialog-container.sample-soil-depth-modal .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0
    }
.dialog-container.sample-soil-depth-modal .dialog-box-body .select-form-input-container {
            width: 110px;
            margin: 5px;
        }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table {
            margin: 20px 40px
        }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr {
                font-size: 14px;
                font-weight: 500;
                padding-bottom: 5px;
            }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr,
            .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center
            }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .form-input, .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .form-input {
                    margin: 5px
                }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .form-input .input-container,
                    .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .form-input input,
                    .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .form-input .input-container,
                    .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .form-input input {
                        height: 100%;
                        text-align: center;
                        text-indent: 0;
                    }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr > div, .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row > div {
                    flex-grow: 1;
                    width: 10px;
                }
.dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-hdr .remove-icon, .dialog-container.sample-soil-depth-modal .dialog-box-body .depth-table .depth-tbl-row .remove-icon {
                    cursor: pointer;
                }
.dialog-container.sample-soil-depth-modal .dialog-box-body .add-link {
            display: block;
            margin-top: 15px;
        }
.default .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #404040
            }
.default .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.default .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.default .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #000000;
                    }
.default .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #000000;
            }
.default .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #262626;
        }
.default-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #919191
            }
.default-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.default-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.default-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.default-light .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.default-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #e2e2e2;
        }
.sky .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #0194d3
            }
.sky .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #878787
                }
.sky .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.sky .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.sky .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.sky .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b1b1b1;
        }
.sky-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #0194d3
            }
.sky-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #878787
                }
.sky-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.sky-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.sky-light .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.sky-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b1b1b1;
        }
.grass .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #325132
            }
.grass .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #e5e9e5
                }
.grass .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.grass .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #667c66;
                    }
.grass .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #667c66;
            }
.grass .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #424443;
        }
.grass-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #545f54
            }
.grass-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #e5e9e5
                }
.grass-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.grass-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #babfba;
                    }
.grass-light .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #babfba;
            }
.grass-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #9cadb2;
        }
.mud .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #766146
            }
.mud .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.mud .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.mud .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #4b3705;
                    }
.mud .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #4b3705;
            }
.mud .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #8c7454;
        }
.mud-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #a37b42
            }
.mud-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.mud-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.mud-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fff0bb;
                    }
.mud-light .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #fff0bb;
            }
.mud-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b28f5a;
        }
.illini .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #636466
            }
.illini .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.illini .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.illini .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #00467f;
                    }
.illini .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #00467f;
            }
.illini .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #abc8e2;
        }
.illini-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels {
                background-color: #375d81
            }
.illini-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.illini-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.illini-light .rec-event-info .event-sample-soil-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #e1e6fa;
                    }
.illini-light .rec-event-info .event-sample-soil-form .method-selection .tabs-pane {
                background-color: #e1e6fa;
            }
.illini-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #c4d7ed;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl {
    left: 0;
    right: 0;
    top: 0;
    height: calc(100% - 30px);
    overflow-x: auto;
    overflow-y: auto;
    position: relative
}
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .sticky {
        position: absolute;
        top: auto;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header {
        min-height: 0;
        :not(.sticky) {
            visibility: hidden;
            z-index: 0;
        }
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .row {
        display: flex;
        flex-direction: row;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell {
        font-weight: 400;
        padding-top: 16px;
        text-align: center
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.sticky-col,
        .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.sticky {
            z-index: 9;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell {
        z-index: 0
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell.sticky-col {
            z-index: 5;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer,
    .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell {
        flex-grow: 1;
        height: 49px;
        min-width: 55px;
        box-sizing: border-box
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.prod-rating, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer.prod-rating, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell.prod-rating {
            min-width: 135px;
            overflow: visible
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell.prod-rating.fixed-container, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer.prod-rating.fixed-container, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell.prod-rating.fixed-container {
                z-index: 10;
            }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-cell .body-text, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .header-sticky-spacer .body-text, .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .body-cell .body-text {
            text-align: center;
            padding-top: 18px;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .select-form-input-container {
        margin: 0;
        width: 135px;
        margin-left: auto;
        margin-right: auto
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .select-form-input-container .form-input {
            margin: 2px;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .sample-point-tbl .select-form-input-container .select-form-input-options {
            margin-top: 0;
            margin-bottom: 0;
        }
.default .sample-point-tbl {
        background-color: #404040
    }
.default .sample-point-tbl .header-cell {
            background-color: #000000;
        }
.default .sample-point-tbl .header-cell {
            background-color: #000000;
            border-bottom: 1px solid #bfbfbf
        }
.default .sample-point-tbl .body-cell {
            background-color: #000000;
            border-bottom: 1px solid #bfbfbf
        }
.default .sample-point-tbl .header-cell.selected {
                background-color: #8fa61a;
            }
.default .sample-point-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.default .sample-point-tbl .sticky-col {
            background-color: #262626;
        }
.default .sample-point-tbl .sticky-all {
            background-color: #262626;
        }
.default-light .sample-point-tbl {
        background-color: #919191
    }
.default-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
        }
.default-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #cfcfcf
        }
.default-light .sample-point-tbl .body-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #cfcfcf
        }
.default-light .sample-point-tbl .header-cell.selected {
                background-color: #8fa61a;
            }
.default-light .sample-point-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.default-light .sample-point-tbl .sticky-col {
            background-color: #e2e2e2;
        }
.default-light .sample-point-tbl .sticky-all {
            background-color: #e2e2e2;
        }
.sky .sample-point-tbl {
        background-color: #0194d3
    }
.sky .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
        }
.sky .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky .sample-point-tbl .body-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky .sample-point-tbl .header-cell.selected {
                background-color: #235635;
            }
.sky .sample-point-tbl .body-cell.selected {
                background-color: #235635;
            }
.sky .sample-point-tbl .sticky-col {
            background-color: #b1b1b1;
        }
.sky .sample-point-tbl .sticky-all {
            background-color: #b1b1b1;
        }
.sky-light .sample-point-tbl {
        background-color: #0194d3
    }
.sky-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
        }
.sky-light .sample-point-tbl .header-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky-light .sample-point-tbl .body-cell {
            background-color: #fcfcfc;
            border-bottom: 1px solid #c8c8c8
        }
.sky-light .sample-point-tbl .header-cell.selected {
                background-color: #235635;
            }
.sky-light .sample-point-tbl .body-cell.selected {
                background-color: #235635;
            }
.sky-light .sample-point-tbl .sticky-col {
            background-color: #b1b1b1;
        }
.sky-light .sample-point-tbl .sticky-all {
            background-color: #b1b1b1;
        }
.grass .sample-point-tbl {
        background-color: #325132
    }
.grass .sample-point-tbl .header-cell {
            background-color: #667c66;
        }
.grass .sample-point-tbl .header-cell {
            background-color: #667c66;
            border-bottom: 1px solid #9cadb2
        }
.grass .sample-point-tbl .body-cell {
            background-color: #667c66;
            border-bottom: 1px solid #9cadb2
        }
.grass .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.grass .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.grass .sample-point-tbl .sticky-col {
            background-color: #424443;
        }
.grass .sample-point-tbl .sticky-all {
            background-color: #424443;
        }
.grass-light .sample-point-tbl {
        background-color: #545f54
    }
.grass-light .sample-point-tbl .header-cell {
            background-color: #babfba;
        }
.grass-light .sample-point-tbl .header-cell {
            background-color: #babfba;
            border-bottom: 1px solid #424443
        }
.grass-light .sample-point-tbl .body-cell {
            background-color: #babfba;
            border-bottom: 1px solid #424443
        }
.grass-light .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.grass-light .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.grass-light .sample-point-tbl .sticky-col {
            background-color: #9cadb2;
        }
.grass-light .sample-point-tbl .sticky-all {
            background-color: #9cadb2;
        }
.mud .sample-point-tbl {
        background-color: #766146
    }
.mud .sample-point-tbl .header-cell {
            background-color: #4b3705;
        }
.mud .sample-point-tbl .header-cell {
            background-color: #4b3705;
            border-bottom: 1px solid #f2f3f8
        }
.mud .sample-point-tbl .body-cell {
            background-color: #4b3705;
            border-bottom: 1px solid #f2f3f8
        }
.mud .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.mud .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.mud .sample-point-tbl .sticky-col {
            background-color: #8c7454;
        }
.mud .sample-point-tbl .sticky-all {
            background-color: #8c7454;
        }
.mud-light .sample-point-tbl {
        background-color: #a37b42
    }
.mud-light .sample-point-tbl .header-cell {
            background-color: #fff0bb;
        }
.mud-light .sample-point-tbl .header-cell {
            background-color: #fff0bb;
            border-bottom: 1px solid #d6c282
        }
.mud-light .sample-point-tbl .body-cell {
            background-color: #fff0bb;
            border-bottom: 1px solid #d6c282
        }
.mud-light .sample-point-tbl .header-cell.selected {
                background-color: #8fa61a;
            }
.mud-light .sample-point-tbl .body-cell.selected {
                background-color: #8fa61a;
            }
.mud-light .sample-point-tbl .sticky-col {
            background-color: #b28f5a;
        }
.mud-light .sample-point-tbl .sticky-all {
            background-color: #b28f5a;
        }
.illini .sample-point-tbl {
        background-color: #636466
    }
.illini .sample-point-tbl .header-cell {
            background-color: #00467f;
        }
.illini .sample-point-tbl .header-cell {
            background-color: #00467f;
            border-bottom: 1px solid #f2f3f8
        }
.illini .sample-point-tbl .body-cell {
            background-color: #00467f;
            border-bottom: 1px solid #f2f3f8
        }
.illini .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.illini .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.illini .sample-point-tbl .sticky-col {
            background-color: #abc8e2;
        }
.illini .sample-point-tbl .sticky-all {
            background-color: #abc8e2;
        }
.illini-light .sample-point-tbl {
        background-color: #375d81
    }
.illini-light .sample-point-tbl .header-cell {
            background-color: #e1e6fa;
        }
.illini-light .sample-point-tbl .header-cell {
            background-color: #e1e6fa;
            border-bottom: 1px solid #abc8e2
        }
.illini-light .sample-point-tbl .body-cell {
            background-color: #e1e6fa;
            border-bottom: 1px solid #abc8e2
        }
.illini-light .sample-point-tbl .header-cell.selected {
                background-color: #ffff00;
            }
.illini-light .sample-point-tbl .body-cell.selected {
                background-color: #ffff00;
            }
.illini-light .sample-point-tbl .sticky-col {
            background-color: #c4d7ed;
        }
.illini-light .sample-point-tbl .sticky-all {
            background-color: #c4d7ed;
        }
.sky .sample-point-tbl .body-cell.selected {
        color: #ffffff;
    }
.sky-light .sample-point-tbl .body-cell.selected {
        color: #ffffff;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-sample-tissue-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 60px);
    overflow: hidden
}
.event-sample-tissue-form .bucket {
        min-height: unset;
    }
.event-sample-tissue-form .method-selection {
        margin: 0 9px 9px 9px
    }
.event-sample-tissue-form .method-selection.tabs .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
                padding-top: 12px;
            }
.event-sample-tissue-form .method-selection.tabs .tabs-pane {
                padding: 8px
            }
.event-sample-tissue-form .method-selection.tabs .tabs-pane .manual-method-steps,
                .event-sample-tissue-form .method-selection.tabs .tabs-pane .grid-method-steps {
                    display: flex;
                    flex-direction: row-reverse;
                }
.event-sample-tissue-form .readonly-row {
        width: 95%;
        margin-left: 1em;
        padding-bottom: 1em;
        flex-direction: column
    }
.event-sample-tissue-form .readonly-row .readonly-label {
            padding-top: 1em;
            font-weight: bold;
        }
.event-sample-tissue-form .readonly-row .readonly-value {
            padding-left: 2em;
            padding-top: 0.5em;
        }
.event-sample-tissue-form .input-row .select-form-input-container {
            max-width: 50%;
        }
.event-sample-tissue-form .sample-points {
        padding: 5px 10px 0px 10px
    }
.event-sample-tissue-form .sample-points .table-controls {
            display: flex;
            flex-direction: row;
            min-height: 20px;
            max-height: 20px;
            justify-content: space-between;
            line-height: 20px;
            padding-bottom: 20px
        }
.event-sample-tissue-form .sample-points .table-controls .checkbox-div {
                width: auto;
                width: initial;
            }
.event-sample-tissue-form .sample-points .table-controls .btn-place-points {
                width: 100px;
            }
.event-sample-tissue-form .crop-selected-results {
        height: calc(100% - 145px);
    }
.event-sample-tissue-form .alt-results {
        height: calc(100% - 195px);
    }
.event-sample-tissue-form .no-crop-selected-results {
        height: calc(100% - 90px);
    }
.event-sample-tissue-form .alt-no-results {
        height: calc(100% - 205px);
    }
.event-sample-tissue-form .crop-selected-no-results {
        height: calc(100% - 165px);
    }
.event-sample-tissue-form .no-crop-selected-no-results {
        height: calc(100% - 110px);
    }
.default .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #404040
            }
.default .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.default .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.default .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #000000;
                    }
.default .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #000000;
            }
.default-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #919191
            }
.default-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.default-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.default-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.default-light .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.sky .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #0194d3
            }
.sky .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #878787
                }
.sky .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.sky .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.sky .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.sky-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #0194d3
            }
.sky-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #878787
                }
.sky-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.sky-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.sky-light .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.grass .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #325132
            }
.grass .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #e5e9e5
                }
.grass .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.grass .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #667c66;
                    }
.grass .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #667c66;
            }
.grass-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #545f54
            }
.grass-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #e5e9e5
                }
.grass-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.grass-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #babfba;
                    }
.grass-light .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #babfba;
            }
.mud .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #766146
            }
.mud .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.mud .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.mud .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #4b3705;
                    }
.mud .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #4b3705;
            }
.mud-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #a37b42
            }
.mud-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.mud-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.mud-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fff0bb;
                    }
.mud-light .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #fff0bb;
            }
.illini .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #636466
            }
.illini .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.illini .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.illini .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #00467f;
                    }
.illini .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #00467f;
            }
.illini-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels {
                background-color: #375d81
            }
.illini-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.illini-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.illini-light .rec-event-info .event-sample-tissue-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #e1e6fa;
                    }
.illini-light .rec-event-info .event-sample-tissue-form .method-selection .tabs-pane {
                background-color: #e1e6fa;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-scouting-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1
}
.event-scouting-form .method-selection {
        margin: 0 9px 9px 9px
    }
.event-scouting-form .method-selection.tabs .tabs-labels li.tab-label:not(.left-non-label):not(.right-non-label) {
                padding-top: 12px;
            }
.event-scouting-form .method-selection.tabs .tabs-pane {
                padding: 8px
            }
.event-scouting-form .method-selection.tabs .tabs-pane .manual-method-steps,
                .event-scouting-form .method-selection.tabs .tabs-pane .grid-method-steps {
                    display: flex;
                    flex-direction: row-reverse;
                }
.event-scouting-form .scouting-event-container .bucket-content .scouting-details {
                padding-top: 1em;
                width: 95%;
            }
.event-scouting-form .scouting-event-container .bucket-content .textarea-form-input {
                width: 95%;
                height: 90px;
            }
.event-scouting-form .scouting-event-container .select-form-sub-header {
            font-weight: bold;
            cursor: default;
            height: 50%;
            display: flex;
            align-items: center;
            padding-top: 5px;
            margin-left: 5px;
        }
.event-scouting-form .scouting-event-container .observation-item-header {
            font-weight: bold;
            padding-bottom: 1em;
        }
.event-scouting-form .scouting-event-container .no-issues-found {
            height: 18px;
            margin-top: 3px
        }
.event-scouting-form .scouting-event-container .no-issues-found .checkbox-div .checkbox-label {
                margin-top: 1px;
            }
.event-scouting-form .scouting-event-container .input-row-observation {
            width: 100%;
            display: flex;
            flex-direction: row
        }
.event-scouting-form .scouting-event-container .input-row-observation .value {
                flex-grow: 1;
                width: calc(50% - 16px);
            }
.event-scouting-form .scouting-event-container .input-row-observation .unit {
                width: 50%;
            }
.event-scouting-form .scouting-event-container .input-row-observation .select-input-category-container {
                position: relative;
                min-width: 20px;
            }
.event-scouting-form .scouting-event-container .input-row-observation .pressure,
            .event-scouting-form .scouting-event-container .input-row-observation .growth {
                max-width: 50%;
            }
.event-scouting-form .scouting-event-container .observation-photo-row {
            width: 100%;
            margin-left: 1px;
            font-size: 0px /* required to eliminate the vertical space between img elements when wrapping */
        }
.event-scouting-form .scouting-event-container .observation-photo-row .observation-photo-thumbnail {
                width: 73px;
                height: 73px;
                margin: 5px 3px;
                border-radius: 3px;
                border-style: solid;
                border-width: 3px;
                object-fit: contain;
                cursor: pointer;
            }
.event-scouting-form .scouting-event-container .observation-photo-row .observation-photo-thumbnail-loader {
                display: inline-block;
                position: relative;
                width: 73px;
                height: 73px;
                margin: 5px 3px;
                border-radius: 3px;
                border-style: solid;
                border-width: 3px;
                text-align: center;
                vertical-align: top
            }
.event-scouting-form .scouting-event-container .observation-photo-row .observation-photo-thumbnail-loader .loader-container > div {
                    background-color: transparent !important;
                }
.event-scouting-form .scouting-event-container .observation-photo-row .dialog-box-body {
                overflow-y: hidden
            }
.event-scouting-form .scouting-event-container .observation-photo-row .dialog-box-body .fullsize-photo-not-found-msg {
                    width: 400px;
                    height: 400px;
                    line-height: 400px;
                    text-align: center;
                }
.event-scouting-form .scouting-event-container .observation-photo-row .dialog-box-body .description-input-row {
                    width: 80%;
                }
.event-scouting-form .scouting-event-container .observation-photo-row .dialog-box-body .fullsize-photo-row {
                    width: 400px;
                    min-height: 400px
                }
.event-scouting-form .scouting-event-container .observation-photo-row .dialog-box-body .fullsize-photo-row img {
                        max-width: 400px;
                        width: 100%;
                    }
.event-scouting-form .scouting-event-container .observation-photo-row .add-photo-link-short {
                display: inline-block;
                width: 100%;
                height: 100%;
                margin: 2px 1px 1px 7px;
                text-align: left;
            }
.event-scouting-form .scouting-event-container .observation-photo-row .add-photo-link-tall {
                display: inline-block;
                width: 79px;
                line-height: 83px;
                margin: 2px;
                text-align: center;
                vertical-align: top;
            }
.default .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #404040
            }
.default .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.default .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.default .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #000000;
                    }
.default .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #000000;
            }
.default .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #bfbfbf;
                background-color: #bfbfbf;
            }
.default .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #bfbfbf;
                background-color: #bfbfbf;
            }
.default-light .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #919191
            }
.default-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.default-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.default-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.default-light .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.default-light .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #cfcfcf;
                background-color: #cfcfcf;
            }
.default-light .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #cfcfcf;
                background-color: #cfcfcf;
            }
.sky .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #0194d3
            }
.sky .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #878787
                }
.sky .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.sky .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.sky .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.sky .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #c8c8c8;
                background-color: #c8c8c8;
            }
.sky .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #c8c8c8;
                background-color: #c8c8c8;
            }
.sky-light .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #0194d3
            }
.sky-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #878787
                }
.sky-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.sky-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fcfcfc;
                    }
.sky-light .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #fcfcfc;
            }
.sky-light .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #c8c8c8;
                background-color: #c8c8c8;
            }
.sky-light .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #c8c8c8;
                background-color: #c8c8c8;
            }
.grass .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #325132
            }
.grass .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #e5e9e5
                }
.grass .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.grass .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #667c66;
                    }
.grass .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #667c66;
            }
.grass .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #9cadb2;
                background-color: #9cadb2;
            }
.grass .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #9cadb2;
                background-color: #9cadb2;
            }
.grass-light .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #545f54
            }
.grass-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #e5e9e5
                }
.grass-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.grass-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #babfba;
                    }
.grass-light .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #babfba;
            }
.grass-light .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #424443;
                background-color: #424443;
            }
.grass-light .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #424443;
                background-color: #424443;
            }
.mud .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #766146
            }
.mud .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.mud .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.mud .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #4b3705;
                    }
.mud .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #4b3705;
            }
.mud .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #f2f3f8;
                background-color: #f2f3f8;
            }
.mud .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #f2f3f8;
                background-color: #f2f3f8;
            }
.mud-light .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #a37b42
            }
.mud-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.mud-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.mud-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #fff0bb;
                    }
.mud-light .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #fff0bb;
            }
.mud-light .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #d6c282;
                background-color: #d6c282;
            }
.mud-light .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #d6c282;
                background-color: #d6c282;
            }
.illini .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #636466
            }
.illini .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.illini .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #ffffff;
                    }
.illini .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #00467f;
                    }
.illini .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #00467f;
            }
.illini .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #f2f3f8;
                background-color: #f2f3f8;
            }
.illini .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #f2f3f8;
                background-color: #f2f3f8;
            }
.illini-light .rec-event-info .event-scouting-form .tabs .tabs-labels {
                background-color: #375d81
            }
.illini-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label {
                    background-color: #777777
                }
.illini-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label:not(.active) {
                        color: #000000;
                    }
.illini-light .rec-event-info .event-scouting-form .tabs .tabs-labels li.tab-label.active {
                        background-color: #e1e6fa;
                    }
.illini-light .rec-event-info .event-scouting-form .method-selection .tabs-pane {
                background-color: #e1e6fa;
            }
.illini-light .rec-event-info .event-scouting-form .observation-photo-thumbnail {
                border-color: #abc8e2;
                background-color: #abc8e2;
            }
.illini-light .rec-event-info .event-scouting-form .observation-photo-thumbnail-loader {
                border-color: #abc8e2;
                background-color: #abc8e2;
            }

.event-tillage-form {
    padding: 5px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.planting-event-container .planting-event-section-header {
        width: 370px;
        height: 40px;
        line-height: 40px;
    }
.planting-event-container .transplant-container {
        width: 100%;
    }
.planting-event-container .transplant-checkbox {
        padding-top: 15px;
        padding-left: 5px;
    }
.default .planting-event-section-header {
        background-color: #737373;
    }
.default-light .planting-event-section-header {
        background-color: #b8b8b8;
    }
.sky .planting-event-section-header {
        background-color: #838383;
    }
.sky-light .planting-event-section-header {
        background-color: #838383;
    }
.grass .planting-event-section-header {
        background-color: #424443;
    }
.grass-light .planting-event-section-header {
        background-color: #bfbfbf;
    }
.mud .planting-event-section-header {
        background-color: #ad8f68;
    }
.mud-light .planting-event-section-header {
        background-color: #735523;
    }
.illini .planting-event-section-header {
        background-color: #3698db;
    }
.illini-light .planting-event-section-header {
        background-color: #00467f;
    }


        .rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .price-unit {
            display: flex;
            align-items: flex-end;
            padding: 10px 5px;
            width: 25px;
            flex-grow: unset;
        }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .events-rootstock-name {
            width: 5px;
        }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .price-per-unit {
            flex-grow: 0.75;
        }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .seed-treatment-rate {
            width: 30%;
        }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .seed-treatment-unit {
            width: 70%;
        }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row.seed-treatment-price-container {
        width: 50%;
    }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket.seed-treatment-section-bucket {
        padding: 0px;
    }

.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .event-planting-info-form + .event-zone-info {
        padding: 7px 20px 6px 0;
    }

.planting-variety-hybrid .add-link-container {
        margin: 10px 5px;
    }

.planting-variety-hybrid .child-row .delete-link-container {
        padding: 7px 3px 3px 0px;
    }

.price-unit {
    margin-right: 5px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket.crop-info-section-bucket {
        margin-top: 0px;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .event-planting-info-form .rec-event-info-form {
        flex: 1 0;
    }
.event-planting-info-form {
    overflow-y: auto;
    flex: 1 0;
    display: flex;
    flex-direction: column
}
.event-planting-info-form .field-table-container {
        border-bottom: 0.5px dashed;
    }
.event-planting-info-form .zone-info-heading {
        display: flex;
        margin: 15px 5px 8px 5px;
        font-size: 14px;
        font-weight: 300
    }
.event-planting-info-form .zone-info-heading .zone-size {
            margin-left: 5px;
        }
.event-planting-info-form .crop-unit-table .crop-unit-header {
            display: flex;
            height: 20px;
            font-weight: bold;
            font-family: "open_sansitalic";
            font-size: 14px;
            padding: 0px 5px 5px 5px;
        }
.event-planting-info-form .crop-unit-table .crop-unit-row {
            height: 15px;
            margin: 5px 5px;
            padding: 5px 5px;
            display: flex;
            flex-wrap: nowrap;
        }
.event-planting-info-form .crop-unit-table .crop-section {
            width: 225px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
.event-planting-info-form .crop-unit-table .section-label {
            padding-left: 10px;
            min-width: 50px;
            max-width: 60px;
        }
.event-planting-info-form .crop-unit-table .section-label-header {
            text-align: center;
            max-width: 60px;
        }
.event-planting-info-form .crop-unit-table .unit-section {
            flex-grow: 0.5;
        }
.event-planting-info-form .total-cost-container {
        float: right;
        width: auto;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: 300
    }
.event-planting-info-form .total-cost-container .total-cost-items {
            text-align: end;
            padding-right: 7px;
        }
.default .event-planting-info-form {
        background-color: #404040;
    }
.default .crop-unit-header-border {
        border-bottom: 1px solid #bfbfbf;
    }
.default .crop-unit-table {
        border-bottom: 1px solid #bfbfbf;
    }
.default-light .event-planting-info-form {
        background-color: #919191;
    }
.default-light .crop-unit-header-border {
        border-bottom: 1px solid #cfcfcf;
    }
.default-light .crop-unit-table {
        border-bottom: 1px solid #cfcfcf;
    }
.sky .event-planting-info-form {
        background-color: #0194d3;
    }
.sky .crop-unit-header-border {
        border-bottom: 1px solid #c8c8c8;
    }
.sky .crop-unit-table {
        border-bottom: 1px solid #c8c8c8;
    }
.sky-light .event-planting-info-form {
        background-color: #0194d3;
    }
.sky-light .crop-unit-header-border {
        border-bottom: 1px solid #c8c8c8;
    }
.sky-light .crop-unit-table {
        border-bottom: 1px solid #c8c8c8;
    }
.grass .event-planting-info-form {
        background-color: #325132;
    }
.grass .crop-unit-header-border {
        border-bottom: 1px solid #9cadb2;
    }
.grass .crop-unit-table {
        border-bottom: 1px solid #9cadb2;
    }
.grass-light .event-planting-info-form {
        background-color: #545f54;
    }
.grass-light .crop-unit-header-border {
        border-bottom: 1px solid #424443;
    }
.grass-light .crop-unit-table {
        border-bottom: 1px solid #424443;
    }
.mud .event-planting-info-form {
        background-color: #766146;
    }
.mud .crop-unit-header-border {
        border-bottom: 1px solid #f2f3f8;
    }
.mud .crop-unit-table {
        border-bottom: 1px solid #f2f3f8;
    }
.mud-light .event-planting-info-form {
        background-color: #a37b42;
    }
.mud-light .crop-unit-header-border {
        border-bottom: 1px solid #d6c282;
    }
.mud-light .crop-unit-table {
        border-bottom: 1px solid #d6c282;
    }
.illini .event-planting-info-form {
        background-color: #636466;
    }
.illini .crop-unit-header-border {
        border-bottom: 1px solid #f2f3f8;
    }
.illini .crop-unit-table {
        border-bottom: 1px solid #f2f3f8;
    }
.illini-light .event-planting-info-form {
        background-color: #375d81;
    }
.illini-light .crop-unit-header-border {
        border-bottom: 1px solid #abc8e2;
    }
.illini-light .crop-unit-table {
        border-bottom: 1px solid #abc8e2;
    }
.sky .event-planting-info-form {
        background-color: #e2e2e2;
    }
.sky .crop-unit-header-border {
        border-bottom: 1px solid #c8c8c8;
    }
.sky .crop-unit-table {
        border-bottom: 1px solid #c8c8c8;
    }
.sky-light .event-planting-info-form {
        background-color: #e2e2e2;
    }
.sky-light .crop-unit-header-border {
        border-bottom: 1px solid #c8c8c8;
    }
.sky-light .crop-unit-table {
        border-bottom: 1px solid #c8c8c8;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.irrigation-event-container {
    margin: 0px 3px
}
.irrigation-event-container .drip-checkboxes {
        display: flex;
        padding: 10px;
    }
.irrigation-event-container .irrigation-event-section-header {
        width: 370px;
        height: 40px;
        line-height: 40px;
    }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .irrigation-type {
            width: 195px;
            flex-grow: 0;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .input-row > .installation-type {
            width: 162px;
            flex-grow: 0;
        }
.rec-event-info .tabs.information-tabs > .tabs-pane .rec-event-info-form .bucket.import-field-section-bucket {
        padding: 0px 4px;
    }
.default .irrigation-event-section-header {
        background-color: #737373;
    }
.default-light .irrigation-event-section-header {
        background-color: #b8b8b8;
    }
.sky .irrigation-event-section-header {
        background-color: #838383;
    }
.sky-light .irrigation-event-section-header {
        background-color: #838383;
    }
.grass .irrigation-event-section-header {
        background-color: #424443;
    }
.grass-light .irrigation-event-section-header {
        background-color: #bfbfbf;
    }
.mud .irrigation-event-section-header {
        background-color: #ad8f68;
    }
.mud-light .irrigation-event-section-header {
        background-color: #735523;
    }
.illini .irrigation-event-section-header {
        background-color: #3698db;
    }
.illini-light .irrigation-event-section-header {
        background-color: #00467f;
    }


    .event-general-form .checkbox-div {
        margin-left: 3px;
    }
.event-general-form .temp-input {
        max-width: 85px;
    }
.event-general-form .textarea-form-input {
        width: 95%;
        height: 110px;
    }
.event-general-form .event-label-icon {
        height: 45px;
        width: 50px;
    }
.event-general-form .person-list {
        width: 100%
    }
.event-general-form .person-list .person-item {
            line-height: 25px;
            display: flex;
            flex-flow: row nowrap
        }
.event-general-form .person-list .person-item .trashcan-icon-container {
                width: 10%;
                padding-left: 10px;
            }
.event-general-form .person-list .person-item .person-info {
                flex-grow: 1;
                justify-content: flex-start;
            }
.event-general-form .equipment-list {
        width: 100%
    }
.event-general-form .equipment-list .equipment-item {
            line-height: 25px;
            display: flex;
            flex-flow: row nowrap
        }
.event-general-form .equipment-list .equipment-item .equipment-trashcan-icon-container {
                width: 10%;
                padding-left: 10px;
            }
.event-general-form .equipment-list .equipment-item .equipment-info {
                flex-grow: 1;
                justify-content: flex-start;
                margin-left: 6px;
            }
.event-general-form .equipment-list .equipment-item .empty-container {
                padding-left: 6px;
            }

.rec-event-batch-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative
}
.rec-event-batch-list .rec-event-info {
        flex-grow: 0;
    }
.rec-event-batch-list .rec-event-batch-list-accordion {
        flex-grow: 1;
        position: relative;
    }
.rec-event-batch-list .save-cancel-btns {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px;
        min-height: 34px;
        overflow: hidden
    }
.rec-event-batch-list .save-cancel-btns .default-button {
            margin: 0 7px;
        }

.action-panel .event-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.legend-icon {
    height: 20px;
    min-width: 20px;
}
.legend-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    line-height: 20px;
    height: 20px;
    padding: 0 7px
}
.legend-label.legend-label-hover {
        position: fixed;
        z-index: 100000;
    }
.legend-filler {
    flex-grow: 1;
}
.default .legend-label:hover {
        background-color: #262626;
    }
.default-light .legend-label:hover {
        background-color: #e2e2e2;
    }
.sky .legend-label:hover {
        background-color: #b1b1b1;
    }
.sky-light .legend-label:hover {
        background-color: #b1b1b1;
    }
.grass .legend-label:hover {
        background-color: #424443;
    }
.grass-light .legend-label:hover {
        background-color: #9cadb2;
    }
.mud .legend-label:hover {
        background-color: #8c7454;
    }
.mud-light .legend-label:hover {
        background-color: #b28f5a;
    }
.illini .legend-label:hover {
        background-color: #abc8e2;
    }
.illini-light .legend-label:hover {
        background-color: #c4d7ed;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module .surface-display-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 50%
}
.layer-module .surface-display-container.hide {
        display: none;
    }
.layer-module .surface-display-container .surface-display-select-row {
        display: flex;
        flex-direction: row
    }
.layer-module .surface-display-container .surface-display-select-row > div {
            flex-grow: 1;
            max-width: calc(50% - 11px)
        }
.layer-module .surface-display-container .surface-display-select-row > div .form-input {
                border-radius: 0;
                margin: 5px;
            }
.layer-module .surface-display-container .surface-display-select-row.right-aligned-row {
            padding-left: 22px
        }
.layer-module .surface-display-container .surface-display-select-row.right-aligned-row > div {
                max-width: 50%;
            }
.layer-module .surface-display-container .surface-display-select-row .surface-display-layer-list .select-form-input-option span.letter-icon {
            height: 17px;
            min-width: 17px;
            line-height: 17px;
            font-size: 12px;
            border-radius: 50%;
            margin: 0 5px;
            padding: 0 5px;
            text-align: center;
            box-shadow: none;
        }
.layer-module .surface-display-container .surface-display-map {
        margin: 5px;
        flex-grow: 1;
    }
.layer-module .surface-display-container .floating-dgg {
        left: auto;
        max-width: 80%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1
    }
.layer-module .surface-display-container .floating-dgg .dgg-summary {
            display: flex;
            justify-content: flex-end;
            flex-direction: row
        }
.layer-module .surface-display-container .floating-dgg .dgg-summary .spacer {
                flex-grow: 1;
            }
.layer-module .surface-display-container .floating-dgg .dgg-summary table {
                margin: 0 3px 0 4px
            }
.layer-module .surface-display-container .floating-dgg .dgg-summary table td {
                    line-height: 20px;
                    padding: 0 3px;
                }
.layer-module .surface-display-container .floating-dgg .dgg-summary table td:nth-child(1),
                .layer-module .surface-display-container .floating-dgg .dgg-summary table td:nth-child(2) {
                    text-align: right;
                }
.layer-module .surface-display-container .floating-dgg .dgg-summary table td:nth-child(3) {
                    text-align: left;
                }
.layer-module .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                padding: 2px 2px 0 4px;
                cursor: pointer;
                pointer-events: auto;
            }
.layer-module .surface-display-container .floating-dgg .dgg-label {
            line-height: 20px;
            padding: 0 7px;
            pointer-events: none;
            text-align: center;
        }
.layer-module .surface-display-container .floating-dgg-4-column {
        grid-auto-rows: 10px !important;
        grid-template-rows: repeat(4, 1fr);
        grid-auto-flow: column;
        display: grid;
        width: min-content
    }
.layer-module .surface-display-container .floating-dgg-4-column .dgg-label {
            content-visibility: hidden;
        }
.layer-module .surface-display-container .floating-dgg-2-column {
        grid-auto-rows: 10px !important;
        grid-template-rows: repeat(9, 1fr);
        grid-auto-flow: column;
        display: grid;
        width: min-content;
    }
.layer-module .surface-display-container .floating-legend {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        max-width: calc(50% - 11px)
    }
.layer-module .surface-display-container .floating-legend .legend-checkbox {
            margin: 0;
            font-size: 13px;
            height: 21px;
            width: inherit
        }
.layer-module .surface-display-container .floating-legend .legend-checkbox .checkbox-label {
                margin: 0;
            }
.layer-module .surface-display-container .floating-legend .sample-sites-icon {
            border-radius: 50%;
            height: 12px;
            margin: 3px 0 0 7px;
            width: 12px;
        }
.layer-module .surface-display-container .floating-legend .legend-class-row {
            position: relative;
            display: flex
        }
.layer-module .surface-display-container .floating-legend .legend-class-row .legend-label.legend-label-hover {
                margin-left: 20px;
            }
.layer-module .surface-display-container .floating-legend .legend-class-row.sample-sites-section {
                cursor: pointer;
            }
.layer-module .surface-display-container .floating-legend .legend-class-row .sample-sites-label {
                font-size: 13px;
                flex-grow: 1;
                height: 20px;
                line-height: 20px;
                overflow: hidden;
                padding: 0 7px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
.default .esriMapTooltip {
            background-color: #000000;
            color: #ffffff;
        }
.default .surface-display-container .floating-dgg {
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff
            }
.default .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.default .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.default .surface-display-container .floating-legend {
                color: #ffffff
            }
.default .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.default .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.default .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.default .layer-module .select-form-input-option span.letter-icon {
            color: #000000;
            border: 1px solid #000000;
        }
.default-light .esriMapTooltip {
            background-color: #ffffff;
            color: #000000;
        }
.default-light .surface-display-container .floating-dgg {
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff
            }
.default-light .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.default-light .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.default-light .surface-display-container .floating-legend {
                color: #ffffff
            }
.default-light .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.default-light .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.default-light .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.default-light .layer-module .select-form-input-option span.letter-icon {
            color: #ffffff;
            border: 1px solid #ffffff;
        }
.sky .esriMapTooltip {
            background-color: #ffffff;
            color: #000000;
        }
.sky .surface-display-container .floating-dgg {
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff
            }
.sky .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.sky .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.sky .surface-display-container .floating-legend {
                color: #ffffff
            }
.sky .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.sky .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.sky .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.sky .layer-module .select-form-input-option span.letter-icon {
            color: #ffffff;
            border: 1px solid #ffffff;
        }
.sky-light .esriMapTooltip {
            background-color: #ffffff;
            color: #000000;
        }
.sky-light .surface-display-container .floating-dgg {
                background-color: rgba(0, 0, 0, 0.3);
                color: #ffffff
            }
.sky-light .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.sky-light .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.sky-light .surface-display-container .floating-legend {
                color: #ffffff
            }
.sky-light .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.sky-light .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.sky-light .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(0, 0, 0, 0.3);
                }
.sky-light .layer-module .select-form-input-option span.letter-icon {
            color: #ffffff;
            border: 1px solid #ffffff;
        }
.grass .esriMapTooltip {
            background-color: #000000;
            color: #ffffff;
        }
.grass .surface-display-container .floating-dgg {
                background-color: rgba(66, 68, 67, 0.3);
                color: #ffffff
            }
.grass .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.grass .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.grass .surface-display-container .floating-legend {
                color: #ffffff
            }
.grass .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(66, 68, 67, 0.3);
                }
.grass .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(66, 68, 67, 0.3);
                }
.grass .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(66, 68, 67, 0.3);
                }
.grass .layer-module .select-form-input-option span.letter-icon {
            color: #000000;
            border: 1px solid #000000;
        }
.grass-light .esriMapTooltip {
            background-color: #ffffff;
            color: #000000;
        }
.grass-light .surface-display-container .floating-dgg {
                background-color: rgba(156, 173, 178, 0.3);
                color: #ffffff
            }
.grass-light .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.grass-light .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.grass-light .surface-display-container .floating-legend {
                color: #ffffff
            }
.grass-light .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(156, 173, 178, 0.3);
                }
.grass-light .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(156, 173, 178, 0.3);
                }
.grass-light .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(156, 173, 178, 0.3);
                }
.grass-light .layer-module .select-form-input-option span.letter-icon {
            color: #ffffff;
            border: 1px solid #ffffff;
        }
.mud .esriMapTooltip {
            background-color: #000000;
            color: #ffffff;
        }
.mud .surface-display-container .floating-dgg {
                background-color: rgba(140, 116, 84, 0.3);
                color: #ffffff
            }
.mud .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.mud .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.mud .surface-display-container .floating-legend {
                color: #ffffff
            }
.mud .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(140, 116, 84, 0.3);
                }
.mud .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(140, 116, 84, 0.3);
                }
.mud .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(140, 116, 84, 0.3);
                }
.mud .layer-module .select-form-input-option span.letter-icon {
            color: #000000;
            border: 1px solid #000000;
        }
.mud-light .esriMapTooltip {
            background-color: #ffffff;
            color: #000000;
        }
.mud-light .surface-display-container .floating-dgg {
                background-color: rgba(178, 143, 90, 0.3);
                color: #ffffff
            }
.mud-light .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.mud-light .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.mud-light .surface-display-container .floating-legend {
                color: #ffffff
            }
.mud-light .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(178, 143, 90, 0.3);
                }
.mud-light .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(178, 143, 90, 0.3);
                }
.mud-light .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(178, 143, 90, 0.3);
                }
.mud-light .layer-module .select-form-input-option span.letter-icon {
            color: #ffffff;
            border: 1px solid #ffffff;
        }
.illini .esriMapTooltip {
            background-color: #000000;
            color: #ffffff;
        }
.illini .surface-display-container .floating-dgg {
                background-color: rgba(171, 200, 226, 0.3);
                color: #ffffff
            }
.illini .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.illini .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.illini .surface-display-container .floating-legend {
                color: #ffffff
            }
.illini .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(171, 200, 226, 0.3);
                }
.illini .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(171, 200, 226, 0.3);
                }
.illini .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(171, 200, 226, 0.3);
                }
.illini .layer-module .select-form-input-option span.letter-icon {
            color: #000000;
            border: 1px solid #000000;
        }
.illini-light .esriMapTooltip {
            background-color: #ffffff;
            color: #000000;
        }
.illini-light .surface-display-container .floating-dgg {
                background-color: rgba(196, 215, 237, 0.3);
                color: #ffffff
            }
.illini-light .surface-display-container .floating-dgg .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75)
                }
.illini-light .surface-display-container .floating-dgg .dgg-summary .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
.illini-light .surface-display-container .floating-legend {
                color: #ffffff
            }
.illini-light .surface-display-container .floating-legend .sample-sites-section {
                    background-color: rgba(196, 215, 237, 0.3);
                }
.illini-light .surface-display-container .floating-legend .legend-label {
                    background-color: rgba(196, 215, 237, 0.3);
                }
.illini-light .surface-display-container .floating-legend .legend-filler {
                    background-color: rgba(196, 215, 237, 0.3);
                }
.illini-light .layer-module .select-form-input-option span.letter-icon {
            color: #ffffff;
            border: 1px solid #ffffff;
        }

.layer-module .surface-display-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 50%
}
.layer-module .surface-display-container.hide {
        display: none;
    }
.layer-module .surface-display-container .loader {
        position: relative;
        height: 100%;
    }
.layer-module .surface-display-container .event-surface-stats-table.hide {
            display: none;
        }
.layer-module .surface-display-container .event-surface-stats-table .data-table-header {
            height: 28px;
            margin-top: 5px;
        }
.layer-module .surface-display-container .event-surface-stats-table .data-header-cell {
            max-width: none;
            padding-left: 15px;
            width: 10%
        }
.layer-module .surface-display-container .event-surface-stats-table .data-header-cell .data-table-cell {
                padding-left: 0px;
                text-transform: capitalize;
            }
.layer-module .surface-display-container .event-surface-stats-table .data-table-row {
            width: calc(50vw - 48px);
        }
.layer-module .surface-display-container .event-surface-stats-table .data-table-cell {
            padding-left: 15px;
            max-width: none;
            width: 10%;
        }
.layer-module .surface-display-container .event-surface-stats-table .surface {
            width: 20%;
        }
.layer-module .surface-display-container .event-surface-stats-table .layer {
            width: 35%;
        }
.layer-module .surface-display-container .event-surface-stats-table > div.plain-table-body {
            max-height: none;
            position: absolute;
            top: 95px;
            bottom: 0px;
            width: 50%;
            overflow-y: auto;
        }

.layer-module .split-screen-compare-dialog {
    height: 95%;
    max-width: 97%;
    width: 97%
}
.layer-module .split-screen-compare-dialog .dialog-box-title .selected.default-svg-icon {
            fill: #8fa61a;
        }
.layer-module .split-screen-compare-dialog .dialog-box-title .disabled.default-svg-icon {
            fill: #777777 !important;
            cursor: default;
        }
.layer-module .split-screen-compare-dialog .dialog-box-body {
        display: flex;
        margin: 0;
        padding: 0 2px
    }
.layer-module .split-screen-compare-dialog .dialog-box-body > div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            position: relative
        }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row .split-button-top,
            .layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row .split-button-bottom {
                cursor: pointer;
                position: absolute;
                left: calc(50% - 23px)
            }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row .split-button-top .default-svg-icon, .layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row .split-button-bottom .default-svg-icon {
                    width: 46px;
                    height: 46px;
                }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row .split-button-top {
                top: 5px;
            }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row .split-button-bottom {
                top: auto;
                bottom: 3px;
            }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row.split-4 {
                max-height: 50%;
            }
.layer-module .split-screen-compare-dialog .dialog-box-body .split-screen-row.split-hide {
                display: none;
            }
.layer-module .split-screen-compare-dialog .zone-split-icon {
        width: 32px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.event-module .legend-accordion-item,
.layer-module .legend-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default
}
.event-module .legend-accordion-item .legend-icon, .layer-module .legend-accordion-item .legend-icon {
        margin-left: 66px;
    }
.event-module .legend-accordion-item .legend-label.legend-label-hover, .layer-module .legend-accordion-item .legend-label.legend-label-hover {
        left: 86px;
    }
.default .layer-module .legend-accordion-item {
        background-color: #262626;
    }
.default-light .layer-module .legend-accordion-item {
        background-color: #e2e2e2;
    }
.sky .layer-module .legend-accordion-item {
        background-color: #b1b1b1;
    }
.sky-light .layer-module .legend-accordion-item {
        background-color: #b1b1b1;
    }
.grass .layer-module .legend-accordion-item {
        background-color: #424443;
    }
.grass-light .layer-module .legend-accordion-item {
        background-color: #9cadb2;
    }
.mud .layer-module .legend-accordion-item {
        background-color: #8c7454;
    }
.mud-light .layer-module .legend-accordion-item {
        background-color: #b28f5a;
    }
.illini .layer-module .legend-accordion-item {
        background-color: #abc8e2;
    }
.illini-light .layer-module .legend-accordion-item {
        background-color: #c4d7ed;
    }


    .layer-stats-dialog h2 {
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        padding: 0 10px 10px 10px;
        text-align: center;
    }
.layer-stats-dialog table {
        margin: 0 auto;
    }
.layer-stats-dialog tr {
        padding: 1px 10px;
    }
.layer-stats-dialog .title {
        padding: 1px 5px;
        text-align: left;
    }
.layer-stats-dialog .number {
        padding: 1px 2px 1px 5px;
        text-align: right;
    }
.layer-stats-dialog .units {
        padding: 1px 5px 1px 2px;
        text-align: left;
    }


    .surface-layer-context-menu-batch-dialog-box .list {
        display: block;
        list-style-type: disc;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        margin-right: 0;
    }
.surface-layer-context-menu-batch-dialog-box .body {
        margin-bottom: 1em;
        white-space: normal;
    }
.surface-layer-context-menu-batch-dialog-box .list-item:not(:last-child) {
        margin-bottom: 0.5em;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module .surface-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.layer-module .surface-accordion-item .line-scale-pulse-out-rapid {
        max-height: 25px
    }
.layer-module .surface-accordion-item .line-scale-pulse-out-rapid > div {
            max-height: 20px;
        }
.layer-module .surface-accordion-item .surface-checkbox,
    .layer-module .surface-accordion-item .surface-radio {
        margin: 5px 0 0 40px;
        font-size: 13px;
        width: inherit;
    }
.layer-module .surface-accordion-item .surface-checkbox .checkbox-label {
        margin: 0;
    }
.layer-module .surface-accordion-item .sample-sites-icon {
        border-radius: 50%;
        height: 12px;
        margin: 8px 5px 0 7px;
        width: 12px;
    }
.layer-module .surface-accordion-item .surface-item-label {
        flex: 1 1;
        text-overflow: ellipsis;
        margin-top: 5px;
        overflow: hidden;
        line-height: 20px;
        white-space: nowrap;
    }
.layer-module .surface-accordion-item .warning-icon {
        height: 20px;
        margin: 5px;
    }
.layer-module .surface-accordion-item .context-menu {
        height: 21px;
        margin-top: 4px;
        padding-top: 1px
    }
.layer-module .surface-accordion-item .context-menu .menu-dot {
            line-height: 8px;
            margin-bottom: -2px;
        }
.layer-module .surface-accordion-item .cloud-icon {
        fill: #fff;
        margin-top: 5px;
        margin-right: 5px;
        stroke: #fff;
    }
.default .layer-module .surface-accordion-item {
        background-color: #262626
    }
.default .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #262626;
        }
.default .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #8fa61a;
        }
.default .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #8fa61a;
        }
.default .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.default .layer-module .surface-accordion-item .cloud-icon {
            fill: #bfbfbf;
            stroke: #bfbfbf;
        }
.default-light .layer-module .surface-accordion-item {
        background-color: #e2e2e2
    }
.default-light .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #e2e2e2;
        }
.default-light .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #8fa61a;
        }
.default-light .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #8fa61a;
        }
.default-light .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.default-light .layer-module .surface-accordion-item .cloud-icon {
            fill: #cfcfcf;
            stroke: #cfcfcf;
        }
.sky .layer-module .surface-accordion-item {
        background-color: #b1b1b1
    }
.sky .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #b1b1b1;
        }
.sky .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #235635;
        }
.sky .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #235635;
        }
.sky .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.sky .layer-module .surface-accordion-item .cloud-icon {
            fill: #c8c8c8;
            stroke: #c8c8c8;
        }
.sky-light .layer-module .surface-accordion-item {
        background-color: #b1b1b1
    }
.sky-light .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #b1b1b1;
        }
.sky-light .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #235635;
        }
.sky-light .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #235635;
        }
.sky-light .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.sky-light .layer-module .surface-accordion-item .cloud-icon {
            fill: #c8c8c8;
            stroke: #c8c8c8;
        }
.grass .layer-module .surface-accordion-item {
        background-color: #424443
    }
.grass .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #424443;
        }
.grass .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #ffff00;
        }
.grass .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.grass .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.grass .layer-module .surface-accordion-item .cloud-icon {
            fill: #9cadb2;
            stroke: #9cadb2;
        }
.grass-light .layer-module .surface-accordion-item {
        background-color: #9cadb2
    }
.grass-light .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #9cadb2;
        }
.grass-light .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #ffff00;
        }
.grass-light .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.grass-light .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.grass-light .layer-module .surface-accordion-item .cloud-icon {
            fill: #424443;
            stroke: #424443;
        }
.mud .layer-module .surface-accordion-item {
        background-color: #8c7454
    }
.mud .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #8c7454;
        }
.mud .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #ffff00;
        }
.mud .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.mud .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.mud .layer-module .surface-accordion-item .cloud-icon {
            fill: #f2f3f8;
            stroke: #f2f3f8;
        }
.mud-light .layer-module .surface-accordion-item {
        background-color: #b28f5a
    }
.mud-light .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #b28f5a;
        }
.mud-light .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #8fa61a;
        }
.mud-light .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #8fa61a;
        }
.mud-light .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.mud-light .layer-module .surface-accordion-item .cloud-icon {
            fill: #d6c282;
            stroke: #d6c282;
        }
.illini .layer-module .surface-accordion-item {
        background-color: #abc8e2
    }
.illini .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #abc8e2;
        }
.illini .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #ffff00;
        }
.illini .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.illini .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.illini .layer-module .surface-accordion-item .cloud-icon {
            fill: #f2f3f8;
            stroke: #f2f3f8;
        }
.illini-light .layer-module .surface-accordion-item {
        background-color: #c4d7ed
    }
.illini-light .layer-module .surface-accordion-item .context-menu .menu-dot {
            color: #c4d7ed;
        }
.illini-light .layer-module .surface-accordion-item.expanded .context-menu .menu-dot {
            color: #ffff00;
        }
.illini-light .layer-module .surface-accordion-item:hover .context-menu .menu-dot {
            color: #ffff00;
        }
.illini-light .layer-module .surface-accordion-item .warning-icon {
            fill: #ffff00;
        }
.illini-light .layer-module .surface-accordion-item .cloud-icon {
            fill: #abc8e2;
            stroke: #abc8e2;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module .layer-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.layer-module .layer-accordion-item .layer-label {
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
        margin-left: 5px;
    }
.layer-module .layer-accordion-item .layer-status {
        padding-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
    }
.layer-module .layer-accordion-item .layer-status-completed {
        padding-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
    }
.layer-module .layer-accordion-item .layer-item-loader {
        float: left
    }
.layer-module .layer-accordion-item .layer-item-loader .loader-container {
            position: relative;
        }
.layer-module .layer-accordion-item .layer-item-loader .loader-container > div {
            background-color: transparent;
        }
.layer-module .layer-accordion-item .layer-item-loader .ball-clip-rotate > div {
            height: 15px;
            width: 15px;
        }
.default .layer-module .layer-accordion-item {
        background-color: #404040
    }
.default .layer-module .layer-accordion-item .layer-status {
            color: #e26816;
        }
.default .layer-module .layer-accordion-item .layer-status-completed {
            color: #8fa61a;
        }
.default .letter-icon.has-visible {
        color: #000000;
        background-color: #8fa61a;
    }
.default-light .layer-module .layer-accordion-item {
        background-color: #919191
    }
.default-light .layer-module .layer-accordion-item .layer-status {
            color: #e26816;
        }
.default-light .layer-module .layer-accordion-item .layer-status-completed {
            color: #8fa61a;
        }
.default-light .letter-icon.has-visible {
        color: #fcfcfc;
        background-color: #8fa61a;
    }
.sky .layer-module .layer-accordion-item {
        background-color: #0194d3
    }
.sky .layer-module .layer-accordion-item .layer-status {
            color: #01425e;
        }
.sky .layer-module .layer-accordion-item .layer-status-completed {
            color: #235635;
        }
.sky .letter-icon.has-visible {
        color: #fcfcfc;
        background-color: #235635;
    }
.sky-light .layer-module .layer-accordion-item {
        background-color: #0194d3
    }
.sky-light .layer-module .layer-accordion-item .layer-status {
            color: #01425e;
        }
.sky-light .layer-module .layer-accordion-item .layer-status-completed {
            color: #235635;
        }
.sky-light .letter-icon.has-visible {
        color: #fcfcfc;
        background-color: #235635;
    }
.grass .layer-module .layer-accordion-item {
        background-color: #325132
    }
.grass .layer-module .layer-accordion-item .layer-status {
            color: #e26816;
        }
.grass .layer-module .layer-accordion-item .layer-status-completed {
            color: #ffff00;
        }
.grass .letter-icon.has-visible {
        color: #667c66;
        background-color: #ffff00;
    }
.grass-light .layer-module .layer-accordion-item {
        background-color: #545f54
    }
.grass-light .layer-module .layer-accordion-item .layer-status {
            color: #e26816;
        }
.grass-light .layer-module .layer-accordion-item .layer-status-completed {
            color: #ffff00;
        }
.grass-light .letter-icon.has-visible {
        color: #babfba;
        background-color: #ffff00;
    }
.mud .layer-module .layer-accordion-item {
        background-color: #766146
    }
.mud .layer-module .layer-accordion-item .layer-status {
            color: #f39104;
        }
.mud .layer-module .layer-accordion-item .layer-status-completed {
            color: #ffff00;
        }
.mud .letter-icon.has-visible {
        color: #4b3705;
        background-color: #ffff00;
    }
.mud-light .layer-module .layer-accordion-item {
        background-color: #a37b42
    }
.mud-light .layer-module .layer-accordion-item .layer-status {
            color: #e26816;
        }
.mud-light .layer-module .layer-accordion-item .layer-status-completed {
            color: #8fa61a;
        }
.mud-light .letter-icon.has-visible {
        color: #fff0bb;
        background-color: #8fa61a;
    }
.illini .layer-module .layer-accordion-item {
        background-color: #636466
    }
.illini .layer-module .layer-accordion-item .layer-status {
            color: #f39c11;
        }
.illini .layer-module .layer-accordion-item .layer-status-completed {
            color: #ffff00;
        }
.illini .letter-icon.has-visible {
        color: #00467f;
        background-color: #ffff00;
    }
.illini-light .layer-module .layer-accordion-item {
        background-color: #375d81
    }
.illini-light .layer-module .layer-accordion-item .layer-status {
            color: #f39c11;
        }
.illini-light .layer-module .layer-accordion-item .layer-status-completed {
            color: #ffff00;
        }
.illini-light .letter-icon.has-visible {
        color: #e1e6fa;
        background-color: #ffff00;
    }

.layer-context-menu {
    height: 25px
}

.layer-context-menu .menu-dot {
        height: 6px;
    }

.action-panel .layer-accordion-item .context-menu {
            margin-top: 0;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module .customer-item {
        display: flex;
        align-items: center;
        height: 54px;
        cursor: pointer
    }
.layer-module .customer-item .customer-name {
            padding-left: 20px;
            font-size: 15px;
        }
.default .layer-module .customer-item {
            background-color: #000000;
            border-top: 1px solid #bfbfbf
        }
.default .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #bfbfbf;
            }
.default .layer-module .customer-item:hover {
                background-color: #404040;
            }
.default-light .layer-module .customer-item {
            background-color: #fcfcfc;
            border-top: 1px solid #cfcfcf
        }
.default-light .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #cfcfcf;
            }
.default-light .layer-module .customer-item:hover {
                background-color: #919191;
            }
.sky .layer-module .customer-item {
            background-color: #fcfcfc;
            border-top: 1px solid #c8c8c8
        }
.sky .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #c8c8c8;
            }
.sky .layer-module .customer-item:hover {
                background-color: #0194d3;
            }
.sky-light .layer-module .customer-item {
            background-color: #fcfcfc;
            border-top: 1px solid #c8c8c8
        }
.sky-light .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #c8c8c8;
            }
.sky-light .layer-module .customer-item:hover {
                background-color: #0194d3;
            }
.grass .layer-module .customer-item {
            background-color: #667c66;
            border-top: 1px solid #9cadb2
        }
.grass .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #9cadb2;
            }
.grass .layer-module .customer-item:hover {
                background-color: #325132;
            }
.grass-light .layer-module .customer-item {
            background-color: #babfba;
            border-top: 1px solid #424443
        }
.grass-light .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #424443;
            }
.grass-light .layer-module .customer-item:hover {
                background-color: #545f54;
            }
.mud .layer-module .customer-item {
            background-color: #4b3705;
            border-top: 1px solid #f2f3f8
        }
.mud .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #f2f3f8;
            }
.mud .layer-module .customer-item:hover {
                background-color: #766146;
            }
.mud-light .layer-module .customer-item {
            background-color: #fff0bb;
            border-top: 1px solid #d6c282
        }
.mud-light .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #d6c282;
            }
.mud-light .layer-module .customer-item:hover {
                background-color: #a37b42;
            }
.illini .layer-module .customer-item {
            background-color: #00467f;
            border-top: 1px solid #f2f3f8
        }
.illini .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #f2f3f8;
            }
.illini .layer-module .customer-item:hover {
                background-color: #636466;
            }
.illini-light .layer-module .customer-item {
            background-color: #e1e6fa;
            border-top: 1px solid #abc8e2
        }
.illini-light .layer-module .customer-item.is-not-expanded {
                border-bottom: 1px solid #abc8e2;
            }
.illini-light .layer-module .customer-item:hover {
                background-color: #375d81;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module .feature-data-item {
        height: 54px
    }
.layer-module .feature-data-item .row {
            display: flex;
            flex-direction: row;
            height: 25px;
        }
.layer-module .feature-data-item .letter-icon {
            margin-top: 3px;
            margin-bottom: 3px;
        }
.layer-module .feature-data-item .feature-type {
            display: flex;
            align-items: center;
            margin-left: 6px;
        }
.layer-module .feature-data-item .feature-row {
            display: flex;
            flex-direction: row;
        }
.layer-module .feature-data-item .left-column {
            display: flex;
            align-items: center;
            margin-left: 40px;
        }
.layer-module .feature-data-item .radio-button {
            display: inline-block;
            width: auto;
            height: auto;
        }
.layer-module .feature-data-item .feature-name {
            display: flex;
            align-items: center;
            margin-left: 6px;
            flex-grow: 1;
        }
.default .layer-module .feature-data-item .top-row {
            background-color: #404040;
        }
.default .layer-module .feature-data-item .feature-row {
            background-color: #262626
        }
.default .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #262626;
            }
.default .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #8fa61a;
                }
.default-light .layer-module .feature-data-item .top-row {
            background-color: #919191;
        }
.default-light .layer-module .feature-data-item .feature-row {
            background-color: #e2e2e2
        }
.default-light .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #e2e2e2;
            }
.default-light .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #8fa61a;
                }
.sky .layer-module .feature-data-item .top-row {
            background-color: #0194d3;
        }
.sky .layer-module .feature-data-item .feature-row {
            background-color: #b1b1b1
        }
.sky .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #b1b1b1;
            }
.sky .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #235635;
                }
.sky-light .layer-module .feature-data-item .top-row {
            background-color: #0194d3;
        }
.sky-light .layer-module .feature-data-item .feature-row {
            background-color: #b1b1b1
        }
.sky-light .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #b1b1b1;
            }
.sky-light .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #235635;
                }
.grass .layer-module .feature-data-item .top-row {
            background-color: #325132;
        }
.grass .layer-module .feature-data-item .feature-row {
            background-color: #424443
        }
.grass .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #424443;
            }
.grass .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #ffff00;
                }
.grass-light .layer-module .feature-data-item .top-row {
            background-color: #545f54;
        }
.grass-light .layer-module .feature-data-item .feature-row {
            background-color: #9cadb2
        }
.grass-light .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #9cadb2;
            }
.grass-light .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #ffff00;
                }
.mud .layer-module .feature-data-item .top-row {
            background-color: #766146;
        }
.mud .layer-module .feature-data-item .feature-row {
            background-color: #8c7454
        }
.mud .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #8c7454;
            }
.mud .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #ffff00;
                }
.mud-light .layer-module .feature-data-item .top-row {
            background-color: #a37b42;
        }
.mud-light .layer-module .feature-data-item .feature-row {
            background-color: #b28f5a
        }
.mud-light .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #b28f5a;
            }
.mud-light .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #8fa61a;
                }
.illini .layer-module .feature-data-item .top-row {
            background-color: #636466;
        }
.illini .layer-module .feature-data-item .feature-row {
            background-color: #abc8e2
        }
.illini .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #abc8e2;
            }
.illini .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #ffff00;
                }
.illini-light .layer-module .feature-data-item .top-row {
            background-color: #375d81;
        }
.illini-light .layer-module .feature-data-item .feature-row {
            background-color: #c4d7ed
        }
.illini-light .layer-module .feature-data-item .feature-row .context-menu .menu-dot {
                color: #c4d7ed;
            }
.illini-light .layer-module .feature-data-item .feature-row:hover .context-menu .menu-dot {
                    color: #ffff00;
                }


    .non-field-data-context-menu-container .non-field-data-context-menu.context-menu {
        white-space: nowrap;
        margin-top: 0;
        height: 25px
    }
.non-field-data-context-menu-container .non-field-data-context-menu.context-menu .menu-dot {
            height: 6px;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module .layer-accordion-item-sampling {
        cursor: default;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }
.layer-module .layer-accordion-item-sampling .row {
            display: flex;
            flex-direction: row;
            height: 25px;
        }
.layer-module .layer-accordion-item-sampling .sampling-row {
            display: flex;
            flex-direction: row;
            margin-left: 80px;
        }
.layer-module .layer-accordion-item-sampling .left-column {
            display: flex;
            align-items: center;
            margin-left: 40px;
        }
.layer-module .layer-accordion-item-sampling .layer-label {
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 25px;
            margin-left: 75px;
        }
.layer-module .layer-accordion-item-sampling .feature-name {
            display: flex;
            align-items: center;
            margin-left: 6px;
            flex-grow: 1;
        }
.layer-module .layer-accordion-item-sampling .layer-status-completed {
            font-size: 12px;
            padding-right: 5px;
            overflow: hidden;
            white-space: nowrap;
            line-height: 25px;
            margin-left: 5px;
            min-width: 55px;
        }
.layer-module .layer-accordion-item-sampling .layer-status-event-id {
            font-size: 12px;
            padding-right: 5px;
            overflow: hidden;
            white-space: nowrap;
            line-height: 25px;
            margin-left: 5px;
            min-width: 35px;
        }
.default .layer-module .layer-accordion-item-sampling {
        background-color: #262626
    }
.default .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #262626;
        }
.default .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #8fa61a;
        }
.default-light .layer-module .layer-accordion-item-sampling {
        background-color: #e2e2e2
    }
.default-light .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #e2e2e2;
        }
.default-light .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default-light .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #8fa61a;
        }
.sky .layer-module .layer-accordion-item-sampling {
        background-color: #b1b1b1
    }
.sky .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #b1b1b1;
        }
.sky .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #235635;
        }
.sky-light .layer-module .layer-accordion-item-sampling {
        background-color: #b1b1b1
    }
.sky-light .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #b1b1b1;
        }
.sky-light .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky-light .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #235635;
        }
.grass .layer-module .layer-accordion-item-sampling {
        background-color: #424443
    }
.grass .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #424443;
        }
.grass .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #ffff00;
        }
.grass-light .layer-module .layer-accordion-item-sampling {
        background-color: #9cadb2
    }
.grass-light .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #9cadb2;
        }
.grass-light .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass-light .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #ffff00;
        }
.mud .layer-module .layer-accordion-item-sampling {
        background-color: #8c7454
    }
.mud .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #8c7454;
        }
.mud .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.mud .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #ffff00;
        }
.mud-light .layer-module .layer-accordion-item-sampling {
        background-color: #b28f5a
    }
.mud-light .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #b28f5a;
        }
.mud-light .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.mud-light .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #8fa61a;
        }
.illini .layer-module .layer-accordion-item-sampling {
        background-color: #abc8e2
    }
.illini .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #abc8e2;
        }
.illini .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #ffff00;
        }
.illini-light .layer-module .layer-accordion-item-sampling {
        background-color: #c4d7ed
    }
.illini-light .layer-module .layer-accordion-item-sampling .context-menu .menu-dot {
            color: #c4d7ed;
        }
.illini-light .layer-module .layer-accordion-item-sampling:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini-light .layer-module .layer-accordion-item-sampling .layer-status-completed {
            color: #ffff00;
        }


    .sampling-item-context-menu-container .sampling-item-context-menu.context-menu {
        white-space: nowrap;
        margin-top: 0;
        height: 25px
    }
.sampling-item-context-menu-container .sampling-item-context-menu.context-menu .menu-dot {
            height: 6px;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.analysis-info-bucket-header {
    padding-bottom: 5px;
}
.analysis-form-input {
    max-width: 180px;
    min-width: 164px;
}
.analysis-input {
    margin-left: 7px;
    height: 40px
}
.analysis-input .checkbox-label {
        height: 40px;
    }
.analysis-error-message {
    padding: 10px;
}
.analysis-yield-label-cont {
    display: flex;
    flex-direction: column;
}
.analysis-yield-label {
    color: #bfbfbf;
}
.default .analysis-error-message {
            background-color: #ff0000;
        }
.default-light .analysis-error-message {
            background-color: #ff0000;
        }
.sky .analysis-error-message {
            background-color: #ff0000;
        }
.sky-light .analysis-error-message {
            background-color: #ff0000;
        }
.grass .analysis-error-message {
            background-color: #ff0000;
        }
.grass-light .analysis-error-message {
            background-color: #ff0000;
        }
.mud .analysis-error-message {
            background-color: #ff0000;
        }
.mud-light .analysis-error-message {
            background-color: #ff0000;
        }
.illini .analysis-error-message {
            background-color: #ff0000;
        }
.illini-light .analysis-error-message {
            background-color: #ff0000;
        }
.event-count {
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
}
.seasons {
    padding-left: 15px;
}

.analysis-layer-profit-loss-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
}

.analysis-layer-profit-loss-radio-button {
    margin: auto;
    margin-left: 3px;
}

.analysis-layer-profit-loss-input {
    width: auto;
    display: inline-block;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.dialog-container.break-even-config-modal {
    width: 650px;
    height: 650px
}
.dialog-container.break-even-config-modal .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0;
        min-height: 100px;
    }
.dialog-container.break-even-config-modal .grid-container {
        display: grid;
        grid-template-columns: 3.7fr 1.5fr 0.3fr 0.3fr;
        grid-gap: 5px
    }
.dialog-container.break-even-config-modal .grid-container .detailed-profit-loss-input {
            width: 80px;
            height: 22px;
            margin-bottom: 1px !important;
            margin-right: 10px !important;
        }
.dialog-container.break-even-config-modal .grid-container .table-detailed {
            display: block;
        }
.dialog-container.break-even-config-modal .grid-container .row-detailed {
            display: inline-flex;
            width: 650px;
        }
.dialog-container.break-even-config-modal .grid-container .cell {
            display: inline-block;
        }
.dialog-container.break-even-config-modal .grid-container .use-cell {
            display: inline-block;
            margin-top: 12px;
        }
.dialog-container.break-even-config-modal .grid-container .text-cell {
            display: inline-block;
            width: 180px;
            text-align: right;
            padding-right: 20px;
            margin-top: 15px;
        }
.dialog-container.break-even-config-modal .grid-container .title-text-cell {
            display: inline-block;
            width: 180px;
            text-align: right;
            height: 20px;
            padding-top: 10px;
            margin-left: 10px;
            margin-top: 20px;
            margin-bottom: 5px;
            padding-left: 85px;
            padding-right: 55px;
        }
.dialog-container.break-even-config-modal .grid-container .actual-val {
            padding-right: 45px;
            padding-left: 16px;
            width: 40px;
            min-width: 40px;
            text-align: end;
            margin-top: 15px;
        }
.dialog-container.break-even-config-modal .grid-container .title-cell {
            display: inline-block;
            height: 20px;
            padding-top: 10px;
            padding-right: 35px;
        }
.dialog-container.break-even-config-modal .grid-container .total {
            margin-top: 15px;
            margin-bottom: 5px;
            font-weight: bold;
        }
.dialog-container.break-even-config-modal .grid-container .total-value {
            padding-left: 5px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
.dialog-container.break-even-config-modal .grid-container .init-no-value {
            padding-left: 42px;
        }
.dialog-container.break-even-config-modal .grid-container .total-center {
            width: 80px;
            text-align: center;
        }
.dialog-container.break-even-config-modal .form-input:not(.select-form-input) input {
            text-align: center;
        }
.default .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #262626;
        }
.default .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.default-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #e2e2e2;
        }
.default-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.sky .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b1b1b1;
        }
.sky .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.sky-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b1b1b1;
        }
.sky-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.grass .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #424443;
        }
.grass .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.grass-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #9cadb2;
        }
.grass-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.mud .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #8c7454;
        }
.mud .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.mud-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #b28f5a;
        }
.mud-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.illini .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #abc8e2;
        }
.illini .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }
.illini-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row {
            border-bottom: 1px solid #c4d7ed;
        }
.illini-light .rec-event-info .dialog-container.sample-soil-depth-modal .depth-tbl-row .invalid-depth {
            border: 2px solid #f89406;
        }

.analysis-layer-ec-data-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.analysis-layer-ec-data-input {
    width: auto;
    display: inline-block;
}

.analysis-layer-management-area-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.analysis-layer-management-area-radio-button {
    margin: auto;
    margin-left: 3px;
}

.analysis-layer-management-area-input {
    width: auto;
    display: inline-block;
    margin: auto 0;
}

.analysis-layer-management-area-type-input {
    width: 66%;
    display: inline-block;
    grid-column: 1 / 2 span;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}

.zone-icon {
    height: 15px;
    width: 15px;
    margin-left: 5px;
    margin-right: 12px;
}

.zone-info-heading {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 0;
}

.zone-name {
    font-size: 14px;
    font-weight: 700;
}

.zone-size {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 300;
}

.no-zone-selected {
    padding: 15px 20px 10px 20px;
    color: #bfbfbf;
}

.analysis-layer-imagery-setup-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.analysis-layer-imagery-setup-radio-button {
    margin: auto;
    margin-left: 3px;
}

.analysis-layer-imagery-setup-input {
    width: auto;
    display: inline-block;
    margin: auto 0;
}

.analysis-layer-imagery-setup-type-input {
    width: 66%;
    display: inline-block;
    grid-column: 1 / 2 span;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}

.zone-icon {
    height: 15px;
    width: 15px;
    margin-left: 5px;
    margin-right: 12px;
}

.zone-info-heading {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 0;
}

.zone-name {
    font-size: 14px;
    font-weight: 700;
}

.zone-size {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 300;
}

.no-zone-selected {
    padding: 15px 20px 10px 20px;
    color: #bfbfbf;
}


    .imagery-form .imagery-input-row {
        display: flex;
        flex-flow: row nowrap;
        width: 100%
    }
.imagery-form .imagery-input-row .imagery-form-input {
            width: 94%;
        }
.imagery-form .imagery-input-row .select-form-input-container {
            width: 49%;
        }
.imagery-form .imagery-input-row .date-form-input-container {
            width: 49%;
        }
.imagery-form .imagery-input-row .check-box-container {
            margin: 1mm 0 0 1mm;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.non-field-feature-information-title-bar {
    min-height: 60px;
    padding: 4px 5px 0 15px
}
.non-field-feature-information-title-bar .first-line {
        font-size: 11pt;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
    }
.non-field-feature-information-title-bar .second-line {
        font-size: 10pt;
        font-style: italic;
        height: 18px;
        line-height: 18px;
    }
.non-field-feature-information-title-bar .third-line {
        font-size: 9pt;
        height: 18px;
        line-height: 18px;
    }
.default .title-bar {
        background-color: #000000;
        border-bottom: 1px solid #bfbfbf;
        color: #ffffff;
    }
.default-light .title-bar {
        background-color: #fcfcfc;
        border-bottom: 1px solid #cfcfcf;
        color: #000000;
    }
.sky .title-bar {
        background-color: #fcfcfc;
        border-bottom: 1px solid #c8c8c8;
        color: #000000;
    }
.sky-light .title-bar {
        background-color: #fcfcfc;
        border-bottom: 1px solid #c8c8c8;
        color: #000000;
    }
.grass .title-bar {
        background-color: #667c66;
        border-bottom: 1px solid #9cadb2;
        color: #ffffff;
    }
.grass-light .title-bar {
        background-color: #babfba;
        border-bottom: 1px solid #424443;
        color: #000000;
    }
.mud .title-bar {
        background-color: #4b3705;
        border-bottom: 1px solid #f2f3f8;
        color: #ffffff;
    }
.mud-light .title-bar {
        background-color: #fff0bb;
        border-bottom: 1px solid #d6c282;
        color: #000000;
    }
.illini .title-bar {
        background-color: #00467f;
        border-bottom: 1px solid #f2f3f8;
        color: #ffffff;
    }
.illini-light .title-bar {
        background-color: #e1e6fa;
        border-bottom: 1px solid #abc8e2;
        color: #000000;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.information-menu {
    min-width: 60px;
    height: 100%;
    display: inline-block
}
.information-menu .information-menu-tab {
        height: 80px;
        width: 100%;
        cursor: pointer
    }
.information-menu .information-menu-tab .information-menu-tab-text {
            font-size: 10pt;
            text-align: center;
            width: 100%;
            -webkit-user-select: none;
                -ms-user-select: none;
                    user-select: none;
        }
.information-menu .side-menu-icon {
        height: 37px;
        width: 37px;
        margin: 11px 11px 7px;
    }
.default .information-menu {
        background-color: #000000
    }
.default .information-menu .active-tab {
            background-color: #404040;
            color: #8fa61a
        }
.default .information-menu .active-tab .side-menu-icon {
                fill: #8fa61a;
            }
.default .information-menu .general-tab.error {
            color: #ff0000
        }
.default .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.default-light .information-menu {
        background-color: #fcfcfc
    }
.default-light .information-menu .active-tab {
            background-color: #919191;
            color: #8fa61a
        }
.default-light .information-menu .active-tab .side-menu-icon {
                fill: #8fa61a;
            }
.default-light .information-menu .general-tab.error {
            color: #ff0000
        }
.default-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.sky .information-menu {
        background-color: #fcfcfc
    }
.sky .information-menu .active-tab {
            background-color: #0194d3;
            color: #235635
        }
.sky .information-menu .active-tab .side-menu-icon {
                fill: #235635;
            }
.sky .information-menu .general-tab.error {
            color: #ff0000
        }
.sky .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.sky-light .information-menu {
        background-color: #fcfcfc
    }
.sky-light .information-menu .active-tab {
            background-color: #0194d3;
            color: #235635
        }
.sky-light .information-menu .active-tab .side-menu-icon {
                fill: #235635;
            }
.sky-light .information-menu .general-tab.error {
            color: #ff0000
        }
.sky-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.grass .information-menu {
        background-color: #667c66
    }
.grass .information-menu .active-tab {
            background-color: #325132;
            color: #ffff00
        }
.grass .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.grass .information-menu .general-tab.error {
            color: #ff0000
        }
.grass .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.grass-light .information-menu {
        background-color: #babfba
    }
.grass-light .information-menu .active-tab {
            background-color: #545f54;
            color: #ffff00
        }
.grass-light .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.grass-light .information-menu .general-tab.error {
            color: #ff0000
        }
.grass-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.mud .information-menu {
        background-color: #4b3705
    }
.mud .information-menu .active-tab {
            background-color: #766146;
            color: #ffff00
        }
.mud .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.mud .information-menu .general-tab.error {
            color: #ff0000
        }
.mud .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.mud-light .information-menu {
        background-color: #fff0bb
    }
.mud-light .information-menu .active-tab {
            background-color: #a37b42;
            color: #8fa61a
        }
.mud-light .information-menu .active-tab .side-menu-icon {
                fill: #8fa61a;
            }
.mud-light .information-menu .general-tab.error {
            color: #ff0000
        }
.mud-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.illini .information-menu {
        background-color: #00467f
    }
.illini .information-menu .active-tab {
            background-color: #636466;
            color: #ffff00
        }
.illini .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.illini .information-menu .general-tab.error {
            color: #ff0000
        }
.illini .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.illini-light .information-menu {
        background-color: #e1e6fa
    }
.illini-light .information-menu .active-tab {
            background-color: #375d81;
            color: #ffff00
        }
.illini-light .information-menu .active-tab .side-menu-icon {
                fill: #ffff00;
            }
.illini-light .information-menu .general-tab.error {
            color: #ff0000
        }
.illini-light .information-menu .general-tab.error .side-menu-icon {
                fill: #ff0000;
            }
.sky .information-menu .active-tab {
        background-color: #e2e2e2;
    }
.sky-light .information-menu .active-tab {
        background-color: #e2e2e2;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.non-field-feature-information-detail-container {
    padding-top: 16px;
    flex: 1 1;
    width: 84%;
    overflow: auto
}
.non-field-feature-information-detail-container .non-field-feature-information-detail {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between
    }
.non-field-feature-information-detail-container .non-field-feature-information-detail .top-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
.non-field-feature-information-detail-container .non-field-feature-information-detail .feature-type .form-input,
        .non-field-feature-information-detail-container .non-field-feature-information-detail .field-selection .form-input {
            margin-right: 3px;
            margin-bottom: 8px;
        }
.non-field-feature-information-detail-container .non-field-feature-information-detail .field-selection .form-input {
            margin-top: 0;
        }
.non-field-feature-information-detail-container .non-field-feature-information-detail .feature-name {
            margin-left: 3px;
            margin-bottom: 8px;
        }
.non-field-feature-information-detail-container .non-field-feature-information-detail .private-checkbox {
            margin-left: 10px;
        }
.non-field-feature-information-detail-container .non-field-feature-information-detail .description {
            margin-top: 0;
            height: 100px;
        }
.non-field-feature-information-detail-container .non-field-feature-information-detail .extent-checkbox {
            margin-top: 10px;
            margin-left: 3px;
        }
.default .non-field-feature-information-detail-container {
        background-color: #404040
    }
.default .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.default .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.default-light .non-field-feature-information-detail-container {
        background-color: #919191
    }
.default-light .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.default-light .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.sky .non-field-feature-information-detail-container {
        background-color: #0194d3
    }
.sky .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.sky .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.sky-light .non-field-feature-information-detail-container {
        background-color: #0194d3
    }
.sky-light .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.sky-light .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.grass .non-field-feature-information-detail-container {
        background-color: #325132
    }
.grass .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.grass .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.grass-light .non-field-feature-information-detail-container {
        background-color: #545f54
    }
.grass-light .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.grass-light .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.mud .non-field-feature-information-detail-container {
        background-color: #766146
    }
.mud .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.mud .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.mud-light .non-field-feature-information-detail-container {
        background-color: #a37b42
    }
.mud-light .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.mud-light .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.illini .non-field-feature-information-detail-container {
        background-color: #636466
    }
.illini .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.illini .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #ffffff;
            }
.illini-light .non-field-feature-information-detail-container {
        background-color: #375d81
    }
.illini-light .non-field-feature-information-detail-container .label-error {
            color: #ff0000;
        }
.illini-light .non-field-feature-information-detail-container .non-field-feature-information-detail .bucket-arrow-icon {
                fill: #000000;
            }
.sky .non-field-feature-information-detail-container {
        background-color: #e2e2e2;
    }
.sky-light .non-field-feature-information-detail-container {
        background-color: #e2e2e2;
    }

.non-field-feature-information-button-bar {
    max-height: 60px;
    min-height: 60px;
    width: 100%;
    text-align: center;
    padding-top: 15px
}
.non-field-feature-information-button-bar .default-button {
        margin: 9px;
    }

.non-field-feature-information {
    flex: 100 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap
}
.non-field-feature-information .non-field-feature-information-content {
        flex-grow: 100;
        height: 0;
        display: flex;
        flex-flow: row nowrap
    }
.non-field-feature-information .non-field-feature-information-content .content-table-container.with-api-result {
            display: flex;
            flex-grow: 1;
        }

.water-sampling-info {
    display: flex;
    flex-direction: column
}

.water-sampling-info .checkbox-div {
        margin-left: 3px;
    }

.water-sampling-info .temp-input {
        max-width: 85px;
    }

.water-sampling-info .textarea-form-input {
        width: 95%;
        height: 110px;
    }

.water-sampling-info .event-label-icon {
        height: 45px;
        width: 50px;
    }

.water-sampling-info {

    .event-sample-water-form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: calc(100% - 275px);
        overflow: hidden;
        padding-top: 5px
    }
                .event-sample-water-form .sample-results-tbl-container .row > div:not(.attribute-name) {
                    width: 220px;
                }

        .event-sample-water-form .with-results {
            height: calc(100% - 5px);
            padding: 0 10px;
        }
}

.water-sampling-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.water-sampling-radio-button {
    margin: auto;
    margin-left: 3px;
}

.water-sampling-input {
    width: auto;
    display: inline-block;
    margin: auto 0;
}

.water-sampling-type-input {
    width: 66%;
    display: inline-block;
    grid-column: 1 / 2 span;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}

.zone-icon {
    height: 15px;
    width: 15px;
    margin-left: 5px;
    margin-right: 12px;
}

.zone-info-heading {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 0;
}

.zone-name {
    font-size: 14px;
    font-weight: 700;
}

.zone-size {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 300;
}

.no-zone-selected {
    padding: 15px 20px 10px 20px;
    color: #bfbfbf;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.layer-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column
}
.layer-module .layer-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
.layer-module .tabs .tabs-labels {
        min-height: 40px
    }
.layer-module .tabs .tabs-labels li.tab-label {
            height: 40px;
            box-sizing: border-box
        }
.layer-module .tabs .tabs-labels li.tab-label.left-non-label {
                flex-grow: 1;
            }
.layer-module .tabs .tabs-labels li.tab-label.right-non-label {
                width: 30px;
                display: flex;
                flex-direction: row-reverse;
            }
.layer-module .standard-layers {
        display: flex;
        flex-direction: column;
        padding-top: 4px
    }
.layer-module .standard-layers .dynamic-layer,
        .layer-module .standard-layers .graphics-layer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 5px;
            min-height: 25px;
            padding-left: 20px;
            cursor: pointer
        }
.layer-module .standard-layers .dynamic-layer .checkbox-div, .layer-module .standard-layers .graphics-layer .checkbox-div {
                width: auto;
            }
.layer-module .standard-layers .dynamic-layer .legend-icon, .layer-module .standard-layers .graphics-layer .legend-icon {
                box-sizing: border-box;
                height: 20px;
                min-width: 20px;
                width: 20px;
            }
.layer-module .standard-layers .dynamic-layer .legend-label, .layer-module .standard-layers .graphics-layer .legend-label {
                flex-grow: 1;
                line-height: 20px;
                margin-left: 6px
            }
.layer-module .standard-layers .dynamic-layer .legend-label.disabled, .layer-module .standard-layers .graphics-layer .legend-label.disabled {
                    opacity: 50%;
                }
.layer-module .standard-layers .dynamic-layer .sub-legend-layers, .layer-module .standard-layers .graphics-layer .sub-legend-layers {
                flex-basis: 100%
            }
.layer-module .standard-layers .dynamic-layer .sub-legend-layers > :first-child, .layer-module .standard-layers .graphics-layer .sub-legend-layers > :first-child {
                    padding-top: 0;
                }
.layer-module .standard-layers .dynamic-layer .sub-legend-layers .legend-icon, .layer-module .standard-layers .graphics-layer .sub-legend-layers .legend-icon {
                    margin-left: 33px;
                }
.layer-module .standard-layers .dynamic-layer .context-menu, .layer-module .standard-layers .graphics-layer .context-menu {
                height: 21px;
                margin-top: -1px;
                padding-top: 1px
            }
.layer-module .standard-layers .dynamic-layer .context-menu .menu-dot, .layer-module .standard-layers .graphics-layer .context-menu .menu-dot {
                    line-height: 8px;
                    margin-bottom: -2px;
                }
.layer-module .standard-layers .dynamic-layer .svg-container, .layer-module .standard-layers .graphics-layer .svg-container {
                margin-top: -2px;
                padding-right: 5px;
            }
.layer-module .layer-props-section {
        padding: 10px 0 0 20px;
    }
.event-module .surface-properties-dialog, .layer-module .surface-properties-dialog {
        max-width: 300px
    }
.event-module .surface-properties-dialog .attribute-label, .layer-module .surface-properties-dialog .attribute-label {
            border-radius: 4px;
            padding: 10px;
            margin: 5px 10px;
            white-space: normal;
        }
.event-module .surface-properties-dialog .attribute-select .select-form-input, .layer-module .surface-properties-dialog .attribute-select .select-form-input {
            margin-top: 5px;
        }
.event-module .surface-properties-dialog .display-type-select .select-form-input,
        .event-module .surface-properties-dialog .color-scheme-select .select-form-input,
        .layer-module .surface-properties-dialog .display-type-select .select-form-input,
        .layer-module .surface-properties-dialog .color-scheme-select .select-form-input {
            margin-top: 15px;
            margin-bottom: 5px;
        }
.event-module .surface-properties-dialog a, .layer-module .surface-properties-dialog a {
            margin: 0 10px;
        }
.event-module .surface-properties-dialog .surfaces-reset-link, .layer-module .surface-properties-dialog .surfaces-reset-link {
            margin-top: 5px;
        }
.event-module .surface-properties-dialog .color-option, .layer-module .surface-properties-dialog .color-option {
            height: 24px;
        }
.event-module .surface-properties-dialog .color-ramp-section, .layer-module .surface-properties-dialog .color-ramp-section {
            display: flex;
            flex-direction: row;
            margin-top: 7px
        }
.event-module .surface-properties-dialog .color-ramp-section .inputs-section, .layer-module .surface-properties-dialog .color-ramp-section .inputs-section {
                flex: 78 1;
                display: flex;
                flex-direction: column;
            }
.event-module .surface-properties-dialog .color-ramp-section .legend-preview, .layer-module .surface-properties-dialog .color-ramp-section .legend-preview {
                display: flex;
                flex: 22 1;
                flex-direction: column;
                justify-content: center;
                margin-left: 5px;
                padding: 7px 0 7px 15px;
            }
.event-module .surface-properties-dialog .legend-preview-item, .layer-module .surface-properties-dialog .legend-preview-item {
            display: flex;
            flex-direction: row;
            flex-grow: 1
        }
.event-module .surface-properties-dialog .legend-preview-item .legend-icon, .layer-module .surface-properties-dialog .legend-preview-item .legend-icon {
                margin-bottom: 1px;
                max-height: 20px;
                min-height: 17px;
                min-width: 20px;
            }
.event-module .surface-properties-dialog .legend-preview-item .legend-label, .layer-module .surface-properties-dialog .legend-preview-item .legend-label {
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                font-size: 13px;
                line-height: 17px;
                padding: 0 7px;
            }
.default .event-module .dynamic-layer:hover {
            background-color: #262626
        }
.default .event-module .graphics-layer:hover {
            background-color: #262626
        }
.default .layer-module .dynamic-layer:hover {
            background-color: #262626
        }
.default .layer-module .graphics-layer:hover {
            background-color: #262626
        }
.default .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.default .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.default .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.default .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.default-light .event-module .dynamic-layer:hover {
            background-color: #e2e2e2
        }
.default-light .event-module .graphics-layer:hover {
            background-color: #e2e2e2
        }
.default-light .layer-module .dynamic-layer:hover {
            background-color: #e2e2e2
        }
.default-light .layer-module .graphics-layer:hover {
            background-color: #e2e2e2
        }
.default-light .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default-light .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default-light .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default-light .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.default-light .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.default-light .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.default-light .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.default-light .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky .event-module .dynamic-layer:hover {
            background-color: #b1b1b1
        }
.sky .event-module .graphics-layer:hover {
            background-color: #b1b1b1
        }
.sky .layer-module .dynamic-layer:hover {
            background-color: #b1b1b1
        }
.sky .layer-module .graphics-layer:hover {
            background-color: #b1b1b1
        }
.sky .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky-light .event-module .dynamic-layer:hover {
            background-color: #b1b1b1
        }
.sky-light .event-module .graphics-layer:hover {
            background-color: #b1b1b1
        }
.sky-light .layer-module .dynamic-layer:hover {
            background-color: #b1b1b1
        }
.sky-light .layer-module .graphics-layer:hover {
            background-color: #b1b1b1
        }
.sky-light .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky-light .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky-light .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky-light .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #235635;
            }
.sky-light .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky-light .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky-light .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.sky-light .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.grass .event-module .dynamic-layer:hover {
            background-color: #424443
        }
.grass .event-module .graphics-layer:hover {
            background-color: #424443
        }
.grass .layer-module .dynamic-layer:hover {
            background-color: #424443
        }
.grass .layer-module .graphics-layer:hover {
            background-color: #424443
        }
.grass .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.grass .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.grass .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.grass .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.grass-light .event-module .dynamic-layer:hover {
            background-color: #9cadb2
        }
.grass-light .event-module .graphics-layer:hover {
            background-color: #9cadb2
        }
.grass-light .layer-module .dynamic-layer:hover {
            background-color: #9cadb2
        }
.grass-light .layer-module .graphics-layer:hover {
            background-color: #9cadb2
        }
.grass-light .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass-light .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass-light .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass-light .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.grass-light .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.grass-light .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.grass-light .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.grass-light .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.mud .event-module .dynamic-layer:hover {
            background-color: #8c7454
        }
.mud .event-module .graphics-layer:hover {
            background-color: #8c7454
        }
.mud .layer-module .dynamic-layer:hover {
            background-color: #8c7454
        }
.mud .layer-module .graphics-layer:hover {
            background-color: #8c7454
        }
.mud .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.mud .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.mud .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.mud .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.mud .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.mud .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.mud .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.mud .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.mud-light .event-module .dynamic-layer:hover {
            background-color: #b28f5a
        }
.mud-light .event-module .graphics-layer:hover {
            background-color: #b28f5a
        }
.mud-light .layer-module .dynamic-layer:hover {
            background-color: #b28f5a
        }
.mud-light .layer-module .graphics-layer:hover {
            background-color: #b28f5a
        }
.mud-light .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.mud-light .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.mud-light .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.mud-light .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #8fa61a;
            }
.mud-light .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.mud-light .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.mud-light .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.mud-light .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.illini .event-module .dynamic-layer:hover {
            background-color: #abc8e2
        }
.illini .event-module .graphics-layer:hover {
            background-color: #abc8e2
        }
.illini .layer-module .dynamic-layer:hover {
            background-color: #abc8e2
        }
.illini .layer-module .graphics-layer:hover {
            background-color: #abc8e2
        }
.illini .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.illini .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(255, 255, 255, 0.3);
            }
.illini .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.illini .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(255, 255, 255, 0.3);
            }
.illini-light .event-module .dynamic-layer:hover {
            background-color: #c4d7ed
        }
.illini-light .event-module .graphics-layer:hover {
            background-color: #c4d7ed
        }
.illini-light .layer-module .dynamic-layer:hover {
            background-color: #c4d7ed
        }
.illini-light .layer-module .graphics-layer:hover {
            background-color: #c4d7ed
        }
.illini-light .event-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini-light .event-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini-light .layer-module .dynamic-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini-light .layer-module .graphics-layer:hover .context-menu .menu-dot {
                color: #ffff00;
            }
.illini-light .event-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.illini-light .layer-module .surface-properties-dialog .attribute-label {
                border: solid 1px rgba(0, 0, 0, 0.3);
            }
.illini-light .event-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }
.illini-light .layer-module .surface-properties-dialog .color-ramp-section > div:last-child {
                border-left: solid 1px rgba(0, 0, 0, 0.3);
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.action-panel .panel-navigation.disabled .panel-nav-tab:not(.active-nav),
        .action-panel .panel-navigation.disabled .collapse-button {
            cursor: default;
            opacity: 0.6;
        }
.action-panel .panel-navigation {
        min-height: 50px;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer
    }
.action-panel .panel-navigation .module-navigation {
            height: 44px;
            display: flex;
        }
.action-panel .panel-navigation .disabled {
            cursor: default;
        }
.action-panel .panel-navigation .panel-nav-tab {
            height: 44px;
            min-width: 18.5%;
            float: left;
            font-size: 11px;
            text-align: center;
            border: none;
            margin-top: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            cursor: pointer
        }
.action-panel .panel-navigation .panel-nav-tab .text-and-count {
                display: flex;
                flex-direction: row;
            }
.action-panel .panel-navigation .panel-nav-tab .tab-text {
                line-height: 16px;
                min-width: 38px;
            }
.action-panel .panel-navigation .panel-nav-tab .tab-count {
                position: relative;
                left: 5px;
                margin-top: -5px;
                margin-bottom: 3px;
                padding: 3px 3px 0 3px;
                min-width: 7px;
                max-width: 30px;
                font-size: 11px;
                border-radius: 5px;
            }
.action-panel .panel-navigation .panel-nav-tab .svg-container {
                margin-top: 3px;
            }
.action-panel .panel-navigation .collapse-button {
            height: 37px;
            width: 32px;
            float: right;
            padding-top: 10px;
            text-align: center;
            cursor: pointer;
            z-index: 10;
        }
.action-panel .panel-navigation .collapse-button-collapsed {
            position: absolute;
            left: 5px;
        }
.action-panel .panel-navigation .collapse-button-expanded {
            position: static;
            position: initial;
        }
.action-panel .panel-navigation.tab-collapse .panel-nav-tab {
            display: none;
        }
.action-panel .disabled {
        cursor: default;
    }
.default .action-panel .panel-navigation {
            background-color: #404040
        }
.default .action-panel .panel-navigation .panel-nav-tab {
                color: #ffffff
            }
.default .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default .action-panel .active-nav {
            color: #8fa61a;
            background-color: #000000 !important;
            border-bottom: 4px solid #8fa61a;
        }
.default .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #262626;
            background-color: #262626;
        }
.default .action-panel .collapse-button:hover {
            border-bottom: 4px solid #262626;
            background-color: #262626;
        }
.default .action-panel .disabled {
            color: #777777;
        }
.default .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #8fa61a;
            }
.default .action-panel .active-nav .tab-text {
                color: #8fa61a;
            }
.default-light .action-panel .panel-navigation {
            background-color: #919191
        }
.default-light .action-panel .panel-navigation .panel-nav-tab {
                color: #000000
            }
.default-light .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.default-light .action-panel .active-nav {
            color: #8fa61a;
            background-color: #fcfcfc !important;
            border-bottom: 4px solid #8fa61a;
        }
.default-light .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #e2e2e2;
            background-color: #e2e2e2;
        }
.default-light .action-panel .collapse-button:hover {
            border-bottom: 4px solid #e2e2e2;
            background-color: #e2e2e2;
        }
.default-light .action-panel .disabled {
            color: #777777;
        }
.default-light .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #8fa61a;
            }
.default-light .action-panel .active-nav .tab-text {
                color: #8fa61a;
            }
.sky .action-panel .panel-navigation {
            background-color: #0194d3
        }
.sky .action-panel .panel-navigation .panel-nav-tab {
                color: #000000
            }
.sky .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky .action-panel .active-nav {
            color: #235635;
            background-color: #fcfcfc !important;
            border-bottom: 4px solid #235635;
        }
.sky .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #b1b1b1;
            background-color: #b1b1b1;
        }
.sky .action-panel .collapse-button:hover {
            border-bottom: 4px solid #b1b1b1;
            background-color: #b1b1b1;
        }
.sky .action-panel .disabled {
            color: #878787;
        }
.sky .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #235635;
            }
.sky .action-panel .active-nav .tab-text {
                color: #235635;
            }
.sky-light .action-panel .panel-navigation {
            background-color: #0194d3
        }
.sky-light .action-panel .panel-navigation .panel-nav-tab {
                color: #000000
            }
.sky-light .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #01425e;
                    color: #ffffff;
                }
.sky-light .action-panel .active-nav {
            color: #235635;
            background-color: #fcfcfc !important;
            border-bottom: 4px solid #235635;
        }
.sky-light .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #b1b1b1;
            background-color: #b1b1b1;
        }
.sky-light .action-panel .collapse-button:hover {
            border-bottom: 4px solid #b1b1b1;
            background-color: #b1b1b1;
        }
.sky-light .action-panel .disabled {
            color: #878787;
        }
.sky-light .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #235635;
            }
.sky-light .action-panel .active-nav .tab-text {
                color: #235635;
            }
.grass .action-panel .panel-navigation {
            background-color: #325132
        }
.grass .action-panel .panel-navigation .panel-nav-tab {
                color: #ffffff
            }
.grass .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass .action-panel .active-nav {
            color: #ffff00;
            background-color: #667c66 !important;
            border-bottom: 4px solid #ffff00;
        }
.grass .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #424443;
            background-color: #424443;
        }
.grass .action-panel .collapse-button:hover {
            border-bottom: 4px solid #424443;
            background-color: #424443;
        }
.grass .action-panel .disabled {
            color: #e5e9e5;
        }
.grass .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #ffff00;
            }
.grass .action-panel .active-nav .tab-text {
                color: #ffff00;
            }
.grass-light .action-panel .panel-navigation {
            background-color: #545f54
        }
.grass-light .action-panel .panel-navigation .panel-nav-tab {
                color: #000000
            }
.grass-light .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.grass-light .action-panel .active-nav {
            color: #ffff00;
            background-color: #babfba !important;
            border-bottom: 4px solid #ffff00;
        }
.grass-light .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #9cadb2;
            background-color: #9cadb2;
        }
.grass-light .action-panel .collapse-button:hover {
            border-bottom: 4px solid #9cadb2;
            background-color: #9cadb2;
        }
.grass-light .action-panel .disabled {
            color: #e5e9e5;
        }
.grass-light .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #ffff00;
            }
.grass-light .action-panel .active-nav .tab-text {
                color: #ffff00;
            }
.mud .action-panel .panel-navigation {
            background-color: #766146
        }
.mud .action-panel .panel-navigation .panel-nav-tab {
                color: #ffffff
            }
.mud .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #f39104;
                    color: #ffffff;
                }
.mud .action-panel .active-nav {
            color: #ffff00;
            background-color: #4b3705 !important;
            border-bottom: 4px solid #ffff00;
        }
.mud .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #8c7454;
            background-color: #8c7454;
        }
.mud .action-panel .collapse-button:hover {
            border-bottom: 4px solid #8c7454;
            background-color: #8c7454;
        }
.mud .action-panel .disabled {
            color: #777777;
        }
.mud .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #ffff00;
            }
.mud .action-panel .active-nav .tab-text {
                color: #ffff00;
            }
.mud-light .action-panel .panel-navigation {
            background-color: #a37b42
        }
.mud-light .action-panel .panel-navigation .panel-nav-tab {
                color: #000000
            }
.mud-light .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #e26816;
                    color: #ffffff;
                }
.mud-light .action-panel .active-nav {
            color: #8fa61a;
            background-color: #fff0bb !important;
            border-bottom: 4px solid #8fa61a;
        }
.mud-light .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #b28f5a;
            background-color: #b28f5a;
        }
.mud-light .action-panel .collapse-button:hover {
            border-bottom: 4px solid #b28f5a;
            background-color: #b28f5a;
        }
.mud-light .action-panel .disabled {
            color: #777777;
        }
.mud-light .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #8fa61a;
            }
.mud-light .action-panel .active-nav .tab-text {
                color: #8fa61a;
            }
.illini .action-panel .panel-navigation {
            background-color: #636466
        }
.illini .action-panel .panel-navigation .panel-nav-tab {
                color: #ffffff
            }
.illini .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini .action-panel .active-nav {
            color: #ffff00;
            background-color: #00467f !important;
            border-bottom: 4px solid #ffff00;
        }
.illini .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #abc8e2;
            background-color: #abc8e2;
        }
.illini .action-panel .collapse-button:hover {
            border-bottom: 4px solid #abc8e2;
            background-color: #abc8e2;
        }
.illini .action-panel .disabled {
            color: #777777;
        }
.illini .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #ffff00;
            }
.illini .action-panel .active-nav .tab-text {
                color: #ffff00;
            }
.illini-light .action-panel .panel-navigation {
            background-color: #375d81
        }
.illini-light .action-panel .panel-navigation .panel-nav-tab {
                color: #000000
            }
.illini-light .action-panel .panel-navigation .panel-nav-tab .tab-count {
                    background-color: #f39c11;
                    color: #ffffff;
                }
.illini-light .action-panel .active-nav {
            color: #ffff00;
            background-color: #e1e6fa !important;
            border-bottom: 4px solid #ffff00;
        }
.illini-light .action-panel .panel-nav-tab:hover {
            border-bottom: 4px solid #c4d7ed;
            background-color: #c4d7ed;
        }
.illini-light .action-panel .collapse-button:hover {
            border-bottom: 4px solid #c4d7ed;
            background-color: #c4d7ed;
        }
.illini-light .action-panel .disabled {
            color: #777777;
        }
.illini-light .action-panel .active-nav .panel-nav-icon.active-icon {
                fill: #ffff00;
            }
.illini-light .action-panel .active-nav .tab-text {
                color: #ffff00;
            }
.sky .action-panel .panel-navigation .panel-nav-tab .svg-container svg {
                    fill: #ffffff;
                }
.sky .action-panel .panel-navigation .collapse-button .svg-container svg {
                    fill: #ffffff;
                }
.sky .action-panel .panel-navigation .panel-nav-tab .text-and-count .tab-text {
                    color: #ffffff;
                }
.sky .action-panel .panel-navigation .collapse-button .text-and-count .tab-text {
                    color: #ffffff;
                }
.sky .action-panel .panel-navigation .panel-nav-tab.active-nav .svg-container svg.panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky .action-panel .panel-navigation .collapse-button.active-nav .svg-container svg.panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky .action-panel .panel-navigation .panel-nav-tab.active-nav .text-and-count .tab-text {
                        color: #235635;
                    }
.sky .action-panel .panel-navigation .collapse-button.active-nav .text-and-count .tab-text {
                        color: #235635;
                    }
.sky .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover {
                border-bottom: 4px solid #b1b1b1;
                background-color: #b1b1b1;
                color: #000000
            }
.sky .action-panel .panel-navigation .collapse-button:hover {
                border-bottom: 4px solid #b1b1b1;
                background-color: #b1b1b1;
                color: #000000
            }
.sky .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover .svg-container svg {
                    fill: #000000;
                }
.sky .action-panel .panel-navigation .collapse-button:hover .svg-container svg {
                    fill: #000000;
                }
.sky .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover .text-and-count .tab-text {
                    color: #000000;
                }
.sky .action-panel .panel-navigation .collapse-button:hover .text-and-count .tab-text {
                    color: #000000;
                }
.sky .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover.active-nav .panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky .action-panel .panel-navigation .collapse-button:hover.active-nav .panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover.active-nav .tab-text {
                        color: #235635;
                    }
.sky .action-panel .panel-navigation .collapse-button:hover.active-nav .tab-text {
                        color: #235635;
                    }
.sky-light .action-panel .panel-navigation .panel-nav-tab .svg-container svg {
                    fill: #ffffff;
                }
.sky-light .action-panel .panel-navigation .collapse-button .svg-container svg {
                    fill: #ffffff;
                }
.sky-light .action-panel .panel-navigation .panel-nav-tab .text-and-count .tab-text {
                    color: #ffffff;
                }
.sky-light .action-panel .panel-navigation .collapse-button .text-and-count .tab-text {
                    color: #ffffff;
                }
.sky-light .action-panel .panel-navigation .panel-nav-tab.active-nav .svg-container svg.panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky-light .action-panel .panel-navigation .collapse-button.active-nav .svg-container svg.panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky-light .action-panel .panel-navigation .panel-nav-tab.active-nav .text-and-count .tab-text {
                        color: #235635;
                    }
.sky-light .action-panel .panel-navigation .collapse-button.active-nav .text-and-count .tab-text {
                        color: #235635;
                    }
.sky-light .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover {
                border-bottom: 4px solid #b1b1b1;
                background-color: #b1b1b1;
                color: #000000
            }
.sky-light .action-panel .panel-navigation .collapse-button:hover {
                border-bottom: 4px solid #b1b1b1;
                background-color: #b1b1b1;
                color: #000000
            }
.sky-light .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover .svg-container svg {
                    fill: #000000;
                }
.sky-light .action-panel .panel-navigation .collapse-button:hover .svg-container svg {
                    fill: #000000;
                }
.sky-light .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover .text-and-count .tab-text {
                    color: #000000;
                }
.sky-light .action-panel .panel-navigation .collapse-button:hover .text-and-count .tab-text {
                    color: #000000;
                }
.sky-light .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover.active-nav .panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky-light .action-panel .panel-navigation .collapse-button:hover.active-nav .panel-nav-icon.active-icon {
                        fill: #235635;
                    }
.sky-light .action-panel .panel-navigation:not(.disabled) .panel-nav-tab:hover.active-nav .tab-text {
                        color: #235635;
                    }
.sky-light .action-panel .panel-navigation .collapse-button:hover.active-nav .tab-text {
                        color: #235635;
                    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.quick-summary-bar-container {
    min-height: 48px;
    z-index: 1000
}
.quick-summary-bar-container .quick-summary-bar {
        width: 100%;
        height: 30px;
        margin-top: 1px
    }
.quick-summary-bar-container .quick-summary-bar .quick-summary-bar-title {
            font-family: "open_sansregular";
            font-size: 15px;
            font-weight: 300;
            float: left;
            width: 154px;
            height: 100%;
            line-height: 27px;
            padding-left: 10px;
            padding-top: 1px;
        }
.quick-summary-bar-container .quick-summary-bar .quick-summary-stats-container {
            height: 100%;
            width: 275px;
            float: left;
            padding-top: 2px
        }
.quick-summary-bar-container .quick-summary-bar .quick-summary-stats-container .quick-summary-stats {
                float: left;
                width: 19%;
                text-align: center
            }
.quick-summary-bar-container .quick-summary-bar .quick-summary-stats-container .quick-summary-stats div:first-child {
                    font-family: "open_sansregular";
                    font-size: 11px;
                    font-weight: 300;
                    height: 14px;
                }
.quick-summary-bar-container .quick-summary-bar .quick-summary-stats-container .quick-summary-stats div:last-child {
                    font-family: "open_sansitalic";
                    font-size: 11px;
                    font-weight: 300;
                }
.quick-summary-bar-container .quick-summary-bar .quick-summary-stats-container .quick-summary-stats-acres {
                width: 24%;
            }
.quick-summary-bar-container .copyright-msg {
        font-family: "open_sansitalic";
        font-size: 9px;
        font-weight: 300;
        min-width: 100%;
        text-align: center;
        display: block
    }
.quick-summary-bar-container .copyright-msg .privacy-policy-link {
            padding-left: 3px;
        }
.quick-summary-bar-container .quick-summary-bar.three-totals-displayed .quick-summary-bar-title {
            width: 177px;
        }
.quick-summary-bar-container .quick-summary-bar.three-totals-displayed .quick-summary-stats-container {
            width: 252px;
        }
.quick-summary-bar-container .quick-summary-bar.three-totals-displayed .quick-summary-stats {
            width: 33%;
        }
.quick-summary-bar-container .quick-summary-bar.four-totals-displayed .quick-summary-bar-title {
            width: 166px;
        }
.quick-summary-bar-container .quick-summary-bar.four-totals-displayed .quick-summary-stats-container {
            width: 263px;
        }
.quick-summary-bar-container .quick-summary-bar.four-totals-displayed .quick-summary-stats {
            width: 25%;
        }
.privacy-policy-body {
    display: flex;
    flex-direction: column;
}
.default .quick-summary-bar-container {
        background-color: #000000
    }
.default .quick-summary-bar-container .quick-summary-bar {
            background-color: #000000;
            color: #ffffff;
            border-top: 1px solid #bfbfbf;
        }
.default .quick-summary-bar-container .copyright-msg {
            color: #777777;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #262626;
        }
.default-light .quick-summary-bar-container {
        background-color: #fcfcfc
    }
.default-light .quick-summary-bar-container .quick-summary-bar {
            background-color: #fcfcfc;
            color: #000000;
            border-top: 1px solid #cfcfcf;
        }
.default-light .quick-summary-bar-container .copyright-msg {
            color: #777777;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #e2e2e2;
        }
.sky .quick-summary-bar-container {
        background-color: #fcfcfc
    }
.sky .quick-summary-bar-container .quick-summary-bar {
            background-color: #fcfcfc;
            color: #000000;
            border-top: 1px solid #c8c8c8;
        }
.sky .quick-summary-bar-container .copyright-msg {
            color: #878787;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #b1b1b1;
        }
.sky-light .quick-summary-bar-container {
        background-color: #fcfcfc
    }
.sky-light .quick-summary-bar-container .quick-summary-bar {
            background-color: #fcfcfc;
            color: #000000;
            border-top: 1px solid #c8c8c8;
        }
.sky-light .quick-summary-bar-container .copyright-msg {
            color: #878787;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #b1b1b1;
        }
.grass .quick-summary-bar-container {
        background-color: #667c66
    }
.grass .quick-summary-bar-container .quick-summary-bar {
            background-color: #667c66;
            color: #ffffff;
            border-top: 1px solid #9cadb2;
        }
.grass .quick-summary-bar-container .copyright-msg {
            color: #e5e9e5;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #424443;
        }
.grass-light .quick-summary-bar-container {
        background-color: #babfba
    }
.grass-light .quick-summary-bar-container .quick-summary-bar {
            background-color: #babfba;
            color: #000000;
            border-top: 1px solid #424443;
        }
.grass-light .quick-summary-bar-container .copyright-msg {
            color: #e5e9e5;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #9cadb2;
        }
.mud .quick-summary-bar-container {
        background-color: #4b3705
    }
.mud .quick-summary-bar-container .quick-summary-bar {
            background-color: #4b3705;
            color: #ffffff;
            border-top: 1px solid #f2f3f8;
        }
.mud .quick-summary-bar-container .copyright-msg {
            color: #777777;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #8c7454;
        }
.mud-light .quick-summary-bar-container {
        background-color: #fff0bb
    }
.mud-light .quick-summary-bar-container .quick-summary-bar {
            background-color: #fff0bb;
            color: #000000;
            border-top: 1px solid #d6c282;
        }
.mud-light .quick-summary-bar-container .copyright-msg {
            color: #777777;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #b28f5a;
        }
.illini .quick-summary-bar-container {
        background-color: #00467f
    }
.illini .quick-summary-bar-container .quick-summary-bar {
            background-color: #00467f;
            color: #ffffff;
            border-top: 1px solid #f2f3f8;
        }
.illini .quick-summary-bar-container .copyright-msg {
            color: #777777;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #abc8e2;
        }
.illini-light .quick-summary-bar-container {
        background-color: #e1e6fa
    }
.illini-light .quick-summary-bar-container .quick-summary-bar {
            background-color: #e1e6fa;
            color: #000000;
            border-top: 1px solid #abc8e2;
        }
.illini-light .quick-summary-bar-container .copyright-msg {
            color: #777777;
        }
& .dialog-container .privacy-policy-footer {
            border-top: 1px solid #c4d7ed;
        }


    .export-controller-file-modal .row {
        display: flex;
        width: 100%
    }
.export-controller-file-modal .row .checkboxes {
            flex-grow: 1;
            padding-top: 6px
        }
.export-controller-file-modal .row .checkboxes .checkbox-div {
                margin: 2px 10px;
            }
.export-controller-file-modal .row .select-form-input-container {
            width: 236px;
        }
.export-controller-file-modal .row.full-width .text-form-input {
            width: 100%;
        }
.export-controller-file-modal .row.two-column .text-form-input {
            width: 50%;
        }
.export-controller-file-modal .bottom-row {
        width: 50%;
        margin-left: auto;
    }
.export-controller-file-modal .validation-message {
        margin: 8px 0;
    }

.export-controller-custom-filename {
    display: grid;
    grid-template-columns: 1fr 1fr
}
.export-controller-custom-filename .zip-name {
        grid-column: span 2
    }
.export-controller-custom-filename .zip-name .text-form-input {
            width: calc(100% - 30px);
            margin-bottom: 0;
        }
.export-controller-custom-filename .file-list {
        grid-column: span 2;
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 0.5rem;
        align-items: center;
        width: calc(100% - 30px)
    }
.export-controller-custom-filename .file-list label {
            text-wrap: nowrap;
        }
.export-controller-custom-filename .file-list.one-column {
            grid-template-columns: 1fr;
        }
.export-controller-custom-filename .file-list .text-form-input {
            width: 100%;
            margin: 5px 10px;
        }
.export-controller-custom-filename .file-list .field {
            margin: 5px 0;
            grid-column: span 2;
        }

.rec-module {
}

.action-panel .rec-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.validation-feedback-modal {
    padding: 10px;
    padding-left: 10px;
    padding-right: 30px;
    width: 600px;
    height: auto;
}

.validation-feedback-modal .text-form-input {
    width: 50%;
}

.validation-feedback-modal .work-instructions.textarea-form-input {
    width: 100%;
    height: 4em;
}

.validation-feedback-modal .textarea-form-input {
    width: 100%;
    height: calc(12px * 1.5 * 7);
}


    .action-panel .rec-module .rec-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
.action-panel .rec-module .module-loader {
        width: 446px;
        height: 100%;
    }
.action-panel .rec-module .lock-icon {
        height: 35px;
        width: 45px;
        padding-left: 200px;
        padding-top: 50px;
    }
.action-panel .rec-module .lock-icon-text {
        padding-top: 20px;
        text-align: center;
    }


    .rec-general-form .checkbox-div {
        margin-left: 3px;
    }
.rec-general-form .temp-input {
        max-width: 85px;
    }
.rec-general-form .textarea-form-input {
        width: 95%;
        height: 110px;
    }
.rec-general-form .person-list {
        width: 100%
    }
.rec-general-form .person-list .person-item {
            line-height: 25px;
            display: flex;
            flex-flow: row nowrap
        }
.rec-general-form .person-list .person-item .trashcan-icon-container {
                width: 10%;
                padding-left: 10px;
            }
.rec-general-form .person-list .person-item .person-info {
                flex-grow: 1;
                justify-content: flex-start;
            }
.rec-general-form .equipment-list {
        width: 100%
    }
.rec-general-form .equipment-list .equipment-item {
            line-height: 25px;
            display: flex;
            flex-flow: row nowrap
        }
.rec-general-form .equipment-list .equipment-item .equipment-trashcan-icon-container {
                width: 10%;
                padding-left: 10px;
            }
.rec-general-form .equipment-list .equipment-item .equipment-info {
                flex-grow: 1;
                justify-content: flex-start;
                margin-left: 6px;
            }
.rec-general-form .equipment-list .equipment-item .empty-container {
                padding-left: 6px;
            }

.rec-event-batch-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative
}

.rec-event-batch-list .rec-event-info {
        flex-grow: 0;
    }

.rec-event-batch-list .rec-event-batch-list-accordion {
        flex-grow: 1;
        position: relative;
    }

.rec-event-batch-list .save-cancel-btns {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px;
        min-height: 34px;
        overflow: hidden
    }

.rec-event-batch-list .save-cancel-btns .default-button {
            margin: 0 7px;
        }

.batch-rec-event-info .batch-equation-rec-loader {
        height: calc(100% - 160px);
        top: 100px !important;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.rec-manual-field-data .input-row {
        width: 100%;
        display: flex;
        flex-direction: row
    }
.rec-manual-field-data .input-row > * {
            flex-grow: 1;
            width: 20px;
        }
.rec-manual-application-form {
    padding: 5px
}
.rec-manual-application-form .add-mix {
        text-align: right;
        padding-top: 15px;
        padding-right: 15px;
        top: 0px;
    }
.rec-manual-application-form .product-blend-list {
        display: flex;
        flex-flow: row wrap;
        padding: 10px
    }
.rec-manual-application-form .product-blend-list .product-blend-row {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px dashed
        }
.rec-manual-application-form .product-blend-list .product-blend-row .mix-information {
                display: flex;
                flex-flow: row wrap;
                min-width: 60%;
                justify-content: flex-start
            }
.rec-manual-application-form .product-blend-list .product-blend-row .mix-information .mix-title {
                    font-size: 15px;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-weight: 700;
                    width: 100%;
                    padding-bottom: 4px;
                }
.rec-manual-application-form .product-blend-list .product-blend-row .mix-information .mix-product-list {
                    display: flex;
                    flex-flow: column nowrap;
                    padding-top: 10px
                }
.rec-manual-application-form .product-blend-list .product-blend-row .mix-information .mix-product-list .product-row {
                        padding-left: 20px;
                        padding-bottom: 10px;
                    }
.rec-manual-application-form .product-blend-list .product-blend-row .rate-information {
                display: flex;
                min-width: 120px;
                justify-content: flex-end;
                line-height: 40px
            }
.rec-manual-application-form .product-blend-list .product-blend-row .rate-information .target-rate {
                    padding-right: 3px;
                    min-width: 40px;
                }
.rec-manual-application-form .product-blend-list .product-blend-row .rate-information .target-rate.is-locked {
                    padding-right: 3px;
                    min-width: 70px
                }
.rec-manual-application-form .product-blend-list .product-blend-row .rate-information .target-rate.is-locked .form-input {
                        height: 25px
                    }
.rec-manual-application-form .product-blend-list .product-blend-row .rate-information .target-rate.is-locked .form-input input {
                            text-align: center;
                        }
.rec-manual-application-form .product-blend-list .product-blend-row .rate-information .target-rate-unit {
                    min-width: 40px;
                }
.default .rec-manual-field-data {
            background-color: #404040;
        }
.default-light .rec-manual-field-data {
            background-color: #919191;
        }
.sky .rec-manual-field-data {
            background-color: #0194d3;
        }
.sky-light .rec-manual-field-data {
            background-color: #0194d3;
        }
.grass .rec-manual-field-data {
            background-color: #325132;
        }
.grass-light .rec-manual-field-data {
            background-color: #545f54;
        }
.mud .rec-manual-field-data {
            background-color: #766146;
        }
.mud-light .rec-manual-field-data {
            background-color: #a37b42;
        }
.illini .rec-manual-field-data {
            background-color: #636466;
        }
.illini-light .rec-manual-field-data {
            background-color: #375d81;
        }
.sky .rec-manual-field-data {
            background-color: #e2e2e2;
        }
.sky-light .rec-manual-field-data {
            background-color: #e2e2e2;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.equation-group-list {
    padding-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 10px;
    flex-grow: 1;
    font-size: 13px
}
.equation-group-list .equation-group-row {
        width: 100%
    }
.equation-group-list .equation-group-row .equation-group-name-select {
            line-height: 30px;
            min-height: 30px;
            display: flex;
            flex-flow: row nowrap
        }
.equation-group-list .equation-group-row .equation-group-name-select .equation-group-name {
                min-width: 60%;
                flex-grow: 1;
                padding-left: 6px;
                font-weight: 500;
                cursor: pointer;
            }
.equation-group-list .equation-group-row .equation-group-name-select .equation-group-select {
                min-width: 30%;
                text-align: right;
                padding-right: 15px;
            }
.equation-group-list .equation-group-row .equation-group-name-select .equation-group-arrow-icon {
                height: 12px;
                width: 20px;
            }
.equation-group-list .equation-group-row .equation-group-description-attributes {
            display: flex;
            flex-flow: column nowrap;
            font-size: 12px;
            margin-left: 30px;
            width: 80%
        }
.equation-group-list .equation-group-row .equation-group-description-attributes .nutrient-text {
                padding-bottom: 4px;
            }
.equation-group-list .equation-group-row .equation-group-description-attributes .grid-attribute {
                min-height: 16px;
                margin-left: 15px;
                text-align: left;
                width: 80%;
                padding-top: 4px;
            }
.rec-equation-application-summary {
    min-height: 35px
}
.rec-equation-application-summary .change-equation-group {
        display: flex;
        flex-flow: row nowrap;
        padding-left: 10px;
        padding-top: 10px
    }
.rec-equation-application-summary .change-equation-group .change-equation-group-nolink {
            margin-right: 10px;
        }
.rec-equation-application-form {
    padding: 5px
}
.rec-equation-application-form .is-included-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: flex-end
    }
.rec-equation-application-form .is-included-checkbox .checkbox-div: {
            width: auto;
            width: initial;
        }
.rec-equation-application-form .is-included-checkbox .checkbox-container {
            margin-right: 10px;
        }
.rec-equation-application-form .equation-application-bucket-header {
        height: 35px;
        line-height: 35px;
        margin-bottom: 5px
    }
.rec-equation-application-form .equation-application-bucket-header .param-bucket-title {
            display: flex
        }
.rec-equation-application-form .equation-application-bucket-header .param-bucket-title .param-events {
                font-size: 12px;
                flex-grow: 1.5;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 5em;
                margin-right: 1em;
            }
.rec-equation-application-form .nutrient-product-display {
        display: flex;
        flex-flow: column nowrap;
        width: 100%
    }
.rec-equation-application-form .nutrient-product-display .nutrient-product {
            display: flex;
            flex-flow: column nowrap;
            padding-bottom: 10px
        }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section {
                display: flex;
                flex-flow: row nowrap
            }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .product-surface-radio {
                    padding-top: 8px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .nutrient-name {
                    line-height: 35px;
                    min-height: 35px;
                    font-size: 15px;
                    font-weight: 700;
                    flex-grow: 1;
                    margin-left: 0;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .nutrient-name.no-surface {
                    margin-left: 15px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .nutrient-rate {
                    margin-top: 11px;
                    flex-grow: 4;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .add-default-nolink {
                    width: 30%;
                    text-align: right;
                    margin-right: 20px;
                    font-size: 12px;
                    line-height: 45px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section {
                display: flex;
                flex-flow: row nowrap
            }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-blend {
                    width: 55%;
                    margin-left: 15px;
                    flex-grow: 1;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-rec-value {
                    flex-grow: 1;
                    text-align: right;
                    margin-top: 10px;
                    margin-right: 10px;
                    min-height: 25px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-mix-name {
                    font-weight: bold;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-mix-products {
                    margin-left: 12px;
                    margin-top: 5px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section.no-blend-name .product-mix-products {
                    margin-top: 12px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .export-checkbox {
                margin-top: 3px
            }
.rec-equation-application-form .nutrient-product-display .nutrient-product .export-checkbox .checkbox-div {
                    justify-content: flex-end;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .missing-equation-attribute-warning-message {
                border-radius: 5px;
                width: 95%;
                padding: 0.5em;
                font-weight: bold;
            }
.rec-equation-application-form .input-row .checkbox-div {
            margin-top: 15px;
            margin-left: 3px;
            min-width: 49%;
        }
.rec-equation-application-form .input-row .select-form-input-container {
            min-width: 50%;
        }
.default .equation-group-list {
            background-color: #404040;
        }
.default .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #000000;
        }
.default .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.default .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.default .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.default-light .equation-group-list {
            background-color: #919191;
        }
.default-light .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #ffffff;
        }
.default-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.default-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.default-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.sky .equation-group-list {
            background-color: #0194d3;
        }
.sky .equation-application-bucket-header {
            background-color: #878787;
            border-bottom: 2px solid #ffffff;
        }
.sky .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.sky .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.sky .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.sky-light .equation-group-list {
            background-color: #0194d3;
        }
.sky-light .equation-application-bucket-header {
            background-color: #878787;
            border-bottom: 2px solid #ffffff;
        }
.sky-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.sky-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.sky-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.grass .equation-group-list {
            background-color: #325132;
        }
.grass .equation-application-bucket-header {
            background-color: #e5e9e5;
            border-bottom: 2px solid #000000;
        }
.grass .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.grass .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.grass .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.grass-light .equation-group-list {
            background-color: #545f54;
        }
.grass-light .equation-application-bucket-header {
            background-color: #e5e9e5;
            border-bottom: 2px solid #ffffff;
        }
.grass-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.grass-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.grass-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.mud .equation-group-list {
            background-color: #766146;
        }
.mud .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #000000;
        }
.mud .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.mud .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.mud .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.mud-light .equation-group-list {
            background-color: #a37b42;
        }
.mud-light .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #ffffff;
        }
.mud-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.mud-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.mud-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.illini .equation-group-list {
            background-color: #636466;
        }
.illini .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #000000;
        }
.illini .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.illini .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.illini .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.illini-light .equation-group-list {
            background-color: #375d81;
        }
.illini-light .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #ffffff;
        }
.illini-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.illini-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.illini-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.sky .equation-group-list {
        background-color: #e2e2e2;
    }
.sky-light .equation-group-list {
        background-color: #e2e2e2;
    }


    .batch-edit-recs-modal .primary-rec {
        margin-top: 15px;
        margin-bottom: 15px;
    }
.batch-edit-recs-modal .secondary-list {
        margin-left: 30px;
        margin-top: 15px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.equation-group-list {
    padding-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 10px;
    flex-grow: 1;
    font-size: 13px
}
.equation-group-list .equation-group-row {
        width: 100%
    }
.equation-group-list .equation-group-row .equation-group-name-select {
            line-height: 30px;
            min-height: 30px;
            display: flex;
            flex-flow: row nowrap
        }
.equation-group-list .equation-group-row .equation-group-name-select .equation-group-name {
                min-width: 60%;
                flex-grow: 1;
                padding-left: 6px;
                font-weight: 500;
                cursor: pointer;
            }
.equation-group-list .equation-group-row .equation-group-name-select .equation-group-select {
                min-width: 30%;
                text-align: right;
                padding-right: 15px;
            }
.equation-group-list .equation-group-row .equation-group-name-select .equation-group-arrow-icon {
                height: 12px;
                width: 20px;
            }
.equation-group-list .equation-group-row .equation-group-description-attributes {
            display: flex;
            flex-flow: column nowrap;
            font-size: 12px;
            margin-left: 30px;
            width: 80%
        }
.equation-group-list .equation-group-row .equation-group-description-attributes .nutrient-text {
                padding-bottom: 4px;
            }
.equation-group-list .equation-group-row .equation-group-description-attributes .grid-attribute {
                min-height: 16px;
                margin-left: 15px;
                text-align: left;
                width: 80%;
                padding-top: 4px;
            }
.rec-equation-application-form {
    padding: 5px
}
.rec-equation-application-form .is-included-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: flex-end
    }
.rec-equation-application-form .is-included-checkbox .checkbox-div: {
            width: auto;
            width: initial;
        }
.rec-equation-application-form .is-included-checkbox .checkbox-container {
            margin-right: 10px;
        }
.rec-equation-application-form .equation-application-bucket-header {
        height: 35px;
        line-height: 35px;
        margin-bottom: 5px;
    }
.rec-equation-application-form .nutrient-product-display {
        display: flex;
        flex-flow: column nowrap;
        width: 100%
    }
.rec-equation-application-form .nutrient-product-display .nutrient-product {
            display: flex;
            flex-flow: column nowrap;
            padding-bottom: 10px
        }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section {
                display: flex;
                flex-flow: row nowrap
            }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .product-surface-radio {
                    padding-top: 8px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .nutrient-name {
                    line-height: 35px;
                    min-height: 35px;
                    font-size: 15px;
                    font-weight: 700;
                    flex-grow: 1;
                    margin-left: 0;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .nutrient-name.no-surface {
                    margin-left: 15px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .nutrient-rate {
                    margin-top: 11px;
                    flex-grow: 4;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-info-section .add-default-nolink {
                    width: 30%;
                    text-align: right;
                    margin-right: 20px;
                    font-size: 12px;
                    line-height: 45px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section {
                display: flex;
                flex-flow: row nowrap
            }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-blend {
                    width: 55%;
                    margin-left: 15px;
                    flex-grow: 1;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-rec-value {
                    flex-grow: 1;
                    text-align: right;
                    margin-top: 10px;
                    margin-right: 10px;
                    min-height: 25px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-mix-name {
                    font-weight: bold;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section .product-mix-products {
                    margin-left: 12px;
                    margin-top: 5px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .product-blend-section.no-blend-name .product-mix-products {
                    margin-top: 12px;
                }
.rec-equation-application-form .nutrient-product-display .nutrient-product .missing-equation-attribute-warning-message {
                border-radius: 5px;
                width: 95%;
                padding: 0.5em;
                font-weight: bold;
            }
.rec-equation-application-form .input-row .checkbox-div {
            margin-top: 15px;
            margin-left: 3px;
            min-width: 49%;
        }
.rec-equation-application-form .input-row .select-form-input-container {
            min-width: 50%;
        }
.rec-equation-application-form .input-row .seed-count-input {
            min-width: 45.5%;
        }
.default .equation-group-list {
            background-color: #404040;
        }
.default .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #000000;
        }
.default .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.default .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.default .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.default-light .equation-group-list {
            background-color: #919191;
        }
.default-light .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #ffffff;
        }
.default-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.default-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.default-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.sky .equation-group-list {
            background-color: #0194d3;
        }
.sky .equation-application-bucket-header {
            background-color: #878787;
            border-bottom: 2px solid #ffffff;
        }
.sky .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.sky .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.sky .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.sky-light .equation-group-list {
            background-color: #0194d3;
        }
.sky-light .equation-application-bucket-header {
            background-color: #878787;
            border-bottom: 2px solid #ffffff;
        }
.sky-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.sky-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.sky-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.grass .equation-group-list {
            background-color: #325132;
        }
.grass .equation-application-bucket-header {
            background-color: #e5e9e5;
            border-bottom: 2px solid #000000;
        }
.grass .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.grass .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.grass .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.grass-light .equation-group-list {
            background-color: #545f54;
        }
.grass-light .equation-application-bucket-header {
            background-color: #e5e9e5;
            border-bottom: 2px solid #ffffff;
        }
.grass-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.grass-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.grass-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.mud .equation-group-list {
            background-color: #766146;
        }
.mud .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #000000;
        }
.mud .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.mud .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.mud .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.mud-light .equation-group-list {
            background-color: #a37b42;
        }
.mud-light .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #ffffff;
        }
.mud-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.mud-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.mud-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.illini .equation-group-list {
            background-color: #636466;
        }
.illini .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #000000;
        }
.illini .nutrient-product {
            border-bottom: 1px dashed #ffffff
        }
.illini .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.illini .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.illini-light .equation-group-list {
            background-color: #375d81;
        }
.illini-light .equation-application-bucket-header {
            background-color: #777777;
            border-bottom: 2px solid #ffffff;
        }
.illini-light .nutrient-product {
            border-bottom: 1px dashed #000000
        }
.illini-light .nutrient-product .missing-equation-attribute-warning-message {
                background-color: #f89406;
            }
.illini-light .nutrient-product .missing-equation-attribute-warning-message.missing-equation-attribute-error-message {
                background-color: #ff0000;
            }
.sky .equation-group-list {
        background-color: #e2e2e2;
    }
.sky-light .equation-group-list {
        background-color: #e2e2e2;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.buffer-setup {
    display: none;
}
.event-module + .buffer-setup.buffer-setup-open, .rec-module + .buffer-setup.buffer-setup-open, .layer-module + .buffer-setup.buffer-setup-open {
        display: flex;
    }
.dialog-container.buffer-setup-modal {
    width: 385px;
    height: 365px;
    line-height: 17px
}
.dialog-container.buffer-setup-modal .dialog-box-body {
        overflow: visible;
        margin: 0;
        padding: 0
    }
.dialog-container.buffer-setup-modal .dialog-box-body .dialog-box-children > div {
            margin: 10px 20px;
        }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-source {
            display: flex;
            align-items: center
        }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-source .radio-grp-lbl {
                flex-grow: 1;
                text-align: center;
                margin-bottom: 3px;
            }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-source .radio-grp-source {
                flex-grow: 1;
                font-size: 14px;
                line-height: 19px
            }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-source .radio-grp-source .radio-div {
                    margin: 3px;
                }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-dimensions .buffer-input-forms {
                display: flex;
                margin: 0 20px;
                flex-direction: row
            }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-dimensions .buffer-input-forms .select-form-input-container {
                    margin: 0;
                }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-dimensions .buffer-input-forms .form-input {
                    margin: 5px;
                }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-dimensions .buffer-input-forms > * {
                    flex-grow: 1;
                    min-width: 10px;
                }
.dialog-container.buffer-setup-modal .dialog-box-body .buffer-instructions {
            padding: 10px;
            font-style: italic;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.action-panel {
    display: flex;
    flex-direction: column;
    font-size: 13px
}
.action-panel.action-panel-expanded,
    .action-panel.action-panel-expanded .panel-modal {
        min-width: 444px;
        max-width: 444px;
    }
.action-panel.action-panel-collapsed,
    .action-panel.action-panel-collapsed .panel-modal {
        min-width: 1px;
        max-width: 1px;
        border-right: none;
    }
.action-panel .context-menu {
        float: right;
        margin: 5px 5px 0 0;
    }
.action-panel .tabs .tabs-labels {
        min-height: 0
    }
.action-panel .tabs .tabs-labels li.tab-label {
            height: 40px;
            box-sizing: border-box
        }
.action-panel .tabs .tabs-labels li.tab-label.left-non-label {
                flex-grow: 1;
            }
.action-panel .tabs .tabs-labels li.tab-label.right-non-label {
                width: 30px;
                display: flex;
                flex-direction: row-reverse;
            }
.action-panel .tabs .tabs-labels li.tab-label .tab-nav-text {
                padding: 0 2px;
                line-height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
.action-panel .panel-modal {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0
    }
.action-panel .panel-modal .dialog-window,
        .action-panel .panel-modal .modal-overlay {
            position: absolute !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
.action-panel .panel-modal .dialog-container {
            position: fixed !important;
            width: 385px;
            line-height: 17px;
        }
.default .action-panel {
        border-right: 2px solid #bfbfbf
    }
.default .action-panel .tabs {
            border-top: 1px solid #404040
        }
.default .action-panel .tabs .tabs-labels {
                background-color: #404040;
            }
.default .action-panel .context-menu .menu-dot {
                color: #000000;
            }
.default .action-panel .context-menu:hover {
                background-color: #000000
            }
.default .action-panel .context-menu.menu-expanded {
                background-color: #000000
            }
.default .action-panel .context-menu:hover .menu-dot {
                    color: #8fa61a;
                }
.default .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #8fa61a;
                }
.default .action-panel .import-status-error {
            color: #ff0000;
        }
.default .action-panel .import-status-processing {
            color: #e26816;
        }
.default .action-panel .import-status-ready {
            color: #8fa61a;
        }
.default-light .action-panel {
        border-right: 2px solid #cfcfcf
    }
.default-light .action-panel .tabs {
            border-top: 1px solid #919191
        }
.default-light .action-panel .tabs .tabs-labels {
                background-color: #919191;
            }
.default-light .action-panel .context-menu .menu-dot {
                color: #fcfcfc;
            }
.default-light .action-panel .context-menu:hover {
                background-color: #fcfcfc
            }
.default-light .action-panel .context-menu.menu-expanded {
                background-color: #fcfcfc
            }
.default-light .action-panel .context-menu:hover .menu-dot {
                    color: #8fa61a;
                }
.default-light .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #8fa61a;
                }
.default-light .action-panel .import-status-error {
            color: #ff0000;
        }
.default-light .action-panel .import-status-processing {
            color: #e26816;
        }
.default-light .action-panel .import-status-ready {
            color: #8fa61a;
        }
.sky .action-panel {
        border-right: 2px solid #c8c8c8
    }
.sky .action-panel .tabs {
            border-top: 1px solid #0194d3
        }
.sky .action-panel .tabs .tabs-labels {
                background-color: #0194d3;
            }
.sky .action-panel .context-menu .menu-dot {
                color: #fcfcfc;
            }
.sky .action-panel .context-menu:hover {
                background-color: #fcfcfc
            }
.sky .action-panel .context-menu.menu-expanded {
                background-color: #fcfcfc
            }
.sky .action-panel .context-menu:hover .menu-dot {
                    color: #235635;
                }
.sky .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #235635;
                }
.sky .action-panel .import-status-error {
            color: #ff0000;
        }
.sky .action-panel .import-status-processing {
            color: #01425e;
        }
.sky .action-panel .import-status-ready {
            color: #235635;
        }
.sky-light .action-panel {
        border-right: 2px solid #c8c8c8
    }
.sky-light .action-panel .tabs {
            border-top: 1px solid #0194d3
        }
.sky-light .action-panel .tabs .tabs-labels {
                background-color: #0194d3;
            }
.sky-light .action-panel .context-menu .menu-dot {
                color: #fcfcfc;
            }
.sky-light .action-panel .context-menu:hover {
                background-color: #fcfcfc
            }
.sky-light .action-panel .context-menu.menu-expanded {
                background-color: #fcfcfc
            }
.sky-light .action-panel .context-menu:hover .menu-dot {
                    color: #235635;
                }
.sky-light .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #235635;
                }
.sky-light .action-panel .import-status-error {
            color: #ff0000;
        }
.sky-light .action-panel .import-status-processing {
            color: #01425e;
        }
.sky-light .action-panel .import-status-ready {
            color: #235635;
        }
.grass .action-panel {
        border-right: 2px solid #9cadb2
    }
.grass .action-panel .tabs {
            border-top: 1px solid #325132
        }
.grass .action-panel .tabs .tabs-labels {
                background-color: #325132;
            }
.grass .action-panel .context-menu .menu-dot {
                color: #667c66;
            }
.grass .action-panel .context-menu:hover {
                background-color: #667c66
            }
.grass .action-panel .context-menu.menu-expanded {
                background-color: #667c66
            }
.grass .action-panel .context-menu:hover .menu-dot {
                    color: #ffff00;
                }
.grass .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #ffff00;
                }
.grass .action-panel .import-status-error {
            color: #ff0000;
        }
.grass .action-panel .import-status-processing {
            color: #e26816;
        }
.grass .action-panel .import-status-ready {
            color: #ffff00;
        }
.grass-light .action-panel {
        border-right: 2px solid #424443
    }
.grass-light .action-panel .tabs {
            border-top: 1px solid #545f54
        }
.grass-light .action-panel .tabs .tabs-labels {
                background-color: #545f54;
            }
.grass-light .action-panel .context-menu .menu-dot {
                color: #babfba;
            }
.grass-light .action-panel .context-menu:hover {
                background-color: #babfba
            }
.grass-light .action-panel .context-menu.menu-expanded {
                background-color: #babfba
            }
.grass-light .action-panel .context-menu:hover .menu-dot {
                    color: #ffff00;
                }
.grass-light .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #ffff00;
                }
.grass-light .action-panel .import-status-error {
            color: #ff0000;
        }
.grass-light .action-panel .import-status-processing {
            color: #e26816;
        }
.grass-light .action-panel .import-status-ready {
            color: #ffff00;
        }
.mud .action-panel {
        border-right: 2px solid #f2f3f8
    }
.mud .action-panel .tabs {
            border-top: 1px solid #766146
        }
.mud .action-panel .tabs .tabs-labels {
                background-color: #766146;
            }
.mud .action-panel .context-menu .menu-dot {
                color: #4b3705;
            }
.mud .action-panel .context-menu:hover {
                background-color: #4b3705
            }
.mud .action-panel .context-menu.menu-expanded {
                background-color: #4b3705
            }
.mud .action-panel .context-menu:hover .menu-dot {
                    color: #ffff00;
                }
.mud .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #ffff00;
                }
.mud .action-panel .import-status-error {
            color: #ff0000;
        }
.mud .action-panel .import-status-processing {
            color: #f39104;
        }
.mud .action-panel .import-status-ready {
            color: #ffff00;
        }
.mud-light .action-panel {
        border-right: 2px solid #d6c282
    }
.mud-light .action-panel .tabs {
            border-top: 1px solid #a37b42
        }
.mud-light .action-panel .tabs .tabs-labels {
                background-color: #a37b42;
            }
.mud-light .action-panel .context-menu .menu-dot {
                color: #fff0bb;
            }
.mud-light .action-panel .context-menu:hover {
                background-color: #fff0bb
            }
.mud-light .action-panel .context-menu.menu-expanded {
                background-color: #fff0bb
            }
.mud-light .action-panel .context-menu:hover .menu-dot {
                    color: #8fa61a;
                }
.mud-light .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #8fa61a;
                }
.mud-light .action-panel .import-status-error {
            color: #ff0000;
        }
.mud-light .action-panel .import-status-processing {
            color: #e26816;
        }
.mud-light .action-panel .import-status-ready {
            color: #8fa61a;
        }
.illini .action-panel {
        border-right: 2px solid #f2f3f8
    }
.illini .action-panel .tabs {
            border-top: 1px solid #636466
        }
.illini .action-panel .tabs .tabs-labels {
                background-color: #636466;
            }
.illini .action-panel .context-menu .menu-dot {
                color: #00467f;
            }
.illini .action-panel .context-menu:hover {
                background-color: #00467f
            }
.illini .action-panel .context-menu.menu-expanded {
                background-color: #00467f
            }
.illini .action-panel .context-menu:hover .menu-dot {
                    color: #ffff00;
                }
.illini .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #ffff00;
                }
.illini .action-panel .import-status-error {
            color: #ff0000;
        }
.illini .action-panel .import-status-processing {
            color: #f39c11;
        }
.illini .action-panel .import-status-ready {
            color: #ffff00;
        }
.illini-light .action-panel {
        border-right: 2px solid #abc8e2
    }
.illini-light .action-panel .tabs {
            border-top: 1px solid #375d81
        }
.illini-light .action-panel .tabs .tabs-labels {
                background-color: #375d81;
            }
.illini-light .action-panel .context-menu .menu-dot {
                color: #e1e6fa;
            }
.illini-light .action-panel .context-menu:hover {
                background-color: #e1e6fa
            }
.illini-light .action-panel .context-menu.menu-expanded {
                background-color: #e1e6fa
            }
.illini-light .action-panel .context-menu:hover .menu-dot {
                    color: #ffff00;
                }
.illini-light .action-panel .context-menu.menu-expanded .menu-dot {
                    color: #ffff00;
                }
.illini-light .action-panel .import-status-error {
            color: #ff0000;
        }
.illini-light .action-panel .import-status-processing {
            color: #f39c11;
        }
.illini-light .action-panel .import-status-ready {
            color: #ffff00;
        }


    .event-module .accordion-item, .layer-module .accordion-item, .rec-module .accordion-item {
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        white-space: nowrap;
    }

.analytics-main-container {
    margin: auto;
    margin-top: 10px;
    width: 1240px;
    height: 100%;

    display: inline-block
}
.analytics-main-container .dashboardSelector {
        display: flex;
        width: 75%;
        flex-direction: row;
        align-content: center
    }
.analytics-main-container .dashboardSelector .select-form-input-container {
            width: 30%;
            margin: -10px -10px 0 -5px;
        }
.analytics-main-container #dashboardContainer {
        margin-left: 5px;
        height: 90%;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.logo-row .nav-user-container .user-menu-container.nav-container {
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
            display: flex;
            flex-direction: row;
            margin-top: 35px;
            text-align: right;
            white-space: nowrap
        }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
                display: inline-block
            }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation .menu-items-drop-down.menu-position-bottom {
                    margin-top: 6px;
                }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation .notification-value-count {
                    background-color: #e26816;
                    color: #ffffff;
                    margin-right: 5px;
                    padding: 1px 7px;
                }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation:last-child {
                border: none !important;
                padding-right: 2px
            }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation:last-child .menu-items {
                    padding-right: 0
                }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation:last-child .menu-items .menu-items-arrow {
                        margin-right: 0;
                    }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation:last-child.notifications .nav-menu.menu-items {
                    padding-right: 1px;
                }
.logo-row .nav-user-container .user-menu-container.nav-container .top-navigation .link-item-text {
                padding: 0px 10px;
            }
.logo-row .nav-user-container .user-menu-container.nav-container .notification-link {
                padding-left: 10px;
                padding-right: 5px;
            }
.logo-row .nav-user-container .user-menu-container.nav-container .notification-value-count {
                padding: 2px 5px;
                font-family: "open_sansregular";
                font-size: 13px;
                font-weight: 300;
                border-radius: 5px 5px 5px 5px;
                height: 28%;
                line-height: 15px;
                margin-top: 15px;
                margin-left: auto;
            }
.default .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #bfbfbf;
            color: #1daaef;
        }
.default .disabled {
            cursor: default
        }
.default .disabled .menu-items-text {
                cursor: default;
                color: #777777 !important
            }
.default .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #777777 !important
            }
.default .disabled .menu-items-text svg {
                    fill: #777777 !important;
                }
.default .disabled .menu-items-arrow .svg-container svg {
                    fill: #777777 !important;
                }
.default-light .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #cfcfcf;
            color: #0e55a8;
        }
.default-light .disabled {
            cursor: default
        }
.default-light .disabled .menu-items-text {
                cursor: default;
                color: #777777 !important
            }
.default-light .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #777777 !important
            }
.default-light .disabled .menu-items-text svg {
                    fill: #777777 !important;
                }
.default-light .disabled .menu-items-arrow .svg-container svg {
                    fill: #777777 !important;
                }
.sky .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #c8c8c8;
            color: #01425e;
        }
.sky .disabled {
            cursor: default
        }
.sky .disabled .menu-items-text {
                cursor: default;
                color: #878787 !important
            }
.sky .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #878787 !important
            }
.sky .disabled .menu-items-text svg {
                    fill: #878787 !important;
                }
.sky .disabled .menu-items-arrow .svg-container svg {
                    fill: #878787 !important;
                }
.sky-light .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #c8c8c8;
            color: #01425e;
        }
.sky-light .disabled {
            cursor: default
        }
.sky-light .disabled .menu-items-text {
                cursor: default;
                color: #878787 !important
            }
.sky-light .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #878787 !important
            }
.sky-light .disabled .menu-items-text svg {
                    fill: #878787 !important;
                }
.sky-light .disabled .menu-items-arrow .svg-container svg {
                    fill: #878787 !important;
                }
.grass .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #9cadb2;
            color: #e26816;
        }
.grass .disabled {
            cursor: default
        }
.grass .disabled .menu-items-text {
                cursor: default;
                color: #e5e9e5 !important
            }
.grass .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #e5e9e5 !important
            }
.grass .disabled .menu-items-text svg {
                    fill: #e5e9e5 !important;
                }
.grass .disabled .menu-items-arrow .svg-container svg {
                    fill: #e5e9e5 !important;
                }
.grass-light .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #424443;
            color: #e26816;
        }
.grass-light .disabled {
            cursor: default
        }
.grass-light .disabled .menu-items-text {
                cursor: default;
                color: #e5e9e5 !important
            }
.grass-light .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #e5e9e5 !important
            }
.grass-light .disabled .menu-items-text svg {
                    fill: #e5e9e5 !important;
                }
.grass-light .disabled .menu-items-arrow .svg-container svg {
                    fill: #e5e9e5 !important;
                }
.mud .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #f2f3f8;
            color: #f0c248;
        }
.mud .disabled {
            cursor: default
        }
.mud .disabled .menu-items-text {
                cursor: default;
                color: #777777 !important
            }
.mud .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #777777 !important
            }
.mud .disabled .menu-items-text svg {
                    fill: #777777 !important;
                }
.mud .disabled .menu-items-arrow .svg-container svg {
                    fill: #777777 !important;
                }
.mud-light .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #d6c282;
            color: #0e55a8;
        }
.mud-light .disabled {
            cursor: default
        }
.mud-light .disabled .menu-items-text {
                cursor: default;
                color: #777777 !important
            }
.mud-light .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #777777 !important
            }
.mud-light .disabled .menu-items-text svg {
                    fill: #777777 !important;
                }
.mud-light .disabled .menu-items-arrow .svg-container svg {
                    fill: #777777 !important;
                }
.illini .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #f2f3f8;
            color: #f39c11;
        }
.illini .disabled {
            cursor: default
        }
.illini .disabled .menu-items-text {
                cursor: default;
                color: #777777 !important
            }
.illini .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #777777 !important
            }
.illini .disabled .menu-items-text svg {
                    fill: #777777 !important;
                }
.illini .disabled .menu-items-arrow .svg-container svg {
                    fill: #777777 !important;
                }
.illini-light .logo-row .nav-user-container .user-menu-container.nav-container .top-navigation {
            border-right: 1px solid #abc8e2;
            color: #b2962c;
        }
.illini-light .disabled {
            cursor: default
        }
.illini-light .disabled .menu-items-text {
                cursor: default;
                color: #777777 !important
            }
.illini-light .disabled .menu-items-arrow .svg-container {
                cursor: default;
                color: #777777 !important
            }
.illini-light .disabled .menu-items-text svg {
                    fill: #777777 !important;
                }
.illini-light .disabled .menu-items-arrow .svg-container svg {
                    fill: #777777 !important;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.hidden {
    visibility: hidden !important;
}
.import-export-header-container {
    display: flex;
    justify-content: flex-end;
    min-height: 18px;
    line-height: 18px;
    padding: 6px 0;
    width: 1240px;
    margin: auto;
    font-size: 13px;
    font-weight: 300
}
.import-export-header-container .menu-items-text {
        padding: 0 5px;
    }
.import-export-header-container a.menu-items-text.last-header-link {
        padding-right: 0px;
    }
.import-export-header-container .bar {
        margin: 0 5px;
    }
.import-export-header-container .export-modal {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px
    }
.import-export-header-container .export-modal .dialog-box-title {
            justify-content: flex-end;
        }
.import-export-header-container .export-modal .close-icon {
            text-align: right;
        }
.import-export-header-container .export-modal span {
            text-align: center;
        }
.import-export-header-container .product-import-header .hidden {
            height: 0px;
            width: 0px;
        }
.import-export-header-container .product-import-header .menu-items {
            margin: 0 5px;
        }
.dialog-box-title {
    justify-content: flex-end
}
.dialog-box-title .close-icon {
        text-align: right;
    }
.view-log-dialog-box {
    width: 600px
}
.view-log-dialog-box .dialog-drag-bar {
        height: 35px;
    }
.view-log-dialog-box .dialog-box-body {
        margin-bottom: 0
    }
.view-log-dialog-box .dialog-box-body > div {
            width: auto;
        }
.view-log-dialog-box .data-table-cont {
        width: 100%
    }
.view-log-dialog-box .data-table-cont .data-table-header .data-header-cell {
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-wrap: none;
            overflow: hidden;
            padding: 3px 10px;
            display: flex;
            justify-content: center
        }
.view-log-dialog-box .data-table-cont .data-table-header .data-header-cell.data-table-cell {
                flex: 0 1;
            }
.view-log-dialog-box .data-table-cont .data-table-header .data-header-cell .data-header-sort {
                padding-top: 0px;
                flex-grow: inherit;
            }
.view-log-dialog-box .data-table-cont .data-table-header .data-header-cell .data-header-sorting-icon {
                height: 10px;
                width: 10px;
            }
.view-log-dialog-box .data-table-cont .data-table-rows {
            height: 300px
        }
.view-log-dialog-box .data-table-cont .data-table-rows .data-table-row {
                padding: 1px 10px;
                height: 24px;
                font-size: 13px;
                font-weight: 300
            }
.view-log-dialog-box .data-table-cont .data-table-rows .data-table-row .data-table-cell {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-wrap: none;
                    overflow: hidden;
                }
.view-log-dialog-box .data-table-cont .data-table-footer {
            display: none;
        }
.default .import-export-header-container .bar {
            border-left: 1px solid #bfbfbf;
        }
.default .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #404040;
        }
.default-light .import-export-header-container .bar {
            border-left: 1px solid #cfcfcf;
        }
.default-light .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #919191;
        }
.sky .import-export-header-container .bar {
            border-left: 1px solid #c8c8c8;
        }
.sky .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #0194d3;
        }
.sky-light .import-export-header-container .bar {
            border-left: 1px solid #c8c8c8;
        }
.sky-light .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #0194d3;
        }
.grass .import-export-header-container .bar {
            border-left: 1px solid #9cadb2;
        }
.grass .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #325132;
        }
.grass-light .import-export-header-container .bar {
            border-left: 1px solid #424443;
        }
.grass-light .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #545f54;
        }
.mud .import-export-header-container .bar {
            border-left: 1px solid #f2f3f8;
        }
.mud .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #766146;
        }
.mud-light .import-export-header-container .bar {
            border-left: 1px solid #d6c282;
        }
.mud-light .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #a37b42;
        }
.illini .import-export-header-container .bar {
            border-left: 1px solid #f2f3f8;
        }
.illini .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #636466;
        }
.illini-light .import-export-header-container .bar {
            border-left: 1px solid #abc8e2;
        }
.illini-light .view-log-dialog-box .data-table-cont .data-table-rows {
            border-bottom: 5px solid #375d81;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.image-container {
    width: 230px;
    height: 154px;
    overflow: hidden
}
.image-container .default-image {
        width: 230px;
        height: 154px;
        border-radius: 4px 4px 4px 4px;
        background-size: cover;
    }
.photo-preview .dialog-box-title {
        justify-content: flex-end;
    }
.photo-preview .dialog-box-body {
        min-height: 50px
    }
.photo-preview .dialog-box-body img {
            background-size: cover;
            height: 100%;
            width: 100%;
        }
.sub-description-container {
    display: inline-block;
    margin-right: 5px;
    line-height: 1.2;
    font-size: 13px;
    padding-top: 7px;
}
.sub-description {
    font-size: 13px;
    padding-left: 10px;
}
.photo-list-item {
    display: flex;
    width: 420px
}
.photo-list-item .form-input {
        margin: 0;
        width: 90%;
        margin-right: 10px;
        margin-bottom: 10px;
    }
.photo-list-item .item-container {
        flex-grow: 1;
    }
.auto-search-list-container .select-form-input.form-input.text-form-input {
        margin: 10px 0;
    }
.auto-search-list-container .select-form-input-options {
        margin: 0;
    }
.auto-search-list-container .affiliation-table-stretch {
        margin-bottom: 10px;
    }
.auto-search-list-container .form-section-child-stretch .auto-search {
        margin-right: 0;
    }
.additional-agvance-customer .select-form-input.form-input.text-form-input {
    margin: 0 0 10px 0;
    width: 385px;
}
.photo-simple {
    margin-bottom: 10px;
    width: 100%
}
.photo-simple .image-thumbnail {
        width: 50%;
        max-height: 149px;
        border-radius: 4px 4px 4px 4px
    }
.photo-simple .image-thumbnail svg {
            width: 100%;
            height: 100%;
        }
.photo-simple .delete-link-container {
        padding-bottom: 10px;
        text-align: right;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        font-size: 13px;
    }
.photo-simple .add-link {
        font-size: 13px;
    }
.photo-simple.favicon .image-thumbnail {
        width: 50px;
    }
.crop-list-container .plain-data-table .data-table-cell {
    max-width: 80px;
}
.org-level-grid .data-table-row .data-header-cell.org-level-name {
        max-width: 180px;
    }
.org-level-grid .data-table-row .org-level-name {
        flex-grow: 2;
        max-width: 175px;
    }
.org-level-grid .data-table-row .org-level-city {
        max-width: 70px;
    }
.org-level-grid .data-table-row .org-level-state {
        max-width: 70px;
    }
.org-level-search-container .auto-search {
    margin: 10px 0
}
.org-level-search-container .auto-search .select-form-input.form-input.text-form-input {
        margin: 0;
    }
.commodity-add-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px
}
.commodity-add-form .select-form-input-container {
        width: calc(100% - 10px);
    }
.commodity-add-form .span-2 {
        grid-column: 1 / span 2;
    }
.default .photo-simple .image-thumbnail {
            background-color: #bfbfbf;
        }
.default-light .photo-simple .image-thumbnail {
            background-color: #cfcfcf;
        }
.sky .photo-simple .image-thumbnail {
            background-color: #c8c8c8;
        }
.sky-light .photo-simple .image-thumbnail {
            background-color: #c8c8c8;
        }
.grass .photo-simple .image-thumbnail {
            background-color: #9cadb2;
        }
.grass-light .photo-simple .image-thumbnail {
            background-color: #424443;
        }
.mud .photo-simple .image-thumbnail {
            background-color: #f2f3f8;
        }
.mud-light .photo-simple .image-thumbnail {
            background-color: #d6c282;
        }
.illini .photo-simple .image-thumbnail {
            background-color: #f2f3f8;
        }
.illini-light .photo-simple .image-thumbnail {
            background-color: #abc8e2;
        }
.sky .photo-simple .image-thumbnail {
        background-color: #e2e2e2;
        border: 1px solid #e2e2e2;
    }
.sky-light .photo-simple .image-thumbnail {
        background-color: #e2e2e2;
        border: 1px solid #e2e2e2;
    }

.equipment-section {
    width: 395px;
    padding-left: 5px;
}

.growth-stage-container {
    width: 98%;
}
.observation-section {
    margin-bottom: 0px;
}
.observation-section-align {
    margin-left: 20px;
}

.picklist-id-container {
    font-size: 13px;
    padding-bottom: 10px;
    display: flex
}
.picklist-id-container .picklist-id {
        width: 190px;
    }
.picklist-id-container .picklist-active-checkbox {
        width: 100px;
        margin-left: 10px;
    }

.nutrient-radio-group {
    display: flex;
    width: 50%;
    flex-direction: column;
}

.with-api-result .dialog-container .document-dialog-body {
        min-height: 190px
    }

.with-api-result .dialog-container .document-dialog-body .item-container {
            width: 100%;
        }

.with-api-result .dialog-container .document-dialog-body .form-input {
            display: block;
            margin: 10px 0;
            width: 93%;
        }

.with-api-result .dialog-container .document-dialog-body .form-input.textarea-form-input {
            margin: 5px 0 10px 0;
            outline: none;
        }


    .product-id-section span {
        margin: 2px 0;
    }
.manufacturer-section {
    padding-left: 5px;
}
.product-type-section {
    padding-left: 4px;
}
.nutrient-primary-checkbox {
    padding-left: 25px !important;
}

.trait-container {
    display: flex;
    justify-content: space-between
}
.trait-container .checkbox-div {
        padding-right: 17px;
    }
.trait-section-align {
    margin-left: 26px;
}
.trait-section {
    margin-bottom: 0px;
}
.trait-sub-section {
    margin-bottom: 5px;
}

.variety-hybrid-section-container {
    display: flex;
    justify-content: space-between
}
.variety-hybrid-section-container .form-sub-section .form-input {
            margin-right: 0 !important;
        }
.variety-hybrid-select-input {
    width: 183px !important;
}
.variety-hybrid-trait-select-input {
    width: 400px !important;
}
.variety-hybrid-container {
    margin-bottom: 0;
}
.form-sub-section .gmo-checkbox {
    margin-right: 15px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.add-edit-panel.variety-attribute-container {
        width: 100%;
        margin-left: 30px;
    }
.add-edit-panel .auto-search {
        margin-right: 7px;
    }
.add-edit-panel.section-seperator-first {
        padding-right: 20px;
    }
.add-edit-panel.section-seperator {
        padding: 0px 10px 0px 20px;
    }
.add-edit-panel .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
    }
.auto-search
    .variety-hybrid-search-container
    .select-form-input-options
    .select-form-input-option
    .first-line {
    width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    padding-left: 10px;
}
.default .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #404040;
    }
.default-light .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #919191;
    }
.sky .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #0194d3;
    }
.sky-light .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #0194d3;
    }
.grass .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #325132;
    }
.grass-light .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #545f54;
    }
.mud .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #766146;
    }
.mud-light .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #a37b42;
    }
.illini .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #636466;
    }
.illini-light .add-edit-panel .select-form-sub-header {
        border-top: 1px solid #375d81;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.about .quill {
        width: 1150px;
    }
.about .ql-editor {
        overflow-x: hidden;
    }
.about {

    .ql-container.ql-snow {
        border: none;
        height: 72vh;
        max-height: 72vh;
    }
}
.about .tabs-labels {
        max-width: -moz-fit-content;
        max-width: fit-content;
        margin-left: 400px;
    }
.about .tab-label {
        width: 200px;
        line-height: 40px;
    }
.about .release-notes {
        -webkit-user-select: text;
            -ms-user-select: text;
                user-select: text;
        margin-top: 10px;
        width: 1160px;
    }
.about .form-section {
        width: 800px;
    }
.about .build-version {
        margin-left: 380px;
        margin-top: 10px;
    }
.sky .quill {
            color: #000000;
        }
.sky .ql-toolbar {
            border-bottom: 1px solid #000000;
        }
.sky-light .quill {
            color: #000000;
        }
.sky-light .ql-toolbar {
            border-bottom: 1px solid #000000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.slide-container {
    position: absolute;
    z-index: 1001;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    flex: auto;
}
.slide-container.slide {
    animation: slide 1s backwards;
    animation-delay: 1s;
}
.slide-content-container {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex: 1 1
}
.slide-content-container .add-edit-panel {
        max-width: 1240px;
        margin: 15px auto
    }
.slide-content-container .add-edit-panel .bar {
            margin-left: 13px;
        }
.slide-content-container .content-table-container {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        overflow: hidden
    }
.slide-content-container .content-table-container .slide-content-container {
            display: block;
        }
.sliding-panel-header {
    height: 40px;
    font-family: "open_sansregular";
    font-size: 17px;
    font-weight: 500;
    line-height: 40px
}
.sliding-panel-header .display-container {
        width: 1240px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between
    }
.sliding-panel-header .display-container .display-text-container {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden
        }
.sliding-panel-header .display-container .display-text-container .header-item {
                display: inline;
            }
.sliding-panel-header .display-container .display-text-container .header-arrow {
                padding: 0 5px;
            }
.sliding-panel-header .display-container .svg-container {
            cursor: pointer;
            float: right
        }
.sliding-panel-header .display-container .svg-container .default-svg-icon {
                height: 20px;
                width: 20px;
                margin: 10px 0;
            }
.sliding-panel-header .button-container {
        display: flex;
        padding: 2px 0 2px 5px
    }
.sliding-panel-header .button-container .default-button {
            margin-right: 5px;
            height: 32px;
            line-height: 32px
        }
.sliding-panel-header .button-container .default-button.cancel-button.align-with-content {
                margin-right: 3px;
            }
.slide-container.slide-half {
    left: 444px;
    width: calc(100% - 444px);
    animation: slide-half 3s backwards;
    animation-delay: 1s
}
.slide-container.slide-half .sliding-panel-header {
        height: 50px
    }
.slide-container.slide-half .sliding-panel-header .display-container {
            font-size: 20px;
            font-weight: 500;
            height: 50px;
            line-height: 50px;
            width: 99%;
            padding: 0 10px
        }
.slide-container.slide-half .sliding-panel-header .display-container .svg-container .default-svg-icon {
                    margin: 15px;
                }
@keyframes slide-half {
    100% {
        left: 444px;
    }
}
@keyframes slide {
    100% {
        left: 0;
    }
}
@keyframes slide {
    0% {
        left: 2000;
    }
}
@keyframes slide-half {
    0% {
        left: 2000;
    }
}
.default .slide-container {
            background-color: #000000
        }
.default .slide-container .slide-content-container .content-table-container {
                color: #ffffff;
            }
.default .sliding-panel-header {
            background-color: #404040;
            color: #ffffff
        }
.default .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #e26816;
                    }
.default .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #8fa61a;
                }
.default .slide-container.slide-half .sliding-panel-header {
            background-color: #404040;
        }
.default-light .slide-container {
            background-color: #fcfcfc
        }
.default-light .slide-container .slide-content-container .content-table-container {
                color: #000000;
            }
.default-light .sliding-panel-header {
            background-color: #919191;
            color: #ffffff
        }
.default-light .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #e26816;
                    }
.default-light .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #8fa61a;
                }
.default-light .slide-container.slide-half .sliding-panel-header {
            background-color: #919191;
        }
.sky .slide-container {
            background-color: #fcfcfc
        }
.sky .slide-container .slide-content-container .content-table-container {
                color: #000000;
            }
.sky .sliding-panel-header {
            background-color: #0194d3;
            color: #ffffff
        }
.sky .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #01425e;
                    }
.sky .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #235635;
                }
.sky .slide-container.slide-half .sliding-panel-header {
            background-color: #0194d3;
        }
.sky-light .slide-container {
            background-color: #fcfcfc
        }
.sky-light .slide-container .slide-content-container .content-table-container {
                color: #000000;
            }
.sky-light .sliding-panel-header {
            background-color: #0194d3;
            color: #ffffff
        }
.sky-light .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #01425e;
                    }
.sky-light .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #235635;
                }
.sky-light .slide-container.slide-half .sliding-panel-header {
            background-color: #0194d3;
        }
.grass .slide-container {
            background-color: #667c66
        }
.grass .slide-container .slide-content-container .content-table-container {
                color: #ffffff;
            }
.grass .sliding-panel-header {
            background-color: #325132;
            color: #ffffff
        }
.grass .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #e26816;
                    }
.grass .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #ffff00;
                }
.grass .slide-container.slide-half .sliding-panel-header {
            background-color: #325132;
        }
.grass-light .slide-container {
            background-color: #babfba
        }
.grass-light .slide-container .slide-content-container .content-table-container {
                color: #000000;
            }
.grass-light .sliding-panel-header {
            background-color: #545f54;
            color: #ffffff
        }
.grass-light .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #e26816;
                    }
.grass-light .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #ffff00;
                }
.grass-light .slide-container.slide-half .sliding-panel-header {
            background-color: #545f54;
        }
.mud .slide-container {
            background-color: #4b3705
        }
.mud .slide-container .slide-content-container .content-table-container {
                color: #ffffff;
            }
.mud .sliding-panel-header {
            background-color: #766146;
            color: #ffffff
        }
.mud .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #f39104;
                    }
.mud .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #ffff00;
                }
.mud .slide-container.slide-half .sliding-panel-header {
            background-color: #766146;
        }
.mud-light .slide-container {
            background-color: #fff0bb
        }
.mud-light .slide-container .slide-content-container .content-table-container {
                color: #000000;
            }
.mud-light .sliding-panel-header {
            background-color: #a37b42;
            color: #ffffff
        }
.mud-light .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #e26816;
                    }
.mud-light .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #8fa61a;
                }
.mud-light .slide-container.slide-half .sliding-panel-header {
            background-color: #a37b42;
        }
.illini .slide-container {
            background-color: #00467f
        }
.illini .slide-container .slide-content-container .content-table-container {
                color: #ffffff;
            }
.illini .sliding-panel-header {
            background-color: #636466;
            color: #ffffff
        }
.illini .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #f39c11;
                    }
.illini .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #ffff00;
                }
.illini .slide-container.slide-half .sliding-panel-header {
            background-color: #636466;
        }
.illini-light .slide-container {
            background-color: #e1e6fa
        }
.illini-light .slide-container .slide-content-container .content-table-container {
                color: #000000;
            }
.illini-light .sliding-panel-header {
            background-color: #375d81;
            color: #ffffff
        }
.illini-light .sliding-panel-header .display-container .display-text-container .header-item:last-child .display-text {
                        color: #f39c11;
                    }
.illini-light .sliding-panel-header .display-container .svg-container:hover .default-svg-icon {
                    fill: #ffff00;
                }
.illini-light .slide-container.slide-half .sliding-panel-header {
            background-color: #375d81;
        }
.sky
        .sliding-panel-header
        .display-container
        .display-text-container
        .header-item:last-child
        .display-text {
        color: #01425e;
        font-weight: bold;
    }
.sky-light
        .sliding-panel-header
        .display-container
        .display-text-container
        .header-item:last-child
        .display-text {
        color: #01425e;
        font-weight: bold;
    }

@media screen and (max-width: 1240px) {
    .slide-content-container {
        margin-left: 10%
    }

        .slide-content-container .add-edit-panel {
            flex-grow: 1;
        }

        .slide-content-container .content-table-container.data-table-cont {
            flex-grow: 1;
            overflow-x: auto;
        }
        .sliding-panel-header .display-container {
            flex-grow: 1;
            width: 96%;
        }
}

@media (min-width: 961px) and (max-width: 1240px) {
    .slide-content-container {
        margin-left: 6%
    }

        .slide-content-container .add-edit-panel {
            max-width: 1240px;
        }
}


    .onsite-param-input .input-container {
        width: 275px;
    }

.add-link-container {
    margin-top: 10px;
}

.auto-exchange-checkbox {
    margin: 10px 10px 0 10px;
}

.use-as-companylevel-checkbox {
    margin: 10px 10px 0 10px;
}

.click-me-link {
    padding-right: 5px;
}

.telematics-grid .data-table-header .data-header-cell,
    .telematics-grid .plain-data-table .data-table-cell {
        max-width: 154px;
    }

.telematics-grid .data-header-cell.connection-type {
        padding-left: 0px;
    }

.telematics-grid .data-header-cell.connection-name {
        padding-left: 15px;
    }

.telematics-grid .data-header-cell.organization-name {
        padding-left: 15px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.admin-scripts-container {
    display: flex;
    width: 1240px;
    margin: 15px auto
}
.admin-scripts-container .select-form-input-container {
        width: 384px !important;
    }
.admin-scripts-container .org-level-search-container {
        margin-top: -10px;
    }
.admin-scripts-container .section-fence {
        border-width: 1px;
        margin: 0 13px;
    }
.admin-scripts-container .checkbox-div {
        width: 100%;
    }
.admin-scripts-main-container .dialog-container .default-button {
    max-width: unset;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.entity-container {
    display: flex;
    height: 21px;
    width: 521px;
    padding: 5px 0px 0px 5px
}
.entity-container span {
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
.entity-container .checkbox-div {
        width: 30px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
.content-zapper-container {
    display: flex;
    width: 1240px;
    margin: auto;
}
.batch-zapper-tabs {
    max-width: 100%;
    display: inline-block;
    width: 394px;
    padding-top: 5px
}
.batch-zapper-tabs .tab-label {
        line-height: 40px;
        width: 200px;
    }
.batch-zapper-container {
    margin: auto;
    margin-top: 10px;
    width: 1240px
}
.batch-zapper-container .select-company-container {
        width: 100%;
        margin-bottom: 15px
    }
.batch-zapper-container .select-company-container .select-form-input-container {
            margin-top: 15px;
            width: 77%;
        }
.batch-zapper-container .select-company-container .tree-view-container {
            font-size: 13px;
        }
.batch-zapper-container .use-only-cont {
        padding-top: 50px;
    }
.batch-zapper-container .location-entity-container .tree-view-container {
            margin-top: 15px
        }
.batch-zapper-container .location-entity-container .tree-view-container .tree-node-container .label,
                .batch-zapper-container .location-entity-container .tree-view-container .tree-node-container .expand-icon {
                    font-size: 13px;
                    line-height: 13px;
                    padding-top: 4px;
                }
.batch-zapper-container .batch-zapper-entities {
        margin-top: 15px;
        margin-left: 30px
    }
.batch-zapper-container .batch-zapper-entities span {
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
        }
.batch-zapper-container .section-container {
        font-size: 13px;
    }
.batch-zapper-container .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-left: 5px;
    }
.batch-zapper-container .batch-zapper-attribute.select-form-input-container {
        width: 384px;
    }
.batch-zapper-container .entity-search .form-input {
            padding-bottom: 5px;
            width: 300px;
        }
.batch-zapper-container .hierarchy-filter-container {
        width: 100%
    }
.batch-zapper-container .hierarchy-filter-container .auto-search .select-form-input-container {
                padding-top: 5px;
                width: 290px;
            }
.batch-zapper-container .hierarchy-filter-container .select-form-input-container {
            width: 290px;
        }
.batch-zapper-main-container .slide-content-container {
    display: block;
}
.batch-zapper-attribute-search {
    padding-top: 15px;
    padding-bottom: 25px;
}
.batch-zapper-use-only {
    position: absolute;
    margin-left: 220px;
    top: 340px;
}
.default .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #404040;
    }
.default-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #919191;
    }
.sky .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #0194d3;
    }
.sky-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #0194d3;
    }
.grass .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #325132;
    }
.grass-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #545f54;
    }
.mud .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #766146;
    }
.mud-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #a37b42;
    }
.illini .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #636466;
    }
.illini-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #375d81;
    }


    .form-sub-section .address-type {
        width: 180px;
    }
.form-sub-section .address-street-one.form-input {
        width: 100%;
    }
.form-sub-section .address-state {
        width: 80px;
    }
.form-sub-section .address-city {
        width: 160px;
    }
.form-sub-section .address-city.form-input-error {
        height: 39px;
    }
.form-sub-section .address-zip-code {
        width: 110px;
    }
.form-sub-section .address-is-primary {
        margin: auto;
        display: flex;
        width: 100px;
        justify-content: flex-end;
        margin-right: 0px;
    }
.address-header .form-section-header,
    .address-header .form-section-children {
        margin-bottom: 5px;
    }

@media screen and (max-width: 1240px) {
        .section-container .form-section .form-sub-section .address-street-one.form-input {
            width: auto;
        }

        .section-container .form-section .form-sub-section .address-type.select-form-input-container {
            width: 50%;
        }

        .section-container .form-section .form-sub-section .checkbox-div.address-is-primary {
            margin-left: 0px;
            margin-top: 12px;
            justify-content: flex-start;
        }
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.active-inactive-header-container {
    display: flex;
    justify-content: flex-end;
    min-height: 18px;
    font-size: 13px;
    font-weight: 300;
    padding-top: 20px;
    align-items: center;
}
.filter-header-container .org-level-header-cont .active-inactive-container {
        width: auto;
        width: initial;
    }
.filter-header-container .active-inactive-header-container {
        padding-left: 5px;
        width: auto;
        width: initial
    }
.filter-header-container .active-inactive-header-container.move-location-container {
            margin-top: 20px;
            padding: 0 5px 0 0;
        }
.hierarchy-filter-container {
    width: 620px;
    margin-top: 10px
}
.hierarchy-filter-container .form-sub-section .select-form-input-container:only-child {
        width: 250px;
    }
.employee-info-end-date.date-form-input.form-input.text-form-input {
    margin-left: 7px;
}
.user-container .select-form-input-options .select-form-input-option {
            padding: 0px 5px;
            display: flex;
            flex-flow: column wrap
        }
.user-container .select-form-input-options .select-form-input-option .post-primary-label {
                flex-grow: 0;
                flex-basis: 50%;
                align-self: flex-start;
                width: 50%;
                margin-top: 1px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
.user-container .select-form-input-options .select-form-input-option .second-line {
                padding-left: 0px;
                width: 50%;
            }
.user-container .select-form-input-options .select-form-input-option .first-line {
                padding-left: 0px;
                width: 50%
            }
.user-container .select-form-input-options .select-form-input-option .first-line .pre-match {
                    margin-left: 0px;
                }
.user-container .form-input {
        width: 300px;
        margin: 0px;
    }
.person-search-no-option {
    height: 100%;
    text-align: left;
    padding-top: 15px;
}
.person-name {
    width: 50%;
    float: left;
    margin-bottom: 5px;
}
.person-jobtitle {
    width: 50%;
    float: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}
.person-state {
    font-weight: 300;
    font-size: 10px;
    font-style: italic;
}
.salesperson-textbox.form-input {
    width: 250px;
}
.form-sub-section .person-filter {
        width: 100%
    }
.form-sub-section .person-filter .search-icon {
            width: 13px;
            height: 13px;
            padding-top: 4px;
        }
.form-sub-section .person-filter.customer-name-section.select-form-input-container {
        width: 100%;
    }
.form-sub-section .user-supervisor-search {
        width: 185px;
    }
.form-sub-section .auto-search-list-container.additional-agvance-customer {
        width: 100%;
    }
.form-sub-section .portal-integration.select-form-input-container {
        width: 100%;
    }
.auto-search-customer-affiliation {
    padding: 10px 0px
}
.auto-search-customer-affiliation .select-form-input.form-input.text-form-input {
        margin: 0px;
    }
.search-person-options {
    padding-left: 18px
}
.search-person-options .person-info {
        display: flex
    }
.search-person-options .person-info .person-jobtitle {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
.search-person-options .person-info .person-name {
            width: 50%;
        }
.search-person-options .person-info .person-state {
            font-weight: 300;
            font-size: 10px;
        }
.user-role-matrix {
    margin-top: 30px;
    width: 1240px;
    margin: 30px auto 0;
    font-family: "open_sansregular";
    font-size: 13px;
    display: flex
}
.user-role-matrix .matrix-grid {
        flex: 1 1;
    }
.user-role-matrix .column-header-label {
        width: 115px;
        height: 90px;
        vertical-align: bottom;
        line-height: 10;
        font-weight: bold;
    }
.user-role-matrix .column-header {
        overflow: visible;
        height: 90px;
        transform: rotate(-65deg);
        width: 35px;
        vertical-align: bottom;
        line-height: 15;
        font-weight: bold;
    }
.user-role-matrix .grid-body {
        overflow: auto;
        margin-bottom: 30px
    }
.user-role-matrix .grid-body .column-sub-header {
            height: 20px;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
.user-role-matrix .grid-body .row-data {
            width: 32px;
            height: 20px;
            line-height: 1.7;
            text-align: center;
        }
.user-role-matrix .grid-body .row-no-data {
            width: 32px;
            height: 20px;
            text-align: center;
            line-height: 1.7;
        }
.user-role-matrix .grid-body .column-label {
            width: 150px;
            height: 20px;
            line-height: 1.7;
            padding-left: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            float: left;
        }
.customer-affiliation-table .plain-table-body .data-table-cell {
        width: 25%;
    }
.customer-affiliation-table .data-table-header .data-header-cell {
        width: 25%;
    }
.additional-agvance-customer .data-header-cell.agvance-grower-name {
        max-width: 200px;
        flex-grow: 2;
    }
.additional-agvance-customer .data-table-cell.agvance-grower-name {
        max-width: 140px;
        flex-grow: 2;
    }
.agvance-integration .form-section-header {
        margin-bottom: 0px;
    }
.agvance-integration .form-sub-section {
        margin: 10px 0px 0px 0px;
    }
.person-affliliation .data-header-cell.person-name {
        max-width: 110px;
        flex-grow: 2;
        margin-bottom: 0px;
    }
.person-affliliation .data-table-cell.person-city {
        max-width: 70px;
    }
.person-affliliation .data-table-cell.person-name {
        margin-bottom: 0px;
    }
.additional-agvance-customer-grid .data-table-row .data-header-cell.agvance-name, .form-section-child-stretch .data-table-row .data-header-cell.agvance-name {
        max-width: 180px;
    }
.additional-agvance-customer-grid .data-table-row .agvance-name, .form-section-child-stretch .data-table-row .agvance-name {
        flex-grow: 2;
        max-width: 135px;
    }
.additional-agvance-customer-grid .data-table-row .agvance-location, .form-section-child-stretch .data-table-row .agvance-location {
        max-width: 145px;
        padding-left: 0px;
    }
.additional-agvance-customer-grid .data-table-row .agvance-checkbox, .form-section-child-stretch .data-table-row .agvance-checkbox {
        width: 50px;
        padding-left: 0px;
        padding-top: 2px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
.default .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #bfbfbf;
        }
.default .user-role-matrix .column-sub-header {
                background-color: #262626;
            }
.default .user-role-matrix .grid-row {
                border: 1px solid #262626;
            }
.default .user-role-matrix .column-label {
                border-right: 1px solid #262626;
                border-left: 1px solid #262626;
            }
.default .user-role-matrix .row-data {
                border-right: 1px solid #262626;
                border-left: 1px solid #262626;
            }
.default .user-role-matrix .row-no-data {
                border-right: 1px solid #262626;
                border-left: 1px solid #262626;
            }
.default .user-role-matrix .grid-body {
                border-bottom: 2px solid #262626;
                border-top: 2px solid #262626;
            }
.default .person-filter .search-icon {
            fill: #000000;
        }
.default-light .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #cfcfcf;
        }
.default-light .user-role-matrix .column-sub-header {
                background-color: #e2e2e2;
            }
.default-light .user-role-matrix .grid-row {
                border: 1px solid #e2e2e2;
            }
.default-light .user-role-matrix .column-label {
                border-right: 1px solid #e2e2e2;
                border-left: 1px solid #e2e2e2;
            }
.default-light .user-role-matrix .row-data {
                border-right: 1px solid #e2e2e2;
                border-left: 1px solid #e2e2e2;
            }
.default-light .user-role-matrix .row-no-data {
                border-right: 1px solid #e2e2e2;
                border-left: 1px solid #e2e2e2;
            }
.default-light .user-role-matrix .grid-body {
                border-bottom: 2px solid #e2e2e2;
                border-top: 2px solid #e2e2e2;
            }
.default-light .person-filter .search-icon {
            fill: #000000;
        }
.sky .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #c8c8c8;
        }
.sky .user-role-matrix .column-sub-header {
                background-color: #b1b1b1;
            }
.sky .user-role-matrix .grid-row {
                border: 1px solid #b1b1b1;
            }
.sky .user-role-matrix .column-label {
                border-right: 1px solid #b1b1b1;
                border-left: 1px solid #b1b1b1;
            }
.sky .user-role-matrix .row-data {
                border-right: 1px solid #b1b1b1;
                border-left: 1px solid #b1b1b1;
            }
.sky .user-role-matrix .row-no-data {
                border-right: 1px solid #b1b1b1;
                border-left: 1px solid #b1b1b1;
            }
.sky .user-role-matrix .grid-body {
                border-bottom: 2px solid #b1b1b1;
                border-top: 2px solid #b1b1b1;
            }
.sky .person-filter .search-icon {
            fill: #000000;
        }
.sky-light .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #c8c8c8;
        }
.sky-light .user-role-matrix .column-sub-header {
                background-color: #b1b1b1;
            }
.sky-light .user-role-matrix .grid-row {
                border: 1px solid #b1b1b1;
            }
.sky-light .user-role-matrix .column-label {
                border-right: 1px solid #b1b1b1;
                border-left: 1px solid #b1b1b1;
            }
.sky-light .user-role-matrix .row-data {
                border-right: 1px solid #b1b1b1;
                border-left: 1px solid #b1b1b1;
            }
.sky-light .user-role-matrix .row-no-data {
                border-right: 1px solid #b1b1b1;
                border-left: 1px solid #b1b1b1;
            }
.sky-light .user-role-matrix .grid-body {
                border-bottom: 2px solid #b1b1b1;
                border-top: 2px solid #b1b1b1;
            }
.sky-light .person-filter .search-icon {
            fill: #000000;
        }
.grass .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #9cadb2;
        }
.grass .user-role-matrix .column-sub-header {
                background-color: #424443;
            }
.grass .user-role-matrix .grid-row {
                border: 1px solid #424443;
            }
.grass .user-role-matrix .column-label {
                border-right: 1px solid #424443;
                border-left: 1px solid #424443;
            }
.grass .user-role-matrix .row-data {
                border-right: 1px solid #424443;
                border-left: 1px solid #424443;
            }
.grass .user-role-matrix .row-no-data {
                border-right: 1px solid #424443;
                border-left: 1px solid #424443;
            }
.grass .user-role-matrix .grid-body {
                border-bottom: 2px solid #424443;
                border-top: 2px solid #424443;
            }
.grass .person-filter .search-icon {
            fill: #000000;
        }
.grass-light .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #424443;
        }
.grass-light .user-role-matrix .column-sub-header {
                background-color: #9cadb2;
            }
.grass-light .user-role-matrix .grid-row {
                border: 1px solid #9cadb2;
            }
.grass-light .user-role-matrix .column-label {
                border-right: 1px solid #9cadb2;
                border-left: 1px solid #9cadb2;
            }
.grass-light .user-role-matrix .row-data {
                border-right: 1px solid #9cadb2;
                border-left: 1px solid #9cadb2;
            }
.grass-light .user-role-matrix .row-no-data {
                border-right: 1px solid #9cadb2;
                border-left: 1px solid #9cadb2;
            }
.grass-light .user-role-matrix .grid-body {
                border-bottom: 2px solid #9cadb2;
                border-top: 2px solid #9cadb2;
            }
.grass-light .person-filter .search-icon {
            fill: #000000;
        }
.mud .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #f2f3f8;
        }
.mud .user-role-matrix .column-sub-header {
                background-color: #8c7454;
            }
.mud .user-role-matrix .grid-row {
                border: 1px solid #8c7454;
            }
.mud .user-role-matrix .column-label {
                border-right: 1px solid #8c7454;
                border-left: 1px solid #8c7454;
            }
.mud .user-role-matrix .row-data {
                border-right: 1px solid #8c7454;
                border-left: 1px solid #8c7454;
            }
.mud .user-role-matrix .row-no-data {
                border-right: 1px solid #8c7454;
                border-left: 1px solid #8c7454;
            }
.mud .user-role-matrix .grid-body {
                border-bottom: 2px solid #8c7454;
                border-top: 2px solid #8c7454;
            }
.mud .person-filter .search-icon {
            fill: #000000;
        }
.mud-light .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #d6c282;
        }
.mud-light .user-role-matrix .column-sub-header {
                background-color: #b28f5a;
            }
.mud-light .user-role-matrix .grid-row {
                border: 1px solid #b28f5a;
            }
.mud-light .user-role-matrix .column-label {
                border-right: 1px solid #b28f5a;
                border-left: 1px solid #b28f5a;
            }
.mud-light .user-role-matrix .row-data {
                border-right: 1px solid #b28f5a;
                border-left: 1px solid #b28f5a;
            }
.mud-light .user-role-matrix .row-no-data {
                border-right: 1px solid #b28f5a;
                border-left: 1px solid #b28f5a;
            }
.mud-light .user-role-matrix .grid-body {
                border-bottom: 2px solid #b28f5a;
                border-top: 2px solid #b28f5a;
            }
.mud-light .person-filter .search-icon {
            fill: #000000;
        }
.illini .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #f2f3f8;
        }
.illini .user-role-matrix .column-sub-header {
                background-color: #abc8e2;
            }
.illini .user-role-matrix .grid-row {
                border: 1px solid #abc8e2;
            }
.illini .user-role-matrix .column-label {
                border-right: 1px solid #abc8e2;
                border-left: 1px solid #abc8e2;
            }
.illini .user-role-matrix .row-data {
                border-right: 1px solid #abc8e2;
                border-left: 1px solid #abc8e2;
            }
.illini .user-role-matrix .row-no-data {
                border-right: 1px solid #abc8e2;
                border-left: 1px solid #abc8e2;
            }
.illini .user-role-matrix .grid-body {
                border-bottom: 2px solid #abc8e2;
                border-top: 2px solid #abc8e2;
            }
.illini .person-filter .search-icon {
            fill: #000000;
        }
.illini-light .filter-header-container .active-inactive-header-container.move-location-container {
            border-right: 1px solid #abc8e2;
        }
.illini-light .user-role-matrix .column-sub-header {
                background-color: #c4d7ed;
            }
.illini-light .user-role-matrix .grid-row {
                border: 1px solid #c4d7ed;
            }
.illini-light .user-role-matrix .column-label {
                border-right: 1px solid #c4d7ed;
                border-left: 1px solid #c4d7ed;
            }
.illini-light .user-role-matrix .row-data {
                border-right: 1px solid #c4d7ed;
                border-left: 1px solid #c4d7ed;
            }
.illini-light .user-role-matrix .row-no-data {
                border-right: 1px solid #c4d7ed;
                border-left: 1px solid #c4d7ed;
            }
.illini-light .user-role-matrix .grid-body {
                border-bottom: 2px solid #c4d7ed;
                border-top: 2px solid #c4d7ed;
            }
.illini-light .person-filter .search-icon {
            fill: #000000;
        }

.nozzle-dialog {
    min-width: 400px
}
.nozzle-dialog .accessories-group :first-child.select-form-input-container,
        .nozzle-dialog .accessories-group :first-child.form-input {
            margin-right: 10px;
        }
.owner-operator-radio-group {
    flex-grow: 1;
}
.owner-search-section {
    flex-grow: 1;
    max-width: 241px
}
.owner-search-section .select-form-input-container:only-child {
        width: auto;
    }
.owner-search-section .auto-search {
        margin-right: 0px;
    }
.owner-search-section .vendor-search {
        flex-grow: 1
    }
.owner-search-section .vendor-search .auto-search-input {
            width: auto;
            padding-right: 0;
            flex-grow: 1
        }
.owner-search-section .vendor-search .auto-search-input .form-input:only-child {
                width: auto;
            }
.equipment-right-section {
    padding-left: 3px;
}
.telematics-user-search .person-filter.select-form-input-container {
        width: 183px;
        padding-right: 0px;
    }
.telematics-user-search .telematics-equipment-list {
        margin-left: 19px;
    }

@media screen and (max-width: 1240px) {
                .slide-content-container:not(dialog-window) .equipment-setup-cont .column-3-gutter > *,
                .slide-content-container:not(dialog-window) .equipment-setup-cont .column-3-gutter .form-input.text-form-input {
                    width: 50%;
                }

                .slide-content-container:not(dialog-window) .equipment-setup-cont .column-3-gutter .select-form-input-container {
                    width: 100%
                }

                    .slide-content-container:not(dialog-window) .equipment-setup-cont .column-3-gutter .select-form-input-container .form-input.text-form-input {
                        flex-grow: 1;
                    }
                .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section.equipment-right-section,
                .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section.equipment-section {
                    padding-left: 0px;
                }
                    .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section .equipment-notes .form-input.textarea-form-input {
                        margin-top: 7px;
                    }

                    .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section .equipment-notes.equipment-description .form-input.textarea-form-input {
                        margin-bottom: 10px;
                    }
                    .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section .form-sub-section.owner-operator-cont {
                        margin-bottom: 7px;
                    }

                    .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section .form-sub-section .form-input.date-form-input:only-child {
                        width: 50%;
                    }

                    .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section .form-sub-section .purchase-history-date-input {
                        margin-left: 0px;
                    }

            .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section-bucket.purchase-history,
            .slide-content-container:not(dialog-window) .equipment-setup-cont .form-section-child-stretch.mini-grid.maintenance-event {
                margin-bottom: 10px;
            }
}


    .customer-main-container .slide-content-container {
        display: block;
    }
.customer-main-container .community-data-section {
        display: flex;
        margin: 10px 0px 5px 0px;
        width: 50%;
    }
.customer-main-container .customer-info-container .customer-name-section {
            width: 300px;
        }
.customer-main-container .customer-info-container .checkbox-div {
            width: 20%;
        }
.customer-main-container .equipment-grid {
        font-size: 13px
    }
.customer-main-container .equipment-grid .data-header-cell {
            width: 24%
        }
.customer-main-container .equipment-grid .data-header-cell:last-child {
                padding-right: 15px;
            }
.customer-main-container .equipment-grid .data-header-cell .data-table-cell {
                width: 75%;
            }
.customer-main-container .equipment-grid .data-table-cell {
            width: 25%;
        }
.customer-main-container .add-equipment-link {
        margin: 10px 0px 0px 0px;
    }
.customer-main-container .alias-name-container .alias-name.form-input {
        width: 100%;
    }
.customer-main-container .enrollment-container {
        width: 100%
    }
.customer-main-container .enrollment-container .enrollment-auto {
            color: #8fa61a;
            font-size: 25px;
            margin-top: 2px;
        }
.customer-main-container .enrollment-container .enrollment-input {
            width: 50%;
        }
.customer-main-container .enrollment-container .enrollment-checkbox {
            width: 90px;
            margin-left: 5px;
        }
.customer-main-container .enrollment-container .enrollment-form-link {
            font-size: 13px;
            margin: auto;
            line-height: 27px;
            padding-left: 3px
        }
.customer-main-container .enrollment-container .enrollment-form-link:hover .context-menu .menu-dot {
                    visibility: visible;
                }
.customer-main-container .enrollment-container .enrollment-form-link .context-menu-container {
                float: right;
                margin-left: 5px
            }
.customer-main-container .enrollment-container .enrollment-form-link .context-menu-container .menu-dot {
                    visibility: hidden;
                }
.customer-main-container .enrollment-container .enrollment-form-add {
            font-size: 13px;
            margin: auto;
            margin-left: 3px;
            line-height: 27px;
        }
.customer-main-container .enrollment-button {
        width: 44%;
        max-width: 200px;
        margin-top: 0px;
        height: 45px;
    }
.customer-main-container .auto-search-list-container.additional-agvance-customer .auto-search {
        margin-right: 0;
    }
.customer-main-container .agvance-search-loading-container {
        position: relative;
        min-width: 68px;
        left: 4px
    }
.customer-main-container .agvance-search-loading-container .loader-container {
            position: relative;
            margin-bottom: 4px;
        }
.customer-main-container .agvance-search-loading-container .loader-container > div {
            background-color: transparent;
        }
.customer-main-container .agvance-search-loading-container .line-scale-party > div {
            background-color: #404040;
            height: 20px;
        }

.vendor-search-no-option {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.vendor-search-sub-item {
    font-size: 10px;
    font-style: italic;
}

.vendor-search .form-input.text-form-input {
    height: 35px;
}

.active-inactive {
    margin-left: 10px;
    padding-top: 25px;
}

.sampling-required-checkbox {
    width: 55% !important;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.equation-text-area-container {
    flex-grow: 1
}
.equation-text-area-container .form-sub-section {
        flex-direction: column;
        justify-content: space-between
    }
.equation-text-area-container .form-sub-section.form-section-child-stretch.form-section-row-2 {
            margin-top: 56px;
        }
.equation-text-area-container .form-input {
        margin-bottom: 15px;
    }
.equation-text-area-container .equation-text-button-container {
        display: flex;
        justify-content: space-between;
    }
.equation-text-area-container .form-input.textarea-form-input.formula-text-area {
        margin-left: 3px;
        height: 260px;
        margin-bottom: 10px
    }
.equation-text-area-container .form-input.textarea-form-input.formula-text-area textarea {
            font-family: "Arial";
        }
.equation-text-area-container .form-input.textarea-form-input.formula-text-area .input-label-container {
            height: 15px;
        }
.read-only-content span {
        margin-bottom: 10px;
    }
.read-only-content span:first {
        margin-top: 15px;
    }
.section-container-equation {
    margin-bottom: 0px
}
.section-container-equation .shift-button {
        margin-left: 250px;
    }
.section-container-equation .rec-output-text {
        width: 180px;
        display: flex;
        align-items: center
    }
.section-container-equation .rec-output-text span {
            font-weight: 900;
            margin-left: 15px;
        }
.elemental-oxide-radio-group {
    display: flex;
    width: 50%;
}
.equation-input-id.form-input.text-form-input {
    min-width: 47%;
    max-width: 47%;
}
.parameter-section {
    margin-top: 7px;
    height: 45px
}
.parameter-section .parameter-section-label {
        width: 50%;
        margin-right: 7px;
        margin-top: 10px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
.default .rec-output-text span {
            color: #e26816;
        }
.default-light .rec-output-text span {
            color: #e26816;
        }
.sky .rec-output-text span {
            color: #01425e;
        }
.sky-light .rec-output-text span {
            color: #01425e;
        }
.grass .rec-output-text span {
            color: #e26816;
        }
.grass-light .rec-output-text span {
            color: #e26816;
        }
.mud .rec-output-text span {
            color: #f39104;
        }
.mud-light .rec-output-text span {
            color: #e26816;
        }
.illini .rec-output-text span {
            color: #f39c11;
        }
.illini-light .rec-output-text span {
            color: #f39c11;
        }


    .equipment-setup-cont .owner-operator-cont {
        display: flex;
        justify-content: space-around;
    }

.equipment-setup-cont .assigned-to-cont {
        margin-top: 15px;
        font-size: 13px;
    }

.equipment-setup-cont .accessories-group {
        display: flex;
        padding-bottom: 10px;
    }

.equipment-setup-cont .selected-owner-content {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        padding-top: 15px
    }

.equipment-setup-cont .selected-owner-content .italic-text {
            margin-top: 5px;
            font-family: "open_sansitalic";
            font-size: 13px;
            font-weight: 300;
        }

.equipment-setup-cont .column-3-gutter > * {
            width: 116.5px;
        }

.equipment-setup-cont .column-3-gutter .select-form-input-container {
            width: 116.5px;
        }

.equipment-setup-cont .column-3-gutter-dialog-box {
        display: flex
    }

.equipment-setup-cont .column-3-gutter-dialog-box .date-form-input-container {
            margin-bottom: 10px;
        }

.equipment-setup-cont .column-3-gutter-dialog-box .date-form-input.form-input.text-form-input {
            width: 121px;
            margin-right: 8px;
            margin-bottom: 8px;
        }

.equipment-setup-cont .column-3-gutter-dialog-box .form-input.text-form-input {
            width: 121px;
        }

.equipment-setup-cont .column-3-gutter-dialog-box .select-form-input-container {
            width: 121px;
        }

.equipment-setup-cont .form-sub-section .purchase-history-date-input {
            margin-left: 20px
        }

.equipment-setup-cont .form-sub-section .purchase-history-date-input .input-label-container {
                width: 180px;
                display: flex;
            }

.equipment-setup-cont .equipment-notes .form-input.textarea-form-input {
        height: 75px;
        margin-top: 0;
        flex-grow: 1;
        width: auto
    }

.equipment-setup-cont .equipment-notes .form-input.textarea-form-input .input-container {
            min-height: 85%;
        }

.equipment-setup-cont .accessories-dialog .dialog-box-body {
            width: 430px
        }

.equipment-setup-cont .accessories-dialog .dialog-box-body .accessories-group {
                display: flex;
                justify-content: space-between
            }

.equipment-setup-cont .accessories-dialog .dialog-box-body .accessories-group .select-form-input-container:only-child {
                    width: 192px;
                }

.sliding-panel-header .button-container .default-button.cancel-button.equipment-cancel {
    margin-right: 3px;
}

.with-api-result .dialog-container.maintenance-event-dialog .dialog-box-body {
        width: 400px
    }

.with-api-result .dialog-container.maintenance-event-dialog .dialog-box-body .description-cont .form-input.textarea-form-input.maintenance-event-textarea {
            margin-top: 10px;
            width: 97%;
            height: 60px;
        }

.with-api-result .dialog-container.bin-tank-dialog .dialog-box-body {
        width: 400px;
    }

.with-api-result .dialog-container.fueling-event-dialog .dialog-box-body .description-cont .form-input.textarea-form-input.fueling-event-textarea {
            width: 97.5%;
            height: 60px;
        }

.with-api-result .dialog-container.fueling-event-dialog .dialog-box-body .column-3-gutter-dialog-box .date-form-input-container {
                margin-bottom: 0px;
            }

.with-api-result .dialog-container.usage-reading-dialog .dialog-box-body .column-3-gutter-dialog-box .form-input.textarea-form-input.usage-reading-textarea {
                margin-top: 8px;
                height: 60px;
            }

.with-api-result .dialog-container.usage-reading-dialog .dialog-box-body .column-3-gutter-dialog-box .date-form-input-container {
                margin-bottom: 0px;
            }

.with-api-result .dialog-container.usage-reading-dialog .dialog-box-body .accessories-group {
            padding-bottom: 0px;
        }


    .import-filter-main-container .form-input {
        margin: 10px 0px 0px 0px;
    }
.sliding-panel-header .button-container .default-button.cancel-button.import-filter-cancel {
    margin-right: 1px;
}
.vertical-section-spacer {
    padding-top: 15px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.entity-container {
    display: flex;
    height: 21px;
    width: 521px;
    padding: 5px 0px 0px 5px
}
.entity-container span {
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
.entity-container .checkbox-div {
        width: 30px;
        margin-top: 0px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
.location-migration-main-container {
    margin: auto;
    margin-top: 10px;
    width: 1240px
}
.location-migration-main-container .migrate-options-checkboxes {
        display: flex;
        flex: auto;
        margin-top: 16px;
        width: 50%;
    }
.location-migration-main-container .auto-search-options {
        top: 37px;
        min-width: 196px;
        margin-left: 10px;
    }
.location-migration-main-container .radio-button-group {
        display: flex;
        flex: auto;
        margin-top: 16px;
    }
.location-migration-main-container .migrate-options-cont {
        margin-top: 8px;
        width: 100%;
    }
.location-migration-main-container .migrate-button {
        margin-left: 130px;
    }
.location-migration-main-container .migrate-radio {
        width: 250px;
    }
.location-migration-main-container .form-section-child-stretch {
        width: 285px;
    }
.location-migration-main-container .select-location-section {
        padding: 16px
    }
.location-migration-main-container .select-location-section .select-location-label {
            margin-left: 10px;
        }
.location-migration-main-container .select-location-section .entity-search {
            width: 216px;
            margin-top: 32px;
        }
.location-migration-main-container .select-location-section .tree-view-container {
            margin-left: 10px;
            margin-top: 24px;
        }
.location-migration-main-container .tree-view-container {
        margin-top: 15px
    }
.location-migration-main-container .tree-view-container .tree-node-container .label,
            .location-migration-main-container .tree-view-container .tree-node-container .expand-icon {
                font-size: 13px;
                line-height: 13px;
                padding-top: 4px;
            }
.location-migration-main-container .migrate-options-section {
        width: 900px
    }
.location-migration-main-container .migrate-options-section .form-section {
            width: 100%;
        }
.location-migration-main-container .migrate-options-section .migrate-options-textarea-cont {
            height: 100%
        }
.location-migration-main-container .migrate-options-section .migrate-options-textarea-cont .form-section-children {
                height: 100%;
            }
.location-migration-main-container .migrate-options-section .migrate-options-textarea-cont .migrate-options-textarea {
                height: 100%
            }
.location-migration-main-container .migrate-options-section .migrate-options-textarea-cont .migrate-options-textarea .form-input.textarea-form-input {
                    height: 100%;
                }
.location-migration-main-container .submit-options {
        width: 100%;
    }
.location-migration-main-container .migrate-options-label {
        padding: 16px 16px 16px 0px;
    }
.location-migration-main-container .select-company-container {
        width: 100%;
        margin-bottom: 30px
    }
.location-migration-main-container .select-company-container .select-form-input-container {
            margin-top: 15px;
            width: 75%;
        }
.location-migration-main-container .use-only-cont {
        padding-top: 50px;
    }
.location-migration-main-container .location-entity-container .tree-view-container {
            margin-top: 15px
        }
.location-migration-main-container .location-entity-container .tree-view-container .tree-node-container .label,
                .location-migration-main-container .location-entity-container .tree-view-container .tree-node-container .expand-icon {
                    font-size: 13px;
                    line-height: 13px;
                    padding-top: 4px;
                }
.location-migration-main-container .batch-zapper-entities {
        margin-top: 15px;
        margin-left: 30px
    }
.location-migration-main-container .batch-zapper-entities span {
            font-family: "open_sansregular";
            font-size: 13px;
            font-weight: 300;
        }
.location-migration-main-container .select-form-sub-header {
        font-weight: bold;
        cursor: default;
        height: 50%;
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-left: 5px;
    }
.location-migration-main-container .batch-zapper-attribute.select-form-input-container {
        width: 100%;
    }
.batch-zapper-main-container .slide-content-container {
    display: block;
}
.default .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #404040;
    }
.default-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #919191;
    }
.sky .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #0194d3;
    }
.sky-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #0194d3;
    }
.grass .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #325132;
    }
.grass-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #545f54;
    }
.mud .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #766146;
    }
.mud-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #a37b42;
    }
.illini .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #636466;
    }
.illini-light .batch-zapper-container .select-form-sub-header {
        border-top: 1px solid #375d81;
    }


    .form-sub-section .phone-type {
        width: 30%;
    }
.form-sub-section .phone-no {
        width: 70%;
    }


    .z-to-n-header.url-header .form-section-header,
    .z-to-n-header.url-header .form-section-children {
        margin-bottom: 5px;
    }
.url-item .form-input:only-child {
    margin: 0;
    width: 100%;
}


    .org-level-details .form-input.textarea-form-input {
        margin: 10px 0px;
    }
.org-level-details .form-sub-section .form-input.org-type-name {
        width: 100%;
    }
.org-level-details .form-section.grid-section {
        padding-bottom: 5px;
    }
.org-level-details .form-sub-section .migrate-to-customer-select {
        width: 100%;
        margin-top: 10px;
    }
.org-level-details .equipment-aff-grid .data-header-cell,
        .org-level-details .equipment-aff-grid .plain-table-body .data-table-cell {
            width: 25%;
        }
.org-level-details .equipment-aff-grid .add-link {
            font-size: 13px;
        }
.org-level-details .agvance-integration-checkbox-cont .checkbox-div {
            margin: 12px 7px 0px 0px;
            width: 50%;
        }

@media screen and (max-width: 1240px) {
        .form-section.equipment-right-section,
        .form-section.equipment-section {
            padding-left: 0px;
        }
}

@media screen and (max-width: 1240px) {
            .slide-content-container .person-setup-cont .person-section, .slide-content-container .user-setup-cont .person-section, .slide-content-container .vendor-setup-cont .person-section, .slide-content-container .owner-setup-cont .person-section, .slide-content-container .org-level-details .person-section, .slide-content-container .system-settings-cont .person-section {
                margin-left: 0;
            }
                .slide-content-container .person-setup-cont .user-section .user-request-password, .slide-content-container .user-setup-cont .user-section .user-request-password, .slide-content-container .vendor-setup-cont .user-section .user-request-password, .slide-content-container .owner-setup-cont .user-section .user-request-password, .slide-content-container .org-level-details .user-section .user-request-password, .slide-content-container .system-settings-cont .user-section .user-request-password {
                    margin: 10px 0 5px 0;
                }

                .slide-content-container .person-setup-cont .user-section .select-form-input-container, .slide-content-container .user-setup-cont .user-section .select-form-input-container, .slide-content-container .vendor-setup-cont .user-section .select-form-input-container, .slide-content-container .owner-setup-cont .user-section .select-form-input-container, .slide-content-container .org-level-details .user-section .select-form-input-container, .slide-content-container .system-settings-cont .user-section .select-form-input-container {
                    width: 50%;
                }

            .slide-content-container .person-setup-cont .only-child-responsive,
            .slide-content-container .person-setup-cont .policy-section .form-input.privacy-policy-title.only-child-responsive,
            .slide-content-container .user-setup-cont .only-child-responsive,
            .slide-content-container .user-setup-cont .policy-section .form-input.privacy-policy-title.only-child-responsive,
            .slide-content-container .vendor-setup-cont .only-child-responsive,
            .slide-content-container .vendor-setup-cont .policy-section .form-input.privacy-policy-title.only-child-responsive,
            .slide-content-container .owner-setup-cont .only-child-responsive,
            .slide-content-container .owner-setup-cont .policy-section .form-input.privacy-policy-title.only-child-responsive,
            .slide-content-container .org-level-details .only-child-responsive,
            .slide-content-container .org-level-details .policy-section .form-input.privacy-policy-title.only-child-responsive,
            .slide-content-container .system-settings-cont .only-child-responsive,
            .slide-content-container .system-settings-cont .policy-section .form-input.privacy-policy-title.only-child-responsive {
                width: 50%;
            }

            .slide-content-container .person-setup-cont .add-link-container,
            .slide-content-container .person-setup-cont .add-edit-levels-link,
            .slide-content-container .user-setup-cont .add-link-container,
            .slide-content-container .user-setup-cont .add-edit-levels-link,
            .slide-content-container .vendor-setup-cont .add-link-container,
            .slide-content-container .vendor-setup-cont .add-edit-levels-link,
            .slide-content-container .owner-setup-cont .add-link-container,
            .slide-content-container .owner-setup-cont .add-edit-levels-link,
            .slide-content-container .org-level-details .add-link-container,
            .slide-content-container .org-level-details .add-edit-levels-link,
            .slide-content-container .system-settings-cont .add-link-container,
            .slide-content-container .system-settings-cont .add-edit-levels-link {
                margin: 10px 0;
            }
                    .slide-content-container .person-setup-cont .form-section.phone-header .form-section-header, .slide-content-container .person-setup-cont .form-section.email-header .form-section-header, .slide-content-container .person-setup-cont .form-section.url-header .form-section-header, .slide-content-container .user-setup-cont .form-section.phone-header .form-section-header, .slide-content-container .user-setup-cont .form-section.email-header .form-section-header, .slide-content-container .user-setup-cont .form-section.url-header .form-section-header, .slide-content-container .vendor-setup-cont .form-section.phone-header .form-section-header, .slide-content-container .vendor-setup-cont .form-section.email-header .form-section-header, .slide-content-container .vendor-setup-cont .form-section.url-header .form-section-header, .slide-content-container .owner-setup-cont .form-section.phone-header .form-section-header, .slide-content-container .owner-setup-cont .form-section.email-header .form-section-header, .slide-content-container .owner-setup-cont .form-section.url-header .form-section-header, .slide-content-container .org-level-details .form-section.phone-header .form-section-header, .slide-content-container .org-level-details .form-section.email-header .form-section-header, .slide-content-container .org-level-details .form-section.url-header .form-section-header, .slide-content-container .system-settings-cont .form-section.phone-header .form-section-header, .slide-content-container .system-settings-cont .form-section.email-header .form-section-header, .slide-content-container .system-settings-cont .form-section.url-header .form-section-header {
                        margin-bottom: 0;
                    }

                    .slide-content-container .person-setup-cont .form-section.phone-header .form-section-children, .slide-content-container .person-setup-cont .form-section.email-header .form-section-children, .slide-content-container .person-setup-cont .form-section.url-header .form-section-children, .slide-content-container .user-setup-cont .form-section.phone-header .form-section-children, .slide-content-container .user-setup-cont .form-section.email-header .form-section-children, .slide-content-container .user-setup-cont .form-section.url-header .form-section-children, .slide-content-container .vendor-setup-cont .form-section.phone-header .form-section-children, .slide-content-container .vendor-setup-cont .form-section.email-header .form-section-children, .slide-content-container .vendor-setup-cont .form-section.url-header .form-section-children, .slide-content-container .owner-setup-cont .form-section.phone-header .form-section-children, .slide-content-container .owner-setup-cont .form-section.email-header .form-section-children, .slide-content-container .owner-setup-cont .form-section.url-header .form-section-children, .slide-content-container .org-level-details .form-section.phone-header .form-section-children, .slide-content-container .org-level-details .form-section.email-header .form-section-children, .slide-content-container .org-level-details .form-section.url-header .form-section-children, .slide-content-container .system-settings-cont .form-section.phone-header .form-section-children, .slide-content-container .system-settings-cont .form-section.email-header .form-section-children, .slide-content-container .system-settings-cont .form-section.url-header .form-section-children {
                        margin-bottom: 0;
                    }

                    .slide-content-container .person-setup-cont .form-section.phone-header .form-sub-section.url-item,
                    .slide-content-container .person-setup-cont .form-section.phone-header .form-sub-section .email-container,
                    .slide-content-container .person-setup-cont .form-section.email-header .form-sub-section.url-item,
                    .slide-content-container .person-setup-cont .form-section.email-header .form-sub-section .email-container,
                    .slide-content-container .person-setup-cont .form-section.url-header .form-sub-section.url-item,
                    .slide-content-container .person-setup-cont .form-section.url-header .form-sub-section .email-container,
                    .slide-content-container .user-setup-cont .form-section.phone-header .form-sub-section.url-item,
                    .slide-content-container .user-setup-cont .form-section.phone-header .form-sub-section .email-container,
                    .slide-content-container .user-setup-cont .form-section.email-header .form-sub-section.url-item,
                    .slide-content-container .user-setup-cont .form-section.email-header .form-sub-section .email-container,
                    .slide-content-container .user-setup-cont .form-section.url-header .form-sub-section.url-item,
                    .slide-content-container .user-setup-cont .form-section.url-header .form-sub-section .email-container,
                    .slide-content-container .vendor-setup-cont .form-section.phone-header .form-sub-section.url-item,
                    .slide-content-container .vendor-setup-cont .form-section.phone-header .form-sub-section .email-container,
                    .slide-content-container .vendor-setup-cont .form-section.email-header .form-sub-section.url-item,
                    .slide-content-container .vendor-setup-cont .form-section.email-header .form-sub-section .email-container,
                    .slide-content-container .vendor-setup-cont .form-section.url-header .form-sub-section.url-item,
                    .slide-content-container .vendor-setup-cont .form-section.url-header .form-sub-section .email-container,
                    .slide-content-container .owner-setup-cont .form-section.phone-header .form-sub-section.url-item,
                    .slide-content-container .owner-setup-cont .form-section.phone-header .form-sub-section .email-container,
                    .slide-content-container .owner-setup-cont .form-section.email-header .form-sub-section.url-item,
                    .slide-content-container .owner-setup-cont .form-section.email-header .form-sub-section .email-container,
                    .slide-content-container .owner-setup-cont .form-section.url-header .form-sub-section.url-item,
                    .slide-content-container .owner-setup-cont .form-section.url-header .form-sub-section .email-container,
                    .slide-content-container .org-level-details .form-section.phone-header .form-sub-section.url-item,
                    .slide-content-container .org-level-details .form-section.phone-header .form-sub-section .email-container,
                    .slide-content-container .org-level-details .form-section.email-header .form-sub-section.url-item,
                    .slide-content-container .org-level-details .form-section.email-header .form-sub-section .email-container,
                    .slide-content-container .org-level-details .form-section.url-header .form-sub-section.url-item,
                    .slide-content-container .org-level-details .form-section.url-header .form-sub-section .email-container,
                    .slide-content-container .system-settings-cont .form-section.phone-header .form-sub-section.url-item,
                    .slide-content-container .system-settings-cont .form-section.phone-header .form-sub-section .email-container,
                    .slide-content-container .system-settings-cont .form-section.email-header .form-sub-section.url-item,
                    .slide-content-container .system-settings-cont .form-section.email-header .form-sub-section .email-container,
                    .slide-content-container .system-settings-cont .form-section.url-header .form-sub-section.url-item,
                    .slide-content-container .system-settings-cont .form-section.url-header .form-sub-section .email-container {
                        width: 97%;
                    }

                .slide-content-container .person-setup-cont .form-section .copyright-text.form-input.text-form-input, .slide-content-container .user-setup-cont .form-section .copyright-text.form-input.text-form-input, .slide-content-container .vendor-setup-cont .form-section .copyright-text.form-input.text-form-input, .slide-content-container .owner-setup-cont .form-section .copyright-text.form-input.text-form-input, .slide-content-container .org-level-details .form-section .copyright-text.form-input.text-form-input, .slide-content-container .system-settings-cont .form-section .copyright-text.form-input.text-form-input {
                    width: 97%;
                }

            .slide-content-container .person-setup-cont .date-form-input.form-input.text-form-input, .slide-content-container .user-setup-cont .date-form-input.form-input.text-form-input, .slide-content-container .vendor-setup-cont .date-form-input.form-input.text-form-input, .slide-content-container .owner-setup-cont .date-form-input.form-input.text-form-input, .slide-content-container .org-level-details .date-form-input.form-input.text-form-input, .slide-content-container .system-settings-cont .date-form-input.form-input.text-form-input {
                width: 50%
            }

                .slide-content-container .person-setup-cont .date-form-input.form-input.text-form-input.employee-info-end-date, .slide-content-container .user-setup-cont .date-form-input.form-input.text-form-input.employee-info-end-date, .slide-content-container .vendor-setup-cont .date-form-input.form-input.text-form-input.employee-info-end-date, .slide-content-container .owner-setup-cont .date-form-input.form-input.text-form-input.employee-info-end-date, .slide-content-container .org-level-details .date-form-input.form-input.text-form-input.employee-info-end-date, .slide-content-container .system-settings-cont .date-form-input.form-input.text-form-input.employee-info-end-date {
                    margin-left: 0;
                }

                .slide-content-container .person-setup-cont .date-form-input.form-input.text-form-input.privacy-last-updated, .slide-content-container .user-setup-cont .date-form-input.form-input.text-form-input.privacy-last-updated, .slide-content-container .vendor-setup-cont .date-form-input.form-input.text-form-input.privacy-last-updated, .slide-content-container .owner-setup-cont .date-form-input.form-input.text-form-input.privacy-last-updated, .slide-content-container .org-level-details .date-form-input.form-input.text-form-input.privacy-last-updated, .slide-content-container .system-settings-cont .date-form-input.form-input.text-form-input.privacy-last-updated {
                    width: 100%;
                }

            .slide-content-container .person-setup-cont .person-filter.user-supervisor-search.select-form-input-container, .slide-content-container .user-setup-cont .person-filter.user-supervisor-search.select-form-input-container, .slide-content-container .vendor-setup-cont .person-filter.user-supervisor-search.select-form-input-container, .slide-content-container .owner-setup-cont .person-filter.user-supervisor-search.select-form-input-container, .slide-content-container .org-level-details .person-filter.user-supervisor-search.select-form-input-container, .slide-content-container .system-settings-cont .person-filter.user-supervisor-search.select-form-input-container {
                width: 50%;
                margin-bottom: 10px;
            }

            .slide-content-container .person-setup-cont .checkbox-div,
            .slide-content-container .person-setup-cont .checkbox-div.is-primary-container,
            .slide-content-container .user-setup-cont .checkbox-div,
            .slide-content-container .user-setup-cont .checkbox-div.is-primary-container,
            .slide-content-container .vendor-setup-cont .checkbox-div,
            .slide-content-container .vendor-setup-cont .checkbox-div.is-primary-container,
            .slide-content-container .owner-setup-cont .checkbox-div,
            .slide-content-container .owner-setup-cont .checkbox-div.is-primary-container,
            .slide-content-container .org-level-details .checkbox-div,
            .slide-content-container .org-level-details .checkbox-div.is-primary-container,
            .slide-content-container .system-settings-cont .checkbox-div,
            .slide-content-container .system-settings-cont .checkbox-div.is-primary-container {
                margin-left: 0px;
                margin-top: 12px;
                justify-content: flex-start;
            }

            .slide-content-container .person-setup-cont .checkbox-div.user-is-active, .slide-content-container .user-setup-cont .checkbox-div.user-is-active, .slide-content-container .vendor-setup-cont .checkbox-div.user-is-active, .slide-content-container .owner-setup-cont .checkbox-div.user-is-active, .slide-content-container .org-level-details .checkbox-div.user-is-active, .slide-content-container .system-settings-cont .checkbox-div.user-is-active {
                margin-top: 12px;
                margin-bottom: 0;
            }

            .slide-content-container .person-setup-cont .time-zone-select, .slide-content-container .user-setup-cont .time-zone-select, .slide-content-container .vendor-setup-cont .time-zone-select, .slide-content-container .owner-setup-cont .time-zone-select, .slide-content-container .org-level-details .time-zone-select, .slide-content-container .system-settings-cont .time-zone-select {
                margin-bottom: 5px;
            }

        .slide-content-container .section-container .form-section .form-sub-section.copyright-policy-sub-section {
            margin-bottom: 10px;
        }
            .slide-content-container .owner-setup-cont .form-section-children {
                margin-bottom: 10px;
            }
}

@media screen and (max-width: 440px) {
    .slide-content-container {
            & .person-setup-cont & .user-setup-cont.form-section, & .vendor-setup-cont.form-section, & .owner-setup-cont.form-section, & .org-level-details.form-section, & .system-settings-cont.form-section {
                width: auto;
            }
    }
}


    .form-sub-section .email-container {
        width: 100%;
    }
.form-sub-section .is-primary-container {
        display: flex;
        justify-content: flex-end;
        width: 100px;
    }
.email-header .form-section-header {
        margin-bottom: 5px;
    }
.email-header .form-section-children {
        margin-bottom: 5px;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.community-data-container {
    font-size: 13px
}
.community-data-container .community-data-label {
        padding-bottom: 10px;
    }
.community-data-container .checkbox-div {
        width: auto;
    }
.community-customer {
    width: 130px;
}
.community-data-pool {
    width: 100px;
}
.owner-setup-cont .form-sub-section .migrate-to-customer-select {
        width: 100%;
        margin-top: 10px;
    }
.theme-selector .form-sub-section {
        min-height: 80px;
    }
.theme-selector .theme-control-cont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%
    }
.theme-selector .theme-control-cont .radio-div {
            width: 50px;
            height: 65px;
            display: flex;
            flex-direction: column;
            margin: 8px 10px
        }
.theme-selector .theme-control-cont .radio-div .radio-label {
                min-height: 35px;
                min-width: 35px;
            }
.theme-selector .theme-control-cont .radio-div .radio-button-container {
                margin: 0px 35% 8px 35%;
            }
.add-edit-levels-link {
    font-size: 13px;
}
.add-level-sub-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto 15px auto
}
.add-level-sub-section .link-add-level {
        width: 100%;
        text-align: right;
        font-family: "open_sansregular";
        font-size: 13px;
        padding-bottom: 7px;
    }
.add-level-sub-section .level-name-input {
        width: 96%;
    }
.policy-section .date-form-input-container {
        max-width: 50%;
        width: 50%;
    }
.policy-section .form-sub-section .form-input.privacy-policy-title {
            width: 45%;
            margin-right: 10px;
        }
.policy-section .form-sub-section .form-input.privacy-last-updated {
            width: 95%;
        }
.form-sub-section .report-logo-checkbox {
        margin: 0px;
        width: auto;
    }
.form-sub-section .form-input.owner-email-input:only-child {
    width: 75.5%;
}
.sliding-panel-header .button-container .default-button.cancel-button.owner-admin-cancel {
    margin-right: 3px;
}
.default .theme-selector .radio-div .default.radio-label {
                background-color: #000000;
                border: 2px solid #737373;
            }
.default-light .theme-selector .radio-div .default-light.radio-label {
                background-color: #fcfcfc;
                border: 2px solid #b8b8b8;
            }
.sky .theme-selector .radio-div .sky.radio-label {
                background-color: #fcfcfc;
                border: 2px solid #838383;
            }
.sky-light .theme-selector .radio-div .sky-light.radio-label {
                background-color: #fcfcfc;
                border: 2px solid #838383;
            }
.grass .theme-selector .radio-div .grass.radio-label {
                background-color: #667c66;
                border: 2px solid #424443;
            }
.grass-light .theme-selector .radio-div .grass-light.radio-label {
                background-color: #babfba;
                border: 2px solid #bfbfbf;
            }
.mud .theme-selector .radio-div .mud.radio-label {
                background-color: #4b3705;
                border: 2px solid #ad8f68;
            }
.mud-light .theme-selector .radio-div .mud-light.radio-label {
                background-color: #fff0bb;
                border: 2px solid #735523;
            }
.illini .theme-selector .radio-div .illini.radio-label {
                background-color: #00467f;
                border: 2px solid #3698db;
            }
.illini-light .theme-selector .radio-div .illini-light.radio-label {
                background-color: #e1e6fa;
                border: 2px solid #00467f;
            }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.org-level-grid-container {
    margin-left: auto;
    margin-right: auto;
    width: 1240px
}
.org-level-grid-container .org-level-nested-row {
        overflow-y: auto
    }
.org-level-grid-container .org-level-nested-row .data-table-paginator {
            padding-left: 30px;
        }
.org-level-grid-container .org-level-header-cont {
        display: flex;
        justify-content: flex-end;
        float: right;
        width: 50%;
    }
.org-level-grid-container .org-level-table.child-component .content-table-container.data-table-cont.nested-data-table {
            width: 98%
        }
.org-level-grid-container .org-level-table.child-component .content-table-container.data-table-cont.nested-data-table
                .data-table-row.data-table-header
                .data-table-cell.data-table-cell-relative.data-table-container {
                width: 10%;
                padding-left: 0px;
            }
.org-level-grid-container .org-level-table.child-component .content-table-container.data-table-cont.nested-data-table .nested-table-row .data-table-container {
                width: 5%
            }
.org-level-grid-container .org-level-table.child-component .content-table-container.data-table-cont.nested-data-table .nested-table-row .data-table-container.no-arrow {
                    width: 10%;
                }
.org-level-grid-container .org-level-table.child-component .content-table-container.data-table-cont.nested-data-table .data-table-rows {
                overflow-y: hidden;
            }
.org-level-grid-container .org-level-table.child-component.non-editable-grid .data-table-row.data-table-header {
            padding-left: 50px;
        }
.org-level-grid-container .nested-row-icon {
        cursor: pointer;
        line-height: 2;
    }
.org-level-grid-container .footer-bar {
        width: 100%;
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-left: auto;
        margin-right: auto;
    }
.org-level-grid-container .data-table-container.location-icon-box {
        width: 107px;
    }
.org-level-grid-container .location-table-row .data-table-container.location-icon-box {
        width: 50px;
    }
.org-level-grid-container .company-table-header .empty-cell {
        width: 65px;
    }
.owner-add-edit-page {
    width: 1240px;
    margin: auto;
}
.sliding-panel-header .button-container .default-button.cancel-button.org-level-cancel {
        margin-right: 1px;
    }
.sliding-panel-header .button-container .default-button.cancel-button.owner-cancel {
        margin-right: 4px;
    }
.default .footer-bar {
            background-color: #404040;
        }
.default-light .footer-bar {
            background-color: #919191;
        }
.sky .footer-bar {
            background-color: #0194d3;
        }
.sky-light .footer-bar {
            background-color: #0194d3;
        }
.grass .footer-bar {
            background-color: #325132;
        }
.grass-light .footer-bar {
            background-color: #545f54;
        }
.mud .footer-bar {
            background-color: #766146;
        }
.mud-light .footer-bar {
            background-color: #a37b42;
        }
.illini .footer-bar {
            background-color: #636466;
        }
.illini-light .footer-bar {
            background-color: #375d81;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.owner-table {
    margin-top: 5px;
}
.default .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.default-light .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.sky .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.sky-light .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.grass .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.grass-light .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.mud .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.mud-light .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.illini .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.illini-light .owner-table .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }


    .form-sub-section .license-type {
        width: 30%;
    }
.form-sub-section .license-number {
        width: 70%;
    }
.form-sub-section .license-expiration-date.form-input {
        width: 130px;
    }
.form-sub-section .license-country {
        width: 150px;
    }
.form-sub-section .license-state {
        width: 70px;
    }
.license-header .form-section-header {
        margin-bottom: 0px;
    }
.license-header .sub-description-container {
        margin-bottom: 7px;
    }
.license-container {
    margin-bottom: 5px;
}
.license-table .data-header-cell .data-table-cell {
        max-width: 150px;
    }

.person-section {
    margin-left: 25px
}
.person-section .job-container .jobtitle-container {
            width: 300px;
        }
.person-section .job-container .checkbox-div {
            width: 20%;
        }

.filter-header-container {
    display: flex;
    width: 1240px;
    margin: auto;
    margin-bottom: 5px;
    min-height: 60px
}

.filter-header-container .filter-container {
        width: 50%;
    }

.filter-header-container .active-inactive-container {
        width: 50%;
    }

.sliding-panel-header .button-container .default-button.cancel-button.person-cancel {
    margin-right: 4px;
}


        .setup-season-filter .season-filter-grid .data-table-header {
            height: 20px;
            padding-right: 16px;
        }
.setup-season-filter .season-filter-grid .data-header-cell {
            max-width: none;
            width: 10%;
            line-height: normal !important
        }
.setup-season-filter .season-filter-grid .data-header-cell .data-table-cell {
                text-transform: capitalize;
            }
.setup-season-filter .season-filter-grid .data-table-cell {
            max-width: none
        }
.setup-season-filter .season-filter-grid .data-table-cell.target-type {
                padding-left: 19px;
                width: calc(40% - 19px);
            }
.setup-season-filter .season-filter-grid .data-table-cell.filter-type {
                padding-left: 12px;
                width: calc(40% - 12px);
            }
.setup-season-filter .season-filter-grid .plain-table-body {
            margin-bottom: 0;
        }
.setup-season-filter .season-filter-grid .target-type {
            width: 40%;
        }
.setup-season-filter .season-filter-grid .filter-type {
            width: 40%;
        }
.setup-season-filter .add-link-container {
        font-size: 13px;
        margin-top: 6px
    }
.setup-season-filter .add-link-container.no-table {
            margin-top: 10px;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.user-preference-main-container .theme-selector {
        margin-bottom: 10px
    }
.user-preference-main-container .theme-selector .form-sub-section {
            min-height: 80px;
        }
.user-preference-main-container .theme-selector .theme-control-cont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 145px;
            margin-left: auto;
            margin-right: auto;
            justify-content: flex-start
        }
.user-preference-main-container .theme-selector .theme-control-cont .radio-div {
                width: 50px;
                height: 65px;
                display: flex;
                flex-direction: column;
                margin: 8px 10px
            }
.user-preference-main-container .theme-selector .theme-control-cont .radio-div .radio-label {
                    min-height: 35px;
                    min-width: 35px;
                }
.user-preference-main-container .theme-selector .theme-control-cont .radio-div .radio-button-container {
                    margin: 8px 35%;
                }
.user-preference-main-container .notification-settings {
        display: flex;
        flex-grow: 2;
        flex-direction: row
    }
.user-preference-main-container .notification-settings .options-header {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 14px;
            margin-left: 5px;
        }
.user-preference-main-container .notification-settings .notification-section {
            width: 190px;
        }
.user-preference-main-container .receive-notification-row {
        display: flex;
        margin: 3px 5px;
    }
.user-preference-main-container .onsite-notifications {
        display: flex;
        margin: 3px 5px;
    }
.user-preference-main-container .onsite-autoimport-container {
        display: flex;
        align-items: center;
        margin: 3px 5px;
        padding-bottom: 10px;
        height: 45px
    }
.user-preference-main-container .onsite-autoimport-container .multi-select-container {
            min-width: 242px;
            max-width: 242px;
            margin-left: 10px
        }
.user-preference-main-container .onsite-autoimport-container .multi-select-container .form-input {
                padding-bottom: 0px;
                position: relative;
                top: -9px;
                left: -9px;
                width: 230px;
            }
.user-preference-main-container .onsite-autoimport-container .multi-select-container .dropdown-menu {
                position: relative;
                top: -14px;
            }
.user-preference-main-container .season-filter-section {
        margin-bottom: 6px
    }
.user-preference-main-container .season-filter-section .form-section-header {
            margin-bottom: 0;
        }
.user-preference-main-container .season-filter-section.no-table .form-section-header {
            height: 30px;
            padding-bottom: 5px;
        }
.user-preference-main-container .season-filter-section .setup-season-filter {
            margin-left: 5px;
        }
.user-preference-main-container .attribute-color-override-section {
        margin-bottom: 6px
    }
.user-preference-main-container .attribute-color-override-section .form-section-header {
            margin-bottom: 0;
        }
.user-preference-main-container .attribute-color-override-section.no-table .form-section-header {
            height: 30px;
            padding-bottom: 5px;
        }
.user-preference-main-container .attribute-color-override-section .setup-attribute-color-override {
            margin-left: 5px;
        }
.user-preference-main-container .menu-open .form-input {
            width: 356px;
        }
.default .theme-selector .radio-div .radio-label {
        border: 2px solid #737373;
    }
.default.radio-label {
        background-color: #000000;
    }
.default-light .theme-selector .radio-div .radio-label {
        border: 2px solid #b8b8b8;
    }
.default-light.radio-label {
        background-color: #fcfcfc;
    }
.sky .theme-selector .radio-div .radio-label {
        border: 2px solid #838383;
    }
.sky.radio-label {
        background-color: #fcfcfc;
    }
.sky-light .theme-selector .radio-div .radio-label {
        border: 2px solid #838383;
    }
.sky-light.radio-label {
        background-color: #fcfcfc;
    }
.grass .theme-selector .radio-div .radio-label {
        border: 2px solid #424443;
    }
.grass.radio-label {
        background-color: #667c66;
    }
.grass-light .theme-selector .radio-div .radio-label {
        border: 2px solid #bfbfbf;
    }
.grass-light.radio-label {
        background-color: #babfba;
    }
.mud .theme-selector .radio-div .radio-label {
        border: 2px solid #ad8f68;
    }
.mud.radio-label {
        background-color: #4b3705;
    }
.mud-light .theme-selector .radio-div .radio-label {
        border: 2px solid #735523;
    }
.mud-light.radio-label {
        background-color: #fff0bb;
    }
.illini .theme-selector .radio-div .radio-label {
        border: 2px solid #3698db;
    }
.illini.radio-label {
        background-color: #00467f;
    }
.illini-light .theme-selector .radio-div .radio-label {
        border: 2px solid #00467f;
    }
.illini-light.radio-label {
        background-color: #e1e6fa;
    }
.sky .theme-selector .radio-div .radio-label {
        border: 2px solid #838383;
    }
.sky.radio-label {
        background-color: #fcfcfc;
    }
.sky-light .theme-selector .radio-div .radio-label {
        border: 2px solid #838383;
    }
.sky-light.radio-label {
        background-color: #fcfcfc;
    }


        .setup-attribute-color-override .attribute-color-override-grid .data-table-header {
            height: 20px;
            padding-right: 16px;
        }
.setup-attribute-color-override .attribute-color-override-grid .data-header-cell {
            max-width: none;
            width: 25%;
            padding-left: 0px;
            line-height: normal !important
        }
.setup-attribute-color-override .attribute-color-override-grid .data-header-cell .data-table-cell {
                text-transform: capitalize;
            }
.setup-attribute-color-override .attribute-color-override-grid .data-header-cell .data-table-cell-relative {
                padding-left: 0px;
            }
.setup-attribute-color-override .attribute-color-override-grid .data-header-cell.attribute {
                padding-left: 12px;
            }
.setup-attribute-color-override .attribute-color-override-grid .data-header-cell.color {
                max-width: 5%;
            }
.setup-attribute-color-override .attribute-color-override-grid .data-table-row {
            padding-right: 16px;
        }
.setup-attribute-color-override .attribute-color-override-grid .data-table-cell {
            max-width: none
        }
.setup-attribute-color-override .attribute-color-override-grid .data-table-cell.attribute-type {
                padding-left: 0px;
                width: calc(25% - 19px);
            }
.setup-attribute-color-override .attribute-color-override-grid .data-table-cell.attribute {
                padding-left: 19px;
                width: calc(25% - 19px);
            }
.setup-attribute-color-override .attribute-color-override-grid .data-table-cell.color {
                padding-left: 8px;
                max-width: 16px;
            }
.setup-attribute-color-override .attribute-color-override-grid .plain-table-body {
            margin-bottom: 0;
        }
.setup-attribute-color-override .color-picker-container {
        padding: 0 50px 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
    }
.setup-attribute-color-override .add-link-container {
        font-size: 13px;
        margin-top: 6px
    }
.setup-attribute-color-override .add-link-container.no-table {
            margin-top: 10px;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.user-section {
    display: flex;
    justify-content: space-between
}
.user-section .select-form-input-container {
        width: 183px;
    }
.user-section .user-request-password {
        display: block;
        font-family: "open_sansregular";
        font-size: 13px;
    }
.form-sub-section .user-is-active {
        width: 100px;
    }
.form-sub-section .jobtitle-container {
        width: 100%;
    }
.view-user-role-container {
    display: flex;
    justify-content: flex-end;
    font-family: "open_sansregular";
    font-size: 13px;
}
.sliding-panel-header .button-container .default-button.cancel-button.user-cancel {
    margin-right: 4px;
}
.default .sales-person-id-error a:visited {
            color: #ff0000;
        }
.default .sales-person-id-error a:link {
            color: #ff0000;
        }
.default .sales-person-id-error a:active {
            color: #ff0000;
        }
.default-light .sales-person-id-error a:visited {
            color: #ff0000;
        }
.default-light .sales-person-id-error a:link {
            color: #ff0000;
        }
.default-light .sales-person-id-error a:active {
            color: #ff0000;
        }
.sky .sales-person-id-error a:visited {
            color: #ff0000;
        }
.sky .sales-person-id-error a:link {
            color: #ff0000;
        }
.sky .sales-person-id-error a:active {
            color: #ff0000;
        }
.sky-light .sales-person-id-error a:visited {
            color: #ff0000;
        }
.sky-light .sales-person-id-error a:link {
            color: #ff0000;
        }
.sky-light .sales-person-id-error a:active {
            color: #ff0000;
        }
.grass .sales-person-id-error a:visited {
            color: #ff0000;
        }
.grass .sales-person-id-error a:link {
            color: #ff0000;
        }
.grass .sales-person-id-error a:active {
            color: #ff0000;
        }
.grass-light .sales-person-id-error a:visited {
            color: #ff0000;
        }
.grass-light .sales-person-id-error a:link {
            color: #ff0000;
        }
.grass-light .sales-person-id-error a:active {
            color: #ff0000;
        }
.mud .sales-person-id-error a:visited {
            color: #ff0000;
        }
.mud .sales-person-id-error a:link {
            color: #ff0000;
        }
.mud .sales-person-id-error a:active {
            color: #ff0000;
        }
.mud-light .sales-person-id-error a:visited {
            color: #ff0000;
        }
.mud-light .sales-person-id-error a:link {
            color: #ff0000;
        }
.mud-light .sales-person-id-error a:active {
            color: #ff0000;
        }
.illini .sales-person-id-error a:visited {
            color: #ff0000;
        }
.illini .sales-person-id-error a:link {
            color: #ff0000;
        }
.illini .sales-person-id-error a:active {
            color: #ff0000;
        }
.illini-light .sales-person-id-error a:visited {
            color: #ff0000;
        }
.illini-light .sales-person-id-error a:link {
            color: #ff0000;
        }
.illini-light .sales-person-id-error a:active {
            color: #ff0000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.user-role-details {
    font-size: 13px
}
.user-role-details .accesslevel-container {
        width: 300px;
    }
.user-role-details .sub-header {
        font-family: "open_sansitalic";
        font-size: 11px;
    }
.user-role-details .header-section {
        margin: 5px 0px;
    }
.user-role-details .red-star {
        font-size: 15px;
        font-weight: 900;
    }
.default .red-star {
            color: #ff0000;
        }
.default-light .red-star {
            color: #ff0000;
        }
.sky .red-star {
            color: #ff0000;
        }
.sky-light .red-star {
            color: #ff0000;
        }
.grass .red-star {
            color: #ff0000;
        }
.grass-light .red-star {
            color: #ff0000;
        }
.mud .red-star {
            color: #ff0000;
        }
.mud-light .red-star {
            color: #ff0000;
        }
.illini .red-star {
            color: #ff0000;
        }
.illini-light .red-star {
            color: #ff0000;
        }

.user-role-table {
    margin-top: 5px;
}

.select-company-container {
    flex-direction: column;
    margin-bottom: 0
}
.select-company-container .select-company-message {
        margin: 5px 0;
    }
.select-company-container .select-form-input-container {
        width: 97.5%;
    }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.system-profile-main-container .slide-content-container {
        display: block
    }
.system-profile-main-container .slide-content-container .add-edit-panel.system-settings-cont {
            overflow: visible;
            padding-top: 15px;
            margin: 0 auto;
        }
.system-profile-main-container .system-profile-section {
        width: 33.33%;
    }
.system-profile-main-container .form-sub-section .copyright-text.form-input {
            width: 100%;
        }
.system-profile-main-container .form-sub-section .policy-last-updated.form-input {
            width: 95%;
        }
.system-profile-main-container .grid-section .form-section-header {
        margin-bottom: 10px;
    }
.default .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.default-light .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.sky .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.sky-light .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.grass .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.grass-light .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.mud .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.mud-light .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.illini .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }
.illini-light .system-settings-cont .photo-simple.photo-simple-error .add-link .menu-items-text {
            color: #ff0000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.template-manager-cont {
    margin: 15px auto;
}
.slide-content-container .content-table-container.template-manager-cont .slide-content-container {
    display: flex;
}
.template-manager-grid {
    display: flex;
    flex-direction: column;
    min-width: 1048px;
    margin-left: 30px
}
.template-manager-grid .select-form-input-container {
        margin: 0px;
        width: 175px;
        height: 25px
    }
.template-manager-grid .select-form-input-container .select-form-input.form-input.text-form-input {
            margin: 0px;
            height: 25px
        }
.template-manager-grid .select-form-input-container .select-form-input.form-input.text-form-input .input-label-container {
                min-height: 0px;
                line-height: 0px;
            }
.template-manager-grid .select-form-input-container .select-form-input-options {
            margin: 0px;
        }
.template-manager-grid-cell {
    width: 133px;
    height: 27px;
    line-height: 27px;
    overflow: hidden;
    padding-left: 5px;
    font-size: 13px;
    flex-grow: 1;
}
.template-manager-tabs {
    display: flex;
    flex-direction: column
}
.template-manager-tabs .tab-label {
        height: 40px;
        font-size: 18px;
        line-height: 45px;
        max-width: 194px;
    }
.template-manager-tabs .tabs-labels {
        flex-basis: 60%;
        padding-left: 30px;
        max-height: 50px;
        background-clip: content-box;
    }
.template-manager-tabs .tab-pane {
        display: flex;
        flex: 1 1;
    }
.grid-header {
    display: flex;
    justify-content: space-around;
    min-height: 21px;
    margin-top: 10px;
    margin-bottom: 5px
}
.grid-header .checkbox-div {
        width: auto;
    }
.grid-header-radio-group {
    display: flex
}
.grid-header-radio-group .radio-div {
        height: 25px;
        width: 115px;
        font-size: 13px;
    }
.template-manager-grid-container {
    display: block;
    overflow-y: auto;
    overflow-x: hidden
}
.template-manager-grid-container .template-manager-grid-table {
        display: flex;
        flex-grow: 1;
        overflow-y: visible;
    }
.import-attribute-cont {
    display: flex;
    padding-bottom: 3px
}
.import-attribute-cont .select-form-input.form-input.text-form-input {
        border: none;
        padding: 0px;
    }
.import-attribute-cont .counter-cont {
        width: 25px;
        text-align: right;
        line-height: 25px;
        padding-right: 3px;
    }
.import-attribute-cont div.text-form-input .input-container input {
        font-size: 13px;
    }
.file-metadata-cont {
    width: 200px;
    display: flex;
    flex-direction: column
}
.file-metadata-cont .free-text {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        font-family: "open_sansregular";
        font-size: 13px;
        font-weight: 300;
        line-height: 1.5
    }
.file-metadata-cont .free-text .checkbox-div {
            width: 100%;
        }
.file-metadata-cont button {
        margin: 10px;
        width: 105px;
    }
.file-metadata-cont .form-input.text-form-input.delimiter-container {
        width: 88px;
    }
.file-metadata-cont .form-input.text-form-input {
        margin: 10px 10px 0px 10px;
        width: 181px;
    }
.file-metadata-cont .select-form-input-container {
        width: 100%;
    }
.file-metadata-cont .select-form-input-options {
        margin-left: 10px;
    }
.file-metadata-cont .vendor-grid-cont {
        margin-top: 10px;
        width: 80%
    }
.file-metadata-cont .vendor-grid-cont .plain-data-table.cell-stretch {
            width: 216px;
        }
.file-metadata-cont .file-type-select .select-form-input.form-input.text-form-input {
            margin-top: 0px;
            margin-bottom: 10px;
        }
.default .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #737373;
            }
.default .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #262626;
            }
.default .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #262626;
            }
.default .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.default-light .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #b8b8b8;
            }
.default-light .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #e2e2e2;
            }
.default-light .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #e2e2e2;
            }
.default-light .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.sky .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #838383;
            }
.sky .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #b1b1b1;
            }
.sky .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #b1b1b1;
            }
.sky .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #afafaf;
            }
.sky-light .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #838383;
            }
.sky-light .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #b1b1b1;
            }
.sky-light .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #b1b1b1;
            }
.sky-light .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #afafaf;
            }
.grass .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #424443;
            }
.grass .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #424443;
            }
.grass .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #424443;
            }
.grass .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.grass-light .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #bfbfbf;
            }
.grass-light .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #9cadb2;
            }
.grass-light .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #9cadb2;
            }
.grass-light .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.mud .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #ad8f68;
            }
.mud .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #8c7454;
            }
.mud .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #8c7454;
            }
.mud .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.mud-light .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #735523;
            }
.mud-light .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #b28f5a;
            }
.mud-light .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #b28f5a;
            }
.mud-light .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.illini .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #3698db;
            }
.illini .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #abc8e2;
            }
.illini .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #abc8e2;
            }
.illini .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }
.illini-light .template-manager-cont .template-manager-grid .is-header-row {
                background-color: #00467f;
            }
.illini-light .template-manager-cont .template-manager-grid .grid-row {
                display: flex;
                border-bottom: 1px solid #c4d7ed;
            }
.illini-light .template-manager-cont .template-manager-grid .import-attribute-cont {
                border-bottom: 1px solid #c4d7ed;
            }
.illini-light .template-manager-cont .template-manager-tabs .tabs-labels {
                background-color: #404040;
            }

.email-container.form-input:only-child {
    width: 100%;
}

& .form-sub-section {
    margin-bottom: 9px;
}

.user-section {
    display: flex;
    justify-content: space-between
}
.user-section .select-form-input-container {
        width: 183px;
    }
.user-section .user-request-password {
        display: block;
        font-family: "open_sansregular";
        font-size: 13px;
    }
.form-sub-section .user-is-active {
        width: 100px;
    }
.form-sub-section .jobtitle-container {
        width: 100%;
    }
.form-sub-section .full-width-input {
        width: 100% !important;
    }
.form-section-children .extra-margin {
    margin-bottom: 10px !important;
}
.view-user-role-container {
    display: flex;
    justify-content: flex-end;
    font-family: "open_sansregular";
    font-size: 13px;
}
.no-link-style {
    padding-bottom: 5px;
    font-size: 13px;
}
.sliding-panel-header .button-container .default-button.cancel-button.vendor-cancel {
    margin-right: 4px;
}

.section-container .no-bar.section-fence.nutrient-target-active {
    margin-left: 28px
}

.section-container .no-bar.section-fence.nutrient-target-active .form-sub-section.form-section-row-2 {
        margin-top: 57px;
    }

& .set-margin {
    margin-top: 65px;
}

.report-book-options-table {
    margin-top: 10px;
    display: flex
}
.report-book-options-table .dialog-window .report-book-options-error-container {
            display: flex;
            flex-direction: column
        }
.report-book-options-table .dialog-window .report-book-options-error-container .report-book-options-error-header {
                font-size: 16px;
                margin-bottom: 10px
            }
.report-book-options-table .dialog-window .report-book-options-error-container .report-book-options-error-header .config-name {
                    font-weight: bold;
                }
.report-book-options-table .dialog-window .report-book-options-error-container p {
                margin-bottom: 5px;
            }

.active-inactive-container {
    width: 1240px;
    margin: -15px auto 10px;
}

.setup-report-books-table {
    margin-top: 5px;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.with-report-container {
    display: flex;
    flex-grow: 1;
    max-height: calc(100% - 1px)
}
.with-report-container p.error-message {
        white-space: pre-wrap;
    }
.reports-container {
    flex-grow: 1;
    background-color: transparent;
    padding-left: 15px;
    display: flex;
    flex-direction: column
}
.reports-container .report-grid-col {
        width: 38%;
        max-width: 200px;
    }
.reports-container .email-container.form-input.text-form-input {
        width: 50%;
    }
.reports-container .content-table-container.data-table-cont.reports-table-cont {
        flex-basis: 180px;
    }
.reports-container .options-header {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
    }
.reports-container .report-options-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
.reports-container .report-table-header {
        padding-top: 28px;
        padding-bottom: 14px;
    }
.reports-container .reports-table-cont {
        min-width: 99%;
        width: 99%;
        margin: 0;
        margin-right: 0px;
        flex-basis: 180px
    }
.reports-container .reports-table-cont .data-table-rows:empty {
                height: 20px;
            }
.reports-container .category-section {
        margin-left: 15px;
    }
.reports-container .report-grid-cont {
        flex-grow: 1;
        display: flex;
        flex-direction: column
    }
.reports-container .report-grid-cont .season-column {
            max-width: 76px;
        }
.reports-container .report-options-accordion-item .expand-collapse-icon {
            height: 14px;
            width: 13px;
        }
.reports-container .report-options-accordion-item .collapse-button-rotate-90 {
            transform: rotate(-90deg);
        }
.reports-container .report-options-accordion-item .collapse-button-rotate-45 {
            transform: rotate(-45deg);
        }
.reports-container .reports-options-container {
        display: flex;
        max-height: 50%
    }
.reports-container .reports-options-container .report-options-inner-container {
            padding: 10px;
            flex-direction: columns;
            flex: 1 1;
            overflow-y: auto
        }
.reports-container .reports-options-container .report-options-inner-container .report-options {
                padding: 0;
            }
.reports-container .reports-options-container .report-options-inner-container .date-form-input-container:last-child {
                margin-right: 0;
            }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container {
                display: flex;
                flex-direction: column;
                flex: 1 1;
                padding-bottom: 10px
            }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .report-options-content {
                    min-height: unset;
                }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .bucket {
                    width: 384px;
                    margin: 2.5px 0;
                    padding-left: 0
                }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .bucket .bucket-header {
                        background-color: #404040 !important;
                        color: white;
                        padding: 0 10px;
                        min-height: 40px;
                        min-height: 40px;
                        line-height: 40px;
                        font-size: 13px
                    }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .bucket .bucket-header .bucket-symbol {
                            float: right;
                        }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .bucket .report-options {
                        padding: 5px;
                        padding-bottom: 0
                    }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .bucket .report-options .form-sub-section:last-child {
                            margin-bottom: 0;
                        }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .bucket .report-options .checkbox-div {
                            margin-top: 12px;
                            margin-bottom: 0px;
                            width: 48%;
                        }
.reports-container .reports-options-container .report-options-inner-container .report-options-accordion-container .report-options-accordion-item-header {
                    cursor: pointer;
                    padding: 5px;
                    padding-left: 10px;
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #000000;
                    font-size: 16px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
.reports-container .reports-options-container .report-options-inner-container {

            .create-button {
                margin-left: 0px;
                height: 37px;
            }
        }
.reports-container .reports-options-container .report-types {
            flex: 1 1;
            padding: 15px;
            overflow-y: auto
        }
.reports-container .reports-options-container .report-types .checkbox-div {
                margin-bottom: 5px;
            }
.reports-container .reports-options-container .report-options {
            padding: 15px;
            flex-direction: column;
            flex: 1 1;
            overflow-y: auto
        }
.reports-container .reports-options-container .report-options .date-form-input-container {
                margin-right: 8px;
                min-width: 49%
            }
.reports-container .reports-options-container .report-options .date-form-input-container .date-form-input.form-input.text-form-input {
                    margin-right: 10px;
                }
.reports-container .reports-options-container .report-options .date-form-input-container .rdtPicker {
                    position: fixed;
                }
.reports-container .reports-options-container .report-options .report-options-content {
                min-height: 180px
            }
.reports-container .reports-options-container .report-options .report-options-content .select-form-input-container {
                    width: auto;
                }
.reports-container .reports-options-container .report-options .crop-map-variety-crop-label {
                padding-left: 6px;
            }
.reports-container .reports-options-container .report-options .form-sub-section.two-report-options {
                    flex-wrap: wrap;
                    grid-column-gap: 11px;
                    column-gap: 11px;
                    grid-row-gap: 10px;
                    row-gap: 10px
                }
.reports-container .reports-options-container .report-options .form-sub-section.two-report-options .select-form-input-container {
                        margin-right: 0px;
                        padding-right: 7px;
                    }
.reports-container .reports-options-container .report-options .form-sub-section.two-report-options .checkbox-div {
                        margin-top: 12px;
                        margin-bottom: 0px !important;
                        width: 48%;
                    }
.reports-container .reports-options-container .report-options .form-sub-section.two-report-options .page-selection {
                        padding-top: 0px;
                        margin-right: 7px;
                        width: calc(50% - 15px);
                    }
.reports-container .reports-options-container .report-options .form-sub-section.two-report-options .nonField-features-checkbox {
                        width: 46%;
                    }
.reports-container .reports-options-container .report-options .form-sub-section .categories-link {
                    font-size: 13px;
                    line-height: 44px;
                    width: 50%
                }
.reports-container .reports-options-container .report-options .form-sub-section .categories-link.report-options-modal-link {
                        line-height: 25px;
                        margin-top: 10px;
                        margin-bottom: auto;
                        padding-bottom: 0px;
                    }
.reports-container .reports-options-container .report-options .form-sub-section .yield-basemap-select {
                    width: 183px !important;
                }
.reports-container .reports-options-container .report-options .form-sub-section .select-form-input-container + .categories-link {
                    margin-left: 2px;
                }
.reports-container .reports-options-container .report-options .form-sub-section.create-button button {
                    margin-left: 0px;
                    height: 37px;
                }
.reports-container .reports-options-container .report-options .form-sub-section .select-form-input-container.display-options-list {
                    width: 183px;
                }
.reports-container .reports-options-container .report-options .form-sub-section.field-symbology-options {
                    width: 100%;
                    height: 35px !important;
                    line-height: 25px;
                    margin-bottom: 0 !important
                }
.reports-container .reports-options-container .report-options .form-sub-section.field-symbology-options > a {
                        margin: 10px 0 auto 4px !important;
                        width: 197px !important;
                        font-size: 13px;
                    }
.reports-container .reports-options-container .report-options .form-sub-section.field-symbology-options .checkbox-div {
                        margin-top: auto !important;
                        margin-bottom: 0 !important;
                    }
.reports-container .reports-options-container .report-options .form-sub-section.two-wide {
                    display: flex;
                    flex-flow: row wrap
                }
.reports-container .reports-options-container .report-options .form-sub-section.two-wide > div,
                    .reports-container .reports-options-container .report-options .form-sub-section.two-wide > a {
                        flex: 0 1 auto;
                        width: 46.5%;
                        margin-bottom: 10px
                    }
.reports-container .reports-options-container .report-options .form-sub-section.two-wide > div.form-section-child-stretch, .reports-container .reports-options-container .report-options .form-sub-section.two-wide > a.form-section-child-stretch {
                            width: 100%;
                        }
.reports-container .reports-options-container .report-options .form-sub-section.two-wide > div:nth-child(even), .reports-container .reports-options-container .report-options .form-sub-section.two-wide > a:nth-child(even) {
                            padding-right: 3px;
                        }
.reports-container .reports-options-container .report-options .form-sub-section.two-wide.force-wrap > div:nth-child(even) {
                        margin-right: 0;
                    }
.reports-container .reports-options-container .report-options .form-sub-section .event-id-checkbox {
                    margin-right: 7px;
                }
.reports-container .reports-options-container .report-options .form-sub-section .crop-yield-link-empty {
                    margin-right: 7px;
                }
.reports-container .reports-options-container .report-options .form-sub-section .legend-type-radio-group {
                    width: 50%
                }
.reports-container .reports-options-container .report-options .form-sub-section .legend-type-radio-group .legend-type-radio-group-header {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
.reports-container .reports-options-container .report-options .form-sub-section .tree-view-parent .checkbox-div {
                        margin-top: 0px !important;
                        margin-bottom: 0px !important;
                    }
.reports-container .reports-options-container .report-options .farm-map-label-color-options {
                margin-left: 4px;
                width: calc(50% - 15px);
            }
.reports-container .reports-options-container .report-options .crop-yield-dialog {
                width: 415px;
            }
.reports-container .reports-table-cont .data-table-checkbox {
        width: 5%;
        max-width: 5%;
    }
.reports-container .report-viewer {
        flex: 1 1;
        height: 100%;
        display: flex
    }
.reports-container .report-viewer iframe {
            width: 100%;
            height: 100%;
        }
.reports-container .report-viewer .report-viewer-close {
            cursor: pointer;
        }
.default .reports-table-cont .data-table-rows:empty {
                    background-color: #404040;
                }
.default .report-progress-text {
            color: #e26816;
        }
.default .report-error-text {
            color: #ff0000 !important;
        }
.default .report-types {
            border-right: 1px solid #ffffff;
        }
.default .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.default-light .reports-table-cont .data-table-rows:empty {
                    background-color: #919191;
                }
.default-light .report-progress-text {
            color: #e26816;
        }
.default-light .report-error-text {
            color: #ff0000 !important;
        }
.default-light .report-types {
            border-right: 1px solid #ffffff;
        }
.default-light .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.sky .reports-table-cont .data-table-rows:empty {
                    background-color: #0194d3;
                }
.sky .report-progress-text {
            color: #01425e;
        }
.sky .report-error-text {
            color: #ff0000 !important;
        }
.sky .report-types {
            border-right: 1px solid #ffffff;
        }
.sky .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.sky-light .reports-table-cont .data-table-rows:empty {
                    background-color: #0194d3;
                }
.sky-light .report-progress-text {
            color: #01425e;
        }
.sky-light .report-error-text {
            color: #ff0000 !important;
        }
.sky-light .report-types {
            border-right: 1px solid #ffffff;
        }
.sky-light .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.grass .reports-table-cont .data-table-rows:empty {
                    background-color: #325132;
                }
.grass .report-progress-text {
            color: #e26816;
        }
.grass .report-error-text {
            color: #ff0000 !important;
        }
.grass .report-types {
            border-right: 1px solid #ffffff;
        }
.grass .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.grass-light .reports-table-cont .data-table-rows:empty {
                    background-color: #545f54;
                }
.grass-light .report-progress-text {
            color: #e26816;
        }
.grass-light .report-error-text {
            color: #ff0000 !important;
        }
.grass-light .report-types {
            border-right: 1px solid #ffffff;
        }
.grass-light .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.mud .reports-table-cont .data-table-rows:empty {
                    background-color: #766146;
                }
.mud .report-progress-text {
            color: #f39104;
        }
.mud .report-error-text {
            color: #ff0000 !important;
        }
.mud .report-types {
            border-right: 1px solid #ffffff;
        }
.mud .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.mud-light .reports-table-cont .data-table-rows:empty {
                    background-color: #a37b42;
                }
.mud-light .report-progress-text {
            color: #e26816;
        }
.mud-light .report-error-text {
            color: #ff0000 !important;
        }
.mud-light .report-types {
            border-right: 1px solid #ffffff;
        }
.mud-light .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.illini .reports-table-cont .data-table-rows:empty {
                    background-color: #636466;
                }
.illini .report-progress-text {
            color: #f39c11;
        }
.illini .report-error-text {
            color: #ff0000 !important;
        }
.illini .report-types {
            border-right: 1px solid #ffffff;
        }
.illini .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }
.illini-light .reports-table-cont .data-table-rows:empty {
                    background-color: #375d81;
                }
.illini-light .report-progress-text {
            color: #f39c11;
        }
.illini-light .report-error-text {
            color: #ff0000 !important;
        }
.illini-light .report-types {
            border-right: 1px solid #ffffff;
        }
.illini-light .reports-options-container {
            border-bottom: 1px solid #ffffff;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.report-book-creation-container-spacer {
    display: flex;
    justify-content: center
}
.report-book-creation-container-spacer .report-book-creation-container {
        display: flex;
        padding: 0 5px 5px 5px;
        width: 1240px
    }
.report-book-creation-container-spacer .report-book-creation-container .report-book-name-and-active-container {
            display: flex;
            align-items: center
        }
.report-book-creation-container-spacer .report-book-creation-container .report-book-name-and-active-container .name-options-location-container {
                display: flex;
                flex-direction: column;
            }
.report-book-creation-container-spacer .report-book-creation-container .report-book-name-and-active-container .report-book-name {
                width: 395px;
                margin-left: 0;
                margin-right: 20px;
            }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-section {
            display: flex;
            justify-content: space-between;
            position: relative
        }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-section .checkbox-div {
                width: -moz-fit-content;
                width: fit-content;
            }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-section .loader-container {
                position: absolute;
                top: -10px;
            }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-info {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            justify-content: flex-end
        }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-info a {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 255px;
                line-height: 20px;
                padding: 0 5px 5px 0;
                font-size: 13px;
            }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-info button {
                min-width: 38px;
                margin-bottom: 5px
            }
.report-book-creation-container-spacer .report-book-creation-container .cover-page-info button .svg-container {
                    padding-top: 2px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options {
            display: flex;
            flex-direction: column
        }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .options.form-section {
                    width: 405px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .options .form-sub-section {
                    flex-direction: column;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .options .report-book-memo {
                    width: 395px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .location-container {
                display: flex
            }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .location-container .form-section {
                    width: 405px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .location-container .location-selection-container {
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px
                }
.report-book-creation-container-spacer .report-book-creation-container .report-book-options .location-container .location-selection-container .select-form-input-container {
                        width: 395px;
                    }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            margin-top: 10px
        }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .form-section {
                width: 955px
            }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .form-section .data-table-header .data-table-container {
                        max-width: 50px;
                    }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .form-section .data-table-rows {
                    max-height: 650px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .form-section .no-arrow {
                    max-width: 50px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .form-section .grid-col-5 {
                    max-width: 25px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .form-section .grid-col-40 {
                    max-width: 385px;
                }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .reorder-button {
                cursor: pointer
            }
.report-book-creation-container-spacer .report-book-creation-container .report-types-and-configurations-container .reorder-button .arrow-icon {
                    height: 15px;
                    width: 15px;
                }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-type-and-configuration-modal .dialog-box-children {
                    display: flex;
                    flex-direction: row;
                }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-type-and-configuration-modal .dialog-box-footer .default-button {
                        max-width: 155px;
                    }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-book-report-options-modal {
                min-width: 400px
            }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-book-report-options-modal .dialog-box-body {
                    max-height: unset
                }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-book-report-options-modal .dialog-box-body .dialog-box-children .reports-options-container {
                            flex-direction: column;
                            border-bottom: unset
                        }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-book-report-options-modal .dialog-box-body .dialog-box-children .reports-options-container > .text-form-input {
                                margin-left: 15px;
                            }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-book-report-options-modal .dialog-box-body .dialog-box-children .reports-options-container > .text-form-input ~ .text-form-input {
                                margin-left: 10px;
                            }
.report-book-creation-container-spacer .report-book-creation-container .dialog-window .report-book-report-options-modal .dialog-box-body .dialog-box-children .reports-options-container .report-options {
                                overflow-y: unset;
                            }
.default .default-button svg {
        fill: #ffffff;
    }
.default-light .default-button svg {
        fill: #ffffff;
    }
.sky .default-button svg {
        fill: #ffffff;
    }
.sky-light .default-button svg {
        fill: #ffffff;
    }
.grass .default-button svg {
        fill: #ffffff;
    }
.grass-light .default-button svg {
        fill: #ffffff;
    }
.mud .default-button svg {
        fill: #ffffff;
    }
.mud-light .default-button svg {
        fill: #ffffff;
    }
.illini .default-button svg {
        fill: #ffffff;
    }
.illini-light .default-button svg {
        fill: #ffffff;
    }


    .report-page-selection-dialog .dialog-box-body.restricted {
        max-height: 500px !important;
        overflow: auto;
    }
.report-page-selection-dialog .report-page-selection-report {
        padding-bottom: 10px !important
    }
.report-page-selection-dialog .report-page-selection-report .checkbox-div {
            margin: 0 !important;
            width: 100% !important;
        }
.report-page-selection-dialog .report-page-selection-report .report-page-selection-pages {
            padding-left: 30px !important;
        }

.release-notes-editor {
    min-width: 1240px;
    width: 1240px;
}

.quill .ql-container {
    height: 75vh !important;
    min-height: 75vh !important;
}

.report-field-symbology-dialog {
    width: 300px
}
.report-field-symbology-dialog .form-section-header {
        background-color: unset;
        font-size: 14px;
        margin-bottom: 0;
    }
.report-field-symbology-dialog .checkbox-div {
        margin-bottom: 0;
        margin-left: 10px;
        margin-top: 0
    }
.report-field-symbology-dialog .checkbox-div .checkbox-label {
            height: 20px;
        }
.report-field-symbology-dialog .checkbox-div.no-checkbox .checkbox-label:first-of-type {
                margin-left: 0;
            }
.report-field-symbology-dialog .checkbox-label-text-span.caret {
        font-size: 8px;
    }
.report-field-symbology-dialog .color-picker-container .color-selector-wrap {
            min-width: 19px;
            min-height: 19px;
        }
.report-field-symbology-dialog .color-picker-container .color-selector-button {
            min-width: 18px;
            min-height: 18px;
        }
.report-field-symbology-dialog .color-picker-container .default-svg-icon {
            width: 10px;
            height: 10px;
        }
.report-field-symbology-dialog .reset {
        padding: 10px;
        width: 100px;
    }


    .api-result .dialog-container {
        min-height: 75px
    }
.api-result .dialog-container .dialog-box-body {
            min-height: 50px;
            -webkit-user-select: auto;
                -ms-user-select: auto;
                    user-select: auto
        }
.api-result .dialog-container .dialog-box-body .detail-block:not(:last-child) {
                margin-bottom: 2em;
            }
.api-result .dialog-container .dialog-box-body .detail-item-list {
                display: block;
                list-style-type: disc;
                margin: 0.5em 0;
                padding-left: 20px;
            }
.api-result .dialog-container .dialog-box-body .detail-item:not(:last-child) {
                margin-bottom: 0.5em;
            }
.api-result .dialog-container .dialog-box-title {
            padding: 0;
            margin: 0;
        }

.report-report-books-container {
    max-height: 100%;
}

.report-book-error-info-container {
    display: flex;
    flex-direction: column
}

.report-book-error-info-container > :not(:first-child) {
        padding-top: 10px;
        border-top: 1px solid white;
    }

.report-book-error-info-container .error-info-container {
        display: flex;
        flex-direction: column
    }

.report-book-error-info-container .error-info-container .report-book-company-name {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
        }

.report-book-error-info-container .error-info-container .report-type-error-info-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px
        }

.report-book-error-info-container .error-info-container .report-type-error-info-container .report-type-name {
                font-weight: bold;
                margin-bottom: 5px;
            }

.report-book-error-info-container .error-info-container .report-type-error-info-container .error-code-field-config-info-container {
                display: flex;
                flex-direction: column
            }

.report-book-error-info-container .error-info-container .report-type-error-info-container .error-code-field-config-info-container :not(:last-child) {
                    margin-bottom: 2.5px;
                }

.report-book-error-info-container .error-info-container .report-type-error-info-container .error-code-field-config-info-container .error-code {
                    margin-bottom: 5px;
                }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.header {
    min-height: 80px;
    display: flex;
    flex-direction: column
}
.header .build-info {
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        white-space: nowrap;
        font-family: "open_sansregular";
        font-size: 12px;
        font-weight: 500;
    }
.header .logo-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }
.header .logo-row .svg-container {
            margin-top: -10px;
            cursor: pointer;
        }
.header .logo-row .logo {
            width: auto;
            height: auto
        }
.header .logo-row .logo .system-logo {
                width: 337px;
                height: 76px;
                padding-left: 7%;
                padding-top: 0;
                cursor: pointer
            }
.header .logo-row .logo .system-logo svg,
                .header .logo-row .logo .system-logo img {
                    height: 100%;
                    width: auto;
                }
.header .logo-row .nav-user-container {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between
        }
.header .logo-row .nav-user-container .user-menu-container {
                display: block;
                margin: 10px 10px 0 0;
                height: 16px;
                top: 0;
                text-align: right
            }
.header .logo-row .nav-user-container .user-menu-container .user-menu {
                    margin-left: 4px;
                    display: inline-block;
                }
.header .logo-row .nav-user-container .quick-changer-nolink-container {
                text-align: right;
                margin-right: 12px;
                margin-top: 6px;
                font-size: small;
                display: flex;
                justify-content: flex-end;
            }
.header .login-center {
        text-align: center;
    }
.default .header {
        background-color: #000000;
        color: #ffffff
    }
.default .header .quick-changer-nolink {
            color: #ffffff;
        }
.default .header .quick-changer-nolink:link {
            color: #ffffff;
        }
.default .header .quick-changer-nolink:visited {
            color: #ffffff;
        }
.default-light .header {
        background-color: #fcfcfc;
        color: #000000
    }
.default-light .header .quick-changer-nolink {
            color: #000000;
        }
.default-light .header .quick-changer-nolink:link {
            color: #000000;
        }
.default-light .header .quick-changer-nolink:visited {
            color: #000000;
        }
.sky .header {
        background-color: #fcfcfc;
        color: #000000
    }
.sky .header .quick-changer-nolink {
            color: #000000;
        }
.sky .header .quick-changer-nolink:link {
            color: #000000;
        }
.sky .header .quick-changer-nolink:visited {
            color: #000000;
        }
.sky-light .header {
        background-color: #fcfcfc;
        color: #000000
    }
.sky-light .header .quick-changer-nolink {
            color: #000000;
        }
.sky-light .header .quick-changer-nolink:link {
            color: #000000;
        }
.sky-light .header .quick-changer-nolink:visited {
            color: #000000;
        }
.grass .header {
        background-color: #667c66;
        color: #ffffff
    }
.grass .header .quick-changer-nolink {
            color: #ffffff;
        }
.grass .header .quick-changer-nolink:link {
            color: #ffffff;
        }
.grass .header .quick-changer-nolink:visited {
            color: #ffffff;
        }
.grass-light .header {
        background-color: #babfba;
        color: #000000
    }
.grass-light .header .quick-changer-nolink {
            color: #000000;
        }
.grass-light .header .quick-changer-nolink:link {
            color: #000000;
        }
.grass-light .header .quick-changer-nolink:visited {
            color: #000000;
        }
.mud .header {
        background-color: #4b3705;
        color: #ffffff
    }
.mud .header .quick-changer-nolink {
            color: #ffffff;
        }
.mud .header .quick-changer-nolink:link {
            color: #ffffff;
        }
.mud .header .quick-changer-nolink:visited {
            color: #ffffff;
        }
.mud-light .header {
        background-color: #fff0bb;
        color: #000000
    }
.mud-light .header .quick-changer-nolink {
            color: #000000;
        }
.mud-light .header .quick-changer-nolink:link {
            color: #000000;
        }
.mud-light .header .quick-changer-nolink:visited {
            color: #000000;
        }
.illini .header {
        background-color: #00467f;
        color: #ffffff
    }
.illini .header .quick-changer-nolink {
            color: #ffffff;
        }
.illini .header .quick-changer-nolink:link {
            color: #ffffff;
        }
.illini .header .quick-changer-nolink:visited {
            color: #ffffff;
        }
.illini-light .header {
        background-color: #e1e6fa;
        color: #000000
    }
.illini-light .header .quick-changer-nolink {
            color: #000000;
        }
.illini-light .header .quick-changer-nolink:link {
            color: #000000;
        }
.illini-light .header .quick-changer-nolink:visited {
            color: #000000;
        }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 5, 2015 */
@font-face {
    font-family: "open_sansregular";
    src: url(/static/media/opensans-regular.b3b07d78.eot);
    src: url(/static/media/opensans-regular.b3b07d78.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-regular.063538bc.woff2) format("woff2"),
        url(/static/media/opensans-regular.e939ac32.woff) format("woff"),
        url(/static/media/opensans-regular.0849a481.ttf) format("truetype"),
        url(/static/media/opensans-regular.a9ba7f3d.svg#open_sansregular) format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 6, 2015 */
@font-face {
    font-family: "open_sansitalic";
    src: url(/static/media/opensans-italic.c4679faf.eot);
    src: url(/static/media/opensans-italic.c4679faf.eot?#iefix) format("embedded-opentype"),
        url(/static/media/opensans-italic.c8de8e62.woff2) format("woff2"), url(/static/media/opensans-italic.cf9a16bb.woff) format("woff"),
        url(/static/media/opensans-italic.e5b08341.ttf) format("truetype"),
        url(/static/media/opensans-italic.3a2eaccc.svg#open_sansitalic) format("svg");
    font-weight: normal;
    font-style: normal;
}
.root-component {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    font-family: "open_sansregular";
    font-size: 14px;
    font-weight: 300;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none
}
.root-component .nav-component {
        text-align: right;
        padding: 0 10px;
    }
.root-component .main-content-container {
        max-height: calc(100% - 80px);
        height: calc(100% - 80px);
        background-color: transparent;
        flex-grow: 1;
        overflow: visible;
        display: flex;
        flex-direction: row;
        position: relative;
    }
a:focus,
a:hover {
    cursor: pointer;
}
::-ms-clear {
    display: none;
}
::-webkit-scrollbar-button {
    height: 15px;
}
::-webkit-scrollbar {
    width: 16px;
}
::-webkit-scrollbar,
::-webkit-scrollbar-button,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
    box-sizing: border-box;
}
.default {
        color: #ffffff;

        scrollbar-base-color: #000000;
        scrollbar-base-color: #bfbfbf;
        scrollbar-track-color: #000000;
        scrollbar-arrow-color: #bfbfbf;
        scrollbar-shadow-color: #bfbfbf
    }
.default .root-component .main-content-container {
            border-top: 2px solid #bfbfbf;
        }
.default a:focus {
            color: #8fa61a;
        }
.default a:hover {
            color: #8fa61a;
        }
.default ::-webkit-scrollbar-button {
            background-color: #404040;
            border: 1px solid #bfbfbf;
        }
.default ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #bfbfbf;
            background-color: #000000;
        }
.default ::-webkit-scrollbar-track-piece {
            background-color: #000000;
            border-left: 1px solid #bfbfbf;
            border-right: 1px solid #bfbfbf;
        }
.default ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #bfbfbf;
        }
.default .disabled {
            color: #777777 !important;
        }
.default-light {
        color: #000000;

        scrollbar-base-color: #fcfcfc;
        scrollbar-base-color: #cfcfcf;
        scrollbar-track-color: #fcfcfc;
        scrollbar-arrow-color: #cfcfcf;
        scrollbar-shadow-color: #cfcfcf
    }
.default-light .root-component .main-content-container {
            border-top: 2px solid #cfcfcf;
        }
.default-light a:focus {
            color: #8fa61a;
        }
.default-light a:hover {
            color: #8fa61a;
        }
.default-light ::-webkit-scrollbar-button {
            background-color: #919191;
            border: 1px solid #cfcfcf;
        }
.default-light ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #cfcfcf;
            background-color: #fcfcfc;
        }
.default-light ::-webkit-scrollbar-track-piece {
            background-color: #fcfcfc;
            border-left: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
        }
.default-light ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #cfcfcf;
        }
.default-light .disabled {
            color: #777777 !important;
        }
.sky {
        color: #000000;

        scrollbar-base-color: #fcfcfc;
        scrollbar-base-color: #c8c8c8;
        scrollbar-track-color: #fcfcfc;
        scrollbar-arrow-color: #c8c8c8;
        scrollbar-shadow-color: #c8c8c8
    }
.sky .root-component .main-content-container {
            border-top: 2px solid #c8c8c8;
        }
.sky a:focus {
            color: #235635;
        }
.sky a:hover {
            color: #235635;
        }
.sky ::-webkit-scrollbar-button {
            background-color: #0194d3;
            border: 1px solid #c8c8c8;
        }
.sky ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #c8c8c8;
            background-color: #fcfcfc;
        }
.sky ::-webkit-scrollbar-track-piece {
            background-color: #fcfcfc;
            border-left: 1px solid #c8c8c8;
            border-right: 1px solid #c8c8c8;
        }
.sky ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #c8c8c8;
        }
.sky .disabled {
            color: #878787 !important;
        }
.sky-light {
        color: #000000;

        scrollbar-base-color: #fcfcfc;
        scrollbar-base-color: #c8c8c8;
        scrollbar-track-color: #fcfcfc;
        scrollbar-arrow-color: #c8c8c8;
        scrollbar-shadow-color: #c8c8c8
    }
.sky-light .root-component .main-content-container {
            border-top: 2px solid #c8c8c8;
        }
.sky-light a:focus {
            color: #235635;
        }
.sky-light a:hover {
            color: #235635;
        }
.sky-light ::-webkit-scrollbar-button {
            background-color: #0194d3;
            border: 1px solid #c8c8c8;
        }
.sky-light ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #c8c8c8;
            background-color: #fcfcfc;
        }
.sky-light ::-webkit-scrollbar-track-piece {
            background-color: #fcfcfc;
            border-left: 1px solid #c8c8c8;
            border-right: 1px solid #c8c8c8;
        }
.sky-light ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #c8c8c8;
        }
.sky-light .disabled {
            color: #878787 !important;
        }
.grass {
        color: #ffffff;

        scrollbar-base-color: #667c66;
        scrollbar-base-color: #9cadb2;
        scrollbar-track-color: #667c66;
        scrollbar-arrow-color: #9cadb2;
        scrollbar-shadow-color: #9cadb2
    }
.grass .root-component .main-content-container {
            border-top: 2px solid #9cadb2;
        }
.grass a:focus {
            color: #ffff00;
        }
.grass a:hover {
            color: #ffff00;
        }
.grass ::-webkit-scrollbar-button {
            background-color: #325132;
            border: 1px solid #9cadb2;
        }
.grass ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #9cadb2;
            background-color: #667c66;
        }
.grass ::-webkit-scrollbar-track-piece {
            background-color: #667c66;
            border-left: 1px solid #9cadb2;
            border-right: 1px solid #9cadb2;
        }
.grass ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(66, 68, 67, 0.2);
            background-color: #9cadb2;
        }
.grass .disabled {
            color: #e5e9e5 !important;
        }
.grass-light {
        color: #000000;

        scrollbar-base-color: #babfba;
        scrollbar-base-color: #424443;
        scrollbar-track-color: #babfba;
        scrollbar-arrow-color: #424443;
        scrollbar-shadow-color: #424443
    }
.grass-light .root-component .main-content-container {
            border-top: 2px solid #424443;
        }
.grass-light a:focus {
            color: #ffff00;
        }
.grass-light a:hover {
            color: #ffff00;
        }
.grass-light ::-webkit-scrollbar-button {
            background-color: #545f54;
            border: 1px solid #424443;
        }
.grass-light ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #424443;
            background-color: #babfba;
        }
.grass-light ::-webkit-scrollbar-track-piece {
            background-color: #babfba;
            border-left: 1px solid #424443;
            border-right: 1px solid #424443;
        }
.grass-light ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(156, 173, 178, 0.2);
            background-color: #424443;
        }
.grass-light .disabled {
            color: #e5e9e5 !important;
        }
.mud {
        color: #ffffff;

        scrollbar-base-color: #4b3705;
        scrollbar-base-color: #f2f3f8;
        scrollbar-track-color: #4b3705;
        scrollbar-arrow-color: #f2f3f8;
        scrollbar-shadow-color: #f2f3f8
    }
.mud .root-component .main-content-container {
            border-top: 2px solid #f2f3f8;
        }
.mud a:focus {
            color: #ffff00;
        }
.mud a:hover {
            color: #ffff00;
        }
.mud ::-webkit-scrollbar-button {
            background-color: #766146;
            border: 1px solid #f2f3f8;
        }
.mud ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #f2f3f8;
            background-color: #4b3705;
        }
.mud ::-webkit-scrollbar-track-piece {
            background-color: #4b3705;
            border-left: 1px solid #f2f3f8;
            border-right: 1px solid #f2f3f8;
        }
.mud ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(140, 116, 84, 0.2);
            background-color: #f2f3f8;
        }
.mud .disabled {
            color: #777777 !important;
        }
.mud-light {
        color: #000000;

        scrollbar-base-color: #fff0bb;
        scrollbar-base-color: #d6c282;
        scrollbar-track-color: #fff0bb;
        scrollbar-arrow-color: #d6c282;
        scrollbar-shadow-color: #d6c282
    }
.mud-light .root-component .main-content-container {
            border-top: 2px solid #d6c282;
        }
.mud-light a:focus {
            color: #8fa61a;
        }
.mud-light a:hover {
            color: #8fa61a;
        }
.mud-light ::-webkit-scrollbar-button {
            background-color: #a37b42;
            border: 1px solid #d6c282;
        }
.mud-light ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #d6c282;
            background-color: #fff0bb;
        }
.mud-light ::-webkit-scrollbar-track-piece {
            background-color: #fff0bb;
            border-left: 1px solid #d6c282;
            border-right: 1px solid #d6c282;
        }
.mud-light ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(178, 143, 90, 0.2);
            background-color: #d6c282;
        }
.mud-light .disabled {
            color: #777777 !important;
        }
.illini {
        color: #ffffff;

        scrollbar-base-color: #00467f;
        scrollbar-base-color: #f2f3f8;
        scrollbar-track-color: #00467f;
        scrollbar-arrow-color: #f2f3f8;
        scrollbar-shadow-color: #f2f3f8
    }
.illini .root-component .main-content-container {
            border-top: 2px solid #f2f3f8;
        }
.illini a:focus {
            color: #ffff00;
        }
.illini a:hover {
            color: #ffff00;
        }
.illini ::-webkit-scrollbar-button {
            background-color: #636466;
            border: 1px solid #f2f3f8;
        }
.illini ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #f2f3f8;
            background-color: #00467f;
        }
.illini ::-webkit-scrollbar-track-piece {
            background-color: #00467f;
            border-left: 1px solid #f2f3f8;
            border-right: 1px solid #f2f3f8;
        }
.illini ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(171, 200, 226, 0.2);
            background-color: #f2f3f8;
        }
.illini .disabled {
            color: #777777 !important;
        }
.illini-light {
        color: #000000;

        scrollbar-base-color: #e1e6fa;
        scrollbar-base-color: #abc8e2;
        scrollbar-track-color: #e1e6fa;
        scrollbar-arrow-color: #abc8e2;
        scrollbar-shadow-color: #abc8e2
    }
.illini-light .root-component .main-content-container {
            border-top: 2px solid #abc8e2;
        }
.illini-light a:focus {
            color: #ffff00;
        }
.illini-light a:hover {
            color: #ffff00;
        }
.illini-light ::-webkit-scrollbar-button {
            background-color: #375d81;
            border: 1px solid #abc8e2;
        }
.illini-light ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px #abc8e2;
            background-color: #e1e6fa;
        }
.illini-light ::-webkit-scrollbar-track-piece {
            background-color: #e1e6fa;
            border-left: 1px solid #abc8e2;
            border-right: 1px solid #abc8e2;
        }
.illini-light ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(196, 215, 237, 0.2);
            background-color: #abc8e2;
        }
.illini-light .disabled {
            color: #777777 !important;
        }

